import React from "react";
import { Container, Wrapper, Left, Image, Right, Content } from "./styles";
import { useNavigate } from "react-router-dom";
import logo_blue from "../../../assets/logo/logo_blue.svg";
import logo_coin from "../../../assets/logo/logo_coin.svg";
import { useGlobalStore } from "../../../../infra/state/GlobalStore";
import { Menuburguer } from "./components/MenuBurguer";

interface Props {
	openModal: boolean;
	width?: string;
}

const Navbar: React.FC<Props> = ({ openModal, width = "100%" }) => {

	const users = useGlobalStore();
	const navigate = useNavigate();
	const formatWalletBalance = (balance: number | undefined) => {
		if (typeof balance === "undefined") {
			return "";
		}
		const formattedBalance = Math.floor(balance).toString().slice(0, -2);
		return formattedBalance;
	};


	return (
		<Container openModal={openModal} width={width}>
			<Wrapper>
				<Left>
					<Image onClick={() => navigate('/home')} src={logo_blue} alt="logo" />
				</Left>
				<Right>
					<img src={logo_coin} style={{ padding: "0" }} alt="Gooders coins icon" />
					<Content>
						<span data-test="wallet.balance">
							{users.state.user?.wallet.balance !== undefined && users.state.user?.wallet.balance < 1 ? 0
								: formatWalletBalance(users.state.user?.wallet.balance)}
						</span>
						<p>Gooders Coins</p>
					</Content>
				</Right>
				<Menuburguer />
			</Wrapper>
		</Container>
	);
};

export default Navbar;