import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Box = styled.main`
  background: ${(props) => props.theme.colors.backgroundGray};
  color: ${(props) => props.theme.colors.primary};
  padding: 70px 40px 150px;
  @media (max-width: ${theme.media.laptop}) {
    padding: 70px 20px 150px;
  }
`;

const Container__Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
  }
`;

const Container__Title = styled.h1`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.x};
`;


const Container__Button = styled.div`
  display: flex;
  gap: 16px;
  width: 60%;
  @media (max-width: ${theme.media.laptop}) {
    width: 100%;
  }
  @media (max-width: ${theme.media.mobileSmall}) {
    flex-direction: column;
  }
`;

const Container__Main = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.colors.background};
  height: auto;
  width: 100%;
  padding: 16px;
`;

const Container__Support = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container__Choice = styled.div`
  display: flex;
  gap: 24px;
  padding-top: 16px;
`;


const Container__Wrapper = styled.div`
  position: relative;
`;

const Container__Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container__SubTitle = styled.h2`
  padding: 16px 0 0;
  display: flex;
  align-items: flex-end;
`;

const Container__Avatar = styled.div`
  position: absolute;
  right: 10px;
  margin-top: -220px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightgray};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    border-radius: 50%;
  }
  @media (max-width: ${theme.media.laptop}) {
    margin-top: -40px;
    padding: 4px;
    img {
      width: 44px !important;
    }
  }
`;

const Container__Input = styled.div`
  padding: 16px 0;
  display: flex;
  gap: 8px;
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
  }
`;

const Container__Inputs = styled.div`
  width: 100%;
`;

const Container__Table = styled.div`
  padding: 8px 0;
`;

const Container__Tables = styled.div`
    @media (max-width: ${theme.media.mobile}) {
  display: none;
}
`;

const Container__Mobile = styled.div`
    display: none;
  width: 100%;
@media (max-width: ${theme.media.mobile}) {
  display: initial;
}
`;

const Container__Form = styled.form`
  padding: 8px 0;
`;

export const Container = {
  support: Container__Support,
  choice: Container__Choice,
  box: Container__Box,
  title: Container__Title,
  input: Container__Input,
  avatar: Container__Avatar,
  table: Container__Table,
  tables: Container__Tables,
  mobile: Container__Mobile,
  inputs: Container__Inputs,
  header: Container__Header,
  main: Container__Main,
  button: Container__Button,
  wrapper: Container__Wrapper,
  head: Container__Head,
  subTitle: Container__SubTitle,
  form: Container__Form
};

const Text__SubTitle = styled.div<{ active?: boolean }>`
  font-weight: ${(props) => props.active && '700'};
  font-size: ${(props) => props.theme.fontSize.x};
  border-bottom: ${(props) => props.active && '1px solid #FBB22F'};
`;

export const Text = {
  subTitle: Text__SubTitle,
};
