import { Content } from './styles';
import person from '../../../../../../../assets/icons/person.svg';
import { LabelInput } from '../../../../../../../components/common/Input';
import { useForm } from '../../../context/formContext';

export const FormAvatar = () => {
  const { formik } = useForm();

  return (
    <Content.acess>
      <Content.form>
        <LabelInput
          htmlFor="username"
          src1={person}
          id="username"
          name="username"
          type="text"
          placeholder="Digite um usuário"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          aria-labelledby="username"
          error={formik.handleRenderError('username')}
        >
          Username
        </LabelInput>
      </Content.form>
    </Content.acess>
  );
};
