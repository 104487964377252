import styled from "styled-components";
import { theme } from "../../../themes/blue"

const Container__Box = styled.main`
  background: ${(props) => props.theme.colors.backgroundGray};
  color: ${(props) => props.theme.colors.primary};
  padding: 70px 40px 15px;
  @media (max-width: ${theme.media.laptop}) {
    padding: 70px 20px 150px;
  }
`;

const Container__Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0 16px;
  @media (max-width: ${theme.media.mobileSmall}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
  }
`;

const Container__Title = styled.h1`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.x};
  line-height: 24px;
`;

const Container__Button = styled.div`
  display: flex;
  gap: 16px;
  width: 50%;
  @media (max-width: ${theme.media.laptop}) {
    width: 100%;
  }
  @media (max-width: ${theme.media.mobileSmall}) {
    flex-direction: column;
  }
`;


const Container__Support = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

const Container__Choice = styled.div`
	display: flex;
	gap: 24px;
	padding: 16px 0 0 16px;
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
  }
`;


const Container__SubTitle = styled.p<{ active?: boolean }>`
font-weight: ${props => props.active && "700"};;
font-size: ${props => props.theme.fontSize.x};
border-bottom: ${props => props.active && "1px solid #FBB22F"};
@media (max-width: ${theme.media.mobileSmall}) {
  font-size: ${props => props.theme.fontSize.md};
}
`;


export const Container = {
  box: Container__Box,
  title: Container__Title,
  header: Container__Header,
  support: Container__Support,
	choice: Container__Choice,
  subTitle: Container__SubTitle,
  button: Container__Button
};



