
import { Container } from './styles';
import { useState } from 'react';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { SmallBlueButton } from '../../../components/common/Button';
import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { TableListPartner } from './components/TableListPartner';
import { CreateRewards } from '../gerenciar-recompensas/components/CreateRewards';
import { TableListInstitutions } from './components/TableListInstitutions';
import { TableListOrgs } from './components/TableListOrgs';
import { TableListGooders } from './components/TableListGooders';

enum ActiveTabEnum {
    'PARTNER',
    'INSTITUTION',
    'ORG',
    'GOODERS'
}

export const GerenciarUser = () => {
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const user = useGlobalStore()
    const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.PARTNER);

    return (
        <>
            <LayoutSidebar>
                <Container.box>
                    <Container.header>
                        <Container.title data-test="title-campaign">Usuários</Container.title>
                        {user.state.acl.selectedACL?.type === UserTypeEnum.GOODERS ? (
                            <Container.button>
                                <SmallBlueButton onClick={() => navigate('/createuser')} loading={false}>
                                    Criar Parceiro
                                </SmallBlueButton>
                                <SmallBlueButton onClick={() => navigate('/createuser?type=org')} loading={false}>
                                    Criar Org
                                </SmallBlueButton>
                                <SmallBlueButton onClick={() => navigate('/createuser?type=institution')} loading={false}>
                                    Criar Instituição
                                </SmallBlueButton>
                            </Container.button>
                        ) : null}
                    </Container.header>
                    <Container.support>
                        <Container.choice>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.PARTNER)}>
                                <Container.subTitle active={activeTab === ActiveTabEnum.PARTNER}>
                                    Parceiros
                                </Container.subTitle>
                            </Link>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.INSTITUTION)}>
                                <Container.subTitle active={activeTab === ActiveTabEnum.INSTITUTION}>
                                    Instituições
                                </Container.subTitle>
                            </Link>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.ORG)}>
                                <Container.subTitle active={activeTab === ActiveTabEnum.ORG}>
                                    Organizações
                                </Container.subTitle>
                            </Link>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.GOODERS)}>
                                <Container.subTitle active={activeTab === ActiveTabEnum.GOODERS}>
                                    Gooders
                                </Container.subTitle>
                            </Link>
                        </Container.choice>
                    </Container.support>
                    <Container.support>
                        {activeTab === ActiveTabEnum.PARTNER ?
                            <TableListPartner /> : null}
                        {activeTab === ActiveTabEnum.INSTITUTION ?
                            <TableListInstitutions /> : null}
                        {activeTab === ActiveTabEnum.ORG ?
                            <TableListOrgs /> : null}
                        {activeTab === ActiveTabEnum.GOODERS ?
                            <TableListGooders /> : null}
                    </Container.support>
                </Container.box>
            </LayoutSidebar>
            <CreateRewards open={openModal} onClose={() => setOpenModal(false)} />
        </>
    );
};
