import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';


const Container__Main = styled.div`
display: none;
ul {
    list-style: none;
}
@media (max-width: ${theme.media.mobile}) {
  display: initial;
}
`;

const Container__BurgerIcon = styled.img`
  display: none;
@media (max-width: ${theme.media.mobile}) {
  display: initial;
  width: 24px;
}`;


const Container__MenuMobile = styled.div`
  display: flex;
  width: 100%;

  &.Mobile {
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0;
    background: ${(props) => props.theme.colors.white};
    justify-content: center;
    padding-left: 24px;
    height: calc(100vh - 150px);
  }
  nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    
  }
  ul {
    display: flex;
    flex-direction: column;
    :last-child {
      align-items: center;
      width: 100%;
      padding-top: 65%;
    }
  }
  li {
    padding-top: 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    
  }
  .logout {
    padding-left: 8px;
  }
  .line-bottom {
      margin-left: -30px;
    }
@media (max-width: ${theme.media.mobile}) {
  display: initial;
}`;




export const Container = {
  main: Container__Main,
  burgerIcon: Container__BurgerIcon,
  menuMobile: Container__MenuMobile,
};

const Nav__Text = styled.div`
  padding-left: 12px;
  font-size: ${(props) => props.theme.fontSize.sm};
  letter-spacing: 0.02em;
  color:  ${(props) => props.theme.colors.primary};
  font-weight: 600;
`;

const Nav__Img = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-left: -30px;
  img {
    min-width: 150px;
  }
`;

export const Nav = {
  text: Nav__Text,
  img: Nav__Img,
};

export const Lines = styled.div`
  width: 90%;
  border-bottom: 2px solid ${(props) => props.theme.colors.paleGray};
`;
