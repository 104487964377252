import { useState } from "react";
import { Container, Text } from './styles';
import { Link, useNavigate } from "react-router-dom";
import Member from "./components/Member";
import { SmallBlueButton } from "../../../components/common/Button";
import { LayoutSidebar } from "../../../components/containers/LayoutSidebar";
import { useGlobalStore } from "../../../../infra/state/GlobalStore";
import { UserTypeEnum } from "../../../../domain/interfaces/User";
import { EditData } from "./components/EditData";

enum ActiveTabEnum {
    'EDITPROFILE',
    'MEMBER',
}


export const Profile: React.FC = () => {
    const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.EDITPROFILE);
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const user = useGlobalStore()

    return (
        <LayoutSidebar>
            <Container.box>
                <Container.header>
                    <Container.title data-test="title-Profile">Perfil</Container.title>
                    {user.state.acl.selectedACL?.type === UserTypeEnum.GOODERS ? (
                        <Container.button>
                            <SmallBlueButton onClick={() => navigate('/createuser')} loading={false}>
                                Criar Parceiro
                            </SmallBlueButton>
                            <SmallBlueButton onClick={() => navigate('/createuser?type=org')} loading={false}>
                                Criar Org
                            </SmallBlueButton>
                            <SmallBlueButton onClick={() => navigate('/createuser?type=institution')} loading={false}>
                                Criar Instituição
                            </SmallBlueButton>
                        </Container.button>
                    ) : null}
                    {user.state.acl.selectedACL?.type === UserTypeEnum.ORG ? (
                        <Container.button style={{ maxWidth: "300px" }}>
                            <SmallBlueButton onClick={() => navigate('/createuser?type=institution')} loading={false}>
                                Criar Instituição
                            </SmallBlueButton>
                        </Container.button>
                    ) : null}
                </Container.header>
                <Container.main>

                    <Container.support>
                        <Container.choice>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.EDITPROFILE)}>
                                <Text.subTitle active={activeTab === ActiveTabEnum.EDITPROFILE}>
                                    Editar Perfil
                                </Text.subTitle>
                            </Link>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.MEMBER)}>
                                <Text.subTitle active={activeTab === ActiveTabEnum.MEMBER}>
                                    Instituições | Organizações
                                </Text.subTitle>
                            </Link>
                        </Container.choice>
                    </Container.support>
                    <Container.support>
                        {activeTab === ActiveTabEnum.EDITPROFILE ? (
                            <EditData />
                        ) : (
                            <Member />
                        )}
                    </Container.support>
                </Container.main>
            </Container.box>
        </LayoutSidebar>
    );
};