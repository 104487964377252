import styled from 'styled-components';
import { theme } from '../../../../../../../themes/blue';

const Content__Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content__Acess = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${theme.media.mobileSmall}) {
    flex-wrap: wrap;
  }
`;

export const Content = {
  form: Content__Form,
  acess: Content__Acess,
};
