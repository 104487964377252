import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';
import { SharedUseCase } from '../item/CreateItemForCampaignUseCase';

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
}

export class CreateImageForCampaignUseCase extends SharedUseCase<
  Params,
  FormData,
  {
    payload: unknown;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execInstitution() {
    return await HttpClient(
      'post',
      `/campaigns/institution/${this.params!.institutionId}/${this.params!.campaignId}/images`,
      this.dto!,
      true
    );
  }

  protected override async execOrg() {
    return await HttpClient(
      'post',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}/${
        this.params!.campaignId
      }/images`,
      this.dto!,
      true
    );
  }

  protected override async execGooders() {
    return await HttpClient(
      'put',
      `/campaigns/gooders/${this.params!.campaignId}/images`,
      this.dto!,
      true
    );
  }
}