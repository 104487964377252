import { UserTypeEnum } from '../../../domain/interfaces/User';
import { HttpClient } from '../../HttpClient';
import { SharedUseCase } from '../campaign/item/CreateItemForCampaignUseCase';

export interface GetReport{
  amount: number,
  nameOfUser: string,
  isPerson: boolean,
  campaign: string,
  anonymous: boolean,
  createdAt: string,
  orderStatus: string
  orderId: number,
  closedAt: string,
  gatewayId: number
}


interface Params {
  orgId?: number;
  institutionId?: number;
}


export class GetReportUseCase extends SharedUseCase<
  Params,
  GetReport,
  {
    payload: { data: GetReport[];} | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execOrg(){
    return await HttpClient<void, { data:  GetReport[] }>(
      'get',
      `/orders/reports/payments/orgs/${this.params!.orgId}`
    );
  }

  protected override async execInstitution(){
    return await HttpClient<void, { data:  GetReport[] }>(
      'get',
      `/orders/reports/payments/institutions/${this.params!.institutionId}`
    );
  }

}



