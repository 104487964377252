import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Support = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container__Choice = styled.div`
  display: flex;
  gap: 24px;
  padding-top: 16px;
`;

const Container__Wrapper = styled.form`

`;

const  Container__Content = styled.div`
display: flex;
width: 100%;
gap: 16px;
padding-bottom: 16px;
 @media (max-width: ${theme.media.laptop}) {
   flex-direction: column;
  }`;



const Container__Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container__SubTitle = styled.h2`
  padding: 16px 0 0;
  display: flex;
  align-items: flex-end;
`;

const Container__Avatar = styled.div`
  right: 10px;
  margin-top: -40px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightgray};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.media.laptop}) {
    margin-top: 0px;
    padding: 4px;
  }
`;

const Container__Input = styled.div`
  padding: 16px 0;
  display: flex;
  gap: 8px;
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
  }
`;

const Container__Card = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:${props => props.theme.colors.lightgray};
  border-radius: 8px;
  min-width: 250px;
  width: 250px;
  color: ${props => props.theme.colors.dark};
  &.hidden {
  display: none;
  }
`;

const Container__Image = styled.div`
  width: 100%;
  height: 116px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.white};
  display: flex;
  position: relative;
  justify-content: center;
	
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
		width: 100%;
  	height: 100%;
    p {
    position: absolute;
    bottom: 10px;
    }
		.selectedImage {
			object-fit: cover;
			width: 100%;
  	height: 100%;
			border-radius: 8px;
		}
		.deletImage {
			width: 12px;
    position: absolute;
    right: -15px;
    bottom: 0px;
		}
  }
  `;

const Container__Box = styled.div`
  width: 100%;
  display: flex;
  padding: 21px 0px 14px;
  justify-content: space-between;
  font-weight: 500;
  line-height: 24px;
  span {
    font-weight: 700;
  }
  `;
  
const Container__Gooders = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  color: ${props => props.theme.colors.primary};
  font-weight: 700;
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 18px;
  img {
    width: 24px;
    height: 24px;
    padding-right: 8px;
  }
`;

const Container_Perforated = styled.div`
  margin: 22px 0;
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  position: relative;
  left: -24px;
  transition: all 0.3s ease-in-out;
  div {
  min-width: 12px;
  max-width: 12px;
  max-height: 12px;
  min-height: 12px;
  background: #fff;
  border-radius: 50%;
  &:first-child {
  min-width: 18px;
  max-width: 18px;
  max-height: 18px;
  min-height: 18px;
  }
  &:last-child {
  min-width: 18px;
  max-width: 18px;
  max-height: 18px;
  min-height: 18px;
  }
  }
`;

const Container__Form = styled.div`
flex: 1;
`;

export const Container = {
  support: Container__Support,
  choice: Container__Choice,
  input: Container__Input,
  avatar: Container__Avatar,
  wrapper: Container__Wrapper,
  head: Container__Head,
  subTitle: Container__SubTitle,
  card: Container__Card,
	box: Container__Box,
	image: Container__Image,
	gooders: Container__Gooders,
	perforated: Container_Perforated,
  form: Container__Form, 
  content: Container__Content
};

const Text__Description = styled.div`
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 18px;
  color: ${props => props.theme.colors.gray};
`;

const Text__SubTitle = styled.h2<{ active?: boolean }>`
  font-weight: ${(props) => props.active && '700'};
  font-size: ${(props) => props.theme.fontSize.x};
  border-bottom: ${(props) => props.active && '1px solid #FBB22F'};
`;

export const Text = {
  subTitle: Text__SubTitle,
  description: Text__Description,
};
