import { Container } from './styles';

interface PropsImage {
  imageSrc: string;
  altText: string;
}

export const HeaderImg = ({ imageSrc, altText }: PropsImage) => {
  return (
    <Container.img>
      <img src={imageSrc} alt={altText} />
    </Container.img>
  );
};
