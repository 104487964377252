import { HttpClient } from "../../HttpClient";

export interface ListPartner {
  id: number,
  contact: string,
  name: string,
  logo: string,
  cnpj: string,
  expiresAt: string,
  code: string,
  quantity: number
}

  export async function ListPatnerUseCase() {
    const response = await HttpClient<void, { data: ListPartner[] }>(
      'get', 
      `/api/rewards/partners`);
    return response;
  }

  

