import styled from "styled-components";


const Container__Text = styled.main`
  background: #F5F5F5;
  color: #467DC0;
  padding: 25px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 200px; 
  overflow-y: auto; 
  h3 {
    font-weight: 600;
  }
  p {
    font-size: 14px;
    color: ${props => props.theme.colors.black};
  }
`;
const Container__Line = styled.div`
border-bottom: 2px solid ${props => props.theme.colors.primary};
`;


export const Container = {
	text: Container__Text,
  line: Container__Line,
}
