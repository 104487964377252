import Table from '../../../../../components/common/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { Container } from '../TableListPartner/styles';
import { GridColDef } from '@mui/x-data-grid';
import update from '../../../../../assets/icons/update.svg';
import delet from '../../../../../assets/icons/delet.svg';
import createUser from '../../../../../assets/icons/createUser.svg';
import seeUsers from '../../../../../assets/icons/seeUsers.svg';
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { SearchTableListUser } from '../TableListPartner/components/SearchTableListUser';
import { ModalEditInstitution } from './components/ModalEditInstitutions';
import {
  Institution,
  ListInstitutionsUseCase,
} from '../../../../../../application/useCases/gooders/ListInstitutionsUseCase';
import { DeleteInstituitonsUseCase } from '../../../../../../application/useCases/gooders/DeleteInstituitonsUseCase';
import { ModalConnectUser } from './components/ModalConnectUser';
import { ModalListConnectUser } from './components/ModalListConnectUser';
import { UserTypeEnum } from '../../../../../../domain/interfaces/User';
import { ConnectUserInstUseCase } from '../../../../../../application/useCases/gooders/ConnectUserInstUseCase';
import { ListConnectUserInstUseCase } from '../../../../../../application/useCases/gooders/ListConnectUserInstUseCase';
import { DeleteConnectionUserInstituitonUseCase } from '../../../../../../application/useCases/gooders/DeleteConnectionUserInstituitonUseCase';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';

export const TableListInstitutions = () => {
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [institution, setInstitution] = useState<Institution | null>(null);
  const [search, setSearch] = useState('');
  const clearFilters = () => {
    setSearch('');
  };
  const [modalController, setModalController] = useState({
    edit: false,
    deleteAlert: false,
    createNewMember: false,
    listMembers: false,
  });
  const { setAlert } = useAlert();

  function setNewInstitutionInStateUsingInstitutionId(institutionId: number) {
    console.log(institutionId);
    const institution = institutions.find((i) => i.id === institutionId);

    if (!institution) setInstitution(null);
    else setInstitution(institution);
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await ListInstitutionsUseCase();
      if (!error && payload) {
        setInstitutions(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  const columns: GridColDef<{ id: number }>[] = [
    {
      field: 'name',
      headerName: 'Instituição',
      minWidth: 216,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'document',
      headerName: 'Documento',
      minWidth: 200,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'telephone',
      headerName: 'Telefone',
      minWidth: 100.3,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'Usuários',
      headerName: 'Usuários',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'table-header',
      renderCell: (params) => {
        return (
          <>
            <img
              onClick={() => {
                setNewInstitutionInStateUsingInstitutionId(params.row.id);
                setModalController((state) => ({ ...state, createNewMember: true }));
              }}
              src={createUser}
              alt="action"
              title="Associar Usuário"
              style={{ cursor: 'pointer', paddingRight: '16px' }}
            />
            <img
              onClick={() => {
                setNewInstitutionInStateUsingInstitutionId(params.row.id);
                setModalController((state) => ({ ...state, listMembers: true }));
              }}
              src={seeUsers}
              alt="action"
              title="Listar Usuário"
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'table-header',
      renderCell: (params) => {
        return (
          <>
            <img
              onClick={() => {
                setNewInstitutionInStateUsingInstitutionId(params.row.id);
                setModalController((state) => ({ ...state, edit: true }));
              }}
              src={update}
              alt="action"
              style={{ cursor: 'pointer', paddingRight: '16px' }}
            />
            <img
              onClick={() => {
                handleConfirmModal();
                setNewInstitutionInStateUsingInstitutionId(params.row.id);
              }}
              src={delet}
              alt="action"
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      },
      sortable: false,
      filterable: false,
    },
  ];

  const rows = useMemo(() => {
    return institutions.map((inst, index) => ({
      index: index,
      id: inst.id,
      name: inst.name,
      document: inst.document,
      telephone: inst.telephone,
    }));
  }, [institutions]);

  const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredRows = useMemo(() => {
    if (search === '') return rows;

    const searchTermLoweCase = search.toLowerCase();

    return rows.filter((row) => {
      const name = row['name'].toLowerCase();
      const document = row['document'].toLowerCase();
      return name.includes(searchTermLoweCase) || document.includes(searchTermLoweCase);
    });
  }, [search, rows]);

  function handleConfirmModal() {
    setModalController((state) => ({ ...state, deleteAlert: true }));
  }

  async function handleCloseConfirmModal(result: boolean) {
    setModalController((state) => ({ ...state, deleteAlert: false }));

    if (!result) return;

    const { error } = await DeleteInstituitonsUseCase(institution!.id);

    if (error) {
      setAlert(error, 'error');
    } else {
      setAlert('Instituição deletado com sucesso!', 'success');
    }
  }

  function IconEdit(id: number) {
    setModalController((state) => ({ ...state, edit: true }));
    setNewInstitutionInStateUsingInstitutionId(id);
    setInstitution(id as any);
  }

  function IconDelete(id: number) {
    handleConfirmModal();
    setNewInstitutionInStateUsingInstitutionId(id);
  }

  function IconList(id: number) {
    setNewInstitutionInStateUsingInstitutionId(id);
    setModalController((state) => ({ ...state, listMembers: true }));
  }

  function IconCreateNewMember(id: number) {
    setNewInstitutionInStateUsingInstitutionId(id);
    setModalController((state) => ({ ...state, createNewMember: true }));
  }

  const cardData: CardData[] = filteredRows.map((institution) => ({
    id: institution.id,
    title: ['Name', 'Document', 'Telephone'],
    value: [institution.name, institution.document, institution.telephone],
  }));

  return (
    <Container.main>
      <Container.filter>
        <SearchTableListUser search={search} searchText={searchText} clearFilters={clearFilters} />
      </Container.filter>
      <Container.table>
        <Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
      </Container.table>
      <Container.mobile>
        <CardsTable
          cardData={cardData}
          IconEdit={IconEdit}
          IconDelete={IconDelete}
          IconList={IconList}
          IconConnect={IconCreateNewMember}
        />
      </Container.mobile>

      {institution && (
        <>
          <dialog open={modalController.createNewMember}>
            <ModalConnectUser
              onClose={() => setModalController((state) => ({ ...state, createNewMember: false }))}
              useCase={new ConnectUserInstUseCase(UserTypeEnum.INSTITUTION)}
              rowId={institution.id}
            />
          </dialog>

          <dialog open={modalController.listMembers}>
            <ModalListConnectUser
              onClose={() => setModalController((state) => ({ ...state, listMembers: false }))}
              useCase={new ListConnectUserInstUseCase(UserTypeEnum.INSTITUTION)}
              useCase2={new DeleteConnectionUserInstituitonUseCase(UserTypeEnum.INSTITUTION)}
              Id={institution.id}
            />
          </dialog>

          <dialog open={modalController.edit}>
            <ModalEditInstitution
              onClose={() => setModalController((state) => ({ ...state, edit: false }))}
              institution={institution}
            />
          </dialog>

          <dialog open={modalController.deleteAlert}>
            <ModalConfirm
              title="Excluir Instituição"
              content="Deseja continuar? Ao clicar em deletar sua instituição será excluída permanentemente!"
              rightButtonText="Deletar"
              onClose={handleCloseConfirmModal}
            />
          </dialog>
        </>
      )}
    </Container.main>
  );
};
