import styled from "styled-components";

export interface _Props {
  width?: number
  active?: boolean;
}

export const _NavIconLink = styled.div<_Props>`
	width: ${props => props.width ? props.width + "rem" : "30px"};
  padding: 6px;
  background: ${props => props.active ? "#345B7E" : "white"};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
	img {
		width: 100%;
	}
`;