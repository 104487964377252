import styled from 'styled-components';
import { theme } from '../../../../../../themes/blue';

const Container__Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
`;

const Container__Input = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Container__Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Inputs = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  gap: 16px;
  h3 {
    font-weight: 300;
    line-height: 24px;
    padding-bottom: 16px;
  }
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Value = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Container__Types = styled.div``;

const Container__Fiedlset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const Container = {
  main: Container__Main,
  input: Container__Input,
  types: Container__Types,
  box: Container__Box,
  fieldset: Container__Fiedlset,
  inputs: Container__Inputs,
  value: Container__Value,
};

const Text__Title = styled.h3`
  font-weight: 500;
  line-height: 24px;
`;

export const Text = {
  title: Text__Title,
};

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 8px;
  font-weight: 400;
  line-height: 24px;
  &:checked::before {
    background: ${(props) => props.theme.colors.primary};
  }
`;
