import { HttpClient } from "../../HttpClient";


export interface TransactionsRewards {
  index: number;
  description: string;
  goodersPrice: number;
  code: string;
  id: number;
  off: number;
  isLink: boolean;
  type: 'MONEY' | 'PERCENTAGE';
  partner: {
    id: number;
    logo: string;
    name: string;
  }
}

export async function UserTransactionsRewardsUseCase() {

  const response = await HttpClient<undefined, { data: TransactionsRewards[] }>
  (
  'get', 
  '/api/rewards/users/coupons'
  );
  return response;
  }

