import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Img = styled.div`
  width: 60%;
  img {
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    width: 80%;
  }
  @media (max-width: ${theme.media.tablet}) {
    width: 100%;
  }
`;

export const Container = {
  img: Container__Img,
};
