import { Container, Line } from '../../LayoutModal/styles';
import { useState } from 'react';
import { useGlobalCampaignUnique } from '../../../../../../../infra/state/useCampaign';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from '../../../../../../../infra/config/AlertContext/useAlert';
import { LabelInputFormCampaign } from '../../../../Input';
import { SelectInput } from '../../../../SelectInput';
import { BlueButton } from '../../../../Button';
import {
  CreateItemOrderDto,
  UserDonationsItems,
} from '../../../../../../../application/useCases/donation/UserDonationItem';
import SucessItem from './components';

export const validationSchema = Yup.object().shape({
  itemId: Yup.string().required('É necessário selecionar um item!'),
  quantity: Yup.number()
    .integer('Dígite um numero inteiro')
    .min(1, 'A quantidade de horas deve ser maior ou igual a 1')
    .required('Digite a quantidade de horas'),
});
interface Props {
  onClose: () => void;
}

export const ModalDonation: React.FC<Props> = ({ onClose }) => {
  const [donation, setDonation] = useState('');
  const [selectedItemAddress, setSelectedItemAddress] = useState<{
    address: string, quantity: number, donated: number | undefined
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const state = useGlobalCampaignUnique();
  const { setAlert } = useAlert();
  const [returnItem, setReturnItem] = useState(false);

  const handleDonationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(e);
    if (!e.target.value) {
      setSelectedItemAddress(null);
    }
    state?.campaignUnique?.items.forEach((vc) => {
      if (vc.id === Number(e.target.value)) {
        setSelectedItemAddress({
          address: vc.address,
          quantity: vc.quantity,
          donated: vc.donated,
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: { quantity: '', itemId: '' },
    validationSchema,
    onSubmit: async (values) => {
      if (!state.campaignUnique) {
        setAlert('Não foi possível registrar sua doação!', 'error');
      }

      const dto: CreateItemOrderDto = {
        campaignId: state.campaignUnique!.id,
        itemId: Number(values.itemId),
        quantity: Number(values.quantity),
      };

      const { error } = await UserDonationsItems(dto);
      if (error) {
        setAlert(error, 'error');
      } else {
        setAlert('Doação registrada com sucesso', 'success');
        setReturnItem(true)
      }
    },
  });

  return (
    <>
      {returnItem ? (
        <  SucessItem onClose={onClose} />
      ) : (
        <>
          <Container.Input>
            <Container.Text>
              <SelectInput
                htmlFor="itemId"
                name="itemId"
                id="itemId"
                value={formik.values.itemId}
                onChange={handleDonationChange}
                onBlur={formik.handleBlur}
                required
                options={[
                  { value: '', label: '' },
                  ...(state.campaignUnique?.items?.map((item) => ({
                    value: item.id.toString(),
                    label: item.name,
                  })) || []),
                ]}
              >
                Objeto de doação
              </SelectInput>
              {formik.touched.itemId && formik.errors.itemId && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.itemId}</span>
              )}
              <LabelInputFormCampaign
                htmlFor="quantity"
                id="quantity"
                type="number"
                name="quantity"
                placeholder=""
                value={formik.values.quantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                min={1}
                max={12}
                required
                aria-labelledby="quantity"
              >
                Quantidade
              </LabelInputFormCampaign>
              {formik.touched.quantity && formik.errors.quantity && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.quantity}</span>
              )}
              {selectedItemAddress && (
                <Container.Address style={{ marginTop: "16px" }}>
                  <span>Informações </span>
                  Local de entrega: {selectedItemAddress.address}
                  <Line className="line" />
                  Item: {selectedItemAddress.quantity}
                  <p>Itens Doados: {selectedItemAddress.donated} </p>
                </Container.Address>
              )}
            </Container.Text>
            <Container.Image>
              <img src={state.campaignUnique?.image} alt="campaign" />
            </Container.Image>
          </Container.Input>
          <BlueButton onClick={formik.submitForm} loading={loading}>
            Finalizar Doação
          </BlueButton>
        </>
      )}
    </>
  );
};
