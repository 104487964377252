import { Container } from './styles';
import warning from '../../../../../../../assets/icons/warning.svg';
import close from '../../../../../../../assets/icons/close.svg';
import { useState } from 'react';
import { SmallGreenButton, SmallRedButton } from '../../../../../../common/Button';

interface Props {
  onClose: (status: string) => void;
  idOfOrderToChangeStatus: {
    id: number;
    newStatus: string;
  };
  updateStatus: (status: {
    id: number;
    newStatus: string
  }) => Promise<void>;
}


export const ModalEditStatus = ({ onClose, idOfOrderToChangeStatus, updateStatus }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async (status: string) => {
    setLoading(true);
    const { id } = idOfOrderToChangeStatus;
    await updateStatus({ id, newStatus: status });
    onClose(status);
  };



  return (
    <Container.box>
      <Container.warning>
        <Container.close src={close} alt="warning" onClick={() => onClose('')} />
        <Container.icon>
          <img src={warning} alt="warning" />
          <h3>Deseja atualizar o status?</h3>
        </Container.icon>
        <p>Uma vez que o status for atualizado não será possível desfazer essa ação!</p>
        <Container.action>
          <SmallRedButton onClick={() => handleConfirm('CANCELADO')} loading={loading}>
            Cancelado
          </SmallRedButton>
          <SmallGreenButton onClick={() => handleConfirm('SUCESSO')} loading={loading}>
            Sucesso
          </SmallGreenButton>
        </Container.action>
      </Container.warning>
    </Container.box>
  );
};
