import styled from 'styled-components';
import { theme } from '../../../../../../../themes/blue';

const Container__Box = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(70, 125, 192, 0.03);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.black};
  z-index: 999;
  cursor: initial;
`;

const Container__Modal = styled.div`
  width: 60%;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 16px rgba(73, 80, 87, 0.32);
  border-radius: 8px;
  padding: 28px 24px;
  position: relative;
  @media (max-width: ${theme.media.tablet}) {
    width: 85%;
  }
`;

const Container__Close = styled.img`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const Container = {
  box: Container__Box,
  modal: Container__Modal,
  close: Container__Close,
};
