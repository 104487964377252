import { HttpClient } from '../../HttpClient';

export type MoneyDonationType = 'PIX' | 'BOLETO' | 'CREDITCARD';

export interface ItemOutput {
  address: string;
  id: number;
  name: string;
  quantity: number;
  category: string;
  donated?: number;
  isActive: boolean
}

export interface MoneyDonationOptionsOutput {
  multiplier: number;
  target: number;
  id: number;
  accumulated: number;
  description: string;
  isActive:boolean;
  types: MoneyDonationType[];
}

export interface VacanciesOutput {
  id: number;
  title: string;
  description: string;
  // voluntaryHours: number;
  address: string;
  state: string;
  city: string;
  startDate: string;
  endDate: string;
  multiplier: number;
  endHour: string;
  startHour: string;
  quantity: number;
  subscribersCount: number;
  isActive: boolean
}
interface InstitutionOutput {
  name: string;
  id: number;
}

export interface UniqueCampaignOutput {
  title: string;
  summary: string;
  description: string;
  category: string;
  image: string;
  address: string;
  state: string;
  city: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  id: number;
  progress: number;
  ods: string;
  responsible: string;
  responsibleTelephone: string;
  institution: InstitutionOutput;
  manualPaymentOrder: { amount: number}
  images: { src: string; id: number }[];
  items: ItemOutput[] | [];
  moneyDonationOptions: MoneyDonationOptionsOutput | null;
  vacancies: VacanciesOutput[] | [];
}

export async function GetCampaign(id: string) {
  const response = await HttpClient<void, { data: UniqueCampaignOutput }>(
    'get',
    `/campaigns/unique/${id}`
  );
  return response;
}
