import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import Table from "../../../common/Table";
import { Container } from '../TableAllInformation/styles'
import { GetReportTableVacancie, GetReportVacancieUseCase } from "../../../../../application/useCases/report/GetReportVacancieUseCase";
import { useGlobalStore } from "../../../../../infra/state/GlobalStore";
import { useAlert } from "../../../../../infra/config/AlertContext/useAlert";
import { MyDate } from "../../../../../application/utils";
import { CardData, CardsTable } from "../../../common/CardsTable";

interface Props {
    useCase: GetReportVacancieUseCase;
}

export const TableAllVoluntary = ({ useCase }: Props) => {

    const [reportVoluntary, setReportVoluntary] = useState<GetReportTableVacancie[]>([]);
    const { state } = useGlobalStore();
    const { setAlert } = useAlert();

    useEffect(() => {
        const fetchData = async () => {
            if (state.acl.selectedACL) {
                const orgId = state.acl.selectedACL.id;
                const institutionId = state.acl.selectedACL.id;
                const { error, payload } = await useCase.execute({ orgId, institutionId });
                if (!error && payload) {
                    setReportVoluntary(payload.data);
                } else {
                    setAlert(error!, 'error');
                }
            }
        };
        fetchData();
    }, []);


    const columns: GridColDef[] = [
        { field: 'Usuário', headerName: 'Usuário', minWidth: 250, flex: 1, headerClassName: 'table-header', },
        { field: 'Email', headerName: 'Email', minWidth: 250, flex: 1, headerClassName: 'table-header', },
        { field: 'Telefone', headerName: 'Telefone', minWidth: 100, flex: 1, headerClassName: 'table-header', },
        { field: 'Campanha', headerName: 'Campanha', minWidth: 120.3, flex: 1, headerClassName: 'table-header', },
        { field: 'Vaga', headerName: 'Vaga', minWidth: 120.3, flex: 1, headerClassName: 'table-header', },
        {
            field: 'Data', headerName: 'Datas', minWidth: 200, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center', sortable: false,
            filterable: false,
        },
        { field: 'Horário', headerName: 'Horários', minWidth: 120, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center' },
        { field: 'Status da Vaga', headerName: 'Status', minWidth: 100.3, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center' },
    ];

    const rows =
        (reportVoluntary?.map((item, index: number) => ({
            index: index,
            id: index,
            'Usuário': item.userName,
            'Email': item.userEmail,
            'Telefone': item.userTelephone,
            'Campanha': item.campaignTitle,
            'Vaga': item.vacancyTitle,
            'Data': MyDate.formatISOString(item.vacancyStartDate) + " - " + MyDate.formatISOString(item.vacancyEndDate),
            'Horário': item.vacancyStartHour + " " + "ás" + " " + item.vacancyEndHour,
            'Status da Vaga': item.orderStatus,
        })) ?? [])
            .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

    const cardData: CardData[] = reportVoluntary.map((item, index: number) => ({
        id: index,
        title: [
            'Usuário',
            'Email',
            'Telefone',
            'Campanha',
            'Vaga',
            'Data',
            'Horário',
            'Status da Vaga',
        ],
        value: [
            item.userName,
            item.userEmail,
            item.userTelephone,
            item.campaignTitle,
            item.vacancyTitle,
            MyDate.formatISOString(item.vacancyStartDate) + " - " + MyDate.formatISOString(item.vacancyEndDate),
            item.vacancyStartHour + " " + "ás" + " " + item.vacancyEndHour,
            item.orderStatus,
        ],
    }));

    return (
        <>
            <Container.title> Voluntariado - Analítico </Container.title>
            <Container.table>
                <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={10} />
            </Container.table>
            <Container.mobile>
                <CardsTable cardData={cardData} />
            </Container.mobile>
        </>
    );
};