import styled from "styled-components";

export const Container = styled.header<{ openModal: boolean; width?: string  }>`
	height: 74px;
	background: ${props => props.theme.colors.white};
	position: fixed;
  width: ${props => props.width};
	z-index: 2;
  box-shadow: 0px 20px 30px rgb(153 153 153 / 10%);
	@media (max-width: 600px) {
    width: 100%;
	}
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
`;

export const Left = styled.div`
@media (max-width: 480px) {
  img {
  width: 130px;
  }
}
`;

export const Image = styled.img`
  max-width: 150px;
  max-height: auto;
  height: 54px;
  object-fit: contain;
`;

export const Right = styled.div`
  display: flex;
  img {
  width: 45px;
  height: 45px;
  }
  @media (max-width: 480px) {
  position: relative;
  gap: 6px;
  img {
  width: 28px;
  height: 28px;
  position: absolute;
  top: -3px;
  left: 10px;
  }
  }
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8px;
  span{
  font-weight: 600;
  font-size: ${props => props.theme.fontSize.l};
  p {
  font-size: ${props => props.theme.fontSize.smX};
  font-weight: 500;
  }
  }
  @media (max-width: 480px) {
  position: relative;
  gap: 6px;
  span{
  font-weight: 600;
  padding-left: 50px;
  p {
  font-size:${props => props.theme.fontSize.smX};
  }
  }
  }
`;