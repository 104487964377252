import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';
import { SharedUseCase } from '../item/CreateItemForCampaignUseCase';

interface EditDonationMoneyDto {
  description: string,
  multiplier: number,
  target: number,
  isActive: boolean
}

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
}

export class UpdateDonationMoneyForCampaignUseCase extends SharedUseCase<
  Params,
  EditDonationMoneyDto,
  {
    payload: void | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execOrg() {
    const modifiedDto = {
      ...this.dto!,
      target: this.dto!.target * 100, 
    };

    return await HttpClient<EditDonationMoneyDto, void>(
      'put',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}/${
        this.params!.campaignId
      }/payment-donation`,
      modifiedDto
    );
  }


  protected override async execInstitution() {
    const modifiedDto = {
      ...this.dto!,
      target: this.dto!.target * 100, 
    };

    return await HttpClient<EditDonationMoneyDto, void>(
      'put',
      `/campaigns/institution/${this.params!.institutionId}/${this.params!.campaignId}/payment-donation`,
      modifiedDto
    );
  }

  protected override async execGooders() {
    const modifiedDto = {
      ...this.dto!,
      target: this.dto!.target * 100, 
    };

    return await HttpClient<EditDonationMoneyDto, void>(
      'put',
      `/campaigns/gooders/${this.params!.campaignId}/payment-donation`,
      modifiedDto
    );
  }

  
}
