import styled from "styled-components";

const Container__Page = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.colors.backgroundGray};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container__Main = styled.div`
  width: 60%;
  height: 45%;
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
`;

const Container__Box = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  text-align: center;
  img { 
    width: 150px;
    height: 150px;
  }
`; 

const Container__Title = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: ${props => props.theme.colors.dark};
  padding-top: 32px;
`;

const Container__Text = styled.div`
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.md};
  line-height: 22px;
`;

export const Container = {
	page: Container__Page,
	main: Container__Main,
	box: Container__Box,
	title: Container__Title,
	text: Container__Text,
};

