/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */

interface Props {
 active: boolean;
}
export const Heart = ({ active }: Props ) => {

	if (active) return (
		<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M18.6592 1.16741C16.0192 -0.632588 12.7592 0.207412 10.9992 2.26741C9.23924 0.207412 5.97924 -0.642588 3.33924 1.16741C1.93924 2.12741 1.05924 3.74741 0.999237 5.45741C0.859237 9.33741 4.29924 12.4474 9.54924 17.2174L9.64924 17.3074C10.4092 17.9974 11.5792 17.9974 12.3392 17.2974L12.4492 17.1974C17.6992 12.4374 21.1292 9.32741 20.9992 5.44741C20.9392 3.74741 20.0592 2.12741 18.6592 1.16741ZM11.0992 15.7274L10.9992 15.8274L10.8992 15.7274C6.13924 11.4174 2.99924 8.56741 2.99924 5.67741C2.99924 3.67741 4.49924 2.17741 6.49924 2.17741C8.03924 2.17741 9.53924 3.16741 10.0692 4.53741H11.9392C12.4592 3.16741 13.9592 2.17741 15.4992 2.17741C17.4992 2.17741 18.9992 3.67741 18.9992 5.67741C18.9992 8.56741 15.8592 11.4174 11.0992 15.7274Z" fill="#fff"/>
		</svg>

	);

	return (
		<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M18.6592 1.16741C16.0192 -0.632588 12.7592 0.207412 10.9992 2.26741C9.23924 0.207412 5.97924 -0.642588 3.33924 1.16741C1.93924 2.12741 1.05924 3.74741 0.999237 5.45741C0.859237 9.33741 4.29924 12.4474 9.54924 17.2174L9.64924 17.3074C10.4092 17.9974 11.5792 17.9974 12.3392 17.2974L12.4492 17.1974C17.6992 12.4374 21.1292 9.32741 20.9992 5.44741C20.9392 3.74741 20.0592 2.12741 18.6592 1.16741ZM11.0992 15.7274L10.9992 15.8274L10.8992 15.7274C6.13924 11.4174 2.99924 8.56741 2.99924 5.67741C2.99924 3.67741 4.49924 2.17741 6.49924 2.17741C8.03924 2.17741 9.53924 3.16741 10.0692 4.53741H11.9392C12.4592 3.16741 13.9592 2.17741 15.4992 2.17741C17.4992 2.17741 18.9992 3.67741 18.9992 5.67741C18.9992 8.56741 15.8592 11.4174 11.0992 15.7274Z" fill="#1485C8"/>
		</svg>

	);
};