import styled from "styled-components";

const Container__Box = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 32px;
  gap: 8px;
  img { 
    width: 150px;
    height: 150px;
  }
`;


const Container__Title = styled.div`
font-weight: 600;
font-size:  ${props => props.theme.fontSize.xll};
line-height: 32px;
color:  ${props => props.theme.colors.dark};
padding-top: 32px;
`;

const Container__Text = styled.div`
font-weight: 400;
font-size: ${props => props.theme.fontSize.md};
line-height: 22px;
`;


export const Container__Loading = styled.div`
	width: 200px;
	height: 200px;
	border:solid 12px ${props => props.theme.colors.lightgray};
	border-radius: 50%;
	border-right-color: transparent;
	animation-name: rotate; 
	animation-duration: .9s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
	}
`;

export const Container = {
	box: Container__Box,
	title: Container__Title,
	text: Container__Text,
  loading: Container__Loading
};

