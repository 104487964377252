import { Container, Text } from './styles';
import logo_blue from '../../../assets/logo/logo_blue.svg';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const HeaderLogin = ({ children }: Props) => {
  return (
    <Container.header>
      <img src={logo_blue} alt="logo" />
      <Text.title>{children}</Text.title>
    </Container.header>
  );
};
