import { Container } from './styles'

export const TermsOfMemberShip: React.FC = () => {

    return (
        <Container.text>
            <h3>Termo de Adesão</h3>
            <p>	O trabalho voluntário a ser desempenhado junto a esta instituição, de acordo com a Lei nº 9.608 de 18/02/1998 é atividade não remunerada, e não gera vínculo empregatício nem funcional, ou quaisquer obrigações trabalhistas, previdenciárias e afins.</p>
            <p>	Declaro estar ciente da legislação específica sobre Serviço Voluntário e aceito atuar como Voluntário nos termos do presente Termo de Adesão.</p>
            <Container.line />
            <h2>TERMO DE LICENÇA DE USO DE IMAGEM E VOZ</h2>
            <p>INTERCEMENT PARTICIPAÇÕES S.A., sociedade anônima, com sede na cidade de São Paulo, Estado
                de São Paulo, na Av. das Nações Unidas, n.º 12495, Andar 13, CEP 04.578-000. Inscrita no Cadastro
                Nacional da Pessoa Jurídica do Ministério da Fazenda (“CNPJ/MF”) sob nº 10.456.140/0001-22, em
                conjunto com suas subsidiárias, afiliadas, controladoras, controladas e/ou entidades sob controle
                comum ou pertencentes ao mesmo grupo econômico, doravante denominadas, em conjunto
                “Autorizada”;</p>
            <p>Autorizador e Autorizada serão doravante designados individualmente como “Parte” e conjuntamente
                como “Partes”.
                Resolvem as Partes celebrar o presente Termo de Autorização de Uso de Imagem e Voz (“Termo de
                Autorização”), que se regerá pelas cláusulas e condições abaixo:</p>
            <p>1. OBJETO
                1.1. O Autorizador deseja licenciar (“Licença de Uso”) à Autorizada o uso de sua imagem e voz
                (“Imagens”), e tão somente isto, para as finalidades abaixo (“Finalidades”). A Licença de Uso ora
                concedida pelo Autorizador à Autorizada é celebrada a título gratuito, consideradas as Finalidades:</p>
            <p>(i) Ações de comunicação interna e marketing (TV/vídeos, websites, murais, portais,
                apresentações, folhetos, banners, entre outros) da Autorizada;
                (ii) Ações de comunicação externa e marketing (TV/vídeos, website, press release, portais,
                apresentações, folhetos, banners, entre outros) da Autorizada;
                (iii) Utilização em perfil de rede social da Autorizada, tais como – mas não se limitando a
                – Facebook, LinkedIn, Instagram, WhatsApp, etc.;
                (iv) Criação e alimentação de banco de imagem de propriedade da Autorizada;
                1.2. A Autorizada poderá exercer a sua Licença de Uso e utilizar as Imagens em âmbito nacional e
                internacional, sem que seja devido ao Autorizador qualquer remuneração ou compensação adicional
                por isto.
                1.3. Pelo presente Termo de Autorização o Autorizador também concede à Autorizada o direito
                de sublicenciar a Licença de Uso, nos exatos termos aqui acordados, para a MOVER PARTICIPAÇÕES
                S.A., sociedade anônima, com sede na cidade de São Paulo, Estado de São Paulo, na Avenida
                Presidente Juscelino Kubitschek, nº 1909, 30º Andar, CEP 04.543-907, inscrita no Cadastro Nacional da
                Pessoa Jurídica do Ministério da Fazenda (“CNPJ/MF”) sob nº 01.098.905/0001-09, em conjunto com
                suas subsidiárias, afiliadas, controladoras, controladas e/ou entidades sob controle comum ou
                pertencentes ao mesmo grupo econômico, em conjunto com suas subsidiárias, afiliadas, controladoras,
                controladas e/ou entidades sob controle comum ou pertencentes ao mesmo grupo econômico</p>
            <p>2. FIXAÇÃO DAS IMAGENS

                2.1. A Autorizada poderá fixar as Imagens em qualquer tipo de suporte material, tais como:
                (i) Suportes de computação gráfica em geral, ou armazená-los em banco de dados da
                Autorizada;
                (ii) Banco de imagens no servidor próprio da Autorizada ou em plataforma terceirizada de
                armazenamento em nuvem;
                (iii) Sítios da Autorizada, Intranet, Portal e Rede Sociais da Autorizada;
                (iv) Apresentações PowerPoint ou software que desempenhe função similar; e
                (v) Material impresso</p>
            <p>3. PRAZO E ENCERRAMENTO DO TERMO DE AUTORIZAÇÃO
                3.1. O presente Termo de Autorização durará por 10 anos, obrigando-se as Partes por si, seus
                herdeiros e sucessores a qualquer título.
                3.2. Caso o Autorizador deseje encerrar o presente Termo de Autorização, deverá endereçar
                Notificação por escrito à Autorizada, registrando o seu desejo de descontinuar a presente relação,
                momento em que a Autorizada deverá envidar os seus melhores esforços para encerrar a utilização
                das Imagens dentro de um prazo de 90 (noventa) dias. O Autorizador, ainda, deverá indicar de forma
                pormenorizada os exatos itens que utilizem as Imagens protegidas que deseja que a Autorizada cesse
                o uso, sob pena de a continuidade do uso não configurar ato ilícito.
                3.3. Caso a Autorizadora, durante a vigência deste Termos de Autorização, produza material
                (“Material”) que misture as Imagens objeto da Licença de Uso com outros Materiais de propriedade da
                Autorizada, o Autorizador somente poderá exercer os direitos aqui representados sobre a parte do
                Material que incorporar as Imagens. O Autorizador neste ato reconhece expressamente que a
                Autorizada é a única e exclusiva detentora de todos os direitos autorais patrimoniais sobre o Material,
                de modo que a Autorizada poderá livremente dispor dele desde que não incorpore as Imagens objetos
                da Licença de Uso.
            </p>
            <p>4. DISPOSIÇÕES GERAIS E FORO

                4.1. A assinatura deste Termo de Autorização não obriga a Autorizada ou seus autorizados, licenciados
                e cessionários a usarem ou exibirem as Imagens, mas obriga o Autorizador a zelar pela boa imagem
                da Autorizada.
                4.2. Fica eleito o foro da Comarca da sede da empresa para dirimir quaisquer dúvidas oriundas deste
                Termo de Autorização, em prevalência a qualquer outro por mais privilegiado que seja.
                Assim, justas e contratadas, assinam as Partes o presente instrumento, em 2 (duas) vias de igual teor e
                forma e na presença de duas testemunhas, abaixo assinadas.</p>
            <p></p>
            <p></p>
        </Container.text>
    );
};