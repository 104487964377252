import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import gooders_coin from '../../../assets/logo/logo_coin.svg'
import { useAlert } from '../../../../infra/config/AlertContext/useAlert'
import { BlueButton } from '../../../components/common/Button'
import { LabelInputFormCampaign } from '../../../components/common/Input'
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar'
import { GenerateHashInterface, GenerateHashUseCase } from '../../../../application/useCases/gooders/GenerateHashUseCase'
import { defaultOption } from '../../../components/common/SelectInput/components/SelectOptionsState'
import { SelectInput } from '../../../components/common/SelectInput'
import { ListInstituion, ListInstitutionGoodersUseCase } from '../../../../application/useCases/institution/ListInstitutionUseCase'

const validationSchema = Yup.object().shape({
  count: Yup.string().required('Preencha a quantidade de hash'),
  goodersAmount: Yup.number().required(
    'Insira a quantidade de gooders'
  )
})

export const GenerateHash: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [listInstitution, setListInstitution] = useState<ListInstituion[]>([]);
  const { setAlert } = useAlert()

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await ListInstitutionGoodersUseCase();
      if (!error && payload) {
        setListInstitution(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      count: '',
      goodersAmount: '',
      institutionId: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      setLoading(true)
      const dto: GenerateHashInterface = {
        count: +values.count,
        goodersAmount: +values.goodersAmount,
        institutionId: +values.institutionId
      }
      const { error } = await GenerateHashUseCase(dto)
      setLoading(false)
      if (error) {
        setAlert(error, 'error')
      } else {
        setAlert('Hash gerado com sucesso!', 'success')
        window.location.pathname = '/hash'
      }
    }
  })

  return (
    <LayoutSidebar>
      <Container.box>
        <Container.header>
          <Container.title >Hash</Container.title>
        </Container.header>
        <Container.main onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}>
          <Container.title>Gerar Hash</Container.title>
          <Container.wrapper>
            <Container.left>
              <Container.text>
                Adicione a quantidade de hash que deseja gerar e quantidade de
                Gooders correspondente:
              </Container.text>
              <SelectInput
                htmlFor="institutionId"
                id="institutionId"
                name="institutionId"
                value={formik.values.institutionId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  defaultOption,
                  ...listInstitution.map((institution) => ({
                    value: String(institution.id),
                    label: institution.name,
                  })),
                ]}
                error={
                  formik.touched.institutionId && formik.errors.institutionId
                    ? formik.errors.institutionId
                    : undefined
                }
              >
                Instituição *
              </SelectInput>
              <LabelInputFormCampaign
                id="count"
                htmlFor="count"
                type="number"
                name="count"
                placeholder="Qual a quantidade de hash deseja gerar?"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.count}
                aria-labelledby="count"
                error={
                  formik.touched.count &&
                    formik.errors.count
                    ? formik.errors.count
                    : undefined
                }
              >
                Quantidade de hash
              </LabelInputFormCampaign>
              <LabelInputFormCampaign
                id="goodersAmount"
                htmlFor="goodersAmount"
                type="number"
                name="goodersAmount"
                placeholder="Insira a quantidade de gooders para cada Hash"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.goodersAmount}
                aria-labelledby="goodersAmount"
                error={
                  formik.touched.goodersAmount &&
                    formik.errors.goodersAmount
                    ? formik.errors.goodersAmount
                    : undefined
                }
              >
                Gooders
              </LabelInputFormCampaign>
            </Container.left>
            <Container.right>
              <img src={gooders_coin} alt="Coin" />
            </Container.right>
          </Container.wrapper>
          <BlueButton type="submit" loading={loading}>
            Gerar Hash
          </BlueButton>
        </Container.main>
      </Container.box>
    </LayoutSidebar>
  )
}

