import { Container } from './styles';
import error403 from '../../assets/error403.jpg';
import Navbar from '../../components/common/Navbar';
import { BlueButton } from '../../components/common/Button';
import { useNavigate } from 'react-router-dom';

export const NotAuthorized: React.FC = () => {

  const navigate = useNavigate();

  return (
    <>
      <Navbar openModal={false} width="100%" />
      <Container.page>
        <Container.box>
          <h1>
            Ops! Não autorizado!
          </h1>
          <Container.button>
            <BlueButton onClick={() => navigate('/home')} loading={false}>Voltar</BlueButton>
          </Container.button>
          <img src={error403} alt="error403" />
        </Container.box>
      </Container.page>
    </>
  );
}

