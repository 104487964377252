import styled from "styled-components";
import {theme} from '../../../../../../../themes/blue'

const Container__Box = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
  background: rgba(70, 125, 192, 0.03);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 999;
`;



const Container__Warning = styled.div`
  width: 60%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 16px rgba(73, 80, 87, 0.32);
  border-radius: 8px;
  padding: 28px 24px;
  position: relative;
  justify-content: center;
  gap: 20px;
  p {
  font-weight: 300;
  font-size: 16px;
  display: flex;
  align-items: center;
  img {
    margin: 0 4px;
  }
  span {
    font-weight: 600;
    padding-left: 8px;
  }
  }
  @media (max-width: ${theme.media.mobile}) {
    width: 90%;
    p {
      flex-direction: column;
    }
  }
`;

const Container__Close = styled.img`
  width: 17px;
  height: 17px;
  position: absolute;
  top: 31px;
  right: 31px;
  cursor: pointer;
`;


export const Container = {
	box: Container__Box,
	warning: Container__Warning,
	close: Container__Close
};
