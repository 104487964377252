
import { HeaderPages } from "../../../components/common/HeaderPages";




export const Report: React.FC = () => {

    return (
        <HeaderPages title="Relatórios">

        </HeaderPages>
    );
};