import { useState } from 'react';
import { Container } from './styles';
import email from '../../../../../../../assets/icons/email.svg';
import eyes from '../../../../../../../assets/icons/eyes.svg';
import padlock from '../../../../../../../assets/icons/padlock.svg';
import { LabelInput } from '../../../../../../../components/common/Input';
import { BlueButton } from '../../../../../../../components/common/Button';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoginUserUseCase } from '../../../../../../../../application/useCases/user/LoginUser';

export function VerifyNextAndRedirect(urlQuerys: URLSearchParams) {
  const nextRoute = urlQuerys.get('next');
  if (nextRoute) {
    // Redireciona o usuário para o caminho enviado pela URL
    window.location.pathname = nextRoute;
    return;
  } else {
    window.location.pathname = '/home';
  }
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, 'Digite um email válido')
    .required('O campo de email é obrigatório'),
  password: Yup.string()
    .min(4, 'A senha deve ter no mínimo 4 caracteres')
    .required('O campo de senha é obrigatório'),
});

export const FormLogin = () => {
  const { setAlert } = useAlert();

  const urlQuerys = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { error } = await LoginUserUseCase(values);
      setLoading(false);

      if (error) {
        setAlert(error, 'error');
      } else {
        VerifyNextAndRedirect(urlQuerys);
      }
    },
  });

  return (
    <>
      <Container.form onSubmit={formik.handleSubmit}>
        <LabelInput
          id="email"
          htmlFor="email"
          type="email"
          name="email"
          placeholder="Insira seu email..."
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          aria-labelledby="email"
          src1={email}
          error={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
        >
          {' '}
          Email{' '}
        </LabelInput>

        <LabelInput
          id="password"
          htmlFor="password"
          name="password"
          type="password"
          placeholder="********"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          aria-labelledby="password"
          src1={padlock}
          src2={eyes}
          error={
            formik.touched.password && formik.errors.password ? formik.errors.password : undefined
          }
        >
          {' '}
          Senha{' '}
        </LabelInput>

        <BlueButton dataTest="btn-login" type="submit" loading={loading}>
          Entrar
        </BlueButton>
      </Container.form>
    </>
  );
};
