import { GridColDef, GridFilterItem } from '@mui/x-data-grid';
import Table from '../../../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import logo_coin from '../../../../../../../assets/logo/logo_coin.svg';
import { TransactionsRewards, UserTransactionsRewardsUseCase } from '../../../../../../../../application/useCases/rewards/UserTransactionsRewardsUseCase';
import { Container } from '../../styles'
import { CardData, CardsTable } from '../../../../../../../components/common/CardsTable';

export const TableTransactionsRewards = () => {
  const { setAlert } = useAlert();
  const [transactionRewards, setTransactionRewards] = useState<TransactionsRewards[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await UserTransactionsRewardsUseCase();
      if (!error && payload) {
        setTransactionRewards(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    { field: 'Parceiro:', headerName: 'Parceiro:', minWidth: 150, flex: 1, headerClassName: 'table-header', },
    {
      field: 'Desconto:', headerName: 'Desconto:', minWidth: 50, flex: 1, headerClassName: 'table-header',
      renderCell: (params) => {
        const tipo = params.row['Tipo:'];
        const off = params.row['Desconto:'];
        const isMoney = tipo === 'MONEY';
        return (
          <div style={{ fontSize: '13px', display: 'flex', alignItems: 'center' }}>
            {isMoney ? `R$ ${off}` : `${off}%`}
          </div>
        );
      },
    },
    {
      field: 'Tipo:', headerName: 'Tipo:', minWidth: 80, flex: 1, headerClassName: 'table-header',
    },
    {
      field: 'Descrição:', headerName: 'Descrição:', minWidth: 100, flex: 1, headerClassName: 'table-header',

    },
    {
      field: 'Gooders Coins', headerName: 'Gooders Coins', minWidth: 120, flex: 1, headerAlign: 'right', align: 'right', headerClassName: 'table-header',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', color: '#C31526', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
          <img src={logo_coin} alt="logo_coin" style={{ width: '20px', paddingRight: '6px' }} />
          {params.row['Gooders Coins']}
        </div>
      ),
    },
  ];

  const rows = transactionRewards.map((rewards, index) => ({
    index: index,
    id: index,
    'Parceiro:': rewards.partner.name,
    'Desconto:': rewards.off,
    'Tipo:': rewards.type === 'MONEY' ? 'Valor' : 'Porcentagem',
    'Descrição:': rewards.description,
    'Gooders Coins': '-' + String(rewards.goodersPrice / 100) + ' Coins',
  }));

  const cardData: CardData[] = transactionRewards.map((rewards) => ({
    id: rewards.id,
    title: [
      'Parceiro',
      'Desconto',
      'Tipo',
      'Descrição',
      'Gooders Coins'
    ],
    value: [
      rewards.partner.name,
      rewards.off,
      rewards.type === 'MONEY' ? 'Valor' : 'Porcentagem',
      rewards.description,
      '-' + String(rewards.goodersPrice / 100)
    ]
  }));

  return (
    <>
      <Container.desktop>
        <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />;
      </Container.desktop >
      <Container.mobile>
        <CardsTable cardData={cardData} />
      </Container.mobile>
    </>
  )
};
