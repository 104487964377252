import { User } from '../../../domain/interfaces/User';
import { Http } from '../../../infra/config/http/Http';
import { HttpClient } from '../../HttpClient';

export interface GetUserDto {
  jwt: string;
}

export class GetUserUseCase {
  constructor(private token?: string) {}
  async exec() {
    const { error, payload } = await HttpClient<unknown, { data: User }>('get', '/users');
    if (!error && payload) {
      return {
        data: payload,
      };
    } else {
      return { error };
    }
  }
}
