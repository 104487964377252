import { useEffect, useState } from "react";
import { LayoutSidebar } from "../../../components/containers/LayoutSidebar";
import { useSearch } from "../../../components/hooks/useSearch";
import { Container } from "./styles";
import { CardDiscount } from "../../../components/common/CardDiscount";
import { SearchDiscount } from "../../../components/common/SearchDiscount";
import { GetListCouponUseCase, ListCoupon } from "../../../../application/useCases/rewards/GetListCouponUseCase";
import { PaginationListCause } from "../campaigns/components/ListCampaigns/components/PaginationListCause";
import { useGlobalStore } from "../../../../infra/state/GlobalStore";
import { useAlert } from "../../../../infra/config/AlertContext/useAlert";
import { divideBy100 } from "../../../../application/utils";


export const Rewards: React.FC = () => {

  const pageSizeOptions = [16, 32, 64, 128];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const { searchTerm, handleSearchTermChange } = useSearch({
    onSearchTermChange: (term) => console.log(term),
  });
  const [openModals, setOpenModals] = useState<number[]>([]);
  const list = useGlobalStore()
  const { setAlert } = useAlert();

  useEffect(() => {
    if (list.state.rewards.length === 0) {
      const fetchData = async () => {
        const { payload, error } = await GetListCouponUseCase();
        if (!error && payload) {
          list.actions.setRewards(payload.data)
        }
      };
      fetchData();
    }
  }, []);


  const handlePageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const filteredCauses = list.state.rewards?.filter((rewards: ListCoupon) =>
    searchTerm
      ? [rewards.partner.name?.toLowerCase(), rewards.description?.toLowerCase()].some((field) =>
        field?.includes(searchTerm.toLowerCase())
      )
      : true
  );
  const startIndex = (currentPage - 1) * pageSize;
  const pagedCauses = filteredCauses?.slice(startIndex, startIndex + pageSize);


  const handleButtonListCards = (goodersPrice: number, couponId: number,) => {
    const formattedGoodersPrice = divideBy100(goodersPrice);
    const formattedWallet = divideBy100(list.state.user?.wallet?.balance ?? 0);
    if (formattedWallet < formattedGoodersPrice) {
      setAlert('Você não possui saldo suficiente para resgatar este cupom!', 'error');
    } else {
      setOpenModals((prevOpenModals) => [...prevOpenModals, couponId]);
    }
  };


  return (
    <LayoutSidebar>
      <Container.page>
        <Container.header>
          <Container.title>Recompensas</Container.title>
        </Container.header>
        <Container.Main>
          <Container.head>
            <SearchDiscount searchTerm={searchTerm} onSearchTermChange={handleSearchTermChange} />
            <PaginationListCause
              totalCount={0}
              pageSize={pageSize}
              startIndex={startIndex}
              pagedCauses={pagedCauses}
              causes={filteredCauses}
              pageSizeOptions={pageSizeOptions}
              handlePageSizeChange={handlePageSizeChange}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </Container.head>
          <Container.Cards>
            {pagedCauses
              .filter((rewards: ListCoupon) =>
                searchTerm
                  ? [rewards.partner.name?.toLowerCase(),
                  rewards.description?.toLowerCase()].some((field) =>
                    field?.includes(searchTerm.toLowerCase())
                  )
                  : true
              )
              .length > 0 ? (
              pagedCauses.map((rewards: ListCoupon) => (
                <CardDiscount
                  key={rewards.id}
                  id={rewards.id}
                  name={rewards.partner.name}
                  logo={rewards.partner.logo}
                  off={rewards.off}
                  description={rewards.description}
                  goodersPrice={rewards.goodersPrice}
                  code={rewards.code}
                  type={rewards.type as "MONEY" | "PERCENTAGE"}
                  isLink={rewards.isLink}
                  buttonText="Resgatar"
                  onClickFunction={(goodersPrice, id) => handleButtonListCards(goodersPrice, id)}
                  openModal={openModals.includes(rewards.id)}
                  setOpenModal={(value) =>
                    value
                      ? setOpenModals((prevOpenModals) => [...prevOpenModals, rewards.id])
                      : setOpenModals((prevOpenModals) => prevOpenModals.filter((id) => id !== rewards.id))
                  }
                />
              ))
            ) : (
              <div>
                <p>Lamentamos, nenhuma recompensa encontrada.
                  Tente novamente.</p>
              </div>
            )
            }
          </Container.Cards>
        </Container.Main>
      </Container.page>

    </LayoutSidebar>
  );
};