import { Container } from './styles';
import { useEffect, useState } from 'react';
import number_user from '../../../assets/dashboard/number_user.svg';
import number_campaign from '../../../assets/dashboard/number_campaign.svg';
import number_donation from '../../../assets/dashboard/number_donation.svg';
import number_new_cause from '../../../assets/dashboard/number_new_cause.svg';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { DashboardPayload, InstitutionDahsboardUseCase } from '../../../../application/useCases/dahsboard/InstitutionDashboardUseCase';
import { convertStringNumberToString } from '../../gooders/dashboard';
import { CardStatistics } from '../../../components/shared/dashboard/CardStatistics';
import { ItemsTableDahsboard } from '../../../components/shared/dashboard/Table/TableItems';
import { VacanciesTableDahsboard } from '../../../components/shared/dashboard/Table/VacanciesTable';

export const DashboardInst: React.FC = () => {
  const [data, setData] = useState<DashboardPayload | undefined>();
  const { setAlert } = useAlert();
  const users = useGlobalStore();

  useEffect(() => {
    async function fetchData() {
      if (users.state.acl.selectedACL) {
        const instId = users.state.acl.selectedACL.id;
        const { error, payload } = await InstitutionDahsboardUseCase(instId);
        if (!error && payload) {
          setData(payload.data);
        } else {
          setAlert(error!, 'error');
        }
      }
    }
    fetchData();
  }, [users.state.acl.selectedACL]);

  const images = [number_user, number_campaign, number_donation, number_new_cause];

  return (
    <Container.main>
      <Container.cards>
        <Container.card>
          <CardStatistics
            title="Campanhas"
            startNumber={data ? data.total_active_campaigns : '0'}
            percentage=""
            label="Total"
            subTitle="Campanhas "
            imageSrc={images[1]}
          />
          <CardStatistics
            title="Usuários"
            startNumber={data ? data.total_volunteers_subscribed : '0'}
            percentage=""
            label="Total"
            subTitle="Usuários inscritos"
            imageSrc={images[0]}
          />
        </Container.card>
        <Container.card>
          <CardStatistics
            title=""
            startNumber={data ? convertStringNumberToString(data.total_campaigns_money_goal) : '0'}
            percentage=""
            label="Total"
            subTitle="Metas cadastradas"
            imageSrc={images[2]}
          />
          <CardStatistics
            title=""
            startNumber={data ? convertStringNumberToString(data.total_donations_accumulated) : '0'}
            percentage=""
            label="Total"
            subTitle="Valor arrecadado"
            imageSrc={images[3]}
          />
        </Container.card>
      </Container.cards>
      <Container.table>
        <ItemsTableDahsboard data={data?.itemOrders} />
        <VacanciesTableDahsboard data={data?.vacancyOrders} />
      </Container.table>
    </Container.main>
  );
};
