import { useState } from 'react';
import { Container } from '../../../login/components/LoginUser/components/FormLogin/styles';
import eyes from '../../../../../assets/icons/eyes.svg';
import padlock from '../../../../../assets/icons/padlock.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { LabelInput } from '../../../../../components/common/Input';
import { BlueButton } from '../../../../../components/common/Button';
import { ResetPasswordUseCase } from '../../../../../../application/useCases/user/ForgetPasswordUseCase';
import { useLocation } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required('O campo de senha é obrigatório'),
  confirmPassword: Yup.string().required('Confirme sua senha de acesso'),
});

export const FormNewPassword = () => {
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname.split('/redefinir-senha/')[1];
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { error } = await ResetPasswordUseCase({
        ...values,
        token: currentPath,
      });
      setLoading(false);
      if (error) {
        setAlert(error, 'error');
      } else {
        window.location.pathname = '/login';
      }
    },
  });

  return (
    <>
      <Container.form onSubmit={formik.handleSubmit}>
        <LabelInput
          id="newPassword"
          htmlFor="newPassword"
          name="newPassword"
          type="password"
          placeholder="********"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          aria-labelledby="newPassword"
          src1={padlock}
          src2={eyes}
          error={
            formik.touched.newPassword && formik.errors.newPassword
              ? formik.errors.newPassword
              : undefined
          }
        >
          {' '}
          Nova Senha{' '}
        </LabelInput>
        <LabelInput
          src2={eyes}
          src1={padlock}
          type="password"
          id="confirmPassword"
          placeholder="******"
          name="confirmPassword"
          htmlFor="confirmPassword"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          aria-labelledby="confirmPassword"
          value={formik.values.confirmPassword}
          error={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? formik.errors.confirmPassword
              : undefined
          }
        >
          Confirme sua senha
        </LabelInput>
        <BlueButton dataTest="btn-login" type="submit" loading={loading}>
          Confirmar
        </BlueButton>
      </Container.form>
    </>
  );
};
