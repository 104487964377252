import { Box } from '@mui/material';
import { DataGrid, GridColDef, ptBR, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';



interface TableProps {
    rows: Array<Record<string, any>>;
    columns: GridColDef[];
    menuIconDisplay: string;
    numberRows: number
}

function CustomToolbar() {
    return (
        <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
            <GridToolbarExport
                csvOptions={{
                    fileName: 'Tabela',
                    delimiter: ';',
                    utf8WithBom: true,
                }} />
        </GridToolbarContainer>
    );
}

export default function Table({ rows, columns, menuIconDisplay, numberRows }: TableProps) {
    const showPagination = rows.length >= 5;

    return (
        <Box style={{
            width: '100%',
            color: 'white', fontFamily: 'Poppins', border: 0,
        }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: numberRows },
                    },
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                hideFooterPagination={!showPagination}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
                sx={{
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                        backgroundColor: 'white',
                    },
                    '& .MuiDataGrid-main': {
                        paddingTop: '34px'
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        position: 'absolute',
                        width: '120px',
                        borderTop: 'none',
                        top: '-2px',
                        right: '0px',
                        zIndex: 1,
                    },
                    '& .MuiDataGrid-footerContainer': {
                        position: 'absolute',
                        width: '100%',
                        borderTop: 'none',
                        top: '-10px',
                        right: '120px',
                    },
                    '@media (max-width: 785px)': {
                        '& .MuiDataGrid-main': {
                            paddingTop: '100px'
                        },
                        '& .MuiDataGrid-toolbarContainer': {
                            right: '0px'
                        },
                        '& .MuiDataGrid-footerContainer': {
                            height: '100px',
                            alignItems: 'flex-end',
                            right: '0px'
                        },
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                        backgroundColor: '#f5f5f5',
                    },
                    [`& .${gridClasses.cell}`]: {
                        borderBottom: 'none',
                    },
                    [`& .${gridClasses.cellContent}`]: {
                        fontSize: '13px',
                        borderBottom: 'none',
                    },
                    [`& .${gridClasses.cell}`]: {
                        fontSize: '13px',
                        borderBottom: 'none',
                    },
                    [`& .${gridClasses.menuIconButton}`]: {
                        color: 'white',
                        fontSize: '13px',
                        bgcolor: 'transparent',
                        display: menuIconDisplay,
                        alignItems: 'center'
                    },
                    [`& .${gridClasses.sortIcon}`]: {
                        color: 'white',
                        fontSize: '13px',
                        borderRadius: 0,
                        bgcolor: 'transparent',
                    },
                    [`& .${gridClasses.columnHeaderTitle}`]: {
                        fontSize: '13px',
                        fontWeight: 500,
                    },
                    [`& .${gridClasses.filterIcon}`]: {
                        color: 'white',
                    },
                    [`& .${gridClasses.selectedRowCount}`]: {
                        fontSize: '13px'
                    },
                    [`& .MuiTablePagination-selectLabel`]: {
                        fontSize: '13px',
                        fontFamily: 'Poppins',
                        borderBottom: 'none',
                    },
                    [`& .MuiTablePagination-select`]: {
                        fontSize: '13px',
                        fontFamily: 'Poppins',
                    },
                    [`& .MuiTablePagination-displayedRows`]: {
                        fontSize: '13px',
                        fontFamily: 'Poppins',
                    },
                    boxShadow: 0,
                    border: 0,
                    borderColor: 0,
                    fontFamily: 'Poppins'
                }}

            />
        </Box>
    );
}

