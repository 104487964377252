import { Container } from '../../../../../components/shared/gerenciar-campanha/TableListCampaignOrg/styles';
import { GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../components/common/Table';
import { VacancyOrdersOrg } from '../../../../../../application/useCases/dahsboard/OrgDashboardUseCase';
import { MyDate, dateSortComparator, dateValueFormatter } from '../../../../../../application/utils';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';



export const VacanciesTableDahsboardOrg = ({ data }: { data: VacancyOrdersOrg[] }) => {


    const columns: GridColDef[] = [
        { field: 'Usuário', headerName: 'Usuário', minWidth: 250, flex: 1, headerClassName: 'table-header', },
        { field: 'Instituição', headerName: 'Instituição', minWidth: 250, flex: 1, headerClassName: 'table-header', },
        { field: 'Campanha', headerName: 'Campanha', minWidth: 250, flex: 1, headerClassName: 'table-header', },
        { field: 'Voluntariado', headerName: 'Vaga', minWidth: 250.3, flex: 1, headerClassName: 'table-header', },
        {
            field: 'Data', headerName: 'Data', minWidth: 180, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center',
        },
    ];

    const rows =
        (data?.map((item: VacancyOrdersOrg, index: number) => ({
            index: index,
            id: item.id,
            Usuário: item.user.name,
            Campanha: item.campaign.title,
            Instituição: item.institution.name,
            Voluntariado: item.vacancy.title,
            Data: MyDate.formatISOString(item.vacancy.startDate) + "-" + MyDate.formatISOString(item.vacancy.endDate),
        })) ?? [])
            .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

    const cardData: CardData[] = data.map((item: VacancyOrdersOrg) => ({
        id: item.id,
        title: [
            'Usuário',
            'Instituição',
            'Campanha',
            'Voluntariado',
            'Data'
        ],
        value: [
            item.user.name,
            item.institution.name,
            item.campaign.title,
            item.vacancy.title,
            MyDate.formatISOString(item.vacancy.startDate) + "-" + MyDate.formatISOString(item.vacancy.endDate),
        ]
    }));

    return (
        <>
            <Container.main>
                <Container.title>Voluntariado</Container.title>
                <Container.table>
                    <Table rows={rows} columns={columns} menuIconDisplay="initial" numberRows={5} />
                </Container.table>
                <Container.mobile>
                    <CardsTable cardData={cardData} />
                </Container.mobile>
            </Container.main>
        </>
    );
};
