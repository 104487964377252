import { useContext, useEffect, useState } from 'react';
import { Container, Text } from './styles';
import { CampaignContext } from '../../../../../../../infra/config/CampaignContext';
import { LabelInputFormCampaign } from '../../../../../common/Input';
import { TextArea } from '../../../../../common/TextArea';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const formatCurrency = (value: number) => {
  return `R$ ${value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const validationSchema = Yup.object().shape({
  target: Yup.string()
    .required('Preencha o valor')
    .matches(/^[0-9]+$/, 'Insira um número válido'),
  multiplier: Yup.number().min(0, 'Valor deve ser igual ou maior que 0').optional(),
  description: Yup.string()
    .max(500, 'Limite de caracteres excedido')
    .required('Preencha a descrição de uso'),
});

export const DonationPay = () => {
  const [loading, setLoading] = useState(false);
  const { donationPay, setDonationPay } = useContext(CampaignContext);

  const formik = useFormik({
    initialValues: {
      target: '',
      description: '',
      pix: '',
      creditCard: '',
      boleto: '',
      multiplier: '0',
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = {
        target: Number(values.target),
        description: values.description,
        multiplier: Number(values.multiplier),
      };
      setDonationPay(data);
    },
  });

  useEffect(() => {
    if (donationPay && Object.keys(donationPay).length > 0) {
      formik.setFieldValue('target', donationPay.target || '');
      formik.setFieldValue('description', donationPay.description || '');
      formik.setFieldValue('multiplier', donationPay.multiplier || '');
    }
  }, []);

  useEffect(() => {
    return () => {
      if (!formik.isValidating && Object.keys(formik.errors).length === 0 && loading === false) {
        setLoading(true);
        formik.submitForm();
        setLoading(false);
      }
    };
  }, []);

  return (
    <Container.main>
      <Container.input>
        <Text.title>Doação Financeira</Text.title>
        <Container.box>
          <h3>Meta de arrecadação:</h3>
          <Container.inputs>
            <LabelInputFormCampaign
              htmlFor="target"
              id="target"
              name="target"
              type="number"
              placeholder="R$0,00"
              value={formik.values.target}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-labelledby="Valor"
              error={
                formik.touched.target && formik.errors.target ? formik.errors.target : undefined
              }
            >
              Valor: {formatCurrency(Number(formik.values.target))}
            </LabelInputFormCampaign>

            <LabelInputFormCampaign
              htmlFor="multiplier"
              id="multiplier"
              name="multiplier"
              type="number"
              placeholder=""
              value={formik.values.multiplier}
              onChange={formik.handleChange}
              aria-labelledby="Multiplicador Gooders"
              onBlur={formik.handleBlur}
              error={
                formik.touched.multiplier && formik.errors.multiplier
                  ? formik.errors.multiplier
                  : undefined
              }
            >
              Multiplicador Gooders
            </LabelInputFormCampaign>
          </Container.inputs>
          <Container.inputs>
            <TextArea
              htmlFor="description"
              id="description"
              name="description"
              rows={5}
              maxLength={500}
              placeholder=""
              value={formik.values.description}
              onChange={formik.handleChange}
              aria-labelledby="Descrição de uso"
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : undefined
              }
            >
              Descrição sobre a meta
            </TextArea>
          </Container.inputs>
        </Container.box>
      </Container.input>
    </Container.main>
  );
};
