import styled from 'styled-components';
import { theme } from '../../../themes/blue';

interface ImgBackgroundProps {
  background: string;
}

const Container__Main = styled.div<ImgBackgroundProps>`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background: url(${(props) => props.background});
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.media.monitorSmall}) {
    height: auto;
    padding-top: 20px;
  }
  .error {
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.theme.fontSize.smX};
    line-height: 12px;
  }
`;

const Container__Box = styled.main`
  width: 60%;
  display: flex;
  background: ${(props) => props.theme.colors.background};
  padding: 30px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 8px;
  gap: 40px;
  @media (max-width: 1245px) {
    width: 70%;
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: ${theme.media.mobileSmall}) {
    width: 90%;
  }
`;

export const Container = {
  main: Container__Main,
  box: Container__Box,
};
