import { useState } from 'react';
import { _Input, _InputFormCampaign, _InputRadio } from './styles';
import { StyledComponent } from 'styled-components';

interface LabelInputProps {
  htmlFor: string;
  id: string;
  children: React.ReactNode;
  name: string;
  type: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  src1?: string;
  value?: string | number;
  src2?: string;
  src3?: string;
  placeholder?: string;
  'aria-labelledby': string;
  required?: boolean;
  checked?: boolean;
  maxLength?: number;
  disabled?: any;
  rows?: number;
  min?: number;
  max?: number;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  StyledInput: StyledComponent<'input', any, any, never>;
}

export const InputBase = ({
  id,
  htmlFor,
  children,
  src1,
  src2,
  src3,
  type,
  value,
  onChange,
  name,
  checked,
  placeholder,
  StyledInput,
  error,
  ...props
}: LabelInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledInput>
      <label htmlFor={htmlFor}>
        <img src={src1} alt="" />
        <input
          id={id}
          name={name}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          checked={checked}
          {...props}
          aria-labelledby={htmlFor}
        />
        <img src={src3} alt="" />
        <span>{children}</span>
        {src2 && (
          <img src={src2} alt="" onClick={handleTogglePassword} style={{ cursor: 'pointer' }} />
        )}
      </label>
      <div className="error">{error}</div>
    </StyledInput>
  );
};

export const LabelInput = ({
  id,
  htmlFor,
  children,
  name,
  type,
  value,
  onChange,
  placeholder,
  ...props
}: Omit<LabelInputProps, 'StyledInput'>) => {
  return (
    <InputBase
      id={id}
      htmlFor={htmlFor}
      type={type}
      name={name}
      onChange={onChange}
      value={value}
      StyledInput={_Input}
      {...props}
      aria-labelledby={htmlFor}
    >
      {children}
    </InputBase>
  );
};

export const LabelInputRadio = ({
  id,
  htmlFor,
  children,
  type,
  name,
  src3,
  onChange,
  ...props
}: Omit<LabelInputProps, 'StyledInput'>) => {
  return (
    <InputBase
      src3={src3}
      id={id}
      name={name}
      StyledInput={_InputRadio}
      onChange={onChange}
      type={type}
      htmlFor={htmlFor}
      {...props}
    >
      {children}
    </InputBase>
  );
};

export const LabelInputFormCampaign = ({
  children,
  htmlFor,
  id,
  name,
  onChange,
  type,
  ...props
}: Omit<LabelInputProps, 'StyledInput'>) => {
  return (
    <InputBase
      id={id}
      name={name}
      StyledInput={_InputFormCampaign}
      onChange={onChange}
      type={type}
      htmlFor={htmlFor}
      {...props}
    >
      {children}
    </InputBase>
  );
};
