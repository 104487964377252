import { Container, Input } from '../../LayoutModal/styles';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../../infra/config/AlertContext/useAlert';
import LoadingPay from './components/ReturnPay/LoadingPay';
import { LabelInputFormCampaign } from '../../../../Input';
import { useGlobalCampaignUnique } from '../../../../../../../infra/state/useCampaign';
import { BlueButton } from '../../../../Button';
import { ModalCampaignDonatePay } from '../../../../../../pages/users/campaign/components/ModalCampaignDonatePay';
import { CreatePrePaymentOrderUseCase } from '../../../../../../../application/useCases/checkout/CreatePrePaymentOrder';

interface Props {
  onClose: () => void;
}

interface Payload {
  data?: {
    preOrderId: string;
  };
}

export const ModalPayment: React.FC<Props> = ({ onClose }) => {
  const [quantity, setQuantity] = useState('');
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const [selectedAnonymous, setSelectedAnonymous] = useState(false);
  const { setAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const state = useGlobalCampaignUnique();
  const [exceededLimit, setExceededLimit] = useState(false);
  const [openModalCampaign, setOpenModalCampaign] = useState(false);
  const [campaignId, setCampaignId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const checkLimit = () => {
    const moneyDonationOptions = state.campaignUnique?.moneyDonationOptions;
    if (
      moneyDonationOptions &&
      moneyDonationOptions.accumulated !== undefined &&
      moneyDonationOptions.target !== undefined
    ) {
      setExceededLimit(moneyDonationOptions.accumulated >= moneyDonationOptions.target);
    }
  };

  useEffect(() => {
    checkLimit();
  }, []);

  const handleOpenModal = () => {
    setIsLoading(true);
    setOpenModalCampaign(true);
    setIsLoading(false);
  };

  const formattedQuantity = quantity
    ? Number(quantity).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '';

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setQuantity(e.target.value);
      setSelectedCheckbox(e.target.value);
    } else {
      setQuantity('');
      setSelectedCheckbox('');
    }
  };

  const handleDonateClick = async () => {
    if (quantity.trim() === '') {
      setAlert('Selecione um valor para doar!', 'error');
      return;
    }
    setIsLoading(true);
    const quantityAsNumber = Number(quantity);
    const amount = quantityAsNumber * 100;

    if (state.campaignUnique) {
      const { error, payload }: { error: string | null; payload: unknown } =
        await CreatePrePaymentOrderUseCase({
          campaignId: state.campaignUnique.id,
        });
      if (error) {
        setAlert(error, 'error');
      } else {
        // URL da rota que você deseja abrir em uma nova janela
        const payloadOrder = payload as Payload;
        const preOrderId = payloadOrder.data?.preOrderId;
        const isAnonymous = selectedAnonymous ? 'true' : 'false';
        const payRouteUrl = `/checkout?quantity=${amount}&anonymous=${isAnonymous}&preOrderId=${preOrderId}`;

        // Abre uma nova janela com the URL da rota
        const newWindow = window.open(payRouteUrl, '_blank', 'height=500,width=600');
      }
    } else {
      setAlert(
        'Não foi possível realizar a doação no momento, tente novamente mais tarde!',
        'error'
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <LoadingPay onClose={onClose} campaignId={campaignId} />
      ) : (
        <>
          <Container.Input>
            <Container.Text>
              {!exceededLimit && (
                <>
                  <h3>Qual valor você gostaria de destinar à esta campanha?</h3>
                  <Input.Checkbox>
                    <label htmlFor="100" className={selectedCheckbox === '100' ? 'checked' : ''}>
                      <input type="checkbox" id="100" value="100" onChange={handleQuantityChange} />
                      <p>Doar R$ 100,00 </p>
                    </label>
                  </Input.Checkbox>
                  <Input.Checkbox>
                    <label htmlFor="150" className={selectedCheckbox === '150' ? 'checked' : ''}>
                      <input type="checkbox" id="150" value="150" onChange={handleQuantityChange} />
                      <p>Doar R$ 150,00 </p>
                    </label>
                  </Input.Checkbox>
                  <Input.Checkbox>
                    <label htmlFor="200" className={selectedCheckbox === '200' ? 'checked' : ''}>
                      <input type="checkbox" id="200" value="200" onChange={handleQuantityChange} />
                      <p>Doar R$ 200,00 </p>
                    </label>
                  </Input.Checkbox>
                  <LabelInputFormCampaign
                    htmlFor="valor"
                    id="valor"
                    type="number"
                    name="valor"
                    placeholder="R$"
                    value={quantity}
                    aria-labelledby="valor"
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                  >
                    Digite um valor personalizado{' '}
                  </LabelInputFormCampaign>
                  {formattedQuantity && <span>Total a doar: {formattedQuantity}</span>}
                  <Input.Checkbox>
                    <label
                      htmlFor="anonymous"
                      className={selectedAnonymous === true ? 'checked' : ''}
                      style={{
                        border: 'none',
                        color: selectedAnonymous ? 'white' : 'gray',
                        backgroundColor: selectedAnonymous ? 'gray' : 'white',
                      }}
                    >
                      <input
                        type="checkbox"
                        id="anonymous"
                        value="true"
                        checked={selectedAnonymous}
                        onChange={(e) => setSelectedAnonymous(e.target.checked)}
                      />
                      <p style={{ fontSize: '12px' }}>Deseja continuar de forma anônima?</p>
                    </label>
                  </Input.Checkbox>
                </>
              )}
              {exceededLimit && (
                <>
                  <p>Parabéns! Atingimos a meta financeira estipulada. </p>
                  <p>
                    Você ainda pode apoiar com doação de itens ou voluntariado. Também pode clicar
                    abaixo e apoiar outras causa.{' '}
                  </p>
                </>
              )}
            </Container.Text>
            <Container.Text>
              {state.campaignUnique?.moneyDonationOptions?.description ? (
                <Container.Address>
                  <span>Descrição</span>
                  {state.campaignUnique?.moneyDonationOptions?.description},
                </Container.Address>
              ) : null}
            </Container.Text>
          </Container.Input>
          {!exceededLimit && (
            <BlueButton onClick={handleDonateClick} loading={isLoading}>
              Finalizar Doação
            </BlueButton>
          )}
          {exceededLimit && (
            <BlueButton onClick={handleOpenModal} loading={isLoading}>
              Visualizar outras campanhas
            </BlueButton>
          )}
          <ModalCampaignDonatePay
            open={openModalCampaign}
            onClose={() => setOpenModalCampaign(false)}
          />
        </>
      )}
    </>
  );
};
