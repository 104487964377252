import { Container, Text } from './styles';

interface Props {
  startNumber: string | number;
  label: string;
  percentage: string;
  title: string;
  subTitle: string;
  imageSrc: string;
}

export const CardStatistics: React.FC<Props> = (item) => {
  return (
    <Container.box>
      <Container.img>
        <img src={item.imageSrc} alt="" />
      </Container.img>
      <Container.text>
        <Text.label>{item.subTitle}</Text.label>
        <Text.title>
          {item.startNumber} {item.title}
        </Text.title>
        <Text.label>{item.label}</Text.label>
      </Container.text>
    </Container.box>
  );
};
