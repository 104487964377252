import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Banner = styled.div`
  display: flex;
  padding: 8px 40px;
  background: ${(props) => props.theme.colors.orange};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  img {
    width: 180px;
  }
  p {
    height: fit-content;
    padding: 8px;
    border-radius: 8px;
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.xl};
    line-height: 52px;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.primary};
    text-shadow: 0px 2px 8px rgba(73, 80, 87, 0.08);
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    justify-content: center;
  }
  @media (max-width: ${theme.media.tablet}) {
    p {
      font-size: ${(props) => props.theme.fontSize.lg};
    }
    img {
      max-width: auto;
    }
  }
  @media (max-width: ${theme.media.tablet}) {
    p {
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }
`;

const Container__Reward = styled.div``;
const Container__OtherCause = styled.div``;

const Container__InputACL = styled.div`
display: none;
 @media (max-width: ${theme.media.mobile}) {
  display: flex;
  margin-top: 16px;
 }
`;

export const Container = {
  inputAcl: Container__InputACL,
  banner: Container__Banner,
  reward: Container__Reward,
  otherCause: Container__OtherCause,
};

const Text__Title = styled.div`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.x};
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
  padding-top: 32px;
`;

export const Text = {
  title: Text__Title,
};
