import { useState } from 'react';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { NewAccount } from './components/Account';
import { Avatar } from './components/Avatar';
import { LayoutAuth } from '../../../components/containers/LayoutAuth';
import background_login from '../../../assets/auth/background-login.png';
import { useForm } from './components/context/formContext';

export const CreateAccount: React.FC = () => {
  const [showAvatar, setShowAvatar] = useState(false);
  const { setAlert } = useAlert();

  const { formik } = useForm();

  const handleAdvanceButtonClick = () => {
    if (
      !formik ||
      formik.errors.name ||
      !formik.values.name ||
      formik.errors.email ||
      !formik.values.email ||
      formik.errors.password ||
      !formik.values.password ||
      formik.errors.confirmPassword ||
      !formik.values.confirmPassword ||
      !formik.values.isCheckboxChecked
    ) {
      setAlert('Verifique se todos os campos foram preenchidos', 'error');
      return;
    }
    setShowAvatar(true);
  };

  return (
    <LayoutAuth background={background_login}>
      {showAvatar ? (
        <Avatar setShowAvatar={setShowAvatar} />
      ) : (
        <NewAccount onAdvanceButtonClick={handleAdvanceButtonClick} />
      )}
    </LayoutAuth>
  );
};
