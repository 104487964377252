import { Content } from './styles';
import location from '../../../../../../..//assets/icons/location.svg';
import { LabelInput } from '../../../../../../../components/common/Input';
import { Container } from '../../styles';
import { BlueButton } from '../../../../../../../components/common/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { CompleteUserAccountUseCase } from '../../../../../../../../application/useCases/user/CompleteUserAccout';
import { SelectInputAuth } from '../../../../../../../components/common/SelectInput';
import { selectOptionsState } from '../../../../../../../components/common/SelectInput/components/SelectOptionsState';

const validationSchema = Yup.object().shape({
  telephone: Yup.string().min(9, 'O telefone deve ter no mínimo 9 caracteres').required('Digite o seu número de telefone'),
  city: Yup.string().required('Informe sua cidade'),
  state: Yup.string().required('Informe seu estado'),
});

export const FormAcessUser = () => {
  const { setAlert } = useAlert();
  const [loading, setloading] = useState(false);
  const formik = useFormik({
    initialValues: {
      telephone: '',
      city: '',
      state: '',
      address: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setloading(true);
      const { error } = await CompleteUserAccountUseCase(values);
      setloading(false);
      if (!error) {
        setAlert('Conta atualizada com sucesso', 'success');
        window.location.href = '/home';
      } else {
        setAlert(
          'Não foi possível realizar o cadastro! Verifique se os dados foram enviados corretamente!',
          'error'
        );
      }
    },
  });
  return (
    <Container.form onSubmit={formik.handleSubmit}>
      <Content.acess>
        <Content.form>
          <LabelInput
            htmlFor="telephone"
            id="telephone"
            name="telephone"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.telephone}
            error={
              formik.touched.telephone && formik.errors.telephone
                ? formik.errors.telephone
                : undefined
            }
            onBlur={formik.handleBlur}
            placeholder="+55 xxxxx-xxxx"
            aria-labelledby="telephone"
          >
            Telefone *
          </LabelInput>
        </Content.form>
      </Content.acess>
      <Content.acess>
        <Content.form>
          <LabelInput
            htmlFor="city"
            src1={location}
            id="city"
            name="city"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            placeholder="Cidade"
            aria-labelledby="city"
            error={formik.touched.city && formik.errors.city ? formik.errors.city : undefined}
          >
            Cidade *
          </LabelInput>
          <SelectInputAuth
            htmlFor="state"
            id="state"
            name="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby="state"
            options={[...selectOptionsState]}
            error={
              formik.touched.state && formik.errors.state ? formik.errors.state : undefined
            }
          >
            Estado
          </SelectInputAuth>
        </Content.form>
      </Content.acess>
      <BlueButton dataTest="btn-complete-user-account" type="submit" loading={loading}>
        Continuar
      </BlueButton>
    </Container.form>
  );
};
