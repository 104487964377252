/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */

interface Props {
	active: boolean;
}
export const Home = ({ active }: Props) => {

	if (active) return (
		<svg width="22" height="22" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.9987 2.12683L10.332 5.12683V10.3335H8.9987V6.3335H4.9987V10.3335H3.66536V5.12683L6.9987 2.12683ZM6.9987 0.333496L0.332031 6.3335H2.33203V11.6668H6.33203V7.66683H7.66536V11.6668H11.6654V6.3335H13.6654L6.9987 0.333496Z" fill="#fff"/>
		</svg>

	);

	return (
		<svg width="22" height="22" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.9987 2.12683L10.332 5.12683V10.3335H8.9987V6.3335H4.9987V10.3335H3.66536V5.12683L6.9987 2.12683ZM6.9987 0.333496L0.332031 6.3335H2.33203V11.6668H6.33203V7.66683H7.66536V11.6668H11.6654V6.3335H13.6654L6.9987 0.333496Z" fill="#1485C8"/>
		</svg>

	);
};
