import { HttpClient } from '../../HttpClient';

export interface ListHash {
  code: string;
  id: number;
  gooders: number;
  createdAt: string;
  redeemedAt: string;
  institution: {
    name: string;
  }
}

export async function ListHashUseCase() {
  const response = await HttpClient<void, { data: ListHash[] }>(
    'get',
    `/api/hashes`
  );
  return response;
}
