import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';
import { SharedUseCase } from '../item/CreateItemForCampaignUseCase';

interface CreateFinishGoal {
  amount: number
}

interface Params {
  orgId: number;
  campaignId: number;
}

export class FinishCampaignGoalUseCase extends SharedUseCase<
  Params,
  CreateFinishGoal,
  {
    payload: void | null;
    error: string | null;
  }
> {

  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execOrg() {
    const modifiedDto = {amount: this.dto!. amount * 100 };
    return await HttpClient<CreateFinishGoal, void>(
      'post',
      `/orders/manual/orgs/${this.params!.orgId}/${this.params!.campaignId}`, 
      modifiedDto
    );
  }
}
