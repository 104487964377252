import styled from "styled-components";
import { theme } from "../../../../../themes/blue"

const Container__Main = styled.div`
	display: flex;
	padding-top: 12px;
  flex-direction: column;
`;

const Container__Box = styled.div`
	display: flex;
	gap: 24px;
	padding-top: 12px;
`;


const Container__SubTitle = styled.p<{ active?: boolean }>`
font-weight: ${props => props.active && "700"};
border-bottom: ${props => props.active && "1px solid #FBB22F"};
  @media (max-width: ${theme.media.tablet}) {
    font-size: 11px
}
`;

const Container__Desktop = styled.div`
  width: 100%;
@media (max-width: ${theme.media.mobile}) {
  display: none;
}
`;

const Container__Mobile = styled.div`
  display: none;
  width: 100%;
@media (max-width: ${theme.media.mobile}) {
  display: initial;
}
`;

export const Container = {
	box: Container__Box,
  main: Container__Main,
  subTitle: Container__SubTitle,
  desktop: Container__Desktop,
  mobile: Container__Mobile
};

