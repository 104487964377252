import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container } from './styles';
import { LayoutModal } from '../../../components/common/CardsCause/component/LayoutModal';
import { DescriptionCampaign } from './components/DescriptionCampaign';
import { BlueButton, SmallBlueButton } from '../../../components/common/Button';
import share from '../../../assets/icons/share.svg';
import {
  GetCampaign,
  UniqueCampaignOutput,
} from '../../../../application/useCases/campaign/GetCampaign';
import { useGlobalCampaignUnique } from '../../../../infra/state/useCampaign';
import { ImageCampaign } from './components/ImageCampaign';
import { HeaderCampaign } from './components/HeaderCampaign';
import { HeaderPages } from '../../../components/common/HeaderPages';
import { DonationItems } from './components/DonationItems';
import { DonationVoluntary } from './components/DonationsVoluntary';

export interface Props {
  cause: UniqueCampaignOutput;
  selectedCause: number | null;
}

enum ActiveTabEnum {
  'ITEMS',
  'VOLUNTARY',
}

export const CampaignUnit: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const location = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const state = useGlobalCampaignUnique();
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.ITEMS);

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await GetCampaign(location.path || '');
      if (!error && payload) {
        state.setCampaignUnique(payload.data);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleButtonClick = () => {
    setIsLoading(true);
    setOpenModal(true);
    setIsLoading(false);
    window.scrollTo(0, 0);
  };

  const handleShare = () => {
    navigator.share({
      title: document.title,
      text: 'Gooders',
      url: window.location.href,
    });
  };

  useEffect(() => {
    if (
      state.campaignUnique &&
      state.campaignUnique.items &&
      state.campaignUnique.items.length > 0
    ) {
      setActiveTab(ActiveTabEnum.ITEMS);
    } else if (
      state.campaignUnique &&
      state.campaignUnique.vacancies &&
      state.campaignUnique.vacancies.length > 0
    ) {
      setActiveTab(ActiveTabEnum.VOLUNTARY);
    }
  }, [state.campaignUnique?.items, state.campaignUnique?.vacancies]);

  return (
    <HeaderPages title="Campanha">
      <>
        <Container.main>
          {state.campaignUnique?.images[0] && (
            <ImageCampaign srcOfImages={state.campaignUnique?.images.map(({ src }) => src) ?? []} />
          )}
          <Container.description>
            <HeaderCampaign />
            {state.campaignUnique?.moneyDonationOptions && <DescriptionCampaign />}
            <Container.Progress>
              <span>{state.campaignUnique?.progress ?? 0}%</span>
              <div className="progress">
                <div
                  className="barprogress"
                  style={{ width: `${state.campaignUnique?.progress}%`, maxWidth: '100%' }}
                ></div>
              </div>
            </Container.Progress>
            <>
              {state.campaignUnique?.isActive ? (
                <BlueButton onClick={handleButtonClick} loading={isLoading}>
                  Apoiar Causa
                </BlueButton>
              ) : (
                <BlueButton onClick={() => {}} loading={false}>
                  Campanha desativada
                </BlueButton>
              )}
            </>
            <Container.box>
              <Container.share onClick={handleShare}>
                <p>Compartilhe esta causa:</p>
                <span>
                  <img src={share} alt="gooders" />
                  clique aqui
                </span>
              </Container.share>
            </Container.box>
          </Container.description>
          <LayoutModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            selectedCause={state.campaignUnique?.id || null}
          />
        </Container.main>
        {(state.campaignUnique?.items && state.campaignUnique.items?.length > 0) ||
        (state.campaignUnique?.vacancies && state.campaignUnique?.vacancies?.length > 0) ? (
          <Container.main style={{ paddingTop: 0, width: '100%' }}>
            <Container.content>
              <Container.wrapper>
                {state.campaignUnique?.items?.length > 0 && (
                  <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.ITEMS)}>
                    <Container.subTitle active={activeTab === ActiveTabEnum.ITEMS}>
                      Itens
                    </Container.subTitle>
                  </Link>
                )}
                {state.campaignUnique?.vacancies?.length > 0 && (
                  <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.VOLUNTARY)}>
                    <Container.subTitle active={activeTab === ActiveTabEnum.VOLUNTARY}>
                      Voluntariado
                    </Container.subTitle>
                  </Link>
                )}
              </Container.wrapper>
              <Container.wrapper style={{ width: '100%' }} id="ItemAndVoluntary">
                {activeTab === ActiveTabEnum.ITEMS && state.campaignUnique?.items.length > 0 && (
                  <DonationItems />
                )}
                {activeTab === ActiveTabEnum.VOLUNTARY &&
                  state.campaignUnique?.vacancies?.length > 0 && <DonationVoluntary />}
              </Container.wrapper>
            </Container.content>
          </Container.main>
        ) : null}
      </>
    </HeaderPages>
  );
};
