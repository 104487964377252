import { boolean } from "yup";
import { HttpClient } from "../../HttpClient";


export interface ListSupportedCampaign {
  category: string;
  city: string;
  createdAt: string;
  id: number;
  image: string;
  institutionId: number;
  progress: number;
  state: string;
  summary: string;
  title: string;
  donations: {
    items: boolean;
    money: boolean;
    voluntary: boolean;
  }
  ods: string;
  description: string;
} 


export async function ListSupportedCampaignUseCase() {

  const response = await HttpClient<void, ListSupportedCampaign[]>
  ('get', '/campaigns/users');
  return response;
  
  }
