import { HttpClient } from "../../HttpClient";

export interface CreateVacancieOrderDto {
  campaignId: number;
  vacancieId: number;
  quantityHours?: number;
}

export async function UserSubscribeInVacancy (
  dto: CreateVacancieOrderDto,
) {
  return await HttpClient<CreateVacancieOrderDto, void>(
    'post',
    '/orders/vacancies',
    dto
  );
}
