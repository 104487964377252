import { CreatePaymentOrderPayloadBoleto } from '../../../../../../../../../../application/useCases/checkout/CreatePaymentOrder';
import styled from 'styled-components';
import { BlueButton } from '../../../../../../../../../components/common/Button';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  payload: CreatePaymentOrderPayloadBoleto;
  children: ReactNode;
}

export const PayBoleto = ({ payload, children }: Props) => {
  const CopyPaymentLinkToClipboard = () => {
    navigator.clipboard.writeText(payload.barcode);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding="0 1rem"
      height="100%"
      gap="1rem"
    >
      <BlueButton loading={false}>
        <BoletoStyle href={payload.urlPdf} target="_blank">
          Baixar boleto
        </BoletoStyle>
      </BlueButton>

      {children}
    </Box>
  );
};

/*
  Styled-Components
*/

const BoletoStyle = styled.a`
  width: 80%;
  max-width: 400px;
`;
