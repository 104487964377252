import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { ReactNode, createContext, useContext, useState } from 'react';
import * as Yup from 'yup';
import { CreateUserAccountUseCase } from '../../../../../../application/useCases/user/CreateUserAccount';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { LoginUserUseCase } from '../../../../../../application/useCases/user/LoginUser';
import { useNavigate } from 'react-router-dom';

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Preencha seu nome de usuário'),
  email: Yup.string()
    .matches(emailRegex, 'Digite um email válido')
    .required('O campo de email é obrigatório'),
  password: Yup.string().min(4, 'A senha deve ter no mínimo 4 caracteres').required('Informe uma senha'),
  username: Yup.string()
    .required('Informe seu nome de usuário')
    .max(15, 'O nome de usuário deve ter no máximo 15 caracteres'),
  confirmPassword: Yup.string().required('Confirme sua senha de acesso'),
  isCheckboxChecked: Yup.boolean().isTrue('Aceite os termos e condições'),
});
type Values = {
  name: string;
  email: string;
  password: string;
  username: string;
  avatar: string;
  confirmPassword: string;
  isCheckboxChecked: boolean;
};
type formikType = {
  initialValues: Values;
  errors: FormikErrors<Values>;
  values: Values;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: FormikTouched<Values>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleRenderError(inputId: keyof Values): string | undefined;
  submitForm: () => Promise<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Values>>;
};

const Context = createContext<{ formik: formikType; loading: boolean }>(
  {} as { formik: formikType; loading: boolean }
);

interface Props {
  children: ReactNode;
}
export const FormContext = ({ children }: Props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      username: '',
      avatar: '',
      confirmPassword: '',
      isCheckboxChecked: false,
    },
    validate: ({ password, confirmPassword }) => {
      if (password !== confirmPassword) {
        return { confirmPassword: 'A senha informada esta diferente!' };
      }
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { error } = await CreateUserAccountUseCase(values);

      if (error) {
        setLoading(false);
        setAlert(error, 'error');
        return;
      }
      const response = await LoginUserUseCase(values);
      if (!response.error) {
        window.location.pathname = '/createacess';
      } else {
        navigate('/login');
      }
      setLoading(false);
    },
  });

  function handleRenderError(inputId: keyof Values) {
    return formik.touched[inputId] && formik.errors[inputId] ? formik.errors[inputId] : undefined;
  }

  return (
    <Context.Provider value={{ formik: { ...formik, handleRenderError }, loading }}>
      {children}
    </Context.Provider>
  );
};

export const useForm = () => {
  return useContext(Context);
};
