import { Container } from '../../../../../components/shared/gerenciar-campanha/TableListCampaignOrg/styles';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../components/common/Table';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { useState } from 'react';
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { ItemsOrders } from '../../../../../../application/useCases/dahsboard/InstitutionDashboardUseCase';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { StatusItemsUseCase } from '../../../../../../application/useCases/donation/StatusItemsUseCase';
import { dateSortComparator, dateValueFormatter } from '../../../../../../application/utils';
import { CardData, CardsTable } from '../../../../common/CardsTable';
import { ModalEditStatus } from './components/ModalEditStatus';

export const ItemsTableDahsboard = ({ data }: { data: ItemsOrders[] | undefined }) => {
  const { setAlert } = useAlert();
  const users = useGlobalStore();
  const statusOptions = ['PENDENTE', 'CANCELADO', 'SUCESSO'];
  const [itemdonation, setItems] = useState<ItemsOrders[]>(data || []);
  const [renderConfirmModal, setRenderConfirmModal] = useState(false);
  const [renderEditStatusModal, setRenderEditStatusModal] = useState(false);
  const [idOfOrderToChangeStatus, setIdOfOrderToChangeStatus] = useState({
    id: 0,
    newStatus: '',
  });

  async function updateStatus(status: typeof idOfOrderToChangeStatus) {
    if (status.newStatus !== 'CANCELADO' && status.newStatus !== 'SUCESSO') {
      setAlert('Selecione um status válido!', 'error');
      return;
    }

    const instId = users.state.acl.selectedACL?.id;
    if (!instId) {
      return;
    }
    const itemId = status.id;
    const { error } = await StatusItemsUseCase(status.newStatus, instId, itemId);
    if (!error) {
      setAlert('Status atualizado com sucesso!', 'success');
      setItems((data) =>
        data.map((data) => {
          if (data.id === status.id) {
            data.orderStatus = status.newStatus;
          }
          return data;
        })
      );
    } else {
      setAlert(error, 'error');
    }
  }

  function onCloseConfirmModal(result: boolean) {
    if (result) {
      updateStatus(idOfOrderToChangeStatus);
    }
    setRenderConfirmModal(false);
  }

  async function handleStatusChange(event: SelectChangeEvent<any>, params: GridCellParams) {
    const value = event.target.value as string;
    const id = params.row.id as number;

    setIdOfOrderToChangeStatus({ id, newStatus: value });
    setRenderConfirmModal(true);
  }

  const columns: GridColDef[] = [
    { field: 'Usuário', headerName: 'Usuário', minWidth: 216, flex: 1, headerClassName: 'table-header', },
    { field: 'Campanha', headerName: 'Campanha', minWidth: 240, flex: 1, headerClassName: 'table-header', },
    { field: 'Item', headerName: 'Item', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
    { field: 'Quantidade', headerName: 'Quantidade', minWidth: 120, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center', },
    {
      field: 'Data', headerName: 'Data de inscrição', minWidth: 140, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center',
      sortComparator: dateSortComparator,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'Status',
      headerName: 'Status',
      minWidth: 120,
      flex: 1,
      headerClassName: 'table-header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const selectedValue = params.value || '';
        return (
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <label id={`status-label-${params.row.id}`}>
                <Select
                  className="select"
                  labelId={`status-label-${params.row.id}`}
                  value={selectedValue}
                  onChange={(event) => {
                    handleStatusChange(event, params);
                  }}
                  sx={{
                    '& fieldset': {
                      border: 'none',
                    },
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </label>
            </FormControl>
          </Box>
        );
      },
    },
  ];

  const rows =
    (data?.map((data: ItemsOrders, index: number) => ({
      index: index,
      id: data.id,
      Usuário: data.name,
      Campanha: data.campaignTitle,
      Item: data.itemName,
      Status: data.orderStatus,
      Quantidade: data.quantity,
      Data: new Date(data.createdAt),
    })) ?? [])
      .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

  const cardData: CardData[] = (data ?? []).map((data: ItemsOrders) => ({
    id: data.id,
    title: [
      'Usuário',
      'Campanha',
      'Item',
      'Status',
      'Quantidade',
      'Data',
    ],
    value: [
      data.name,
      data.campaignTitle,
      data.itemName,
      data.orderStatus,
      data.quantity,
      new Date(data.createdAt).toLocaleDateString('pt-BR'),
    ],
  }));

  function IconStatus(id: number) {
    setIdOfOrderToChangeStatus({ id, newStatus: '' });
    setRenderEditStatusModal(true);
  }

  return (
    <>
      <Container.main>
        <Container.title>Doação de Itens</Container.title>
        <Container.table>
          <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />
        </Container.table>
        <Container.mobile>
          <CardsTable cardData={cardData} IconStatus={IconStatus} />
        </Container.mobile>
        {renderConfirmModal && (
          <ModalConfirm
            title="Deseja atualizar o status?"
            content="Uma vez que o status for atualizado não será possível desfazer essa ação!"
            rightButtonText="Atualizar"
            onClose={onCloseConfirmModal}
          />
        )}
        {renderEditStatusModal && (
          <ModalEditStatus
            onClose={(status) => {
              setRenderEditStatusModal(false);
            }}
            idOfOrderToChangeStatus={idOfOrderToChangeStatus}
            updateStatus={updateStatus}
          />
        )}
      </Container.main>
    </>
  );
};
