import { HttpClient } from "../../HttpClient";

export interface EditCoupon {
  quantity: number;
  code: string;
  description: string;
  goodersPrice: number;
  partnerId: number;
  expiresAt:  string;
  type: string;
  off: number;
  id: number
}


  export async function EditCouponUseCase (
    dto: EditCoupon,) {
      dto.goodersPrice *= 100;
    return await HttpClient<EditCoupon, void>(
      'put',
      `/api/rewards/coupons/${dto.id}`,
      dto
    );
  }
  


