import { HttpClient } from '../../HttpClient';



export async function CreatePatnerUseCase(formData: FormData) {
  const { error, payload } = await HttpClient<FormData, void>(
    'post', 
    '/api/rewards/partners', 
    formData,
    true);
  if (!error && payload) {
    return {
      data: payload,
    };
  } else {
    return { error };
  }
}

