import { Container } from '../../../TableListPartner/components/ModalEditPartner/styles';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import close from '../../../../../../../assets/icons/close.svg';
import { validationSchema } from '../../../../../../users/createusertype/components/FormCreateInst/index';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { SmallBlueButton } from '../../../../../../../components/common/Button';
import { Institution } from '../../../../../../../../application/useCases/gooders/ListInstitutionsUseCase';
import { EditInstitutionsUseCase } from '../../../../../../../../application/useCases/gooders/EditInstitutionsUseCase';

interface FormCreatePatnerProps {
  institution: Institution;
  onClose: () => void;
}
export const ModalEditInstitution: React.FC<FormCreatePatnerProps> = ({ institution, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      name: '',
      document: '',
      telephone: '',
      id: '',
      splitKey: '',
      percentageSplit: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const { error } = await EditInstitutionsUseCase({
        ...values,
        id: +values.id,
        split: {
          percentageSplit: values.percentageSplit,
          splitKey: values.splitKey,
        },
      });

      if (error) {
        setAlert(error, 'error');
        setLoading(false);
        return;
      }

      setAlert('Instituição alterada com sucesso', 'success');
      setLoading(false);
      onClose();
    },
  });

  useEffect(() => {
    formik.setValues({
      name: institution.name || '',
      document: institution.document || '',
      telephone: institution.telephone || '',
      splitKey: institution.split.splitKey || '',
      percentageSplit: institution.split.percentageSplit || '',
      id: institution.id.toString(),
    });
  }, [institution]);

  return (
    <Container.box>
      <Container.modal>
        <Container.close src={close} alt="warning" onClick={onClose} />
        <Container.head>
          <Container.subTitle data-test="title-Patner">Editar Instituição</Container.subTitle>
        </Container.head>
        <Container.wrapper onSubmit={formik.handleSubmit}>
          <Container.content>
            <Container.form>
              <Container.input>
                <LabelInputFormCampaign
                  id="name"
                  name="name"
                  type="text"
                  htmlFor="name"
                  placeholder="Nome"
                  aria-labelledby="name"
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                >
                  Nome
                </LabelInputFormCampaign>
              </Container.input>
              <Container.input>
                <LabelInputFormCampaign
                  htmlFor="document"
                  id="document"
                  name="document"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.document}
                  placeholder="00.000.000/0000-00"
                  aria-labelledby="document"
                  error={
                    formik.touched.document && formik.errors.document
                      ? formik.errors.document
                      : undefined
                  }
                >
                  Documento
                </LabelInputFormCampaign>
              </Container.input>
              <Container.input>
                <LabelInputFormCampaign
                  htmlFor="telephone"
                  id="telephone"
                  name="telephone"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telephone}
                  placeholder="Email ou telefone"
                  aria-labelledby="telephone"
                  error={
                    formik.touched.telephone && formik.errors.telephone
                      ? formik.errors.telephone
                      : undefined
                  }
                >
                  Telefone
                </LabelInputFormCampaign>
              </Container.input>

              <Container.input>
                <LabelInputFormCampaign
                  htmlFor="splitKey"
                  id="splitKey"
                  name="splitKey"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.splitKey}
                  placeholder=""
                  aria-labelledby="splitKey"
                  error={
                    formik.touched.splitKey && formik.errors.splitKey
                      ? formik.errors.splitKey
                      : undefined
                  }
                >
                  Chave split
                </LabelInputFormCampaign>
              </Container.input>

              <Container.input>
                <LabelInputFormCampaign
                  htmlFor="percentageSplit"
                  id="percentageSplit"
                  name="percentageSplit"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.percentageSplit}
                  placeholder=""
                  aria-labelledby="percentageSplit"
                  error={
                    formik.touched.percentageSplit && formik.errors.percentageSplit
                      ? formik.errors.percentageSplit
                      : undefined
                  }
                >
                  Porcetagem de repasse para instituicao
                </LabelInputFormCampaign>
              </Container.input>
            </Container.form>
          </Container.content>
          <SmallBlueButton type="submit" loading={loading}>
            Salvar
          </SmallBlueButton>
        </Container.wrapper>
      </Container.modal>
    </Container.box>
  );
};
