
import { Container, Button } from '../gerenciar-campanhas/styles';
import more from '../../../assets/icons/more.svg';
import { useState } from 'react';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { SmallBlueButton } from '../../../components/common/Button';
import { CreateRewards } from './components/CreateRewards';
import { TableListRewards } from './components/TableListRewards';

export const GerenciarRecompensas = () => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleButtonClick = () => {
        setLoading(true);
        setOpenModal(true);
        setLoading(false);
    };

    return (
        <>
            <LayoutSidebar>
                <Container.box>
                    <Container.header>
                        <Container.title data-test="title-campaign">Recompensas</Container.title>
                        <Button>
                            <SmallBlueButton onClick={handleButtonClick} loading={loading}>
                                <img src={more} alt="more" />
                                Criar Recompensas
                            </SmallBlueButton>
                        </Button>
                    </Container.header>
                    <TableListRewards />
                </Container.box>
            </LayoutSidebar>
            <CreateRewards open={openModal} onClose={() => setOpenModal(false)} />
        </>
    );
};
