import { HttpClient } from "../../HttpClient"

export interface GenerateHashInterface {
  count: number,
  goodersAmount: number,
  institutionId: number
}

export async function GenerateHashUseCase(dto: GenerateHashInterface) {
  dto.goodersAmount *= 100
  return await HttpClient<GenerateHashInterface, void>(
    'post',
    '/api/hashes/generate',
    dto
  )
}
