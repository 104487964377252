import { useCallback, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { useGlobalCampaignUnique } from '../../../../../../infra/state/useCampaign';
import { GreenButton, RedButton } from '../../../../../components/common/Button';
import { UpdateActiveStatusOfCampaignUseCase } from '../../../../../../application/useCases/org/campaign/OrgUpdateActiveStatusOfCampaign';

interface Props {
  useCase: UpdateActiveStatusOfCampaignUseCase;
}
export const ChangeActiveStatus = ({ useCase }: Props) => {
  const { campaignUnique, actions } = useGlobalCampaignUnique();
  const { setAlert } = useAlert();
  const [loadingUpdateIsActiveStatus, setLoadingUpdateIsActiveStatus] = useState(false);
  const user = useGlobalStore();

  const handleChangeCampaignStatus = useCallback(
    async (newIsActiveStatus: boolean) => {
      setLoadingUpdateIsActiveStatus(true);
      if (!campaignUnique || !user.state.acl.selectedACL) {
        setAlert('Não foi possível alterar o status da campanha', 'error');
        return;
      }

      const campaignId = campaignUnique.id;
      const institutionId = campaignUnique.institution.id;
      const orgId = user.state.acl.selectedACL.id;

      const { error } = await useCase.execute(
        { orgId, institutionId, campaignId },
        { isActive: newIsActiveStatus }
      );

      if (!error) {
        actions.changeActiveStatus(newIsActiveStatus);
        setAlert('Status atualizado com sucesso', 'success');
      } else {
        setAlert(error, 'error');
      }

      setLoadingUpdateIsActiveStatus(false);
    },
    [campaignUnique, user.state.acl.selectedACL]
  );

  if (campaignUnique?.isActive)
    return (
      <RedButton
        loading={loadingUpdateIsActiveStatus}
        onClick={() => handleChangeCampaignStatus(false)}
      >
        Desativar
      </RedButton>
    );

  return (
    <GreenButton
      loading={loadingUpdateIsActiveStatus}
      onClick={() => handleChangeCampaignStatus(true)}
    >
      Ativar
    </GreenButton>
  );
};
