import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleLogout } from '../../../../application/handlers/HandleLogout';
import { UserRoleEnum, UserTypeEnum } from '../../../../domain/interfaces/User';
import { useAuth } from '../../../../infra/config/AuthProvider/useAuth';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { NotAuthorized } from '../../../pages/notauthorized';
import { LoadingPage } from '../../common/LoadingPage';

interface Props {
  children: JSX.Element;
}

interface Props2 {
  minRoleLvl: UserRoleEnum;
  children: JSX.Element;
}

interface HandleComponentProps {
  components: {
    [UserTypeEnum.GOODERS]?: ReactElement;
    [UserTypeEnum.ORG]?: ReactElement;
    [UserTypeEnum.INSTITUTION]?: ReactElement;
  };
}

export const HandleComponent = ({ components }: HandleComponentProps) => {
  const {
    state: { user, acl },
  } = useGlobalStore();

  const [currentUserType, setCurrentUserType] = useState<UserTypeEnum>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (acl) {
      if (acl.goodersMember) {
        setCurrentUserType(UserTypeEnum.GOODERS);
      } else if (acl.selectedACL?.type) {
        setCurrentUserType(acl.selectedACL?.type);
      }

      setLoading(false);
    }
  }, [user, acl]);

  if (!loading && components[currentUserType!]) return components[currentUserType!] as ReactElement;
  if (!loading && !components[currentUserType!]) {
    return <NotAuthorized />;
  }

  return <LoadingPage></LoadingPage>;
};

export const AclRouteHandler = ({ minRoleLvl, children }: Props2) => {
  const {
    state: { acl },
  } = useGlobalStore();

  if (minRoleLvl === UserRoleEnum.VOLUNTEER) return children;

  if (minRoleLvl === UserRoleEnum.USER && acl.selectedACL?.role !== UserRoleEnum.VOLUNTEER)
    return children;

  if (minRoleLvl === UserRoleEnum.ADMIN && acl.selectedACL?.role === UserRoleEnum.ADMIN)
    return children;

  return <NotAuthorized />;
};

export const ProtectedRoute = ({ children }: Props) => {
  const [waitAuth, setWaitAuth] = useState(true);
  const navigate = useNavigate();

  const {
    state: { user },
  } = useGlobalStore();
  const { loadingUserData } = useAuth();

  useEffect(() => {
    async function handleWithAccessOrRedirect() {
      if (!user) {
        setWaitAuth(false);
        return handleLogout();
      } else if (!user.completed) {
        setWaitAuth(false);
        navigate('/createacess');
      } else {
        setWaitAuth(false);
      }
    }
    if (!loadingUserData) {
      handleWithAccessOrRedirect();
    }
  }, [user, loadingUserData]);

  if (!waitAuth) return children;
  return <></>;
};
