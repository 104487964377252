import { Container, Text } from './styles';
import { LabelInputFormCampaign } from '../../../../../common/Input';
import { TextArea } from '../../../../../common/TextArea';
import { SelectInput } from '../../../../../common/SelectInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { CampaignContext } from '../../../../../../../infra/config/CampaignContext';
import { useAlert } from '../../../../../../../infra/config/AlertContext/useAlert';
import { useGlobalStore } from '../../../../../../../infra/state/GlobalStore';
import {
  ListInstituion,
  ListInstitutionGoodersUseCase,
  ListInstitutionUseCase,
} from '../../../../../../../application/useCases/institution/ListInstitutionUseCase';
import { UserTypeEnum } from '../../../../../../../domain/interfaces/User';
import {
  selectOptionsOds,
  selectOptionsState,
} from '../../../../../common/SelectInput/components/SelectOptionsState';

export const validationSchema = Yup.object().shape({
  title: Yup.string().max(60, 'Limite de caracteres excedido').required('Preencha o título'),
  summary: Yup.string()
    .required('Preencha uma breve descrição')
    .max(255, 'Limite de caracteres excedido'),
  description: Yup.string()
    .required('Informe a descrição da sua causa')
    .max(700, 'Limite de caracteres excedido'),
  category: Yup.string()
    .required('Selecione a categoria')
    .oneOf(
      [
        'Combate à fome',
        'Combate à pobreza',
        'Saúde',
        'Educação',
        'Água',
        'Energia',
        'Desigualdade',
        'Ação Climática',
        'Vida Marinha',
        'Vida Terreste',
      ],
      'Selecione uma opção válida'
    ),
  responsible: Yup.string().required('Nome da pessoa responsável'),
  telephone: Yup.string()
    .optional()
    .matches(/^[0-9]+$/, 'Insira um número válido'),
  address: Yup.string().optional().max(255, 'Limite de caracteres excedido'),
  city: Yup.string().max(50, 'Limite de caracteres excedido').required('Insira a cidade'),
  state: Yup.string().required('Insira o eestado'),
  institutionId: Yup.string().required('Selecione a Instituição'),
  ods: Yup.string().required('Selecione um Objetivo de Desenvolvimento').oneOf(
    [
      'Erradicação da pobreza',
      'Fome zero e agricultura sustentável',
      'Saúde e Bem-Estar',
      'Educação de qualidade',
      'Igualdade de gênero',
      'Água potável e saneamento',
      'Energia limpa e acessível',
      'Trabalho decente e crescimento econômico',
      'Indústria, inovação e infraestrutura',
      'Redução das desigualdades',
      'Cidades e comunidades sustentáveis',
      'Consumo e produção responsáveis',
      'Ação contra a mudança global do clima',
      'Vida na água',
      'Vida terrestre',
      'Paz, Justiça e Instituições Eficazes',
      'Parcerias e meios de implementação'
    ],
    'Selecione uma opção válida'
  ),

});

export const CreateCause = () => {
  const { information, setInformation } = useContext(CampaignContext);
  const defaultOption = { value: '', label: 'Selecione uma opção', disabled: true, selected: true };
  const [loading, setLoading] = useState(false);
  const [listInstitution, setListInstitution] = useState<ListInstituion[]>([]);
  const { setAlert } = useAlert();
  const users = useGlobalStore();

  const formik = useFormik({
    initialValues: {
      title: '',
      summary: '',
      description: '',
      category: '',
      responsible: '',
      telephone: '',
      address: '',
      city: '',
      state: '',
      institutionId: '',
      ods: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = {
        ...information,
        ...values,
        title: values.title,
        summary: values.summary,
        description: values.description,
        category: values.category,
        responsible: values.responsible,
        telephone: values.telephone,
        address: values.address,
        city: values.city,
        state: values.state,
        institutionId: Number(values.institutionId),
        ods: values.ods,
      };
      setInformation(data);
      return;
    },
  });

  useEffect(() => {
    if (information) {
      formik.setFieldValue('institutionId', information.institutionId || '');
      formik.setFieldValue('title', information.title || '');
      formik.setFieldValue('summary', information.summary || '');
      formik.setFieldValue('description', information.description || '');
      formik.setFieldValue('category', information.category || '');
      formik.setFieldValue('responsible', information.responsible || '');
      formik.setFieldValue('telephone', information.telephone || '');
      formik.setFieldValue('address', information.address || '');
      formik.setFieldValue('city', information.city || '');
      formik.setFieldValue('state', information.state || '');
      formik.setFieldValue('ods', information.ods || '');
    }
  }, []);
  useEffect(() => {
    return () => {
      if (!formik.isValidating && Object.keys(formik.errors).length === 0 && loading === false) {
        setLoading(true);
        formik.handleSubmit();
        setLoading(false);
      }
    };
  }, []);

  useEffect(() => {
    const selectedACLType = users.state.acl.selectedACL?.type;
    const selectedACLId = users.state.acl.selectedACL?.id;

    const fetchData = async (fetchFunction: () => Promise<{ error: any, payload: any }>) => {
      const { error, payload } = await fetchFunction();
      if (!error && payload) {
        setListInstitution(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    if (selectedACLType === UserTypeEnum.INSTITUTION) {
      formik.setFieldValue('institutionId', selectedACLId);
    } else if (selectedACLType === UserTypeEnum.ORG) {
      if (selectedACLId !== undefined) {
        fetchData(() => ListInstitutionUseCase(selectedACLId));
      }
    } else if (selectedACLType === UserTypeEnum.GOODERS) {
      fetchData(ListInstitutionGoodersUseCase);
    }
  }, []);

  return (
    <Container.main>
      <Text.title>Informações básicas</Text.title>
      <Container.input>
        <Container.header>
          {users.state.acl.selectedACL?.type !== 'INSTITUTION' && (
            <SelectInput
              htmlFor="institutionId"
              id="institutionId"
              name="institutionId"
              value={formik.values.institutionId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={[
                defaultOption,
                ...listInstitution.map((institution) => ({
                  value: String(institution.id),
                  label: institution.name,
                })),
              ]}
              error={
                formik.touched.institutionId && formik.errors.institutionId
                  ? formik.errors.institutionId
                  : undefined
              }
            >
              Instituição *
            </SelectInput>
          )}
          <LabelInputFormCampaign
            htmlFor="title"
            id="title"
            name="title"
            type="text"
            maxLength={25}
            placeholder=""
            value={formik.values.title}
            onChange={formik.handleChange}
            aria-labelledby="Título"
            onBlur={formik.handleBlur}
            error={formik.touched.title && formik.errors.title ? formik.errors.title : undefined}
          >
            Título *
          </LabelInputFormCampaign>
        </Container.header>
        <Container.textarea>
          <TextArea
            htmlFor="summary"
            id="summary"
            name="summary"
            rows={3}
            maxLength={255}
            placeholder=""
            value={formik.values.summary}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby="Resumo da campanha"
            error={
              formik.touched.summary && formik.errors.summary ? formik.errors.summary : undefined
            }
          >
            Resumo da campanha *
          </TextArea>
          <TextArea
            htmlFor="description"
            id="description"
            name="description"
            rows={3}
            maxLength={700}
            placeholder=""
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby="Descrição do projeto"
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : undefined
            }
          >
            Descrição do projeto *
          </TextArea>
        </Container.textarea>
        <Container.inputs>
          <SelectInput
            htmlFor="category"
            id="category"
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={[
              defaultOption,
              { value: 'Combate à fome', label: 'Combate à fome' },
              { value: 'Combate à pobreza', label: 'Combate à pobreza' },
              { value: 'Saúde', label: 'Saúde' },
              { value: 'Educação', label: 'Educação' },
              { value: 'Água', label: 'Água' },
              { value: 'Energia', label: 'Energia' },
              { value: 'Desigualdade', label: 'Desigualdade' },
              { value: 'Ação Climática', label: 'Ação Climática' },
              { value: 'Vida Marinha', label: 'Vida Marinha' },
              { value: 'Vida Terreste', label: 'Vida Terreste' },
            ]}
            error={
              formik.touched.category && formik.errors.category ? formik.errors.category : undefined
            }
          >
            Categoria *
          </SelectInput>
          <SelectInput
            htmlFor="ods"
            id="ods"
            name="ods"
            placeholder=""
            value={formik.values.ods}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            options={[defaultOption, ...selectOptionsOds]}
            aria-labelledby="ods"
            error={formik.touched.ods && formik.errors.ods ? formik.errors.ods : undefined}
          >
            Objetivo de Desenvolvimento *
          </SelectInput>
          <LabelInputFormCampaign
            htmlFor="responsible"
            id="responsible"
            name="responsible"
            type="text"
            maxLength={25}
            placeholder=""
            value={formik.values.responsible}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            aria-labelledby="responsible"
            error={
              formik.touched.responsible && formik.errors.responsible
                ? formik.errors.responsible
                : undefined
            }
          >
            Pessoa responsável *
          </LabelInputFormCampaign>

        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="telephone"
            id="telephone"
            name="telephone"
            type="text"
            placeholder=""
            value={formik.values.telephone}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            aria-labelledby="Descrição do projeto"
            error={
              formik.touched.telephone && formik.errors.telephone
                ? formik.errors.telephone
                : undefined
            }
          >
            Telefone
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="address"
            id="address"
            name="address"
            type="text"
            placeholder=""
            value={formik.values.address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            aria-labelledby="Endereço"
            error={
              formik.touched.address && formik.errors.address ? formik.errors.address : undefined
            }
          >
            Endereço
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="city"
            id="city"
            name="city"
            type="text"
            placeholder=""
            value={formik.values.city}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            aria-labelledby="Cidade"
            error={formik.touched.city && formik.errors.city ? formik.errors.city : undefined}
          >
            Cidade *
          </LabelInputFormCampaign>
          <SelectInput
            htmlFor="state"
            id="state"
            name="state"
            placeholder=""
            value={formik.values.state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            options={[...selectOptionsState]}
            aria-labelledby="Estado"
            error={formik.touched.state && formik.errors.state ? formik.errors.state : undefined}
          >
            Estado *
          </SelectInput>
        </Container.inputs>
      </Container.input>
    </Container.main>
  );
};
