import { HttpClient } from "../../HttpClient";

export interface StatusItems {
  status: string,
}

export async function StatusItemsUseCase(status: string, instId: number, itemId: number) {
  const dto: StatusItems = {
    status: status,
  };

  return await HttpClient<StatusItems, void>(
    'patch',
    `/orders/institution/${instId}/items/${itemId}`,
    dto
  );
}
  
