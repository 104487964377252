import Table from '../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { Container } from '../../../../gooders/gerenciar-usuarios/components/TableListPartner/styles';
import { GridColDef } from '@mui/x-data-grid';
import createUser from '../../../../../assets/icons/createUser.svg';
import seeUsers from '../../../../../assets/icons/seeUsers.svg';
import { Institution } from '../../../../../../application/useCases/gooders/ListInstitutionsUseCase';
import { UserTypeEnum } from '../../../../../../domain/interfaces/User';
import { SearchTableListUser } from '../../../../gooders/gerenciar-usuarios/components/TableListPartner/components/SearchTableListUser';
import { ListInstitutionsOrgUseCase } from '../../../../../../application/useCases/org/ListInstitutionsOrgUseCase';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { ConnectUserInstOrgUseCase } from '../../../../../../application/useCases/org/ConnectUserInstOrgUseCase';
import { ModalConnectUserOrg } from './components/ModalConnectUserOrg';
import { ModalListConnectUserOrg } from './components/ModaListConnectUserOrg';
import { ListConnectUserInstOrgUseCase } from '../../../../../../application/useCases/org/ListConnectUserInstOrgUseCase';
import { DeleteConnectionUserInstituitonOrgUseCase } from '../../../../../../application/useCases/org/DeleteConnectionUserInstituitonOrgUseCase';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';

export const TableListInstitutionOrg = () => {
  const [listInstitutions, setListInstitutions] = useState<Institution[]>([]);
  const { setAlert } = useAlert();
  const [search, setSearch] = useState('');
  const clearFilters = () => {
    setSearch('');
  };
  const [openModalConnect, setOpenModalConnect] = useState(false);
  const [openModalConnectList, setOpenModalConnectList] = useState(false);
  const [institutionId, setInstitutionId] = useState<number>(0);
  const user = useGlobalStore();

  const orgId = user.state.acl.selectedACL?.id ?? 0;
  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await ListInstitutionsOrgUseCase(orgId);
      if (!error && payload) {
        setListInstitutions(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Instituição',
      minWidth: 216,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'document',
      headerName: 'Documento',
      minWidth: 200,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'telephone',
      headerName: 'Telefone',
      minWidth: 100.3,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'Usuários',
      headerName: 'Usuários',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'table-header',
      renderCell: (params) => {
        return (
          <>
            <img
              onClick={() => {
                setInstitutionId(params.row.id);
                setOpenModalConnect(true);
              }}
              src={createUser}
              alt="action"
              title="Associar Usuário"
              style={{ cursor: 'pointer', paddingRight: '16px' }}
            />
            <img
              onClick={() => {
                setInstitutionId(params.row.id);
                setOpenModalConnectList(true);
              }}
              src={seeUsers}
              alt="action"
              title="Listar Usuário"
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      },
      sortable: false,
      filterable: false,
    },
  ];

  const rows =
    listInstitutions?.map((inst, index) => ({
      index: index,
      id: inst.id,
      name: inst.name,
      document: inst.document,
      telephone: inst.telephone,
    })) ?? [];

  const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filterRows = (search: string) => {
    let filteredRows = rows;
    if (search !== '') {
      const searchTerm = search.toLowerCase();
      filteredRows = filteredRows.filter((row) => {
        const name = row['name'].toLowerCase();
        const document = row['document'].toLowerCase();
        return name.includes(searchTerm) || document.includes(searchTerm);
      });
    }
    return filteredRows;
  };

  const filteredRows = filterRows(search);

  function IconConnect(id: number) {
    setInstitutionId(id);
    setOpenModalConnect(true);
  }

  function IconList(id: number) {
    setInstitutionId(id);
    setOpenModalConnectList(true);
  }

  const cardData: CardData[] = filteredRows.map((inst) => ({
    id: inst.id,
    title: ['Name', 'Documento', 'Telephone'],
    value: [inst.name, inst.document, inst.telephone],
  }));

  return (
    <>
      <Container.main>
        <Container.filter>
          <SearchTableListUser
            search={search}
            searchText={searchText}
            clearFilters={clearFilters}
          />
        </Container.filter>
        <Container.table>
          <Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
        </Container.table>
        <Container.mobile>
          <CardsTable cardData={cardData} IconConnect={IconConnect} IconList={IconList} />
        </Container.mobile>
        {openModalConnect && (
          <ModalConnectUserOrg
            onClose={() => setOpenModalConnect(false)}
            useCase={new ConnectUserInstOrgUseCase(UserTypeEnum.INSTITUTION)}
            rowId={institutionId}
          />
        )}
        {openModalConnectList && (
          <ModalListConnectUserOrg
            onClose={() => setOpenModalConnectList(false)}
            useCase={new ListConnectUserInstOrgUseCase(UserTypeEnum.INSTITUTION)}
            useCase2={new DeleteConnectionUserInstituitonOrgUseCase(UserTypeEnum.INSTITUTION)}
            Id={institutionId}
          />
        )}
      </Container.main>
    </>
  );
};
