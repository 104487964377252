import { GetReportUseCase } from "../../../../application/useCases/donation/GetReportUseCase";
import { UserTypeEnum } from "../../../../domain/interfaces/User";
import { HeaderPages } from "../../../components/common/HeaderPages";
import { TableAllInformation } from "../../../components/shared/report/TableAllInformation";
import { TableDonationPay } from "../../../components/shared/report/TableDonationPay";
import detail from "../../../assets/icons/detail.svg"
import doe_money from "../../../assets/icons/doe_money.svg"
import doe_voluntary from "../../../assets/icons/doe_voluntary.svg"
import doe_items from "../../../assets/icons/doe_items.svg"
import { useState } from "react";
import { Container } from './styles'
import { CardsReport } from "../../../components/shared/report/components/Cards";
import { TableAllItems } from "../../../components/shared/report/TableAllItems";
import { TableAllVoluntary } from "../../../components/shared/report/TableAllVoluntary";
import { GetReportVacancieUseCase } from "../../../../application/useCases/report/GetReportVacancieUseCase";
import { GetReportItemsUseCase } from "../../../../application/useCases/report/GetReportItemsUseCase";
import { GetReceiptOrgInstUseCase } from "../../../../application/useCases/donation/GetReceiptOrgInsttUseCase";

export const ReportOrg: React.FC = () => {

    const [showTableDonationPay, setShowTableDonationPay] = useState(false);
    const [showTableAllInformation, setShowTableAllInformation] = useState(false);
    const [showTableDonationItem, setShowTableDonationItem] = useState(false);
    const [showTableVoluntary, setShowTableVoluntary] = useState(false);
    const [activeCard, setActiveCard] = useState("");
    const images = [doe_money, detail, doe_voluntary, doe_items];

    const handleCardClick = (cardId: string) => {
        setShowTableDonationPay(false);
        setShowTableAllInformation(false);
        setShowTableDonationItem(false);
        setShowTableVoluntary(false);
        if (cardId === "monetary-synthetic") {
            setShowTableDonationPay(true);
        } else if (cardId === "monetary-analytical") {
            setShowTableAllInformation(true);
        } else if (cardId === "item-analytical") {
            setShowTableDonationItem(true);
        } else if (cardId === "voluntary-analytical") {
            setShowTableVoluntary(true);
        }

        setActiveCard(cardId);
    };


    return (
        <HeaderPages title="Relatórios">
            <Container.box>
                <Container.card>
                    <CardsReport
                        title="Monetárias"
                        label="Aprovadas"
                        subTitle="Doações"
                        imageSrc={images[0]}
                        onClick={() => handleCardClick("monetary-synthetic")}
                        activeCard={activeCard}
                        cardId={"monetary-synthetic"}
                    />
                    <CardsReport
                        title="Monetárias"
                        label="Analítico"
                        subTitle="Doações"
                        imageSrc={images[1]}
                        onClick={() => handleCardClick("monetary-analytical")}
                        activeCard={activeCard}
                        cardId={"monetary-analytical"}
                    />
                </Container.card>
                <Container.card>
                    <CardsReport
                        title="Itens"
                        label="Analítico"
                        subTitle="Doações"
                        imageSrc={images[3]}
                        onClick={() => handleCardClick("item-analytical")}
                        activeCard={activeCard}
                        cardId={"item-analytical"}
                    />
                    <CardsReport
                        title="Voluntariado"
                        label="Analítico"
                        subTitle="Vagas"
                        imageSrc={images[2]}
                        onClick={() => handleCardClick("voluntary-analytical")}
                        activeCard={activeCard}
                        cardId={"voluntary-analytical"}
                    />
                </Container.card>
            </Container.box>
            {showTableDonationPay && (
                <TableDonationPay
                    useCase={new GetReportUseCase(UserTypeEnum.ORG)}
                    useCase2={new GetReceiptOrgInstUseCase(UserTypeEnum.ORG)}
                />
            )}
            {showTableAllInformation && (
                <TableAllInformation useCase={new GetReportUseCase(UserTypeEnum.ORG)} />
            )}
            {showTableDonationItem && (
                <TableAllItems useCase={new GetReportItemsUseCase(UserTypeEnum.ORG)} />
            )}
            {showTableVoluntary && (
                <TableAllVoluntary useCase={new GetReportVacancieUseCase(UserTypeEnum.ORG)} />
            )}
        </HeaderPages>
    );
};