import { Container } from '../../../../../components/shared/gerenciar-campanha/TableListCampaignOrg/styles';
import { GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../components/common/Table';
import { ItemsOrdersDahsboardOrg } from '../../../../../../application/useCases/dahsboard/OrgDashboardUseCase';
import { dateSortComparator, dateValueFormatter } from '../../../../../../application/utils';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';



export const ItemsTableDahsboardOrg = ({ data }: { data: ItemsOrdersDahsboardOrg[] }) => {


    const columns: GridColDef[] = [
        { field: 'Usuário', headerName: 'Usuário', minWidth: 216, flex: 1, headerClassName: 'table-header', },
        { field: 'Instituição', headerName: 'Instituição', minWidth: 180, flex: 1, headerClassName: 'table-header', },
        { field: 'Campanha', headerName: 'Campanha', minWidth: 240, flex: 1, headerClassName: 'table-header', },
        { field: 'Item', headerName: 'Item', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
        { field: 'Quantidade', headerName: 'Quantidade', minWidth: 140, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center', },
        {
            field: 'Data', headerName: 'Data de inscrição', minWidth: 140, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center',
            sortComparator: dateSortComparator,
            valueFormatter: dateValueFormatter,
        },
    ];

    const rows =
        (data?.map((data: ItemsOrdersDahsboardOrg, index: number) => ({
            index: index,
            id: data.id,
            Instituição: data.institution.name,
            Usuário: data.user.name,
            Campanha: data.campaign.title,
            Item: data.item.name,
            Quantidade: data.donation.quantity,
            Data: new Date(data.createdAt),
        })) ?? [])
            .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

    const cardData: CardData[] = data.map((data: ItemsOrdersDahsboardOrg) => ({
        id: data.id,
        title: [
            'Usuário',
            'Instituição',
            'Campanha',
            'Item',
            'Quantidade',
            'Data'
        ],
        value: [
            data.user.name,
            data.institution.name,
            data.campaign.title,
            data.item.name,
            data.donation.quantity,
            new Date(data.createdAt).toLocaleDateString('pt-BR'),
        ]
    }));

    return (
        <>
            <Container.main>
                <Container.title>Doação de Itens</Container.title>
                <Container.table>
                    <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />
                </Container.table>
                <Container.mobile>
                    <CardsTable cardData={cardData} />
                </Container.mobile>
            </Container.main>
        </>
    );
};
