import styled from 'styled-components';
import { theme } from '../../../../themes/blue';

const Container__Box = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  gap: 8px;
  flex: 1;
  cursor: pointer;
  min-width: 230px;
  transition: all 300ms linear 0s;
  &:hover {
    box-shadow: 0px 20px 30px rgb(70 125 192 / 20%);
  }
  @media (max-width: ${theme.media.laptop}) {
    min-height: 150px;
  }
`;

const Container__Img = styled.div``;

const Container__Text = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = {
  box: Container__Box,
  img: Container__Img,
  text: Container__Text,
};

const Text__Title = styled.h3`
  font-weight: 600;
  line-height: 32px;
  color: ${(props) => props.theme.colors.darkGray};
`;

const Text__Label = styled.div`
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize.sm};
  line-height: 20px;
  color: ${(props) => props.theme.colors.gray};
`;

export const Text = {
  title: Text__Title,
  label: Text__Label,
};
