import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  background: ${props => props.theme.colors.white};
  border: none;
  label {
  width: 100%;
  position: relative;
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
  }
  select {
  width: 100%;
  padding: 13px;
  border: 1px solid ${props => props.theme.colors.lightgray};
  border-radius: 4px;
  outline: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.sm};
  line-height: 20px;
  color: ${props => props.theme.colors.gray};
  }
  option {
  font-size: 12px;
  color: ${props => props.theme.colors.gray};
  }
  span {
  padding: 0px 4px !important;
  border-radius: 4px;
  background: #FFF;
  position: absolute;
  top: -10px;
  left: 10px;
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.sm};
  line-height: 20px;
  color:  ${props => props.theme.colors.dark};
  }
`;

export const _SelectInput = styled(Container)``;

export const _SelectInputAuth = styled(Container)`
  background: #fff;
  border: none;
  label {
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.dark};
  padding: 6px 8px;
  }
  select{
    border: none;
    padding: 2px;
  }
  span {
  background: ${props => props.theme.colors.white};
  left: 20px;
  }
`;
