import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Create = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.media.monitorSmall}) {
    width: 100%;
  }
`;

const Container__Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: ${theme.media.monitorSmall}) {
    display: none;
  }
`;

const Container__HeaderTablet = styled(Container__Header)`
  display: none;
  @media (max-width: ${theme.media.monitorSmall}) {
    display: flex;
  }
`;

const Container__Form = styled.form`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const Container__Section = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: ${(props) => props.theme.colors.paleBlue};
  span {
    color: ${(props) => props.theme.colors.paleBlue};
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize.lg};
    text-decoration: underline 1px ${(props) => props.theme.colors.paleBlue};
    cursor: pointer;
  }
`;

const Container__Line = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Container__Choice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  img {
    width: 130px;
    height: 130px;
    filter: drop-shadow(0px 2px 12px rgba(73, 80, 87, 0.24));
    border-radius: 8px;
  }
`;

export const Container = {
  header: Container__Header,
  create: Container__Create,
  form: Container__Form,
  section: Container__Section,
  line: Container__Line,
  choice: Container__Choice,
  headerTable: Container__HeaderTablet,
};

const Text__Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize.xll};
    line-height: 32px;
    color: ${(props) => props.theme.colors.primary};
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  p {
    font-size: ${(props) => props.theme.fontSize.smX};
    line-height: 20px;
  }
`;

const Text__Line = styled.div`
  height: 2px;
  width: 100%;
  background: ${(props) => props.theme.colors.lightgray};
`;

export const Text = {
  title: Text__Title,
  line: Text__Line,
};
