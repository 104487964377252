import { Container } from "./styles";
import { useGlobalStore } from "../../../../../../infra/state/GlobalStore";
import locality from "../../../../../assets/icons/locality.svg";
import setting from "../../../../../assets/icons/setting.svg";
import { Link } from "react-router-dom";

export const HeaderMyAccount: React.FC = () => {

	const users = useGlobalStore();
	const { user } = users.state;
	const name = user?.name;
	const nameArray = name?.split(" ");
	let firstName = "";
	let lastName = "";

	if (nameArray && nameArray.length === 1) {
		firstName = nameArray[0];
	} else if (nameArray && nameArray.length > 1) {
		firstName = nameArray[0];
		lastName = nameArray[nameArray.length - 1];
	}

	return (
		<Container.head>
			<Container.text>
				<p>Seja bem-vindo!</p>
				<h2 data-test="h2-firstName-lastName">{`${firstName} ${lastName}`}</h2>
			</Container.text>
			<Container.text>
				<Container.headerRight>
					<img style={{ width: "75px" }} src={`/avatar/${user?.avatar}.svg`} alt="avatar" />
					<Container.textUser>
						<h4 data-test="h4-username">{user?.username}</h4>
						<div>
							<img src={locality} alt="localização" />
							<p data-test="p-city" >{user?.address.city},</p>
							<p data-test="p-state">{user?.address.state}</p>
						</div>
					</Container.textUser>
					<Link to="/profile" className="linkSettings"><img src={setting} alt="setting" /></Link>
				</Container.headerRight>
			</Container.text>
		</Container.head>
	);
};