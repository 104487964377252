import { HttpClient } from "../../HttpClient";
import { ListOrgs } from "./ListOrgsUseCase";


  export async function EditOrgUseCase (
    dto: ListOrgs,
  ) {
    return await HttpClient<ListOrgs, void>(
      'put',
      `/users/gooders/orgs/${dto.id}`,
      dto
    );
  }


