import { useEffect, useRef } from "react";
import { Container } from "./styles";
import { useGlobalStore } from "../../../../../../infra/state/GlobalStore";
import { GetListCampaign } from "../../../../../../application/useCases/campaign/GetListCamapign";
import { Campaign } from "../../../../../components/common/CardsCause";
import close from '../../../../../assets/icons/close.svg'

interface ModalCampaignsProps {
    open: boolean;
    onClose: () => void;
}

export const ModalCampaignDonatePay = ({ open, onClose }: ModalCampaignsProps) => {
    if (!open) return null;


    const list = useGlobalStore()
    const carousel = useRef<HTMLDivElement>(null);



    const handleLeftClick = () => {
        if (carousel.current !== null) {
            carousel.current.scrollLeft -= carousel.current.offsetWidth;
        }
    };

    const handleRightClick = () => {
        if (carousel.current !== null) {
            carousel.current.scrollLeft += carousel.current.offsetWidth;
        }
    };

    useEffect(() => {
        if (list.state.campaigns.length === 0) {
            const fetchData = async () => {
                const { payload, error } = await GetListCampaign();
                if (!error && payload) {
                    list.actions.setCampaigns(payload.data)
                }
            };
            fetchData();
        }
    }, []);

    return (
        <Container.modal>
            <Container.close src={close} alt="close" onClick={onClose} />
            <Container.box>
                <Container.cards ref={carousel} >
                    {list.state.campaigns.map(campaign => (
                        (campaign.progress < 100) && (
                            <Campaign key={campaign.id} cause={campaign} />
                        )
                    ))}
                </Container.cards>
                <Container.button>
                    <button onClick={handleLeftClick} >
                        <span>{"<"}</span>
                    </button>
                    <button onClick={handleRightClick}>
                        <span>{">"}</span>
                    </button>
                </Container.button>
            </Container.box>
        </Container.modal>
    );
};
