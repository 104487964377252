import remove from '../../../../../../assets/icons/remove.svg';
import { useCampaignUnique } from '../../../../../../../infra/state/useCampaign';
import { useAlert } from '../../../../../../../infra/config/AlertContext/useAlert';
import { GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../common/Table';
import { useGlobalStore } from '../../../../../../../infra/state/GlobalStore';
import { DeleteVacancyFromCampaignUseCase } from '../../../../../../../application/useCases/campaign/vacancy/DeleteVacancyFromCampaignUseCase';
import { MyDate } from '../../../../../../../application/utils';
import active from '../../../../../../assets/icons/active.svg';
import { ActiveVacancyUseCase } from '../../../../../../../application/useCases/campaign/vacancy/ActiveVacancyUseCase';

interface Props {
  useCase: DeleteVacancyFromCampaignUseCase;
  useCase3: ActiveVacancyUseCase;
}

export const VacanciesTable = ({ useCase, useCase3 }: Props) => {
  const { campaignUnique, actions, setCampaignUnique } = useCampaignUnique();
  const { setAlert } = useAlert();
  const users = useGlobalStore();

  async function handleDeleteVacancy(vacancyId: number) {
    if (!users.state.acl.selectedACL || !campaignUnique) {
      setAlert('Não foi possível desativar a vaga no momento.', 'error');
      return;
    }

    const orgId = users.state.acl.selectedACL.id;
    const institutionId = campaignUnique.institution.id;
    const campaignId = campaignUnique.id;
    const { error } = await useCase.execute({ orgId, institutionId, campaignId, vacancyId });

    if (error) {
      setAlert(error, 'error');
    } else {
      setAlert('Sua vaga foi desativada com sucesso.', 'success');
      actions.vacancies.remove(vacancyId);
    }
  }

  async function handleActiveVacancies(vacancyId: number, isActive: boolean) {
    if (!users.state.acl.selectedACL || !campaignUnique) {
      return;
    }
    const orgId = users.state.acl.selectedACL.id;
    const institutionId = campaignUnique.institution.id;
    const campaignId = campaignUnique.id;

    const { error } = await useCase3.execute({ orgId, institutionId, campaignId, vacancyId }, { isActive: !isActive });

    if (error) {
      setAlert(error, 'error');
    } else {
      setAlert(`Voluntariado alterado com sucesso`, 'success');
      const updatedCampaignUnique = {
        ...campaignUnique,
        vacancie: campaignUnique.vacancies.map((vacancy) => {
          if (vacancy.id === vacancyId) {
            vacancy.isActive = !isActive;
          }
          return vacancy;
        }),
      };

      setCampaignUnique(updatedCampaignUnique);
    }
  }

  const columns: GridColDef[] = [
    { field: 'Título da vaga:', headerName: 'Título da vaga:', minWidth: 230, flex: 1, headerClassName: 'table-header', },
    { field: 'Quantidade de horas:', headerName: 'Quantidade de horas:', minWidth: 180, headerClassName: 'table-header', sortable: false, filterable: false },
    { field: 'Endereço:', headerName: 'Endereço:', minWidth: 173.3, flex: 1, headerClassName: 'table-header', },
    {
      field: 'Data', headerName: 'Data início:', minWidth: 145, flex: 1, headerClassName: 'table-header', sortable: false, filterable: false,

    },
    {
      field: 'Ativar/Desativar', headerName: 'Ativar/Desativar', minWidth: 130, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => {
        const isActive = params.row.isActive;
        return (
          <img
            onClick={() => handleActiveVacancies(params.row.id, isActive)}
            src={active}
            alt="action"
            style={{
              cursor: 'pointer',
              padding: "3px",
              borderRadius: "4px",
              background: isActive ? '#01742D' : '#CF4451',
            }}
          />
        )
      },
      sortable: false,
      filterable: false,
    },
    {
      field: 'Remover', headerName: 'Remover', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => (
        <img
          onClick={() => handleDeleteVacancy(params.row.id)}
          src={remove}
          alt="action"
          style={{ cursor: 'pointer' }}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ];

  const rows =
    (campaignUnique?.vacancies?.map((vacancy, index: number) => ({
      index: index,
      id: vacancy.id,
      'Título da vaga:': vacancy.title,
      'Quantidade de horas:': vacancy.quantity,
      'Endereço:': vacancy.address,
      'Data': MyDate.formatISOString(vacancy.startDate),
      Remover: 'Remover',
      isActive: vacancy.isActive,
    })) ?? [])
      .sort((a, b) => a['Título da vaga:'].localeCompare(b['Título da vaga:']));

  return <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />;
};
