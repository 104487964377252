import styled from "styled-components";

const Container__Page = styled.div`
  background: ${props => props.theme.colors.backgroundGray};

  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary};
  padding: 40px 52px 40px;
`;

const Container__Box = styled.main`
  width: 100%;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 8px;
  margin-top: 50px;
  padding: 40px;
  gap: 24px;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
  max-width: 500px;
  }
  h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  max-width: 60%;
  @media (max-width: 480px) {
  font-size: 18px;
  }
  }
  img {
  max-width: 90%;
  }
`;

const Container__Button = styled.div`
width: 50%;
`;

export const Container = {
	page: Container__Page,
	box: Container__Box,
  button: Container__Button
};