import { HttpClient } from '../../HttpClient';

export interface ListInstitutionsOrg {
  id: number;
  name: string;
  document: string;
  telephone: string;
  split: {
    splitKey?: string;
    percentageSplit: string;
  };
}

export async function ListInstitutionsOrgUseCase(orgId: number) {
  const response = await HttpClient<void, { data: ListInstitutionsOrg[] }>(
    'get',
    `/users/orgs/${orgId}/institutions`
  );
  return response;
}
