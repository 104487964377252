import { HttpClient } from "../../HttpClient";

export interface ReddemCoupon {
  couponId: number;
}

export async function ReddemCouponUseCase (
  dto: ReddemCoupon,
) {
  return await HttpClient<ReddemCoupon, void>(
    'put',
    `/api/rewards/coupons/${dto.couponId}/redeem`,
    dto
  );
}



