import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

const Container__Box = styled.div`
  width: 100%;
  height: 100%;
  `;

const Container__Input = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 36px;
  padding: 40px 0px 10px;
  color: ${(props) => props.theme.colors.gray};
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
    padding: 12px 0px 10px;
    gap: 6px;
  }
`;

const Container__Text = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  min-height: 250px;
  flex-direction: column;
  gap: 6px;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize.sm};
  line-height: 20px;
  @media (max-width: ${theme.media.laptop}) {
    min-height: 200px;
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    width: 100%;
  }
`;

const Container__Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  img {
    width: 300px;
    height: 280px;
    object-fit: cover;
    border-radius: 8px;
    @media (max-width: ${theme.media.monitorSmall}) {
    display: none;
  }
  }

`;

const Container__Address = styled.div`
  padding: 16px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.lightgray};
  border-radius: 4px;
  font-size:  ${(props) => props.theme.fontSize.sm};
  position: relative;
  p {
    font-size:  ${(props) => props.theme.fontSize.sm};
  }
  span {
    position: absolute;
    background: ${(props) => props.theme.colors.white};
    top: -10px;
    left: 10px;
    padding: 0px 4px !important;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #4E545C;
  }
`;


const Container__Button = styled.div`
width: 200px;
`

export const Container = {
  Input: Container__Input,
  Text: Container__Text,
  Address: Container__Address,
  Image: Container__Image,
  button: Container__Button,
  box: Container__Box,
};


export const Line = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.lightgray};
  margin: 8px 0;
  width: 100%;
`;
