import styled from "styled-components";

const Container__Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding-bottom: 16px;
    gap: 16px;
    padding: 24px 0;
`;

export const Container = {
	form: Container__Form,
};

