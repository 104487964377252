import { useState } from 'react';
import { Container } from './styles';
import { TableTransactionsDonationPay } from './components/TableTransactionsDonationPay';
import { TableTransactionsDonationItem } from './components/TableTransactionsDonationItem';
import { TableTransactionsVoluntary } from './components/TableTransactionsVoluntary';
import { Link } from 'react-router-dom';
import { TableTransactionsRewards } from './components/TableTransactionsRewards';

enum ActiveTabEnum {
  'ITEMS',
  'VOLUNTARY',
  'DONATION',
  'REWARDS'
}

export const Transactions: React.FC = () => {
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.DONATION);

  return (
    <Container.main>
      <Container.box>
        <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.DONATION)}>
          <Container.subTitle active={activeTab === ActiveTabEnum.DONATION}>
            Pagamento
          </Container.subTitle>
        </Link>
        <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.ITEMS)}>
          <Container.subTitle active={activeTab === ActiveTabEnum.ITEMS}>
            Itens
          </Container.subTitle>
        </Link>
        <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.VOLUNTARY)}>
          <Container.subTitle active={activeTab === ActiveTabEnum.VOLUNTARY}>
            Voluntariado
          </Container.subTitle>
        </Link>
        <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.REWARDS)}>
          <Container.subTitle active={activeTab === ActiveTabEnum.REWARDS}>
            Recompensas
          </Container.subTitle>
        </Link>
      </Container.box>
      <Container.box>
        {activeTab === ActiveTabEnum.DONATION && (<TableTransactionsDonationPay />)}
        {activeTab === ActiveTabEnum.ITEMS && (<TableTransactionsDonationItem />)}
        {activeTab === ActiveTabEnum.VOLUNTARY && (<TableTransactionsVoluntary />)}
        {activeTab === ActiveTabEnum.REWARDS && (<TableTransactionsRewards />)}
      </Container.box>
    </Container.main>
  );
};
