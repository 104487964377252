
import { Container } from '../../gooders/gerenciar-usuarios/styles';
import { useState } from 'react';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { SmallBlueButton } from '../../../components/common/Button';
import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { TableListInstitutionOrg } from './components/TableListInstitutionOrg';
import { TableListMemberOrg } from './components/TableListMemberOrg';

enum ActiveTabEnum {
    'INSTITUTION',
    'ORG',
}

export const GerenciarUserOrgs = () => {
    const navigate = useNavigate();
    const user = useGlobalStore()
    const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.ORG);

    return (
        <>
            <LayoutSidebar>
                <Container.box>
                    <Container.header>
                        <Container.title data-test="title-campaign">Usuários</Container.title>
                        {user.state.acl.selectedACL?.type === UserTypeEnum.ORG ? (
                            <Container.button>
                                <SmallBlueButton onClick={() => navigate('/createuser?type=institution')} loading={false}>
                                    Criar Instituição
                                </SmallBlueButton>
                            </Container.button>
                        ) : null}
                    </Container.header>
                    <Container.support>
                        <Container.choice>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.INSTITUTION)}>
                                <Container.subTitle active={activeTab === ActiveTabEnum.INSTITUTION}>
                                    Instituições
                                </Container.subTitle>
                            </Link>
                            <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.ORG)}>
                                <Container.subTitle active={activeTab === ActiveTabEnum.ORG}>
                                    Organizações
                                </Container.subTitle>
                            </Link>
                        </Container.choice>
                    </Container.support>
                    <Container.support>
                        {activeTab === ActiveTabEnum.INSTITUTION ?
                            <TableListInstitutionOrg /> : null}
                        {activeTab === ActiveTabEnum.ORG ?
                            <TableListMemberOrg /> : null}
                    </Container.support>
                </Container.box>
            </LayoutSidebar>
        </>
    );
};
