import { Container, Text } from './styles';

interface Props {
    label: string;
    title: string;
    subTitle: string;
    imageSrc: string;
    onClick: () => void;
    activeCard: string;
    cardId: string;
}

export const CardsReport: React.FC<Props> = ({ label, title, subTitle, imageSrc, onClick, activeCard, cardId }) => {
    return (
        <Container.box onClick={onClick} active={cardId === activeCard}>
            <Container.img>
                <img src={imageSrc} alt="" />
            </Container.img>
            <Container.text>
                <Text.label>{subTitle}</Text.label>
                <Text.title>
                    {title}
                </Text.title>
                <Text.label>{label}</Text.label>
            </Container.text>
        </Container.box>
    );
};
