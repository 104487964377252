import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Container } from '../PaymentMethod/styles';
import { CheckOutContext } from '../../../../../../../../infra/config/CheckOut';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import fetchAddressFromCep from '../../../../../../../../application/useCases/GetCepUseCase';
import { SelectInput } from '../../../../../../../components/common/SelectInput';
import { selectOptionsState } from '../../../../../../../components/common/SelectInput/components/SelectOptionsState';

export interface Address {
  city: string;
  country: string;
  number: string;
  street: string;
  state: string;
  zipCode: string;
}

export const validationSchema = Yup.object().shape({
  zipCode: Yup.string()
    .required('O CEP é obrigatório')
    .matches(/^\d{5}(-\d{3})?$/, 'O CEP deve conter apenas números'),
  street: Yup.string().required('O endereço é obrigatório'),
  number: Yup.string().required('O numero é obrigatório'),
  city: Yup.string().required('A cidade é obrigatória'),
  state: Yup.string().required('O estado é obrigatório'),
  country: Yup.string().required('O país é obrigatório'),
});

export const Address = () => {
  const { address, setAddress, activeComponentIndex, setActiveComponentIndex } =
    useContext(CheckOutContext);
  const { setAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      zipCode: '',
      street: '',
      number: '',
      country: 'BR', // static
      city: '',
      state: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const newsetAddress = { ...values };
      setAddress(newsetAddress);
    },
  });

  useEffect(() => {
    if (address) {
      formik.setFieldValue('zipCode', address.zipCode || '');
      formik.setFieldValue('street', address.street || '');
      formik.setFieldValue('number', address.number || '');
      formik.setFieldValue('city', address.city || '');
      formik.setFieldValue('state', address.state || '');
    }
  }, []);

  const handleNext = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
      setActiveComponentIndex((prevIndex: number) => prevIndex + 1);
    } else {
      setAlert('Todos os campos obrigatórios devem ser preenchidos para continuar.', 'error');
    }
  };

  const handlePrevious = () => {
    setActiveComponentIndex((prevIndex: number) => prevIndex - 1);
  };

  const handleFetchAddress = async () => {
    try {
      const data = await fetchAddressFromCep(formik.values.zipCode);
      const addressData = data;
      formik.setFieldValue('zipCode', addressData.cep || '');
      formik.setFieldValue('street', addressData.logradouro || '');
      formik.setFieldValue('city', addressData.localidade || '');
      formik.setFieldValue('state', addressData.uf || '');
    } catch (err) {
      setAlert('Não foi possivel obter o seu endereço!', 'error');
    }
  };

  useEffect(() => {
    if (formik.values.zipCode && formik.values.zipCode.length === 8) {
      handleFetchAddress();
    }
  }, [formik.values.zipCode]);

  return (
    <Container.box onSubmit={formik.handleSubmit}>
      <h2 style={{ paddingBottom: '16px' }}>Cobrança</h2>
      <LabelInputFormCampaign
        htmlFor="zipCode"
        id="zipCode"
        name="zipCode"
        type="text"
        placeholder=""
        value={formik.values.zipCode}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        aria-labelledby="zipCode"
        error={formik.touched.zipCode && formik.errors.zipCode ? formik.errors.zipCode : undefined}
      >
        Cep
      </LabelInputFormCampaign>
      <LabelInputFormCampaign
        htmlFor="street"
        id="street"
        name="street"
        type="text"
        placeholder=""
        value={formik.values.street}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        aria-labelledby="Endereço"
        error={formik.touched.street && formik.errors.street ? formik.errors.street : undefined}
      >
        Logradouro
      </LabelInputFormCampaign>
      <LabelInputFormCampaign
        htmlFor="number"
        id="number"
        name="number"
        type="text"
        placeholder=""
        value={formik.values.number}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        aria-labelledby="Número"
        error={formik.touched.number && formik.errors.number ? formik.errors.number : undefined}
      >
        Número
      </LabelInputFormCampaign>
      <LabelInputFormCampaign
        htmlFor="city"
        id="city"
        name="city"
        type="text"
        placeholder=""
        value={formik.values.city}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        aria-labelledby="Cidade"
        error={formik.touched.city && formik.errors.city ? formik.errors.city : undefined}
      >
        Cidade
      </LabelInputFormCampaign>

      <SelectInput
        htmlFor="state"
        id="state"
        name="state"
        placeholder=""
        value={formik.values.state}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        aria-labelledby="Estado"
        options={selectOptionsState}
        error={formik.touched.state && formik.errors.state ? formik.errors.state : undefined}
      >
        <option value="estado">Estado</option>
      </SelectInput>
      <Container.buttons>
        <button type="button" onClick={handlePrevious} className="previous-button-border">
          Voltar
        </button>

        <button
          type="submit"
          onClick={handleNext}
          className={activeComponentIndex ? 'next-button-blue' : ''}
        >
          Avançar
        </button>
      </Container.buttons>
    </Container.box>
  );
};
