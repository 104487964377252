import React, { useState } from 'react';
import { Container } from './styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { RescueHashUseCase } from '../../../../application/useCases/user/RescueHashUseCase';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { LabelInputFormCampaign } from '../../../components/common/Input';
import { BlueButton } from '../../../components/common/Button';
import gooders_coin from '../../../assets/logo/logo_coin.svg';

const validationSchema = Yup.object().shape({
  hashCode: Yup.string().required('Digite todos o seu código de Hash'),
});

export const RescueHash: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();

  const formik = useFormik({
    initialValues: {
      hashCode: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { error } = await RescueHashUseCase(values);
      if (!error) {
        setAlert('Resgate efetuado com sucesso!', 'success');
        resetForm();
      } else {
        setAlert(error, 'error');
      }
      setLoading(false);
    },
  });

  return (
    <LayoutSidebar>
      <Container.box>
        <Container.header>
          <Container.title> Resgatar Hash</Container.title>
        </Container.header>
        <Container.main onSubmit={formik.handleSubmit}>
          <Container.title>Resgatar</Container.title>
          <Container.wrapper>
            <Container.left>
              <Container.text>Insira aqui os caracteres do seu Hash:</Container.text>
              <LabelInputFormCampaign
                id="hashCode"
                htmlFor="hashCode"
                type="string"
                name="hashCode"
                placeholder="Digiste o código do seu hashCode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.hashCode}
                aria-labelledby="hashCode"
                error={
                  formik.touched.hashCode && formik.errors.hashCode
                    ? formik.errors.hashCode
                    : undefined
                }
              >
                Código
              </LabelInputFormCampaign>
            </Container.left>
            <Container.right>
              <img src={gooders_coin} alt="Coin" />
            </Container.right>
          </Container.wrapper>
          <BlueButton type="submit" loading={loading}>
            Resgatar
          </BlueButton>
        </Container.main>
      </Container.box>
    </LayoutSidebar>
  );
};
