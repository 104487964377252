import { useEffect, useState } from 'react';
import { Container } from '../../../../styles';
import { useFormik } from 'formik';
import { useGlobalStore } from '../../../../../../../../infra/state/GlobalStore';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { SmallBlueButton } from '../../../../../../../components/common/Button';
import * as Yup from 'yup';
import { EditProfileUseCase } from '../../../../../../../../application/useCases/user/EditProfileUseCase';
import { ModalAvatar } from '../ModalAvatar';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Preencha seu nome de usuário'),
  username: Yup.string()
    .required('Informe seu nome de usuário')
    .max(15, 'O nome de usuário deve ter no máximo 15 caracteres'),
  telephone: Yup.string()
    .required('Insira um numero de telefone')
    .matches(/^[0-9]+$/, 'Insira um número válido'),
});

export const PersonalData = () => {
  const users = useGlobalStore();
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState<string | undefined>(undefined);

  const handleSelectImage = (avatar: { src: string; alt: string; }) => {
    setSelectedAvatar(avatar.src.split('/').pop()?.split('.')[0])
  };

  const { values, touched, errors, handleBlur, handleChange, handleSubmit, setValues, dirty } = useFormik({
    initialValues: {
      name: users.state.user?.name ?? '',
      username: users.state.user?.username ?? '',
      telephone: users.state.user?.telephone ?? '',
      avatar: users.state.user?.avatar ?? '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (selectedAvatar) {
        values.avatar = selectedAvatar;
      }

      setLoading(true);

      const { error } = await EditProfileUseCase(values)
      if (error) {
        setAlert(error, 'error');
      } else {
        setAlert('Alterações salvas com sucesso!', 'success');
      }
      setLoading(false);
    },
  });
  useEffect(() => {
    if (users.state.user) {
      setValues({
        name: users.state.user?.name,
        username: users.state.user?.username,
        telephone: users.state.user?.telephone,
        avatar: users.state.user?.avatar,
      });
    }
  }, [users.state.user]);

  return (
    <Container.form onSubmit={handleSubmit}>
      <Container.avatar id='Container.avatar'>
        <img
          onClick={() => {
            setOpenModal(true);
          }}
          style={{ width: '75px' }}
          src={`/avatar/${users.state.user?.avatar}.svg`}
          alt="avatar"
        />
      </Container.avatar>
      <Container.input>
        <Container.inputs>
          <LabelInputFormCampaign
            id="name"
            name="name"
            type="text"
            htmlFor="name"
            aria-labelledby="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Digite seu nome"
            error={touched.name && errors.name ? errors.name : undefined}
          >
            Nome
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="username"
            id="username"
            name="username"
            type="text"
            placeholder="Digite um usuário"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            aria-labelledby="username"
            error={touched.username && errors.username ? errors.username : undefined}
          >
            Username
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="telephone"
            id="telephone"
            name="telephone"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.telephone}
            placeholder="(xx) xxxxx-xxxx"
            aria-labelledby="número de telefone da instituição"
            error={touched.telephone && errors.telephone ? errors.telephone : undefined}
          >
            Telefone *
          </LabelInputFormCampaign>
        </Container.inputs>
      </Container.input>
      <SmallBlueButton type="submit" loading={loading} disabled={!dirty}>
        Salvar
      </SmallBlueButton>
      <ModalAvatar open={openModal} onClose={() => setOpenModal(false)} handleSelectImage={handleSelectImage} onSave={handleSubmit} />
    </Container.form>
  );
}

