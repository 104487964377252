import { HttpClient } from '../../HttpClient';
import { UniqueCampaignOutput } from './GetCampaign';

export async function GetCampaignForOrgEdit(
  orgId: number,
  institutionId: number,
  campaignId: number
) {
  const response = await HttpClient<void, { data: UniqueCampaignOutput }>(
    'get',
    `/campaigns/org/${orgId}/institution/${institutionId}/my-campaigns/${campaignId}`
  );
  return response;
}

export async function GetCampaignForInstitutionEdit(institutionId: number, campaignId: number) {
  const response = await HttpClient<void, { data: UniqueCampaignOutput }>(
    'get',
    `/campaigns/institution/${institutionId}/my-campaigns/${campaignId}`
  );
  return response;
}

export async function GetCampaignForGoodersEdit(campaignId: number) {
  const response = await HttpClient<void, { data: UniqueCampaignOutput }>(
    'get',
    `campaigns/gooders/${campaignId}/my-campaigns`
  );
  return response;
}
