import { HttpClient } from '../../HttpClient';

export interface ValidatePrePaymentOrderDto {
  prePaymentOrderId: number;
}

export async function ValidatePrePaymentOrderUseCase(dto: ValidatePrePaymentOrderDto) {
  const response = await HttpClient(
    'post',
    `/api/checkout/pre-orders/${dto.prePaymentOrderId}/validate`
  );
  return response;
}
