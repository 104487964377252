import { CreateUserAccountDto } from '../../../domain/dto/CreateUserAccountDto';
import { HttpClient } from '../../HttpClient';

export async function CreateUserAccountUseCase(dto: CreateUserAccountDto) {
  const { error, payload } = await HttpClient<CreateUserAccountDto, void>('post', '/users', dto);
  if (!error && payload) {
    return {
      data: payload,
    };
  } else {
    return { error };
  }
}
