import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Text } from './styles';
import { ListSupportedCause } from '../ListSupportedCause';
import { Transactions } from '../Transactions';
import { ListRewardsUser } from '../ListRewardsUser';

enum ActiveTabEnum {
  'CAMPAIGNS',
  'ORDERS',
  'REWARDS'
}

export const SupportMyAccount = () => {
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.CAMPAIGNS);

  return (
    <>
      <Container.support>
        <Container.choice>
          <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.CAMPAIGNS)}>
            <Text.subTitle data-test="list-supported-cause" active={activeTab === ActiveTabEnum.CAMPAIGNS}>
              Suas campanhas
            </Text.subTitle>
          </Link>
          <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.REWARDS)}>
            <Text.subTitle data-test="list-rewards-coupon" active={activeTab === ActiveTabEnum.REWARDS}>
              Suas recompensas
            </Text.subTitle>
          </Link>
          <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.ORDERS)}>
            <Text.subTitle data-test="transactions" active={activeTab === ActiveTabEnum.ORDERS}>
              Transações
            </Text.subTitle>
          </Link>
        </Container.choice>
      </Container.support>
      <Container.support>
        {activeTab === ActiveTabEnum.CAMPAIGNS ?
          <ListSupportedCause text={'Você ainda não apoiou nenhuma campanha!'} /> : null}
        {activeTab === ActiveTabEnum.REWARDS ?
          <ListRewardsUser text={'Você ainda não efetuou nenhum resgate!'} /> : null}
        {activeTab === ActiveTabEnum.ORDERS ?
          <Transactions /> : null}

      </Container.support>
    </>
  );
};
