import { UserTypeEnum } from '../../../domain/interfaces/User';
import { HttpClient } from '../../HttpClient';
import { SharedUseCase } from '../campaign/item/CreateItemForCampaignUseCase';

export interface GetReportTableVacancie {
    userName: string,
    userEmail: string,
    userTelephone: string,
    institutionName: string,
    campaignTitle: string,
    vacancyTitle: string,
    vacancyStartDate: string,
    vacancyEndDate: string,
    vacancyStartHour: string,
    vacancyEndHour: string,
    orderStatus: string
}


interface Params {
  orgId?: number;
  institutionId?: number;
}


export class GetReportVacancieUseCase extends SharedUseCase<
  Params,
  GetReportTableVacancie,
  {
    payload: { data: GetReportTableVacancie[];} | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execOrg(){
    return await HttpClient<void, { data:  GetReportTableVacancie[] }>(
      'get',
      `/orders/reports/vacancies/orgs/${this.params!.orgId}`
    );
  }

  protected override async execInstitution(){
    return await HttpClient<void, { data:  GetReportTableVacancie[] }>(
      'get',
      `/orders/reports/vacancies/institutions/${this.params!.institutionId}`
    );
  }

}



