import { Container, } from '../../../TableListPartner/components/ModalEditPartner/styles';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import close from '../../../../../../../assets/icons/close.svg';
import { validationSchema } from '../../../../../../users/createusertype/components/FormCreateOrg/index'
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { SmallBlueButton } from '../../../../../../../components/common/Button';
import { ListOrgs } from '../../../../../../../../application/useCases/gooders/ListOrgsUseCase';
import { EditOrgUseCase } from '../../../../../../../../application/useCases/gooders/EditOrgUseCase';


interface FormCreateOrgProps {
	selectedOrg: ListOrgs[];
	onClose: () => void;
	orgId: number;
}
export const ModalEditOrg: React.FC<FormCreateOrgProps> = ({ selectedOrg, onClose, orgId }) => {


	const [loading, setLoading] = useState(false);
	const { setAlert } = useAlert();


	const formik = useFormik({
		initialValues: {
			name: '',
			cnpj: '',
			telephone: '',
			id: '',
		},
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			setLoading(true);
			const orgId = +values?.id;
			if (orgId !== undefined) {
				const { error } = await EditOrgUseCase({ ...values, id: orgId });
				if (error) {
					setAlert(error, 'error');
					setLoading(false);
					return;
				}
				setAlert('Organização alterada com sucesso', 'success');
				resetForm();
				setLoading(false);
				onClose();
			}
		}
	},
	);

	useEffect(() => {
		if (selectedOrg && selectedOrg.length > 0 && orgId) {
			const selectedPartnerObj = selectedOrg.find((org) => org.id === orgId);
			if (selectedPartnerObj) {
				formik.setValues({
					name: selectedPartnerObj.name || '',
					cnpj: selectedPartnerObj.cnpj || '',
					telephone: selectedPartnerObj.telephone || '',
					id: selectedPartnerObj.id.toString(),
				});
			}
		}
	}, []);

	return (
		<>
			<Container.box >
				<Container.modal>
					<Container.close src={close} alt="warning" onClick={onClose} />
					<Container.head>
						<Container.subTitle data-test="title-Patner">Editar Organização</Container.subTitle>
					</Container.head>
					<Container.wrapper onSubmit={formik.handleSubmit}>
						<Container.content>
							<Container.form>
								<Container.input>
									<LabelInputFormCampaign
										id="name"
										name="name"
										type="text"
										htmlFor="name"
										placeholder="Nome"
										aria-labelledby="name"
										onBlur={formik.handleBlur}
										value={formik.values.name}
										onChange={formik.handleChange}
										error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
									>
										Nome
									</LabelInputFormCampaign>
								</Container.input>
								<Container.input>
									<LabelInputFormCampaign
										htmlFor="cnpj"
										id="cnpj"
										name="cnpj"
										type="text"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.cnpj}
										placeholder="CNPJ"
										aria-labelledby="cnpj"
										error={formik.touched.cnpj && formik.errors.cnpj ? formik.errors.cnpj : undefined}
									>
										Documento
									</LabelInputFormCampaign>
								</Container.input>
								<Container.input>
									<LabelInputFormCampaign
										htmlFor="telephone"
										id="telephone"
										name="telephone"
										type="text"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.telephone}
										placeholder="Email ou telefone"
										aria-labelledby="telephone"
										error={formik.touched.telephone && formik.errors.telephone ? formik.errors.telephone : undefined}
									>
										Telefone
									</LabelInputFormCampaign>
								</Container.input>

							</Container.form>
						</Container.content>
						<SmallBlueButton type="submit" loading={loading}>
							Salvar
						</SmallBlueButton>
					</Container.wrapper >
				</Container.modal>
			</Container.box>
		</>
	);
};
