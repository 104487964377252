import { HttpClient } from '../../HttpClient';

interface DonationsOutput {
  money: boolean;
  items: boolean;
  voluntary: boolean;
}

export interface CampaignArrayOutput {
  title: string;
  summary: string;
  city: string;
  state: string;
  category: string;
  ods: string;
  id: number;
  image: string;
  description: string;
  createdAt: string;
  donations: DonationsOutput;
  progress: number
}

export async function GetListCampaign() {

  const response = await HttpClient<void, { data: CampaignArrayOutput[] }>('get', '/campaigns');
  return response;
  }
