import { Container } from './styles';
import { CardStatistics } from '../../../components/shared/dashboard/CardStatistics';
import { useEffect } from 'react';
import number_user from '../../../assets/dashboard/number_user.svg';
import number_campaign from '../../../assets/dashboard/number_campaign.svg';
import number_donation from '../../../assets/dashboard/number_donation.svg';
import number_new_cause from '../../../assets/dashboard/number_new_cause.svg';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { convertStringNumberToString } from '../../gooders/dashboard';
import { LoadingPage } from '../../../components/common/LoadingPage';
import { GetDahsboardUseCase } from '../../../../application/useCases/dahsboard/OrgDashboardUseCase';
import { ItemsTableDahsboardOrg } from './components/table/ItemsTableDahsboardOrg';
import { VacanciesTableDahsboardOrg } from './components/table/VacanciesTableDahsboardOrg';

interface Props {
  useCase: GetDahsboardUseCase;
}

export const DashboardOrg = ({ useCase }: Props) => {
  const { setAlert } = useAlert();
  const users = useGlobalStore();
  const dashboard = useGlobalStore().state.dashboard
  const state = useGlobalStore()

  useEffect(() => {
    async function fetchData() {
      if (users.state.acl.selectedACL) {
        const orgId = users.state.acl.selectedACL.id;
        const { error, payload } = await useCase.execute({ orgId });
        if (!error && payload) {
          state.actions.setDashboard(payload.data)
        } else {
          setAlert(error!, 'error');
        }
      }
    }
    fetchData();
  }, [users.state.acl.selectedACL]);

  const images = [number_user, number_campaign, number_donation, number_new_cause];

  if (dashboard)
    return (
      <Container.main>
        <Container.cards>
          <Container.card>
            <CardStatistics
              title="Campanhas"
              startNumber={dashboard ? dashboard.total_active_campaigns : '0'}
              percentage=""
              label="Total"
              subTitle="Campanhas "
              imageSrc={images[1]}
            />
            <CardStatistics
              title="Usuários"
              startNumber={dashboard ? dashboard.total_volunteers_subscribed : '0'}
              percentage=""
              label="Total"
              subTitle="Usuários inscritos"
              imageSrc={images[0]}
            />
          </Container.card>
          <Container.card>
            <CardStatistics
              title=""
              startNumber={convertStringNumberToString(dashboard.total_campaigns_money_goal)}
              percentage=""
              label="Total"
              subTitle="Meta de arrecadação"
              imageSrc={images[2]}
            />
            <CardStatistics
              title=""
              startNumber={convertStringNumberToString(dashboard.total_donations_accumulated)}
              percentage=""
              label="Total"
              subTitle="Doações recebidas"
              imageSrc={images[3]}
            />
          </Container.card>
        </Container.cards>
        <Container.table>
          <ItemsTableDahsboardOrg data={dashboard?.itemOrders} />
          <VacanciesTableDahsboardOrg data={dashboard?.vacancyOrders} />
        </Container.table>
      </Container.main>
    );

  return (
    <>
      <LoadingPage />
    </>
  );
};
