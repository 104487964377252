import styled from "styled-components";

const Container__Search = styled.form`
  label {
  padding: 6px 0;
  border: 1px solid ${props => props.theme.colors.lightgray};
  border-radius: 4px;
  width: 100%;
}
  input {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.colors.gray};
  border-radius: 4px;
  width: 90%;
  @media (max-width: 768px) {
  width: 85%;
  }
  }
  button {
  padding: 0 6px;
  border: none;
  background: transparent;
  cursor: pointer;
  }
`;

export const Container = {
	Search: Container__Search,
};


