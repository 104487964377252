import { useContext, useState } from 'react';
import { CreateCause } from './component/CreateCause';
import { DonationPay } from './component/DonationPay';
import { Voluntary } from './component/Voluntary';
import { ImageCause } from './component/ImageCause';
import { Container, Line, CloseIcon } from './styles';
import information from '../../../..//assets/icons/barprogress/information.svg';
import pay from '../../../../assets/icons/barprogress/pay.svg';
import item from '../../../../assets/icons/barprogress/item.svg';
import imageicon from '../../../../assets/icons/barprogress/image.svg';
import voluntary from '../../../../assets/icons/barprogress/voluntary.svg';
import close from '../../../../assets/icons/close.svg';
import { CampaignContext } from '../../../../../infra/config/CampaignContext';
import { DonationItems } from './component/DonationItem';

export interface ModalIconProps {
  open: boolean;
  onClose: () => void;
}

export const CampaignCreate = ({ open, onClose }: ModalIconProps) => {
  if (!open) return null;

  const [activeComponentIndex, setActiveComponentIndex] = useState(0);
  const { SubmitForm } = useContext(CampaignContext);

  const formSteps = [
    {
      image: information,
      text: 'Informações básicas',
      onClick: () => setActiveComponentIndex(0),
    },
    {
      image: pay,
      text: 'Doação financeira',
      onClick: () => setActiveComponentIndex(1),
    },
    {
      image: item,
      text: 'Doação de Itens',
      onClick: () => setActiveComponentIndex(2),
    },
    {
      image: voluntary,
      text: 'Voluntariado',
      onClick: () => setActiveComponentIndex(3),
    },
    {
      image: imageicon,
      text: 'Capa',
      onClick: () => setActiveComponentIndex(4),
    },
  ];

  const handleNext = () => {
    setActiveComponentIndex((prevIndex: number) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setActiveComponentIndex((prevIndex: number) => prevIndex - 1);
  };

  const formComponents = [
    <CreateCause key="createCause" />,
    <DonationPay key="donationPay" />,
    <DonationItems key="donationItem" />,
    <Voluntary key="voluntary" />,
    <ImageCause key="imagecause" />,
  ];

  return (
    <Container.modal>
      <Container.form>
        <Container.progress>
          <CloseIcon src={close} alt="close" onClick={onClose} />
          <Container.progressBar>
            {formSteps.map((step, index) => (
              <Container.img key={index} onClick={step.onClick}>
                <img
                  src={step.image}
                  alt={step.text}
                  style={{ opacity: index <= activeComponentIndex ? 1 : 0.7 }}
                />
                <span>{step.text}</span>
              </Container.img>
            ))}
            <Line></Line>
          </Container.progressBar>
        </Container.progress>
        <Container.cause>{formComponents[activeComponentIndex]}</Container.cause>

        <Container.footer>
          {activeComponentIndex > 0 && (
            <button type="button" onClick={handlePrevious} className="previous-button-border">
              Voltar
            </button>
          )}
          {activeComponentIndex !== 4 ? (
            <button
              type="submit"
              onClick={handleNext}
              className={activeComponentIndex ? 'next-button-blue' : ''}
            >
              Próximo
            </button>
          ) : (
            <button type="button" className="last-button" onClick={SubmitForm}>
              Concluir
            </button>
          )}
        </Container.footer>
      </Container.form>
    </Container.modal>
  );
};
