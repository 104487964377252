import Table from '../../../../../components/common/Table';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { Container } from './styles';
import filter from '../../../../../assets/icons/filter.svg';
import { CategoryTableListRewards } from './components/CategoryTableList';
import { SearchTableListRewards } from './components/SearchTableList';
import update from '../../../../../assets/icons/update.svg';
import { GetListCouponForEditUseCase } from '../../../../../../application/useCases/rewards/GetListCouponUseCase';
import { ModalEditCoupon } from '../ModalEditCoupon';
import { GridColDef } from '@mui/x-data-grid';
import delet from '../../../../../assets/icons/delet.svg'
import { DeleteCouponUseCase } from '../../../../../../application/useCases/rewards/DeleteCouponUseCase';
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';

export const TableListRewards = () => {
	const [openModal, setOpenModal] = useState(false);
	const { setAlert } = useAlert();
	const [search, setSearch] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedCoupon, setSelectedCoupon] = useState<number>(0);
	const clearFilters = () => { setSearch(''); setSelectedCategory(''); };
	const list = useGlobalStore()
	const [renderConfirmModal, setRenderConfirmModal] = useState(false);

	useEffect(() => {
		if (list.state.rewards.length === 0) {
			const fetchData = async () => {
				const { payload, error } = await GetListCouponForEditUseCase();
				if (!error && payload) {
					list.actions.setRewards(payload.data)
				} else {
					setAlert(error!, 'error');
				}
			};
			fetchData();
		}
	}, []);


	const columns: GridColDef[] = [
		{ field: 'patner', headerName: 'Parceiro', minWidth: 200, flex: 1, headerClassName: 'table-header', },
		{ field: 'description', headerName: 'Descrição', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
		{ field: 'Tipo', headerName: 'Tipo', minWidth: 120, flex: 1, headerClassName: 'table-header', },
		{
			field: 'rewards', headerName: 'Recompensa', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				const tipoValue = params.row.Tipo;
				const isMoney = tipoValue === 'MONEY';
				const offValue = isMoney ? `R$ ${params.value}` : `${params.value}%`;
				return <span style={{ fontSize: '13px' }}>{offValue}</span>;
			},
		},
		{ field: 'goodersPrice', headerName: 'Gooders', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header', },
		{
			field: 'Ações', headerName: 'Ações', minWidth: 70, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				return (
					<>
						<img
							onClick={() => {
								setSelectedCoupon(params.row.id);
								setOpenModal(true);
							}}
							src={update}
							alt="action"
							style={{ cursor: 'pointer', paddingRight: "16px" }}
						/>
						<img
							onClick={() => {
								handleConfirmModal()
								setSelectedCoupon(params.row.id);
							}}
							src={delet}
							alt="action"
							style={{ cursor: 'pointer', }}
						/>
					</>
				)
			},
			sortable: false,
			filterable: false,
		},
	];


	const rows =
		list.state.rewards?.map((rewards, index) => ({
			index: index,
			id: rewards.id,
			Tipo: rewards.type === 'MONEY' ? 'Valor' : 'Porcentagem',
			description: rewards.description,
			rewards: rewards.off,
			patner: rewards.partner.name,
			goodersPrice: rewards.goodersPrice / 100
		})) ?? [];

	const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedCategory(event.target.value);
	};

	const filterRows = (
		search: string,
		selectedCategory: string,
	) => {
		let filteredRows = rows;

		if (search !== '') {
			const searchTerm = search.toLowerCase();
			filteredRows = filteredRows.filter((row) => {
				const title = row['patner'].toLowerCase();
				const description = row['description'].toLowerCase();
				return title.includes(searchTerm) || description.includes(searchTerm);
			});
		}

		if (selectedCategory !== '') {
			filteredRows = filteredRows.filter((row) => row['Tipo'] === selectedCategory);
		}

		return filteredRows;
	};

	const filteredRows = filterRows(search, selectedCategory,
	);

	function handleConfirmModal() {
		setRenderConfirmModal(true);
	}

	async function handleCloseConfirmModal(result: boolean) {
		setRenderConfirmModal(false);
		if (result) {
			const { error } = await DeleteCouponUseCase(selectedCoupon);
			if (error) {
				setAlert(error, 'error');
			} else {
				setAlert('Cupom deletado com sucesso!', 'success');
			}
		}
	}

	function IconEdit(id: number) {
		setSelectedCoupon(id);
		setOpenModal(true);
	}

	function IconDelete(id: number) {
		handleConfirmModal()
		setSelectedCoupon(id);
	}



	const cardData: CardData[] = filteredRows.map((rewards, index) => ({
		id: rewards.id,
		title: [
			'Parceiro',
			'Descrição',
			'Tipo',
			'Recompensa',
			'Gooders'
		],
		value: [
			rewards.patner,
			rewards.description,
			rewards.Tipo === 'MONEY' ? 'Valor' : 'Porcentagem',
			rewards.rewards,
			rewards.goodersPrice / 100
		]
	}));



	return (
		<>
			<Container.main>
				<Container.title>
					Filtro
					<Container.search style={{ border: 'none' }}>
						<Container.filterButton onClick={clearFilters}>
							<img src={filter} alt="filter" />
							Limpar
						</Container.filterButton>
					</Container.search>
				</Container.title>
				<Container.filter>
					<SearchTableListRewards search={search} searchText={searchText} />
					<CategoryTableListRewards
						selectedCategory={selectedCategory}
						handleCategoryChange={handleCategoryChange}
					/>
				</Container.filter>
				<Container.table>
					<Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
				</Container.table>
				<Container.mobile>
					<CardsTable cardData={cardData} IconDelete={IconDelete} IconEdit={IconEdit} />
				</Container.mobile>
				{openModal && selectedCoupon && (
					<ModalEditCoupon
						open={openModal}
						onClose={() => setOpenModal(false)}
						selectedCoupon={selectedCoupon}
					/>
				)}
				{renderConfirmModal && (
					<ModalConfirm
						title="Deletar Cupom"
						content="Deseja continuar? Ao clicar em Deletar o cupom será excluído permanentemente!"
						rightButtonText="Deletar"
						onClose={handleCloseConfirmModal}

					/>
				)}
			</Container.main>
		</>
	);
};
