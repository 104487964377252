import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
`;

export const ContentLoading = styled.div`
	width: 20px;
	height: 20px;
	border: solid 4px ${props => props.theme.colors.white};
	border-radius: 50%;
	border-right-color: transparent;
	animation-name: rotate; 
	animation-duration: .9s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
	}
`;