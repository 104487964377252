import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Create = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.media.monitorSmall}) {
    width: 100%;
  }
`;

const Container__Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container__Option = styled.div``;

const Container__Section = styled.section`
  width: 100%;
  display: flex;
  padding: 32px 0;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.paleBlue};
  p {
    cursor: pointer;
  }
  span {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize.lg};
    text-decoration: underline 1px ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
  @media (max-width: ${theme.media.monitor}) {
    padding-top: 8px;
    p {
      font-size: ${(props) => props.theme.fontSize.sm};
    }
    span {
      padding: 0;
    }
  }
`;

export const Container = {
  header: Container__Header,
  create: Container__Create,
  option: Container__Option,
  section: Container__Section,
};

const Text__Desc = styled.p`
  font-size: ${(props) => props.theme.fontSize.sm};
  line-height: 20px;
`;

export const Text = {
  desc: Text__Desc,
};
