import Table from '../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { Container } from '../TableListPartner/styles';
import { GridColDef } from '@mui/x-data-grid';
import delet from '../../../../../assets/icons/delet.svg'
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { SearchTableListUser } from '../TableListPartner/components/SearchTableListUser';
import createUser from '../../../../../assets/icons/createUser.svg'
import { ModalConnectUser } from '../TableListInstitutions/components/ModalConnectUser';
import { UserTypeEnum } from '../../../../../../domain/interfaces/User';
import { ConnectUserInstUseCase } from '../../../../../../application/useCases/gooders/ConnectUserInstUseCase';
import { ListGoodersMembers, ListGoodersUseCase } from '../../../../../../application/useCases/gooders/ListGoodersUseCase';
import { DeleteMemberGoodersUseCase } from '../../../../../../application/useCases/gooders/DeleteMemberGoodersUseCase';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';


export const TableListGooders = () => {
	const [listMemberGooders, setListMemberGooders] = useState<ListGoodersMembers[]>([]);
	const { setAlert } = useAlert();
	const [search, setSearch] = useState('');
	const clearFilters = () => { setSearch(''); };
	const [openModalConnect, setOpenModalConnect] = useState(false);
	const [memberGoodersId, setMemberGoodersId] = useState<number>(0);
	const [renderConfirmModal, setRenderConfirmModal] = useState(false);


	useEffect((
	) => {
		const fetchData = async () => {
			const { error, payload } = await ListGoodersUseCase();
			if (!error && payload) {
				setListMemberGooders(payload.data);
			} else {
				setAlert(error!, 'error');
			}
		}
		fetchData();
	}, [])

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Usuário', minWidth: 216, flex: 1, headerClassName: 'table-header', },
		{ field: 'email', headerName: 'Email', minWidth: 200, flex: 1, headerClassName: 'table-header', },
		{ field: 'role', headerName: 'Tipo', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
		{
			field: 'Associar', headerName: 'Associar', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				return (
					<img
						onClick={() => {
							setMemberGoodersId(params.row.id);
							setOpenModalConnect(true);
						}}
						src={createUser}
						alt="action"
						title='Associar Usuário'
						style={{ cursor: 'pointer', paddingRight: "16px" }}
					/>
				)
			},
			sortable: false,
			filterable: false,
		}, {
			field: 'delete', headerName: 'Deletar', minWidth: 50, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				return (
					<img
						onClick={() => {
							handleConfirmModal()
							setMemberGoodersId(params.row.id);
						}}
						src={delet}
						alt="action"
						style={{ cursor: 'pointer', }}
					/>
				)
			},
			sortable: false,
			filterable: false,
		},
	];

	const rows =
		listMemberGooders?.map((member, index) => ({
			index: index,
			id: member.id,
			name: member.username,
			email: member.email,
			role: member.role,
		})) ?? [];

	const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const filterRows = (search: string) => {
		let filteredRows = rows;
		if (search !== '') {
			const searchTerm = search.toLowerCase();
			filteredRows = filteredRows.filter((row) => {
				const name = row['name'].toLowerCase();
				const cnpj = row['email'].toLowerCase();
				return name.includes(searchTerm) || cnpj.includes(searchTerm);
			});
		}
		return filteredRows;
	};

	const filteredRows = filterRows(search);

	function handleConfirmModal() {
		setRenderConfirmModal(true);
	}

	async function handleCloseConfirmModal(result: boolean) {
		setRenderConfirmModal(false);
		if (result) {
			const { error } = await DeleteMemberGoodersUseCase(memberGoodersId);
			if (error) {
				setAlert(error, 'error');
			} else {
				setAlert('Membro excluído com sucesso!', 'success');
			}
		}
	}

	function IconConnect(id: number) {
		setMemberGoodersId(id);
		setOpenModalConnect(true);
	}

	function IconDelete(id: number) {
		handleConfirmModal()
		setMemberGoodersId(id);
	}

	const cardData: CardData[] = filteredRows.map((member, index) => ({
		id: member.id,
		title: [
			'Name',
			'Email',
			'Tipo',
		],
		value: [
			member.name,
			member.email,
			member.role,
		]
	}));

	return (
		<>
			<Container.main>
				<Container.filter>
					<SearchTableListUser search={search} searchText={searchText} clearFilters={clearFilters} />
				</Container.filter>
				<Container.table>
					teste
					<Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
				</Container.table>
				<Container.mobile>
					<CardsTable cardData={cardData} IconConnect={IconConnect} IconDelete={IconDelete} />
				</Container.mobile>
				{openModalConnect && (
					<ModalConnectUser
						onClose={() => setOpenModalConnect(false)}
						useCase={new ConnectUserInstUseCase(UserTypeEnum.GOODERS)}
						rowId={memberGoodersId}
					/>
				)}
				{renderConfirmModal && (
					<ModalConfirm
						title="Excluir Usuário"
						content="Deseja continuar? Ao clicar em deletar seu usuário será excluído!"
						rightButtonText="Deletar"
						onClose={handleCloseConfirmModal}
					/>
				)}
			</Container.main>
		</>
	);
};
