import remove from '../../../../../../assets/icons/remove.svg';
import { useCampaignUnique } from '../../../../../../../infra/state/useCampaign';
import { useAlert } from '../../../../../../../infra/config/AlertContext/useAlert';
import { GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../../components/common/Table';
import { useGlobalStore } from '../../../../../../../infra/state/GlobalStore';
import { DeleteItemFromCampaignUseCase } from '../../../../../../../application/useCases/campaign/item/DeleteItemFromCampaignUseCase';
import { Container } from '../../../../../../pages/users/home/components/Transactions/styles'
import { CardData, CardsTable } from '../../../../../common/CardsTable';
import active from '../../../../../../assets/icons/active.svg';
import { ActiveItemUseCase } from '../../../../../../../application/useCases/campaign/item/ActiveItemUseCase';

interface Props {
  useCase: DeleteItemFromCampaignUseCase
  useCase3: ActiveItemUseCase;
}

export const ItemsTable = ({ useCase, useCase3 }: Props) => {
  const { campaignUnique, actions, setCampaignUnique } = useCampaignUnique();
  const { setAlert } = useAlert();
  const users = useGlobalStore();

  async function handleDeleteItem(itemId: number) {
    if (!users.state.acl.selectedACL || !campaignUnique) {
      return;
    }

    const orgId = users.state.acl.selectedACL.id;
    const institutionId = campaignUnique.institution.id;
    const campaignId = campaignUnique.id;

    const { error } = await useCase.execute({ orgId, institutionId, campaignId, itemId });

    if (error) {
      setAlert(error, 'error');
    } else {
      setAlert('Item excluído com sucesso', 'success');
      actions.items.remove(itemId);
    }
  }


  async function handleActiveItem(itemId: number, isActive: boolean) {

    if (!users.state.acl.selectedACL || !campaignUnique) {
      return;
    }
    const orgId = users.state.acl.selectedACL.id;
    const institutionId = campaignUnique.institution.id;
    const campaignId = campaignUnique.id;

    const { error } = await useCase3.execute({ orgId, institutionId, campaignId, itemId }, { isActive: !isActive });

    if (error) {
      setAlert(error, 'error');
    } else {
      setAlert(`Item alterado com sucesso`, 'success');
      const updatedCampaignUnique = {
        ...campaignUnique,
        items: campaignUnique.items.map((item) => {
          if (item.id === itemId) {
            item.isActive = !isActive;
          }
          return item;
        }),
      };
      setCampaignUnique(updatedCampaignUnique);
    }
  }

  const columns: GridColDef[] = [
    { field: 'Nome do item:', headerName: 'Nome do item:', minWidth: 269, flex: 1, headerClassName: 'table-header', },
    { field: 'Categoria:', headerName: 'Categoria:', minWidth: 269, flex: 1, headerClassName: 'table-header', },
    { field: 'Quantidade:', headerName: 'Quantidade:', minWidth: 204.3, flex: 1, headerClassName: 'table-header', },
    {
      field: 'Ativar/Desativar', headerName: 'Ativar/Desativar', minWidth: 130, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => {
        const isActive = params.row.isActive;
        return (
          <img
            onClick={() => handleActiveItem(params.row.id, isActive)}
            src={active}
            alt="action"
            style={{
              cursor: 'pointer',
              padding: "3px",
              borderRadius: "4px",
              background: isActive ? '#01742D' : '#CF4451',
            }}
          />
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: 'Remover', headerName: 'Remover', minWidth: 130, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => (
        <img
          onClick={() => handleDeleteItem(params.row.id)}
          src={remove}
          alt="action"
          style={{ cursor: 'pointer' }}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ];

  const rows =
    (campaignUnique?.items?.map((item, index: number) => {
      return {
        index: index,
        id: item.id,
        'Nome do item:': item.name,
        'Categoria:': item.category,
        'Quantidade:': item.quantity,
        Remover: 'Remover',
        isActive: item.isActive,

      };
    }) ?? [])
      .sort((a, b) => a['Nome do item:'].localeCompare(b['Nome do item:']));

  const cardData: CardData[] = (campaignUnique?.items ?? []).map((item) => ({
    id: item.id,
    title: [
      'Nome do item',
      'Categoria',
      'Quantidade',
    ],
    value: [
      item.name,
      item.category,
      item.quantity,
    ]
  }));


  return (
    <>
      <Container.desktop>
        <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />
      </Container.desktop>
      <Container.mobile>
        <CardsTable cardData={cardData} IconDelete={handleDeleteItem} />
      </Container.mobile>
    </>
  )
};
