import { Container } from "./styles";
import { HeaderMyAccount } from "./components/HeaderMyAccount";
import { SupportMyAccount } from "./components/SupportMyAccount";
import world from "../../../assets/profile/world.svg";
import { HeaderPages } from "../../../components/common/HeaderPages";
import { AclSelection } from "../../../components/common/Sidebar/components/AclSelection";




export const Home: React.FC = () => {

	return (
		<HeaderPages title="Home">
			<HeaderMyAccount />
			<Container.inputAcl>
				<AclSelection />
			</Container.inputAcl>
			<SupportMyAccount />
			<Container.banner>
				<img src={world} alt="world" />
				<p>#FazerOBemRECOMPENSA!</p>
			</Container.banner>
		</HeaderPages>
	);
};