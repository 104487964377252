import React, { ReactNode, useEffect, useState } from 'react';
import {
  CreateCampaignInterface,
  DonationItem,
  MoneyDonation,
  Vacancy,
} from '../../../domain/interfaces/CreateCampaignInterface';
import { useAlert } from '../AlertContext/useAlert';
import { useGlobalStore } from '../../state/GlobalStore';
import { CreateCampaignUseCase } from '../../../application/useCases/campaign/CreateCampaignUseCase';
import { PatchImageForCampaignUseCase } from '../../../application/useCases/campaign/images/PatchImageForCampaignUseCase';
import { AlertType } from '../AlertContext';

interface CampaignContextType {
  images: File[] | undefined;
  setImages: React.Dispatch<React.SetStateAction<File[] | undefined>>;
  items: DonationItem[];
  setItems: React.Dispatch<React.SetStateAction<DonationItem[] | []>>;
  information: CreateCampaignInterface | undefined;
  setInformation: React.Dispatch<React.SetStateAction<CreateCampaignInterface | undefined>>;
  vacancies: Vacancy[];
  setVacancies: React.Dispatch<React.SetStateAction<Vacancy[] | []>>;
  setAlert: (text: string, type: AlertType) => void;
  SubmitForm: () => void;
  donationPay: MoneyDonation | undefined;
  setDonationPay: React.Dispatch<React.SetStateAction<MoneyDonation | undefined>>;
}

export const CampaignContext = React.createContext<CampaignContextType>({} as CampaignContextType);

interface Props {
  children: ReactNode;
  onClose: () => void;
  useCase1: CreateCampaignUseCase;
  useCase2: PatchImageForCampaignUseCase;
}

export const CampaignProvider = ({ children, onClose, useCase1, useCase2 }: Props) => {
  const [images, setImages] = useState<File[] | undefined>([]);
  const [items, setItems] = useState<DonationItem[] | []>([]);
  const [information, setInformation] = useState<CreateCampaignInterface | undefined>();
  const [vacancies, setVacancies] = useState<Vacancy[] | []>([]);
  const [donationPay, setDonationPay] = useState<MoneyDonation | undefined>();
  const { setAlert } = useAlert();
  const users = useGlobalStore();
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setImages([]);
    setItems([]);
    setInformation(undefined);
    setVacancies([]);
    setDonationPay(undefined);
  };

  const sendImage = async (campaignId: number) => {
    setLoading(true)
    if (users.state.acl.selectedACL) {
      try {
        if (images) {
          const formData = new FormData();
          images.forEach((image) => formData.append('images', image));

          const orgId = users.state.acl.selectedACL.id;
          const institutionId = information?.institutionId as number;

          const { error } = await useCase2.execute({ orgId, institutionId, campaignId }, formData);

          if (!error) {
            setAlert('Campanha cadastrada com sucesso', 'success');
            // setTimeout(() => {
            //   window.location.href = '/listcampaign';
            // }, 2000);
            onClose();
            resetForm();
          } else {
            setAlert(error, 'error');
          }
        }
      } catch (err) { }
    }
    setLoading(false)
  };

  async function SubmitForm() {
    setLoading(true)
    if (!images || !images[0]) {
      setAlert('Selecione a foto da campanha', 'warning');
      return;
    }

    if (!information) {
      setAlert('Confira se as informações básicas foram preenchidas', 'warning');
      return;
    }

    if (!vacancies) {
      return;
    }

    const adapter: CreateCampaignInterface = {
      ...information,
    };

    if (vacancies[0]) {
      adapter.vacancies = vacancies.map((vacancy) => Object.assign({}, vacancy));
    }

    if (items[0]) {
      adapter.items = items.map((items) => Object.assign({}, items));
    }

    if (donationPay?.target) {
      adapter.moneyDonation = {
        target: parseFloat(String(donationPay.target)),
        description: donationPay.description,
        multiplier: donationPay.multiplier,
      };
    }

    if (users.state.acl.selectedACL) {
      const orgId = users.state.acl.selectedACL.id;
      const institutionId = information?.institutionId as number;
      const { error, payload } = await useCase1.execute({ orgId, institutionId }, adapter);
      if (!error && payload && payload.data) {
        await sendImage(payload.data.id);
        resetForm();
      } else {
        setAlert(error!, 'error');
      }
    }
    setLoading(false)
  }

  return (
    <CampaignContext.Provider
      value={{
        images,
        setImages,
        items,
        setItems,
        information,
        setInformation,
        vacancies,
        setVacancies,
        setAlert,
        SubmitForm,
        donationPay,
        setDonationPay,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
