import styled from "styled-components";

export const Container = styled.button`
	display: flex;
	width: 100%;
	padding: 12px 0;
	font-weight: 500;
	line-height: 24px;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	justify-content: center;
	gap: 6px;
	cursor: pointer;
	transition: all 300ms linear 0s;
	background: ${props => props.theme.colors.primary};
	color: ${props => props.theme.colors.white};
	border: none;
	&:hover {
    background: ${props => props.theme.colors.hoverBlue};
	};
`;

export const _BlueButton = styled(Container)`
`;

export const _SmallBlueButton = styled(Container)`
	padding: 4px 8px;
	width: 100%;
`;


export const _SmallTransparentBlueButton = styled(_SmallBlueButton)`
	border: solid 1px ${props => props.theme.colors.primary} !important;
	background: ${props => props.theme.colors.white};
	color: ${props => props.theme.colors.primary};
	width: 100%;
	&:hover {
    color: ${props => props.theme.colors.white};
  };
`;

export const _RedButton = styled(Container)`
  background: ${props => props.theme.colors.red};
  &:hover {
  	background: ${props => props.theme.colors.hoverRed};
  };
`;

export const _SmallRedButton = styled(_RedButton)`
  padding: 4px 8px;
`;

export const _GreenButton = styled(Container)`
  background: ${props => props.theme.colors.green};
  &:hover {
    background: ${props => props.theme.colors.hoverGren};
  };
  
`;

export const _SmallGreenButton = styled(Container)`
	padding: 4px 8px;
	background: ${props => props.theme.colors.green};
  &:hover {
    background:  ${props => props.theme.colors.hoverGren};
  };
`;

