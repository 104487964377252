import { Container, Text } from "./styles";
import logo_coin from "../../../assets/logo/logo_coin.svg";
import { GreenButton } from "../Button";
import { useState } from "react";
import { ModalReward } from "./components/ModalReward";
import { divideBy100 } from "../../../../application/utils";


export interface DiscountProps {
	off: number,
	logo: string,
	name: string,
	id: number
	description: string,
	goodersPrice: number,
	className?: string,
	code: string,
	isLink: boolean,
	buttonText: string,
	type: "MONEY" | "PERCENTAGE",
	onClickFunction: (goodersPrice: number, id: number, code: string) => void;
	openModal?: boolean,
	setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CardDiscount = ({ off, logo, name, goodersPrice, description, id, type, isLink, buttonText, onClickFunction, openModal, setOpenModal, code }: DiscountProps) => {

	const [isLoading, setIsLoading] = useState(false);
	const [isHidden, setIsHidden] = useState(false);

	const handleButtonClick = () => {
		setIsLoading(true);
		onClickFunction(goodersPrice, id, code);
		setIsLoading(false);
	};



	const formattedGoodersPrice = divideBy100(goodersPrice);


	return (
		<Container.card isHidden={isHidden}>
			<Container.image src={logo} alt="logo" />
			<Container.box>
				<span>{name}</span>
			</Container.box>
			<Container.box style={{ justifyContent: "space-between" }}>
				{type === "MONEY" ? (
					<p>R$ {off} OFF</p>
				) : type === "PERCENTAGE" ? (
					<p>{off}% OFF</p>
				) : null}
				<Container.gooders>
					<img src={logo_coin} alt="gooders" />
					<p>{formattedGoodersPrice} Coins</p>
				</Container.gooders>
			</Container.box>
			<Text.description>
				{description}
			</Text.description>
			<Container.perforated>
				<div></div><div></div><div></div><div></div><div></div>
				<div></div><div></div><div></div><div></div><div></div>
				<div></div><div></div><div></div><div></div><div></div><div></div>
			</Container.perforated>
			{!isHidden && (
				<GreenButton onClick={handleButtonClick} loading={isLoading}> {buttonText} </GreenButton>
			)}
			{openModal !== undefined && setOpenModal !== undefined && (
				<ModalReward
					open={openModal}
					onClose={() => setOpenModal(false)}
					selectedCoupon={id}
					updateButtonStatus={setIsHidden}
				/>
			)}
		</Container.card>
	);
};
