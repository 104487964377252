import { Container } from './styles';
import notfound from '../../assets/img/notfound.svg';
import Navbar from '../../components/common/Navbar';
import { useAuth } from '../../../infra/config/AuthProvider/useAuth';
import { BlueButton } from '../../components/common/Button';
import { useNavigate } from 'react-router-dom';

export const NotFound: React.FC = () => {
  const { loadingUserData } = useAuth();
  const navigate = useNavigate();


  if (!loadingUserData) {
    return (
      <>
        <Navbar openModal={false} width="100%" />
        <Container.page>
          <Container.box>
            <h1>
              Ops! A página que você está tentando acessar não pode ser encontrada.
            </h1>
            <Container.button>
              <BlueButton onClick={() => navigate('/home')} loading={false}>Voltar</BlueButton>
            </Container.button>

            <img src={notfound} alt="notfound" style={{ maxWidth: "600px" }} />
          </Container.box>
        </Container.page>
      </>
    );
  }

  return <></>;
};
