import { Container } from "./styles";

interface Props {
	totalCount: number;
	pageSize: number;
	startIndex: number;
	pagedCauses: any[];
	causes: any[];
	pageSizeOptions: number[];
	handlePageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	currentPage: number;
}

export const PaginationListCause: React.FC<Props> = ({
	totalCount,
	pageSize,
	startIndex,
	pagedCauses,
	causes,
	pageSizeOptions,
	handlePageSizeChange,
	setCurrentPage,
	currentPage
}) => {

	return (
		<Container.Pagination >
			<label data-test="campaign-pagination">Exibir:
				<select value={pageSize} onChange={handlePageSizeChange}>
					{pageSizeOptions.map((option) => (
						<option key={option} value={option}>
							{option} por página
						</option>
					))}
				</select>
			</label>
			<span>{`${pagedCauses.length} Campanhas`}</span>
		</Container.Pagination>
	);
};
