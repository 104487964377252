import { UserTypeEnum } from '../../../domain/interfaces/User';
import { HttpClient } from '../../HttpClient';
import { SharedUseCase } from './item/CreateItemForCampaignUseCase';

export interface CampaignLinkedWith {
  campaignId: number;
  institutionId: number;
  category: string;
  summary: string;
  title: string;
  createdAt: string;
  institutionName:  string,
  city:  string,
  state: string,
  isActive: boolean
}

interface Params {
  orgId?: number;
  institutionId?: number;
  campaignId?: number;
}

export class GetCampaignsLinkedWith extends SharedUseCase<
  Params,
  CampaignLinkedWith,
  {
    payload: { data: CampaignLinkedWith[];} | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

protected override async execOrg() {
  return await HttpClient<void, { data: CampaignLinkedWith[] }>(
    'get',
    `/campaigns/org/${this.params!.orgId}/my-campaigns`
  );
}

protected override async execInstitution() {
  return await HttpClient<void, { data: CampaignLinkedWith[] }>(
    'get',
    `/campaigns/institution/${this.params!.institutionId}/my-campaigns`
  );
}

protected override async execGooders() {
  return await HttpClient<void, { data: CampaignLinkedWith[] }>(
    'get',
    `/campaigns/gooders`
  );
}


}