import { Container, } from '../../../TableListPartner/components/ModalEditPartner/styles';
import close from '../../../../../../../assets/icons/close.svg';
import Table from '../../../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { ListConnectUser, ListConnectUserInstUseCase } from '../../../../../../../../application/useCases/gooders/ListConnectUserInstUseCase';
import { ModalConfirm } from '../../../../../../../components/common/ModalConfirm';
import delet from '../../../../../../../assets/icons/delet.svg'
import { DeleteConnectionUserInstituitonUseCase, Params } from '../../../../../../../../application/useCases/gooders/DeleteConnectionUserInstituitonUseCase';
import { CardData, CardsTable } from '../../../../../../../components/common/CardsTable';

interface FormCreatePatnerProps {
	onClose: () => void;
	Id: number;
	useCase: ListConnectUserInstUseCase;
	useCase2: DeleteConnectionUserInstituitonUseCase
}
export const ModalListConnectUser: React.FC<FormCreatePatnerProps> = ({ onClose, Id, useCase, useCase2 }) => {

	const [userConnect, setUserConnect] = useState<ListConnectUser>();
	const { setAlert } = useAlert();
	const [renderConfirmModal, setRenderConfirmModal] = useState(false);
	const [getUserId, setGetUserId] = useState(0);

	useEffect((
	) => {
		const fetchData = async () => {
			const institutionId = Id;
			const orgId = Id;
			const { error, payload } = await useCase.execute({ orgId, institutionId, });
			if (!error && payload) {
				setUserConnect(payload.data);
			} else {
				setAlert(error!, 'error');
			}
		}
		fetchData();
	}, [])

	const columns: GridColDef[] = [
		{ field: 'Usuário', headerName: 'Usuário', minWidth: 269, flex: 1, headerClassName: 'table-header', },
		{ field: 'Email', headerName: 'Email', minWidth: 269, flex: 1, headerClassName: 'table-header', },
		{ field: 'Tipo de usuário', headerName: 'Tipo de usuário', minWidth: 204.3, flex: 1, headerClassName: 'table-header', },
		{
			field: 'Deletar', headerName: 'Deletar', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				return (
					<img
						onClick={() => {
							handleConfirmModal(params.row.id)
								;
						}}
						src={delet}
						alt="action"
						style={{ cursor: 'pointer', }}
					/>
				)
			},
			sortable: false,
			filterable: false,
		},
	];

	const rows =
		userConnect?.members.map((user: any,) => ({
			id: user.id,
			'Usuário': user.username,
			'Email': user.email,
			'Tipo de usuário': user.role,
		})) ?? [];

	function handleConfirmModal(params: number) {
		setRenderConfirmModal(true);
		setGetUserId(params);
	}

	async function handleCloseConfirmModal(result: boolean) {
		setRenderConfirmModal(false);
		if (result) {
			const params: Params = {
				orgId: Id,
				memberId: getUserId,
			};
			const { error } = await useCase2.execute(params);
			if (error) {
				setAlert(error, 'error');
			} else {
				setAlert('O usuário foi excluído!', 'success');
			}
		}
	}

	const cardData: CardData[] = userConnect
		? userConnect.members.map((member: any) => ({
			id: member.id,
			title: ['Name', 'Email', 'Tipo'],
			value: [member.username, member.email, member.role],
		}))
		: [];

	function IconDelete(id: number) {
		handleConfirmModal(id)
	}


	return (
		<>
			<Container.box >
				<Container.modal>
					<Container.close src={close} alt="warning" onClick={onClose} />
					<Container.head>
						<Container.subTitle data-test="title-Patner">Usuários associados</Container.subTitle>
					</Container.head>
					<Container.table>
						<Table rows={rows} columns={columns} menuIconDisplay='flex' numberRows={5} />
					</Container.table>
					<Container.mobile>
						<CardsTable cardData={cardData} IconDelete={IconDelete} />
					</Container.mobile>
				</Container.modal>
				{renderConfirmModal && (
					<ModalConfirm
						title="Excluir usuário?"
						content="Deseja continuar? Ao clicar em Deletar o usuário será excluído!"
						rightButtonText="Deletar"
						onClose={handleCloseConfirmModal}
					/>
				)}
			</Container.box>
		</>
	);
};
