import { HttpClient } from "../../HttpClient";


export async function DeleteInstituitonsUseCase(institutionId: number,)
{
  return await HttpClient(
    'delete',
    `/users/gooders/institutions/${institutionId}`
    );
}
