import styled from "styled-components";
import {theme} from '../../../themes/blue'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background: #fff;
  border: none;
  label {
  width: 100%;
  position: relative;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.dark};
  padding: 6px 8px;
  }
  input {
  width: 100%;
  border-radius: 8px;
  border: none;
  outline: none;
  padding-left: 30px;
  background: transparent;
  font-size: ${props => props.theme.fontSize.sm}!important;
  }
  span {
  padding: 0px 4px !important;
  border-radius: 4px;
  background: ${props => props.theme.colors.white};
  position: absolute;
  top: -10px;
  left: 20px;
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.sm};
  line-height: 20px;
  color:${props => props.theme.colors.dark};;
  }
  img {
  padding-left: 0px;
  position: absolute;
  top: 12px;
  :first-child {
  left: 15px;
  }
  :last-child {
  right: 10px;
  }
  }
`;

export const _Input = styled(Container)``;

export const _InputRadio = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;
  label {
  width: 100%;
  display: flex;
  padding: 8px 0px;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${props => props.theme.colors.lightgray};
  border-radius: 4px;
  }
  input {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #CCCCCC;
  border-radius: 50%;
  outline: none;
  margin-right: 8px;
  position: relative;
  }
  input:checked::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  min-width: 7px;
  min-height: 7px;
  width: 7px;
  height: 7px;
  background-color: ${props => props.theme.colors.primary};
  }
  img {
    width: 26px;
    padding-bottom: 0!important;
   }
  span {
  font-size: ${props => props.theme.fontSize.md};
  line-height: 20px;
  padding-left: 6px;
  @media (max-width: ${theme.media.mobileSmall}) {
    font-size: ${props => props.theme.fontSize.smX};
  }
}
`;

export const _InputFormCampaign = styled(Container)`
  label  {
  border: 1px solid ${props => props.theme.colors.lightgray};
  border-radius: 4px;
  }
  input {
  border: none;
  outline: none;
  width: 100%;
  padding: 6px;
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 20px;
  letter-spacing: 0.02em;
  border-radius: 4px;
  }
  span {
  left: 10px;
  }
`;
