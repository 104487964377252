import { Container } from './styles';
import warning from '../../../assets/icons/warning.svg';
import close from '../../../assets/icons/close.svg';
import { SmallRedButton, SmallTransparentBlueButton } from '../Button';
import { useState } from 'react';
import { VoluntaryLaw } from '../CardsCause/component/Modal/ModalVoluntary/components/VoluntaryLaw';
import { TermsOfMemberShip } from '../CardsCause/component/Modal/ModalVoluntary/components/termsOfMemberShip';

interface Props {
  title: string;
  content: string;
  rightButtonText: string;
  linkText?: string;
  linkText2?: string;
  onClose: (resutl: boolean) => void;
}

export const ModalConfirm = ({ content, title, rightButtonText, linkText, linkText2, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [showLaw, setShowLaw] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const handleLinkLawClick = () => {
    setShowTerms(false);
    setShowLaw(true);
  };

  const handleLinkTermsClick = () => {
    setShowLaw(false);
    setShowTerms(true);
  };

  return (
    <Container.box>
      <Container.warning>
        <Container.close src={close} alt="warning" onClick={() => onClose(false)} />
        <Container.icon>
          <img src={warning} alt="warning" />
          <h3>{title}</h3>
        </Container.icon>
        <p>{content}</p>
        {linkText && (
          <p style={{ paddingBottom: "6px" }}>
            <span style={{ fontSize: "12px", color: "gray", cursor: "pointer" }} onClick={handleLinkLawClick}> {linkText}</span>
          </p>
        )}
        {linkText2 && (
          <p style={{ paddingBottom: "6px" }}>
            <span style={{ fontSize: "12px", color: "gray", cursor: "pointer" }} onClick={handleLinkTermsClick}> {linkText2}</span>
          </p>
        )}
        <Container.action>
          <SmallTransparentBlueButton onClick={() => onClose(false)} loading={loading}>
            Cancelar
          </SmallTransparentBlueButton>
          <SmallRedButton onClick={() => onClose(true)} loading={loading}>
            {rightButtonText}
          </SmallRedButton>
        </Container.action>
        {showLaw && (
          <VoluntaryLaw />
        )}
        {showTerms && (
          <TermsOfMemberShip />
        )}
      </Container.warning>
    </Container.box>
  );
};
