import styled from 'styled-components';

export const Container_Title_And_SwitchBTN = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  button {
    max-width: 6rem;
  }
`;
