import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from './styles';
import { SmallBlueButton } from '../../../../../components/common/Button';
import donate from '../../../../../assets/profile/donate.svg';
import { ROUTES_PATH } from '../../../../../../infra/config/routes';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { TransactionsRewards, UserTransactionsRewardsUseCase } from '../../../../../../application/useCases/rewards/UserTransactionsRewardsUseCase';
import { CardDiscount } from '../../../../../components/common/CardDiscount';
import { ModalCodeCoupon } from './components/ModalCodeCoupon';

interface TextProps {
  text: string;
}

export const ListRewardsUser: React.FC<TextProps> = ({ text }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [couponRewards, setCouponRewards] = useState<TransactionsRewards[]>([]);
  const carousel = useRef<HTMLDivElement>(null);
  const [codeCoupon, setCodeCoupon] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const { setAlert } = useAlert();

  const handleButtonClick = () => {
    setIsLoading(true);
    navigate(ROUTES_PATH.listCampaignsForUsers);
  };

  const handleLeftClick = () => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }
  };

  const handleRightClick = () => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft += carousel.current.offsetWidth;
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await UserTransactionsRewardsUseCase();
      if (!error && payload) {
        setCouponRewards(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);



  const handleButtonCode = (code: string, couponId: number, isLink: boolean) => {
    if (isLink) { window.open(code, '_blank'); }
    if (!isLink) {
      setCodeCoupon(code)
      setOpenModal(true)
    }
  };


  return (
    <Container.support>
      {couponRewards.length === 0 ? (
        <Container.noneSupport>
          <Container.text>
            <p data-test="support-campaign">{text}</p>
            {/* <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
              Ver recompensas
            </SmallBlueButton> */}
          </Container.text>
          <img src={donate} alt="doe" />
        </Container.noneSupport>
      ) : (
        <>
          <Container.cards ref={carousel}>
            {couponRewards.map((coupon, index) => (
              <CardDiscount
                key={index}
                id={coupon.id}
                off={coupon.off}
                logo={coupon.partner.logo}
                name={coupon.partner.name}
                goodersPrice={coupon.goodersPrice}
                description={coupon.description}
                type={coupon.type}
                code={coupon.code}
                buttonText="Exibir código"
                isLink={coupon.isLink}
                onClickFunction={(goodersPrice, id) => handleButtonCode(coupon.code, id, coupon.isLink)}
              />
            ))}
          </Container.cards>
          <Container.buttons>
            <button onClick={handleLeftClick} >
              <span>{"<"}</span>
            </button>
            <button onClick={handleRightClick}>
              <span>{">"}</span>
            </button>
          </Container.buttons>
        </>
      )}
      {codeCoupon && (
        <ModalCodeCoupon
          open={openModal}
          onClose={() => setOpenModal(false)}
          codeCoupon={codeCoupon}
        />
      )}

    </Container.support>
  );
};
