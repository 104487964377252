import { useEffect, useState } from 'react';
import { Container, Line } from './styles';
import { SelectInput } from '../../../../../components/common/SelectInput';
import { useGlobalCampaignUnique } from '../../../../../../infra/state/useCampaign';
import { ItemOutput } from '../../../../../../application/useCases/campaign/GetCampaign';
import { validationSchema } from '../../../../../components/common/CardsCause/component/Modal/ModalDonation/index'
import { CreateItemOrderDto, UserDonationsItems } from '../../../../../../application/useCases/donation/UserDonationItem';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { useFormik } from 'formik';
import { LabelInputFormCampaign } from '../../../../../components/common/Input';
import { BlueButton } from '../../../../../components/common/Button';
export const DonationItems: React.FC = () => {

  const state = useGlobalCampaignUnique();
  const [selectedItem, setSelectedItem] = useState<ItemOutput | null>(null);
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const [returnItem, setReturnItem] = useState(false);


  useEffect(() => {
    const firstItem = state?.campaignUnique?.items || [];
    if (firstItem.length > 0) {
      setSelectedItem(firstItem[0]);
      formik.setFieldValue('itemsId', firstItem[0].id.toString());
    }
  }, [state.campaignUnique?.items]);


  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItemId = Number(e.target.value);
    const selectedCampaignItem = state?.campaignUnique?.items.find(
      (item) => item.id === selectedItemId
    );
    setSelectedItem(selectedCampaignItem || null);
    formik.setFieldValue('itemId', selectedItemId.toString());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: '',
      itemId: selectedItem?.id !== undefined ? selectedItem.id.toString() : ''
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!state.campaignUnique) {
        setAlert('Não foi possível registrar sua doação!', 'error');
      }

      const dto: CreateItemOrderDto = {
        campaignId: state.campaignUnique!.id,
        itemId: Number(values.itemId),
        quantity: Number(values.quantity),
      };

      const { error } = await UserDonationsItems(dto);
      if (error) {
        setAlert('Não foi possível registrar sua doação!', 'error');
      } else {
        setAlert('Doação registrada com sucesso', 'success');
        setReturnItem(true)
      }
    },
  });


  return (
    <Container.box>
      <Container.Input>
        <Container.Text style={{ width: "100%" }}>
          <SelectInput
            htmlFor="itemId"
            name="itemId"
            id="itemId"
            value={formik.values.itemId}
            onChange={handleSelectChange}
            required
            options={[
              ...(state.campaignUnique?.items.map((item) => ({
                value: item.id.toString(),
                label: item.name,
              })) || []),
            ]}
          >
            Objeto de doação
          </SelectInput>
          {formik.touched.itemId && formik.errors.itemId && (
            <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.itemId}</span>
          )}
          <LabelInputFormCampaign
            htmlFor="quantity"
            id="quantity"
            type="number"
            name="quantity"
            placeholder=""
            value={formik.values.quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            min={1}
            max={12}
            required
            aria-labelledby="quantity"
          >
            Quantidade
          </LabelInputFormCampaign>
          {formik.touched.quantity && formik.errors.quantity && (
            <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.quantity}</span>
          )}
          <Container.Address style={{ marginTop: "16px" }} >
            <span>Informações </span>
            Local de entrega: {selectedItem?.address}
            <Line className="line" />
            Item: {selectedItem?.quantity}
            <p>Itens Doados: {selectedItem?.donated} </p>
          </Container.Address>
        </Container.Text>
      </Container.Input>
      <Container.button >
        <BlueButton onClick={formik.submitForm} loading={loading}>
          Finalizar Inscrição
        </BlueButton>
      </Container.button>
    </Container.box>
  )
};
