import { create, useStore } from 'zustand';
import {
  ItemOutput,
  UniqueCampaignOutput,
  VacanciesOutput,
} from '../../application/useCases/campaign/GetCampaign';

interface CampaignUniqueState {
  campaignUnique: UniqueCampaignOutput | null;
  setCampaignUnique: (data: UniqueCampaignOutput) => void;
  removeImageOfCampaign: (imageId: number) => void;
  addImageInCampaign: (imageId: { src: string; id: number }) => void;
  actions: {
    changeActiveStatus: (newActiveStatus: boolean) => void;
    vacancies: {
      add: (vc: VacanciesOutput) => void;
      remove: (id: number) => void;
    };
    items: {
      add: (item: ItemOutput) => void;
      remove: (itemId: number) => void;
    };
  };
}

export const useCampaignUnique = create<CampaignUniqueState>((set) => ({
  campaignUnique: null,
  setCampaignUnique: (data: UniqueCampaignOutput) =>
    set((state) => ({ ...state, campaignUnique: data })),
  removeImageOfCampaign: (imageId) => set((state) => removeImageOfCampaign(state, imageId)),
  addImageInCampaign: (image) => set((state) => addImageInCampaign(state, image)),
  actions: {
    changeActiveStatus: (newActiveStatus) =>
      set((state) => updateActiveStatus(state, newActiveStatus)),
    vacancies: {
      add: (vacancie) => set((state) => addVacancie(state, vacancie)),
      remove: (vcId) => set((state) => removeVacancie(state, vcId)),
    },
    items: {
      add: (item) => set((state) => addItemInCampaign(state, item)),
      remove: (itemId) => set((state) => removeItemOfCampaign(state, itemId)),
    },
  },
}));

const removeImageOfCampaign = (
  state: CampaignUniqueState,
  imageId: number
): CampaignUniqueState => {
  if (state.campaignUnique) {
    const filteredImages = state.campaignUnique.images.filter((img) => img.id !== imageId);
    return {
      ...state,
      campaignUnique: {
        ...state.campaignUnique,
        images: filteredImages,
      },
    };
  }
  return state;
};

const addImageInCampaign = (
  state: CampaignUniqueState,
  image: { src: string; id: number }
): CampaignUniqueState => {
  if (state.campaignUnique) {
    const images = [...state.campaignUnique.images, image];
    return {
      ...state,
      campaignUnique: {
        ...state.campaignUnique,
        images,
      },
    };
  }
  return state;
};

const removeItemOfCampaign = (state: CampaignUniqueState, itemId: number): CampaignUniqueState => {
  if (state.campaignUnique) {
    return {
      ...state,
      campaignUnique: {
        ...state.campaignUnique,
        items: state.campaignUnique.items.filter(({ id }) => id !== itemId),
      },
    };
  }
  return state;
};

const addItemInCampaign = (state: CampaignUniqueState, item: ItemOutput): CampaignUniqueState => {
  if (state.campaignUnique) {
    return {
      ...state,
      campaignUnique: {
        ...state.campaignUnique,
        items: [...state.campaignUnique.items, item],
      },
    };
  }
  return state;
};

const addVacancie = (state: CampaignUniqueState, vc: VacanciesOutput): CampaignUniqueState => {
  if (state.campaignUnique) {
    return {
      ...state,
      campaignUnique: {
        ...state.campaignUnique,
        vacancies: [...state.campaignUnique.vacancies, vc],
      },
    };
  }
  return state;
};

const updateActiveStatus = (
  state: CampaignUniqueState,
  newIsActiveStatus: boolean
): CampaignUniqueState => {
  if (state.campaignUnique) {
    return {
      ...state,
      campaignUnique: {
        ...state.campaignUnique,
        isActive: newIsActiveStatus,
      },
    };
  }
  return state;
};

const removeVacancie = (state: CampaignUniqueState, vcId: number): CampaignUniqueState => {
  if (state.campaignUnique) {
    return {
      ...state,
      campaignUnique: {
        ...state.campaignUnique,
        vacancies: state.campaignUnique.vacancies.filter(({ id }) => id != vcId),
      },
    };
  }
  return state;
};

export function useGlobalCampaignUnique() {
  return useStore(useCampaignUnique);
}
