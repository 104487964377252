import more from '../../../../assets/icons/more.svg';
import { Container, Text } from '../../../../pages/gooders/editar-campanha/styles';
import { useState } from 'react';
import { useGlobalCampaignUnique } from '../../../../../infra/state/useCampaign';
import { LabelInputFormCampaign } from '../../../common/Input';
import { SmallBlueButton } from '../../../common/Button';
import { useFormik } from 'formik';
import { useAlert } from '../../../../../infra/config/AlertContext/useAlert';
import { ItemsTable } from './component/table/ItemsTable';
import { SelectInput } from '../../../common/SelectInput';
import { validationSchema } from '../../gerenciar-campanha/Create/component/DonationItem';
import { useGlobalStore } from '../../../../../infra/state/GlobalStore';
import { CreateItemForCampaignUseCases } from '../../../../../application/useCases/campaign/item/CreateItemForCampaignUseCase';
import { DeleteItemFromCampaignUseCase } from '../../../../../application/useCases/campaign/item/DeleteItemFromCampaignUseCase';
import { ActiveItemUseCase } from '../../../../../application/useCases/campaign/item/ActiveItemUseCase';

interface Props {
  useCase: CreateItemForCampaignUseCases;
  useCase2: DeleteItemFromCampaignUseCase;
  useCase3: ActiveItemUseCase;
}

export const DonationItemsEdit = ({ useCase, useCase2, useCase3 }: Props) => {
  const [loading, setLoading] = useState(false);
  const { campaignUnique, actions } = useGlobalCampaignUnique();
  const users = useGlobalStore();
  const { setAlert } = useAlert();
  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      quantity: '',
      address: '',
      category: '',
      multiplier: '',
      isActive: true
    },
    validationSchema: validationSchema,
    onSubmit: async (fieldValues) => {
      setLoading(true);

      if (!campaignUnique || !users.state.acl.selectedACL) {
        setAlert('Não foi possível adicionar o item no momento', 'error');
        return;
      }

      const campaignId = campaignUnique.id;
      const institutionId = campaignUnique.institution.id;
      const orgId = users.state.acl.selectedACL.id;
      const { error } = await useCase.execute({ campaignId, institutionId, orgId }, fieldValues);

      if (!error) {
        setAlert('Item adicionado com sucesso', 'success');
        actions.items.add({
          ...fieldValues,
          id: new Date().getTime(),
          quantity: +fieldValues.quantity,
        });
      } else {
        setAlert(error, 'error');
      }

      setLoading(false);
    },
  });

  return (
    <Container.main onSubmit={handleSubmit}>
      <Text.title>Doação de Itens</Text.title>
      <Container.input>
        <Container.inputs>
          <SelectInput
            htmlFor="category"
            id="category"
            name="category"
            value={values.category}
            onChange={handleChange}
            aria-labelledby="Categoria de itens"
            options={[
              { value: '', label: '' },
              { value: 'Alimentos', label: 'Alimentos' },
              { value: 'Vestuário', label: 'Vestuário' },
              { value: 'Outros', label: 'Outros' },
            ]}
            onBlur={handleBlur}
            error={touched.category && errors.category ? errors.category : undefined}
          >
            Categoria
          </SelectInput>
          <LabelInputFormCampaign
            htmlFor="name"
            id="name"
            name="name"
            type="text"
            placeholder=""
            value={values.name}
            onChange={handleChange}
            aria-labelledby="Nome do item"
            onBlur={handleBlur}
            error={touched.name && errors.name ? errors.name : undefined}
          >
            Name
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="quantity"
            id="quantity"
            name="quantity"
            type="number"
            placeholder=""
            value={String(values.quantity)}
            onChange={handleChange}
            aria-labelledby="Quantidade"
            onBlur={handleBlur}
            error={touched.quantity && errors.quantity ? errors.quantity : undefined}
          >
            Quantidade
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="multiplier"
            id="multiplier"
            name="multiplier"
            type="number"
            placeholder=""
            value={values.multiplier}
            onChange={handleChange}
            aria-labelledby="Multiplicador Gooders"
            onBlur={handleBlur}
            error={touched.multiplier && errors.multiplier ? errors.multiplier : undefined}
          >
            Multiplicador Gooders
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="address"
            id="address"
            name="address"
            type="text"
            placeholder=""
            value={values.address}
            onChange={handleChange}
            aria-labelledby="Endereço"
            onBlur={handleBlur}
            error={touched.address && errors.address ? errors.address : undefined}
          >
            Endereço
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.button>
          <SmallBlueButton type="submit" loading={loading}>
            <img src={more} alt="more" /> Adicionar item
          </SmallBlueButton>
        </Container.button>
        <ItemsTable useCase={useCase2} useCase3={useCase3} />
      </Container.input>
    </Container.main>
  );
};
