import { useState } from "react";
import { Container } from "./styles";



export const avatars: { src: string; alt: string; }[] = [
	{ src: "/avatar/Avatar2.svg", alt: "Avatar 2" },
	{ src: "/avatar/Avatar3.svg", alt: "Avatar 3" },
	{ src: "/avatar/Avatar4.svg", alt: "Avatar 4" },
	{ src: "/avatar/Avatar5.svg", alt: "Avatar 5" },
	{ src: "/avatar/Avatar6.svg", alt: "Avatar 6" },
	{ src: "/avatar/Avatar7.svg", alt: "Avatar 7" },
	{ src: "/avatar/Avatar8.svg", alt: "Avatar 8" },
	{ src: "/avatar/Avatar9.svg", alt: "Avatar 9" },
	{ src: "/avatar/Avatar10.svg", alt: "Avatar 10" },
	{ src: "/avatar/Avatar12.svg", alt: "Avatar 12" },
	{ src: "/avatar/Avatar13.svg", alt: "Avatar 13" },
	{ src: "/avatar/Avatar14.svg", alt: "Avatar 14" },
	{ src: "/avatar/Avatar15.svg", alt: "Avatar 15" },
	{ src: "/avatar/Avatar16.svg", alt: "Avatar 16" },
	{ src: "/avatar/Avatar17.svg", alt: "Avatar 17" },
	{ src: "/avatar/Avatar18.svg", alt: "Avatar 18" },
	{ src: "/avatar/Avatar19.svg", alt: "Avatar 19" },
	{ src: "/avatar/Avatar20.svg", alt: "Avatar 20" },
	{ src: "/avatar/Avatar21.svg", alt: "Avatar 21" },
	{ src: "/avatar/Avatar22.svg", alt: "Avatar 22" },
	{ src: "/avatar/Avatar23.svg", alt: "Avatar 23" },
	{ src: "/avatar/Avatar24.svg", alt: "Avatar 24" },
	{ src: "/avatar/Avatar25.svg", alt: "Avatar 25" },
	{ src: "/avatar/Avatar26.svg", alt: "Avatar 26" },
	{ src: "/avatar/Avatar27.svg", alt: "Avatar 27" },
	{ src: "/avatar/Avatar28.svg", alt: "Avatar 28" },
	{ src: "/avatar/Avatar29.svg", alt: "Avatar 29" },
	{ src: "/avatar/Avatar30.svg", alt: "Avatar 30" },
	{ src: "/avatar/Avatar31.svg", alt: "Avatar 31" },
	{ src: "/avatar/Avatar32.svg", alt: "Avatar 32" },
	{ src: "/avatar/Avatar33.svg", alt: "Avatar 33" },
	{ src: "/avatar/Avatar34.svg", alt: "Avatar 34" },
	{ src: "/avatar/Avatar35.svg", alt: "Avatar 35" },
	{ src: "/avatar/Avatar36.svg", alt: "Avatar 36" },
	{ src: "/avatar/Avatar37.svg", alt: "Avatar 37" },
	{ src: "/avatar/Avatar38.svg", alt: "Avatar 38" },
	{ src: "/avatar/Avatar39.svg", alt: "Avatar 39" },
	{ src: "/avatar/Avatar40.svg", alt: "Avatar 40" },
	{ src: "/avatar/Avatar41.svg", alt: "Avatar 41" },
	{ src: "/avatar/Avatar42.svg", alt: "Avatar 42" },
	{ src: "/avatar/Avatar43.svg", alt: "Avatar 43" },
	{ src: "/avatar/Avatar44.svg", alt: "Avatar 44" },
];

interface ImgAvatarProps {
	handleSelectImage: (avatar: { src: string; alt: string; }) => void;
}



export const ImgAvatar: React.FC<ImgAvatarProps> = ({ handleSelectImage }) => {
	const [selectedAvatarIndex, setSelectedAvatarIndex] = useState<number | null>(null);

	return (

		<Container.img>
			<ul >
				{avatars.map((avatar, index) => (
					<li key={index}>
						<img src={avatar.src} alt={avatar.alt}
							onClick={() => {
								handleSelectImage(avatar);
								setSelectedAvatarIndex(index);
							}}
							style={{
								border: selectedAvatarIndex === index ? '6px solid #467dc0' : 'none',
							}}
						/>
					</li>
				))}
			</ul>
		</Container.img>
	);
};





