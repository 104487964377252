import React, { createContext, useEffect, useState } from 'react';
import { GetUserUseCase } from '../../../application/useCases/user/GetUserData.usecase';
import { useGlobalStore } from '../../state/GlobalStore';
import { IAuthProvider, IContext } from './types';
import { useAlert } from '../AlertContext/useAlert';
import { UserTypeEnum } from '../../../domain/interfaces/User';
import { handleLogout } from '../../../application/handlers/HandleLogout';

export const AuthContext = createContext<IContext>({} as IContext);

export const AuhtProvider = ({ children }: IAuthProvider) => {
  const [loadingUserData, setLoadingUserData] = useState(true);
  const store = useGlobalStore();
  const { setAlert } = useAlert();
  const urlQuerys = new URLSearchParams(window.location.search);

  useEffect(() => {
    // Caso o usuário não esteja na home será executado
    if (!window.location.pathname.includes('login')) {
      // Obtenha a URL atual
      var url = new URL(window.location.href);

      // Remova o parâmetro "next"
      url.searchParams.delete('next');

      // Crie uma nova URL sem o parâmetro "next"
      var novaURL = url.href;

      // Substitua a URL atual sem recarregar a página
      window.history.replaceState({}, document.title, novaURL);
    }
  }, [loadingUserData]);

  async function getUserData() {
    const token = window.localStorage.getItem('accessToken');

    if (!token) {
      setLoadingUserData(false);
      return;
    }

    const { data } = await new GetUserUseCase(token).exec();

    if (!data) {
      setAlert('Sua sessão expirou', 'alert');
      handleLogout();
      setLoadingUserData(false);
      return;
    }

    store.actions.setUser(data.data);
    store.actions.updateACL(data.data.acls);

    const storedACL = localStorage.getItem('selectedACL');
    const goodersMember = data.data.goodersMember;
    const org = data.data.acls.orgs[0];
    const institution = data.data.acls.institutions[0];

    if (storedACL) {
      const selectedACL = JSON.parse(storedACL);
      store.actions.selectACL(selectedACL);
    }
    else if (goodersMember) {
      const { role } = goodersMember;
      store.actions.selectACL({ role, id: -1, type: UserTypeEnum.GOODERS, name: UserTypeEnum.GOODERS, });
    } else if (org) {
      const { id, role, name } = org;
      store.actions.selectACL({ id, role, type: UserTypeEnum.ORG, name });
    } else if (institution) {
      const { id, role, name } = institution;
      store.actions.selectACL({ id, role, type: UserTypeEnum.ORG, name });
    }

    setLoadingUserData(false);
  }

  useEffect(() => {
    getUserData();
  }, []);

  return <AuthContext.Provider value={{ loadingUserData }}>{children}</AuthContext.Provider>;
};
