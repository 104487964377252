import { StyledComponent } from 'styled-components';
import { Loading } from '../Loading';
import {
  _RedButton,
  _BlueButton,
  _SmallBlueButton,
  _GreenButton,
  _SmallGreenButton,
  _SmallTransparentBlueButton,
  _SmallRedButton,
} from './styles';

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  type?: 'submit';
  loading: boolean;
  StyledButton: StyledComponent<'button', any, any, never>;
  dataTest?: string;
  disabled?: boolean;
}

export const Button = ({ type, children, onClick, loading, StyledButton, dataTest }: Props) => {
  return (
    <StyledButton
      data-test={dataTest}
      type={type}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <Loading /> : children}
    </StyledButton>
  );
};

export const BlueButton = ({
  type,
  children,
  onClick,
  loading,
  dataTest,
}: Omit<Props, 'StyledButton'>) => {
  return (
    <Button
      data-test={dataTest}
      type={type}
      onClick={onClick}
      StyledButton={_BlueButton}
      loading={loading}
    >
      {children}
    </Button>
  );
};

export const SmallBlueButton = ({
  type,
  children,
  onClick,
  loading,
  dataTest,
}: Omit<Props, 'StyledButton'>) => {
  return (
    <Button data-test={dataTest} type={type} onClick={onClick} StyledButton={_SmallBlueButton} loading={loading} >
      {children}
    </Button>
  );
};

export const SmallTransparentBlueButton = ({
  type,
  children,
  onClick,
  loading,
  dataTest,
}: Omit<Props, 'StyledButton'>) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      StyledButton={_SmallTransparentBlueButton}
      loading={loading}
      data-test={dataTest}
    >
      {children}
    </Button>
  );
};

export const RedButton = ({ type, children, onClick, loading }: Omit<Props, 'StyledButton'>) => {
  return (
    <Button type={type} onClick={onClick} StyledButton={_RedButton} loading={loading}>
      {children}
    </Button>
  );
};

export const SmallRedButton = ({
  type,
  children,
  onClick,
  loading,
}: Omit<Props, 'StyledButton'>) => {
  return (
    <Button type={type} onClick={onClick} StyledButton={_SmallRedButton} loading={loading}>
      {children}
    </Button>
  );
};

export const GreenButton = ({ type, children, onClick, loading }: Omit<Props, 'StyledButton'>) => {
  return (
    <Button type={type} onClick={onClick} StyledButton={_GreenButton} loading={loading}>
      {children}
    </Button>
  );
};

export const SmallGreenButton = ({
  type,
  children,
  onClick,
  loading,
}: Omit<Props, 'StyledButton'>) => {
  return (
    <Button type={type} onClick={onClick} StyledButton={_SmallGreenButton} loading={loading}>
      {children}
    </Button>
  );
};
