import { GridSortCellParams, GridValueFormatterParams } from "@mui/x-data-grid";

export class MyDate {
  // input 2023-07-08T00:00:00.000Z
  // output 08/07/2023
  static formatISOString(isoDate?: string) {
    if (!isoDate) {
      return ''
    }
    const [year, month, rest] = isoDate.split('-');
    const [day] = rest.split('T');
    return `${day}/${month}/${year}`;
  }
}


export const divideBy100 = (value: number) => value / 100;

export function dateValueFormatter(params: GridValueFormatterParams<any>): string {
  const dateValue = params.value as Date;
  return dateValue.toLocaleDateString('pt-BR');
}

// Utility function to sort date values
export function dateSortComparator(
  v1: any,
  v2: any,
  param1: GridSortCellParams<any>,
  param2: GridSortCellParams<any>
): number {
  const date1 = param1.api.getCellValue(param1.id, 'Data') as Date;
  const date2 = param2.api.getCellValue(param2.id, 'Data') as Date;
  return date1.getTime() - date2.getTime();
}

// function to display the receipt
export function downloadPDF(payload: ArrayBuffer, fileName: string) {
  const blob = new Blob([payload], {
      type: 'application/pdf',
  });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // Release the temporary URL of the Blob after the download has started
  window.URL.revokeObjectURL(url);
}



export const formatWalletBalanceValue = (balance: number | undefined) => {
  if (balance) {
      return (balance / 100).toLocaleString('pt-BR', {
          currency: 'BRL',
          minimumFractionDigits: 2,
      });
  } else {
      return '';
  }
};
