import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

interface Props {
  active?: boolean;
}

const Container__Main = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(6, 53, 122, 0.05);
  top: 0px;
  left: 0px;
  @media (max-width: ${theme.media.laptop}) {
    padding: 6px;
    position: absolute;
    max-width: 100vw;
    height: auto;
    width: 100%;
  }
`;

const Container__Modal = styled.div`
  min-width: 60%;
  max-width: 60%;
  background: rgba(0, 0, 0, 0);
  transition: all 300ms linear 0s;
  box-shadow: 0px 20px 30px rgb(153 153 153 / 40%);
  position: relative;
  @media (max-width: ${theme.media.laptop}) {
    max-width: 100%;
  }
`;

const Container__title = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  background: transparent;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize.md};
  line-height: 22px;
  color: ${(props) => props.theme.colors.white};
`;

const Container__Content = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0px 8px 8px 8px;
  padding: 0 16px 16px;
  min-height: 100%;
`;

const Container__Border = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container__Input = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 100%;
  min-height: 100%;
  gap: 36px;
  padding: 40px 0px 10px;
  color: ${(props) => props.theme.colors.gray};
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
    padding: 12px 0px 10px;
    gap: 6px;
  }
`;

const Container__Text = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  min-height: 250px;
  flex-direction: column;
  gap: 6px;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize.sm};
  line-height: 20px;
  @media (max-width: ${theme.media.laptop}) {
    min-height: 200px;
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    width: 100%;
  }
`;

const Container__Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  img {
    width: 300px;
    height: 280px;
    object-fit: cover;
    border-radius: 8px;
    @media (max-width: ${theme.media.monitorSmall}) {
      display: none;
    }
  }
`;

const Container__Address = styled.div`
  padding: 16px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.lightgray};
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSize.sm};
  position: relative;
  p {
    font-size: ${(props) => props.theme.fontSize.sm};
  }
  span {
    position: absolute;
    background: ${(props) => props.theme.colors.white};
    top: -10px;
    left: 10px;
    padding: 0px 4px !important;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #4e545c;
  }
`;

const Container__Close = styled.img`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 45px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
`;

export const Container = {
  Main: Container__Main,
  Modal: Container__Modal,
  Content: Container__Content,
  Title: Container__title,
  Border: Container__Border,
  Input: Container__Input,
  Text: Container__Text,
  Image: Container__Image,
  Address: Container__Address,
  close: Container__Close,
};

const Input__Checkbox = styled.div`
  display: flex;
  width: 100%;
  label {
    display: flex;
    border: 1px solid ${(props) => props.theme.colors.green};
    padding: 12px;
    width: 100%;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.green};
  }
  label.checked {
    background-color: ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.white};
  }
  input {
    appearance: none;
    width: 80%;
    width: 18px;
    height: 18px;
    border: 2px solid #cccccc;
    border-radius: 50%;
    outline: none;
    margin-right: 4px;
    position: relative;
    color: ${(props) => props.theme.colors.green};
  }
  input:checked::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 50%;
  }
`;

export const Input = {
  Checkbox: Input__Checkbox,
};

const Title__Pay = styled.div<Props>`
  padding: 8px;
  background: ${(props) => (props.active ? '#6B97CD' : '#DAE5F2')};
  border-radius: 8px 8px 0px 0px;
  color: ${(props) => (props.active ? '#FFFFFF' : '#90B1D9')};
`;

const Title__Donate = styled(Title__Pay)``;
const Title__Voluntary = styled(Title__Pay)``;

export const Title = {
  Pay: Title__Pay,
  Donate: Title__Donate,
  Voluntary: Title__Voluntary,
};

export const Line = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.lightgray};
  margin: 8px 0;
  width: 100%;
`;
