import { HttpClient } from "../../HttpClient";

interface ForgetPassword {
  email: string;
}

export async function ForgetPasswordUseCase(dto: ForgetPassword) {
  const { error, payload } = await HttpClient<ForgetPassword, void>('post', '/auth/forget-password', dto);
  return { error, payload }
}

interface ResetPassword {
  newPassword: string;
  token: string;
}

export async function ResetPasswordUseCase(dto: ResetPassword) {
  const { error, payload } = await HttpClient<ResetPassword, void>('post', '/auth/reset-password', dto);
    return { error, payload }
}

interface ChangePassword {
  currentPassword: string;
  newPassword: string;
}

export async function ChangePasswordUseCase(dto: ChangePassword) {
  const { error, payload } = await HttpClient<ChangePassword, void>('post', '/users/change-password', dto);
    return { error, payload }
}

