import styled from 'styled-components';
import { theme } from '../../../../themes/blue';

const Container__Title = styled.div`
  padding-bottom: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

const Container__Table = styled.div`
  @media (max-width: ${theme.media.mobile}) {
  display: none;
}
`;

const Container__Mobile = styled.div`
  display: none;
  width: 100%;
@media (max-width: ${theme.media.mobile}) {
  display: initial;
}
`;

export const Container = {
    title: Container__Title,
    table: Container__Table,
    mobile: Container__Mobile
};