import { Container, Description } from './styles';
import { useGlobalCampaignUnique } from '../../../../../../infra/state/useCampaign';
import { useMemo } from 'react';

export const DescriptionCampaign: React.FC = () => {
  const { campaignUnique } = useGlobalCampaignUnique();


  const handleAccumulatedValue = useMemo(() => {
    const accumulatedValue = campaignUnique?.moneyDonationOptions?.accumulated;
    if (accumulatedValue) {
      return (accumulatedValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      })
    } else {
      return 0;
    }
  }, [campaignUnique?.moneyDonationOptions?.accumulated]);

  return (
    <>
      <Container.Value>
        <Description.Subtitle>
          Instituição: <Description.Text style={{ fontWeight: 600 }}>{campaignUnique?.institution?.name}</Description.Text>
        </Description.Subtitle>

      </Container.Value>
      <Container.Box>

        <Container.Value>
          <Description.Subtitle>Meta:
            <h5>{(campaignUnique?.moneyDonationOptions?.target ? campaignUnique.moneyDonationOptions.target / 100 : 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h5>
          </Description.Subtitle>
        </Container.Value>
        <Container.Value>
          <Description.Subtitle>Arrecadado:<h5 style={{ fontWeight: 600 }}>{handleAccumulatedValue}</h5></Description.Subtitle>

        </Container.Value>
      </Container.Box>
    </>
  );
};
