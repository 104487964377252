import { useState } from 'react';
import { Container, Container__Loading } from './styles';
import SucessPay from '../SucessPay';
import error from "../../../../../../../../../assets/icons/error.svg";
import { SmallBlueButton } from '../../../../../../../Button';
import processing from '../../../../../../../../../assets/icons/processing.svg';
import { ReturnPayUseCase } from '../../../../../../../../../../application/useCases/donation/ReturnPayUseCase';
import { useAlert } from '../../../../../../../../../../infra/config/AlertContext/useAlert';

interface Props {
  onClose: () => void;
  campaignId: number | null
}

enum PayStatus {
  Loading = 'loading',
  Pending = 'pending',
  Error = 'error',
  Success = 'success'
}

export default function LoadingPay({ onClose, campaignId }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [payStatus, setPayStatus] = useState(PayStatus.Loading);
  const [isClosed, setIsClosed] = useState(false);
  const { setAlert } = useAlert();

  const handleButtonClick = async () => {
    setIsLoading(true);

    const { error, payload } = await ReturnPayUseCase()
    setIsLoading(false);
    if (!error && payload) {
      if (payload?.data.status === 'SUCESSO') {
        setPayStatus(PayStatus.Success);
      } else if (payload?.data.status === 'PENDENTE') {
        if (!isClosed) {
          setPayStatus(PayStatus.Pending);
        } else {
          onClose();
        }
        setIsClosed(true);
      } else if (payload?.data.status === 'CANCELADO') {
        if (!isClosed) {
          setPayStatus(PayStatus.Error);
        } else {
          onClose();
        }
        setIsClosed(true);
      }
    } else {
      setAlert(error!, 'error');
      setPayStatus(PayStatus.Error);
    }
  }

  const renderContent = () => {
    switch (payStatus) {
      case PayStatus.Loading:
        return (
          <>
            <img src={processing} alt="processing" />
            <Container.title>Aguarde!</Container.title>
            <Container.text>Estamos processando a sua transação em uma nova janela. </Container.text> <Container.text style={{ fontWeight: 700 }}>Por favor, verifique se os pop-ups estão desbloqueados.</Container.text > <Container.text>Recomendamos a utilização do Google Chrome para uma melhor experiência nesta operação.</Container.text>
            <Container.text>
              Caso não consiga acessar,
              <a href={window.localStorage.getItem('checkout')!} style={{ fontWeight: 700 }}> clique aqui </a>
            </Container.text>
            <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
              Verificação Transação
            </SmallBlueButton>
          </>
        );
      case PayStatus.Pending:
        return (
          <>
            <Container__Loading />
            <Container.title>Pendente!</Container.title>
            <Container.text>Aguardando o processamento.</Container.text>
            <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
              Tentar Novamente
            </SmallBlueButton>
          </>
        );
      case PayStatus.Error:
        return (
          <>
            <img src={error} alt="error" />
            <Container.title>Ops... Ocorreu um erro!</Container.title>
            <Container.text>Ocorreu um erro inesperado.</Container.text>
            <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
              Tentar Novamente
            </SmallBlueButton>
          </>
        );
      case PayStatus.Success:
        return <SucessPay onClose={onClose} campaignId={campaignId} />;
      default:
        return null;
    }
  };

  return <Container.box>{renderContent()}</Container.box>;
};
