import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Text } from '../../styles';
import { FormAccount } from './components/FormAccount';
import createaccount from '../../../../../assets/auth/createaccount.svg';
import { HeaderImg } from '../../../../../components/common/HeaderImg';
import { HeaderLogin } from '../../../../../components/common/HeaderLogin';
import { BlueButton } from '../../../../../components/common/Button';

interface NewAccountProps {
  onAdvanceButtonClick: () => void;
}

export const NewAccount: React.FC<NewAccountProps> = ({ onAdvanceButtonClick }) => {
  return (
    <>
      <HeaderImg imageSrc={createaccount} altText="voluntary" />
      <Container.create>
        <HeaderLogin>Criar Conta</HeaderLogin>

        <FormAccount />
        <BlueButton
          dataTest="btn-next-step-create-account"
          loading={false}
          onClick={onAdvanceButtonClick}
        >
          Avançar
        </BlueButton>

        <Container.line>
          <Text.line /> <span> Ou </span> <Text.line />
        </Container.line>

        <Container.section>
          <p>Já tem uma conta?</p>
          <Link to="/login">
            <span>Entrar</span>
          </Link>
        </Container.section>
      </Container.create>
    </>
  );
};
