import { Container } from './styles';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { SmallBlueButton } from '../../../components/common/Button';
import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { TableHash } from './components/TableHash';

export const Hash = () => {
	const navigate = useNavigate();
	const user = useGlobalStore()

	return (
		<>
			<LayoutSidebar>
				<Container.box>
					<Container.header>
						<Container.title data-test="title-campaign">Hash</Container.title>
						{user.state.acl.selectedACL?.type === UserTypeEnum.GOODERS ? (
							<Container.button>
								<SmallBlueButton onClick={() => navigate('/generate_hash')} loading={false}>
									Gerar Hash
								</SmallBlueButton>
							</Container.button>
						) : null}
					</Container.header>
					<Container.main>
						<Container.subTitle>
							Hash Gerados
						</Container.subTitle>
						<TableHash />
					</Container.main>
				</Container.box>
			</LayoutSidebar>
		</>
	);
};
