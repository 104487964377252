import { UserTypeEnum } from '../../../domain/interfaces/User';
import { HttpClient } from '../../HttpClient';
import { SharedUseCase } from '../campaign/item/CreateItemForCampaignUseCase';

export interface GetReportTableItems {
    userName: string,
    userEmail: string,
    userTelephone: string,
    institutionName: string,
    campaignTitle: string,
    itemName: string,
    itemQuantity: number,
    closedAt: string,
    orderStatus: string
}


interface Params {
  orgId?: number;
  institutionId?: number;
}


export class GetReportItemsUseCase extends SharedUseCase<
  Params,
  GetReportTableItems,
  {
    payload: { data: GetReportTableItems[];} | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execOrg(){
    return await HttpClient<void, { data:  GetReportTableItems[] }>(
      'get',
      `/orders/reports/items/orgs/${this.params!.orgId}`
    );
  }

  protected override async execInstitution(){
    return await HttpClient<void, { data:  GetReportTableItems[] }>(
      'get',
      `/orders/reports/items/institutions/${this.params!.institutionId}`
    );
  }

}



