import { Container } from './styles';
import Navbar from '../../../components/common/Navbar';
import { BlueButton } from '../../../components/common/Button';
import { LabelInputFormCampaign } from '../../../components/common/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Footer from '../../../components/common/Footer';
import { ForgetPasswordUseCase } from '../../../../application/useCases/user/ForgetPasswordUseCase';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, 'Digite um email válido')
    .required('O campo de email é obrigatório'),
});

export const RequestRecoveryPassword: React.FC = () => {
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { error } = await ForgetPasswordUseCase(values);
      setLoading(false);
      setEmailSent(true);
      setAlert('As informações para recuperação de senha foram enviadas para o email', 'success');
      if (error) {
        setAlert(error, 'error');
        return;
      }
    },
  });

  return (
    <>
      <Container.page>
        <Container.box>
          <h3>Esqueceu a sua senha de acesso?</h3>
          <LabelInputFormCampaign
            id="email"
            htmlFor="email"
            type="email"
            name="email"
            placeholder="Insira seu email..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            aria-labelledby="email"
            error={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
          >
            {' '}
            Email{' '}
          </LabelInputFormCampaign>
          <BlueButton onClick={formik.handleSubmit} loading={loading} disabled={emailSent}>
            Enviar
          </BlueButton>
          <Container.section>
            <p>Fazer login?</p>
            <span onClick={() => navigate('/login')} data-test="link-create-account">
              Entrar
            </span>
          </Container.section>
        </Container.box>
      </Container.page>
    </>
  );
};
