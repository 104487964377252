import { HttpClient } from "../../HttpClient";

export interface CreateItemOrderDto {
  campaignId: number;
  itemId: number;
  quantity: number;
}

export async function UserDonationsItems (
  dto: CreateItemOrderDto,
) {
  return await HttpClient<CreateItemOrderDto, void>(
    'post',
    '/orders/items',
    dto
  );
}
