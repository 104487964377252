import { HttpClient } from "../../HttpClient";



export async function ReturnPayUseCase() {

  const response = await HttpClient<void, { data: { status: string} }>('get', '/orders/last');
  return response;
  }

