import styled from "styled-components";
import { theme } from "../../../themes/blue";

const Container__Main = styled.form`
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  height: auto;
  width: 100%;
  margin-top: 25px;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

const Container__Box = styled.div`
  background: ${(props) => props.theme.colors.backgroundGray};
  color: ${(props) => props.theme.colors.primary};
  padding: 70px 40px 15px;
  @media (max-width: ${theme.media.laptop}) {
    padding: 70px 20px 150px;
  }
`

const Container__Wrapper = styled.div`
  width: 100%;
  padding: 36px;
  margin-bottom: 16px;
  display: flex;
  border-radius: 20px;
  background: white;
  justify-content: center;
  align-items: center;
`

const Container__Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0 16px;
  @media (max-width: ${theme.media.mobileSmall}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
  }
`;


const Container__Title = styled.div`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.x};
  line-height: 24px;
`

const Container__Text = styled.div`
  color: ${(props) => props.theme.colors.primary};
  padding-bottom: 16px;
  @media (max-width: ${theme.media.laptop}) {
    text-align: center;
  }
`

const Container__Left = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: ${theme.media.laptop}) {
    width: 100%;
  }
`

const Container__Right = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 150px;
  }
  @media (max-width: ${theme.media.laptop}) {
    display: none;
  }
`

export const Container = {
	main: Container__Main,
  box: Container__Box,
  title: Container__Title,
  left: Container__Left,
  text: Container__Text,
  right: Container__Right,
  header: Container__Header,
  wrapper: Container__Wrapper,
};
