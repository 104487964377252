import styled from "styled-components";

export interface ContainerProps {
  isHidden: boolean;
}

const Container__Card = styled.div<ContainerProps>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background:${props => props.theme.colors.lightgray};
  border-radius: 8px;
  min-width: 270px;
  width: 270px;
  color: ${props => props.theme.colors.dark};
  min-height: ${(props) => (props.isHidden ? "337px" : "450px")};
  height: ${(props) => (props.isHidden ? "337px" : "450px")};
  &.hidden {
  display: none;
  }
`;

const Container__Image = styled.img`
  width: 100%;
  min-width: 100%;
  height: 116px;
  min-height: 116px;
  border-radius: 8px;
  object-fit: cover;
  `;

const Container__Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding:6px 0;
  font-weight: 500;
  line-height: 24px;
  p {
    font-weight: 600;
  }
  span {
    font-weight: 800;
  }
  `;
  
const Container__Gooders = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  img {
    width: 24px;
    height: 24px;
    padding-right: 8px;
  }
  p {
    color: #043560;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize.smX};
    line-height: 18px;
  }
`;

const Container_Perforated = styled.div`
  margin: 22px 0;
  display: flex;
  gap: 9.5px;
  width: 100%;
  align-items: center;
  position: relative;
  left: -24px;
  transition: all 0.3s ease-in-out;
  div {
  min-width: 8px;
  max-width: 8px;
  max-height: 8px;
  min-height: 8px;
  background: #fff;
  border-radius: 50%;
  &:first-child {
  min-width: 16px;
  max-width: 16px;
  max-height: 16px;
  min-height: 16px;
  }
  &:last-child {
  min-width: 18px;
  max-width: 18px;
  max-height: 18px;
  min-height: 18px;
  }
  }
`;


export const Container = {
	card: Container__Card,
	box: Container__Box,
	image: Container__Image,
	gooders: Container__Gooders,
	perforated: Container_Perforated,
};

export const Line = styled.div``;

const Text__Description = styled.p`
  height: 100px;
  min-height: 100px;
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 18px;
  color: ${props => props.theme.colors.gray};
`;

export const Text = {
	description: Text__Description,
};

