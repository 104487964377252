import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from './styles';
import { SmallBlueButton } from '../../../../../components/common/Button';
import donate from '../../../../../assets/profile/donate.svg';
import { ROUTES_PATH } from '../../../../../../infra/config/routes';
import { ListSupportedCampaign, ListSupportedCampaignUseCase } from '../../../../../../application/useCases/user/ListSupportedCampaignUseCase';
import { Campaign } from '../../../../../components/common/CardsCause';

interface TextProps {
  text: string;
}

export const ListSupportedCause: React.FC<TextProps> = ({ text }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [supportedCampaign, setSupportedCampaign] = useState<ListSupportedCampaign[]>([]);
  const carousel = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    setIsLoading(true);
    navigate(ROUTES_PATH.listCampaignsForUsers);
  };

  const handleLeftClick = () => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }
  };

  const handleRightClick = () => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft += carousel.current.offsetWidth;
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const { payload, error } = await ListSupportedCampaignUseCase();
      if (!error && payload) {
        setSupportedCampaign(payload);
      }
    };
    fetchData();
  }, []);


  return (
    <Container.support>
      {supportedCampaign && supportedCampaign.length === 0 ? (
        <Container.noneSupport>
          <Container.text>
            <p data-test="support-campaign">{text}</p>
            <SmallBlueButton onClick={handleButtonClick} loading={isLoading}>
              Ver campanhas para apoiar
            </SmallBlueButton>
          </Container.text>
          <img src={donate} alt="doe" />
        </Container.noneSupport>
      ) : (
        <>
          <Container.cards ref={carousel}>
            {supportedCampaign && supportedCampaign.map((campaign) => (
              <Campaign key={campaign.id} cause={campaign} />
            ))}
          </Container.cards>
          <Container.buttons>
            <button onClick={handleLeftClick} >
              <span>{"<"}</span>
            </button>
            <button onClick={handleRightClick}>
              <span>{">"}</span>
            </button>
          </Container.buttons>
        </>
      )}
    </Container.support>
  );
};
