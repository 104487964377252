import { HttpClient } from "../../HttpClient";

interface EditProfile {
  city: string,
  state: string
}
export async function EditAddressUseCase(
  dto: EditProfile
) {
  return await HttpClient<EditProfile, void>(
    'put',
    `/users/address`,
    dto
  );
}