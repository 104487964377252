import { Container } from './styles'
import connect_user from '../../../assets/icons/createUser.svg'
import update from '../../../assets/icons/update_cards.svg'
import list_user from '../../../assets/icons/seeUsers.svg'
import detele from '../../../assets/icons/delet.svg'
import report from '../../../assets/icons/report.svg'
import action from '../../../assets/icons/action.svg'
import { useState } from 'react'

export interface CardData {
    id: string | number;
    title: string[];
    value: (string | number)[];
    campaignId?: string | number;
    institutionId?: string | number;
}

interface CardsTableProps {
    IconDelete?: any;
    IconEditCampaign?: (campaignId?: string | number, institutionId?: string | number,) => void
    IconEdit?: any;
    IconDeleteCampaign?: (campaignId?: number, institutionId?: number) => void
    IconList?: any;
    IconConnect?: any;
    IconReport?: (orderId: string) => Promise<void>;
    IconStatus?: any;
    cardData: CardData[];
}


export const CardsTable = ({ IconDelete, IconDeleteCampaign, IconEdit, IconEditCampaign, IconList, IconConnect, cardData, IconReport, IconStatus }: CardsTableProps) => {

    const shouldRenderIcons = IconDelete || IconEdit || IconList || IconConnect || IconReport || IconDeleteCampaign || IconEditCampaign || IconStatus;
    const itemsPerPage = 5;
    const pageCount = Math.ceil(cardData.length / itemsPerPage);

    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentCards = cardData.slice(startIndex, endIndex);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 4;
        let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        let endPage = Math.min(startPage + maxVisiblePages - 1, pageCount);

        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(endPage - maxVisiblePages + 1, 1);
        }
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    className={currentPage === i ? 'active' : ''}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </span>
            );
        }
        if (startPage > 1) {
            pageNumbers.unshift(<span key="start-ellipsis">...</span>);
        }
        if (endPage < pageCount) {
            pageNumbers.push(<span key="end-ellipsis">...</span>);
        }
        return pageNumbers;
    };

    return (
        <Container.box>
            {currentCards.map((card, index) => (
                <Container.card key={index} >
                    <Container.text >
                        {card.title.map((title, titleIndex) => (
                            <Container.row key={titleIndex}>
                                <p>{title}: <span>{card.value[titleIndex]}</span></p>
                            </Container.row>
                        ))}
                    </Container.text>
                    {shouldRenderIcons && (
                        <Container.icon>
                            {IconDelete && (
                                <img
                                    src={detele}
                                    style={{ border: '1px solid red', cursor: 'pointer' }}
                                    className='icons'
                                    onClick={() => {
                                        IconDelete(Number(card.id));
                                    }}
                                />
                            )}
                            {IconDeleteCampaign && (
                                <img
                                    src={detele}
                                    style={{ border: '1px solid red', cursor: 'pointer' }}
                                    className='icons'
                                    onClick={() => {
                                        IconDeleteCampaign(Number(card.campaignId), Number(card.institutionId))
                                    }}
                                />
                            )}
                            {IconEdit && (
                                <img
                                    src={update}
                                    className='icons'
                                    onClick={() => { IconEdit(card.id) }}
                                />
                            )}
                            {IconEditCampaign && (
                                <img
                                    src={update}
                                    className='icons'
                                    onClick={() => { IconEditCampaign(card.campaignId, card.institutionId) }}
                                />
                            )}
                            {IconList && (
                                <img
                                    src={list_user}
                                    style={{ border: '1px solid gray' }}
                                    className='icons'
                                    onClick={() => {
                                        IconList(Number(card.id));
                                    }}
                                />
                            )}
                            {IconConnect && (
                                <img
                                    src={connect_user}
                                    style={{ border: '1px solid gray' }}
                                    className='icons'
                                    onClick={() => { IconConnect(card.id.toString()); }}
                                />
                            )}
                            {IconReport && (
                                <img
                                    src={report}
                                    style={{ border: '1px solid gray', cursor: 'pointer' }}
                                    className='icons'
                                    onClick={() => { IconReport(card.id.toString()); }}
                                />
                            )}
                            {IconStatus && (
                                <img
                                    src={action}
                                    className='icons'
                                    onClick={() => IconStatus(card.id)}
                                />
                            )}
                        </Container.icon>
                    )}
                </Container.card>
            ))}
            <Container.pagination>
                {renderPageNumbers()}
            </Container.pagination>
        </Container.box>
    );
};