import { Container, CloseIcon, Text } from './styles';
import close from '../../../../../assets/icons/close.svg';
import { SelectInput } from '../../../../../components/common/SelectInput';
import { LabelInputFormCampaign } from '../../../../../components/common/Input';
import { TextArea } from '../../../../../components/common/TextArea';
import { useFormik } from 'formik';
import { SmallBlueButton } from '../../../../../components/common/Button';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { ListPartner, ListPatnerUseCase } from '../../../../../../application/useCases/gooders/ListPatnerUseCase';
import { validationSchema } from '../CreateRewards/index'
import { EditCouponUseCase } from '../../../../../../application/useCases/gooders/EditCouponUseCase';
import { GetListCouponForEditUseCase, ListCoupon } from '../../../../../../application/useCases/rewards/GetListCouponUseCase';

enum RewardType {
  PERCENTAGE = 'PERCENTAGE',
  MONEY = 'MONEY',
  LINK = 'LINK',
}

export interface ModalIconProps {
  open: boolean;
  onClose: () => void;
  selectedCoupon: number;
}


export const ModalEditCoupon = ({ open, onClose, selectedCoupon }: ModalIconProps) => {
  if (!open) return null;

  const { setAlert } = useAlert();
  const [loading, setloading] = useState(false);
  const [listPatner, setListPatner] = useState<ListPartner[]>([]);
  const defaultOption = { value: '', label: 'Selecione um parceiro', disabled: true, selected: true };
  const [getCoupon, setGetCoupon] = useState<ListCoupon[]>([])



  useEffect(() => {
    const fetchData = async () => {
      const { payload, error } = await GetListCouponForEditUseCase();
      if (!error && payload) {
        setGetCoupon(payload.data)
      }
    };
    fetchData();
  }, []);



  const couponData = getCoupon.find((coupon) => coupon.id === selectedCoupon) || {
    partnerId: '',
    quantity: '',
    off: '',
    type: '',
    code: '',
    expiresAt: '',
    description: '',
    goodersPrice: 0,
    id: '',
    isLink: false
  };
  const expiresAt = couponData?.expiresAt ? new Date(couponData.expiresAt).toISOString().split('T')[0] : '';

  const formik = useFormik({
    initialValues: {
      partnerId: couponData?.partnerId,
      quantity: couponData?.quantity,
      off: couponData?.off,
      type: couponData?.type,
      code: couponData?.code,
      expiresAt: expiresAt,
      description: couponData?.description,
      goodersPrice: couponData?.goodersPrice ? couponData.goodersPrice / 100 : '',
      id: couponData?.id,
      isLink: couponData?.isLink
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setloading(true);
      const expiresAt = new Date(values.expiresAt);
      const isoExpiresAt = expiresAt.toISOString();
      const convertedValues = {
        ...values,
        quantity: Number(values.quantity),
        goodersPrice: Number(values.goodersPrice),
        partnerId: Number(values.partnerId),
        off: Number(values.off),
        id: Number(values.id),
        expiresAt: isoExpiresAt,
      };
      const { error } = await EditCouponUseCase(convertedValues);
      setloading(false);
      if (!error) {
        setAlert('Cupom alterado com sucesso!', 'success');
        resetForm();
        onClose();
      } else {
        setAlert(error, 'error');
      }

    },
  });

  useEffect((
  ) => {
    if (open) {
      window.scrollTo(0, 0);
    }
    const fetchData = async () => {
      const { error, payload } = await ListPatnerUseCase();
      if (!error && payload) {
        setListPatner(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    }
    fetchData();
  }, [])

  return (
    <Container.modal>
      <Container.form onSubmit={formik.handleSubmit}>
        <Container.progress>
          <CloseIcon src={close} alt="close" onClick={onClose} />
        </Container.progress>
        <Container.main>
          <Text.title>Editar Recompensas</Text.title>
          <Container.input>
            <Container.header>
              < SelectInput
                htmlFor="partnerId"
                id="partnerId"
                name="partnerId"
                value={formik.values.partnerId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  ...listPatner.map((patner) => ({
                    value: String(patner.id),
                    label: patner.name,
                  })),
                ]}
                error={formik.touched.partnerId && formik.errors.partnerId ? formik.errors.partnerId : undefined}>
                Parceiros
              </SelectInput>
              <LabelInputFormCampaign
                htmlFor="quantity"
                id="quantity"
                name="quantity"
                type="number"
                maxLength={25}
                placeholder=""
                value={formik.values.quantity}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                aria-labelledby="quantity"
                error={formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : undefined}>
                Quantidade
              </LabelInputFormCampaign>
            </Container.header>
            <Container.inputs>
              <SelectInput
                htmlFor="type"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  defaultOption,
                  { value: RewardType.PERCENTAGE, label: 'Descontos em porcentagem' },
                  { value: RewardType.MONEY, label: 'Descontos em valores' },
                  { value: RewardType.LINK, label: 'Link de redirecionamento' },
                ]}
                error={formik.touched.type && formik.errors.type ? formik.errors.type : undefined}>
                Categoria
              </SelectInput>
              <LabelInputFormCampaign
                htmlFor="off"
                id="off"
                name="off"
                type="number"
                maxLength={25}
                placeholder=""
                value={formik.values.off}
                onChange={formik.handleChange}
                aria-labelledby="Título"
                onBlur={formik.handleBlur}
                error={formik.touched.off && formik.errors.off ? formik.errors.off : undefined}
              >
                Valor de desconto
              </LabelInputFormCampaign><LabelInputFormCampaign
                htmlFor="goodersPrice"
                id="goodersPrice"
                name="goodersPrice"
                type="number"
                maxLength={25}
                placeholder=""
                value={formik.values.goodersPrice}
                onChange={formik.handleChange}
                aria-labelledby="Título"
                onBlur={formik.handleBlur}
                error={formik.touched.goodersPrice && formik.errors.goodersPrice ? formik.errors.goodersPrice : undefined}>
                Preço em Gooders
              </LabelInputFormCampaign>
            </Container.inputs>
            <Container.inputs>
              <Container.checkbox>
                <label htmlFor="code">
                  <input type="checkbox"
                    id="code"
                    checked={formik.values.isLink}
                    onChange={(e) => formik.setFieldValue('isLink', e.target.checked)} />
                  <p>É um link?</p>
                </label>
              </Container.checkbox>
              <LabelInputFormCampaign
                htmlFor="code"
                id="code"
                name="code"
                type="text"
                placeholder=""
                value={formik.values.code}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                aria-labelledby="Descrição do projeto"
                error={formik.touched.code && formik.errors.code ? formik.errors.code : undefined}>
                Link ou código do cupom
              </LabelInputFormCampaign>
              <LabelInputFormCampaign
                htmlFor="expiresAt"
                id="expiresAt"
                name="expiresAt"
                type="date"
                placeholder=""
                value={formik.values.expiresAt}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                aria-labelledby="Descrição do projeto"
                error={formik.touched.expiresAt && formik.errors.expiresAt ? formik.errors.expiresAt : undefined}>
                Validade
              </LabelInputFormCampaign>
            </Container.inputs>
            <Container.textarea>
              <TextArea
                htmlFor="description"
                id="description"
                name="description"
                rows={3}
                maxLength={500}
                placeholder=""
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-labelledby="Descrição do projeto"
                error={formik.touched.description && formik.errors.description ? formik.errors.description : undefined}>
                Descrição
              </TextArea>
            </Container.textarea>
            <Container.inputs>
            </Container.inputs>
          </Container.input>
        </Container.main >
        <Container.footer>
          <SmallBlueButton type="submit" loading={loading}>Concluir</SmallBlueButton>
        </Container.footer>
      </Container.form>
    </Container.modal>
  );
};
