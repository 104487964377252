import {
  CreateCampaignInterface,
  MoneyDonation,
} from '../../../domain/interfaces/CreateCampaignInterface';
import { HttpClient } from '../../../application/HttpClient';
import { SharedUseCase } from './item/CreateItemForCampaignUseCase';
import { UserTypeEnum } from '../../../domain/interfaces/User';

interface Params {
  orgId: number;
  institutionId: number;
  campaignId?: number;
}

export class CreateCampaignUseCase extends SharedUseCase<
  Params,
  CreateCampaignInterface,
  {
    payload: { data: { id: number } } | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  private extractCampaignData() {
    const dto = this.dto!;
    if (dto?.moneyDonation) {
      dto.moneyDonation.target *= 100;
    }

    for (const key of Object.keys(dto)) {
      const keyObj = key as keyof typeof dto;
      if (!dto[keyObj]) {
        delete dto[keyObj];
      }
    }

    return this.dto!;
  }

  protected override async execOrg() {
    const campaignData = this.extractCampaignData();

    return await HttpClient<CreateCampaignInterface, { data: { id: number } }>(
      'post',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}`,
      campaignData
    );
  }

  protected override async execInstitution() {
    const campaignData = this.extractCampaignData();

    return await HttpClient<CreateCampaignInterface, { data: { id: number } }>(
      'post',
      `/campaigns/institution/${this.params!.institutionId}`,
      campaignData
    );
  }

  protected override async execGooders() {
    const campaignData = this.extractCampaignData();

    return await HttpClient<CreateCampaignInterface, { data: { id: number } }>(
      'post',
      `/campaigns/gooders/${this.params!.institutionId}`,
      campaignData
    );
  }
}
