import styled from "styled-components";
import { theme } from "../../../../../themes/blue"


const Container__Support = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container__Choice = styled.div`
	display: flex;
	gap: 24px;
	padding-top: 16px;
`;

export const Container = {
	support: Container__Support,
	choice: Container__Choice,
};


const Text__SubTitle = styled.p<{ active?: boolean }>`
font-weight: ${props => props.active && "700"};;
font-size: ${props => props.theme.fontSize.x};
border-bottom: ${props => props.active && "1px solid #FBB22F"};
 @media (max-width: ${theme.media.laptop}) {
  font-size: ${props => props.theme.fontSize.base};
}
@media (max-width: ${theme.media.tablet}) {
  font-size: 11px;
}
`;

export const Text = {
	subTitle: Text__SubTitle,
};

