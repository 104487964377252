import React from "react";
import { Container } from "./styles";
import close from "../../../../../../../assets/icons/close.svg";

interface ModalCodeCouponProps extends React.HTMLAttributes<HTMLDivElement> {
	open: boolean;
	onClose: () => void;
	codeCoupon: string;

}

export const ModalCodeCoupon = ({ open, onClose, codeCoupon }: ModalCodeCouponProps) => {

	if (!open) return null;

	return (
		<Container.box >
			<Container.warning>
				<Container.close src={close} alt="warning" onClick={onClose} />
				<p>
					Código de acesso ao cupom: <span>{codeCoupon}</span>
				</p>
			</Container.warning>
		</Container.box>
	);
};
