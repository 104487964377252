import { Container } from '../PaymentMethod/styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { CheckOutContext } from '../../../../../../../../infra/config/CheckOut';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import { ValidatePrePaymentOrderUseCase } from '../../../../../../../../application/useCases/checkout/ValidatePrePaymentOrder';
import { Box, Link } from '@mui/material';
import styled from 'styled-components';
import { ROUTES_PATH } from '../../../../../../../../infra/config/routes';
import { cpf, cnpj } from 'cpf-cnpj-validator';
interface ValidateSchema {
  validateCPForCNPJ: () => any;
}
function validateCPFOrCNPJ(document: string) {
  return cpf.isValid(document) || cnpj.isValid(document) ? true : false;
}
export const validationSchema = Yup.object<ValidateSchema>().shape({
  document: Yup.string()
    .required('O CPF ou CNPJ é obrigatório')
    .matches(/^\d+$/, 'O CPF ou CNPJ deve conter apenas números')
    .test('cpf', 'CPF/CNPJ inválidos', validateCPFOrCNPJ),
  telephone: Yup.string()
    .required('O telefone é obrigatório')
    .length(11, 'máximo de 11 digítos. DDD seguido pelo número')
    .matches(/^\d+$/, 'O telefone deve conter apenas números'),
});

export interface DataPerson {
  document: string;
  anonymous: boolean;
  telephone: {
    countryCode: number;
    areaCode: number;
    number: number;
  };
}

export const DataPerson = () => {
  const {
    dataPerson,
    setDataPerson,
    activeComponentIndex,
    setActiveComponentIndex,
    setAmount,
    setPreOrderId,
  } = useContext(CheckOutContext);
  const { setAlert } = useAlert();
  const { search } = useLocation();
  const [preOrderValidation, setPreOrderValidation] = useState({
    message: '',
    error: false,
  });

  const params = new URLSearchParams(search);
  const preOrderId = params.get('preOrderId');
  const quantity = params.get('quantity');
  const anonymous = params.get('anonymous');
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const response = await ValidatePrePaymentOrderUseCase({
        prePaymentOrderId: Number(preOrderId),
      });
      if (response.error) {
        setPreOrderValidation({
          error: true,
          message: response.error,
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const quantityParsedToFloat = Math.round(Number(quantity));
    setAmount(quantityParsedToFloat);
    setPreOrderId(Number(preOrderId));
  }, []);

  const formatTelephone = (telephone: string) => {
    const cleanedTelephone = telephone.replace(/\D/g, '');

    if (cleanedTelephone.length != 11) {
      throw new Error('Número de telefone inválido. Deve conter 11 dígitos. \n (DDD)99999+9999');
    }
    const countryCode = '55';
    const areaCode = cleanedTelephone.substring(0, 2);
    const number = cleanedTelephone.substring(2);
    return {
      countryCode: Number(countryCode),
      areaCode: Number(areaCode),
      number: Number(number),
    };
  };

  const formik = useFormik({
    initialValues: {
      document: '',
      telephone: '',
      anonymous: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      const formattedTelephone = formatTelephone(values.telephone);
      const newDataPerson = {
        document: values.document,
        telephone: formattedTelephone,
        anonymous: anonymous === 'true' ? true : false,
      };
      setDataPerson(newDataPerson);
      return;
    },
  });

  useEffect(() => {
    if (dataPerson) {
      formik.setFieldValue('document', dataPerson.document || '');
    }
  }, []);

  const handleNext = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
      setActiveComponentIndex((prevIndex: number) => prevIndex + 1);
    } else {
      setAlert('Todos os campos obrigatórios devem ser preenchidos para continuar.', 'error');
    }
  };

  if (preOrderValidation.error) {
    return (
      <Box
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={'1rem'}
      >
        <PreOrderValidationError>{preOrderValidation.message}</PreOrderValidationError>
        <Link
          onClick={() => navigate(ROUTES_PATH.listCampaignsForUsers)}
          margin={'auto'}
          textAlign={'center'}
        >
          voltar para plataforma
        </Link>
      </Box>
    );
  }

  return (
    <Container.box onSubmit={formik.handleSubmit}>
      <LabelInputFormCampaign
        htmlFor="document"
        id="document"
        name="document"
        type="text"
        placeholder=""
        value={formik.values.document}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        aria-labelledby="document"
        error={
          formik.touched.document && formik.errors.document ? formik.errors.document : undefined
        }
      >
        CPF ou CNPJ
      </LabelInputFormCampaign>
      <LabelInputFormCampaign
        htmlFor="telephone"
        id="telephone"
        name="telephone"
        type="text"
        placeholder=""
        value={formik.values.telephone}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        aria-labelledby="Telefone"
        error={
          formik.touched.telephone && formik.errors.telephone ? formik.errors.telephone : undefined
        }
      >
        Telefone com DDD
      </LabelInputFormCampaign>
      <Container.buttons>
        <button
          type="submit"
          onClick={handleNext}
          className={activeComponentIndex ? 'next-button-blue' : ''}
        >
          Avançar
        </button>
      </Container.buttons>
    </Container.box>
  );
};

/* Styled Components */

const PreOrderValidationError = styled.p`
  background-color: ${(props) => props.theme.colors.yellow[300]};
  //color: ${(props) => props.theme.colors.white};
  padding: 1rem;
  border-radius: 0.25rem;
`;
