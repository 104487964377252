import { Container } from '../../styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import { SmallBlueButton } from '../../../../../components/common/Button';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { LabelInputFormCampaign } from '../../../../../components/common/Input';
import { CreateOrgUseCase } from '../../../../../../application/useCases/gooders/CreateOrgUseCase';


export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Preencha o nome da Instituição'),
    cnpj: Yup.string()
        .required('Informe o seu CNPJ')
        .matches(/^\d{14}$/, 'O CNPJ deve conter exatamente 14 números'),
    telephone: Yup.string()
        .required('Informe o seu telefone')
        .matches(/^\d+$/, 'O telefone deve conter apenas números')
        .min(9, 'O telefone deve ter no mínimo 9 caracteres')
        .max(20, 'O telefone deve ter no máximo 20 caracteres'),
});

export const FormCreateOrg = () => {

    const [loading, setLoading] = useState(false);
    const { setAlert } = useAlert();


    const formik = useFormik({
        initialValues: {
            name: '',
            cnpj: '',
            telephone: '',
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            const { error } = await CreateOrgUseCase(values);

            setLoading(false);
            if (!error) {
                setAlert('Organização cadastrado com sucesso', 'success');
                resetForm()
            } else {
                setAlert(error, 'error');
                return;
            }
            setLoading(false);
        }
    },
    );

    return (
        <>
            <Container.head>
                <Container.subTitle data-test="title-Org">Informações</Container.subTitle>
            </Container.head>
            <Container.wrapper onSubmit={formik.handleSubmit}>
                <Container.content>
                    <Container.form>
                        <Container.input>
                            <LabelInputFormCampaign
                                id="name"
                                name="name"
                                type="text"
                                htmlFor="name"
                                placeholder="Organização"
                                aria-labelledby="name"
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                            >
                                Nome
                            </LabelInputFormCampaign>
                        </Container.input>
                        <Container.input>
                            <LabelInputFormCampaign
                                htmlFor="cnpj"
                                id="cnpj"
                                name="cnpj"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cnpj}
                                placeholder="CPNJ"
                                aria-labelledby="cnpj"
                                error={formik.touched.cnpj && formik.errors.cnpj ? formik.errors.cnpj : undefined}
                            >
                                CNPJ
                            </LabelInputFormCampaign>
                        </Container.input>
                        <Container.input>
                            <LabelInputFormCampaign
                                htmlFor="telephone"
                                id="telephone"
                                name="telephone"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.telephone}
                                placeholder="Telefone"
                                aria-labelledby="telephone"
                                error={formik.touched.telephone && formik.errors.telephone ? formik.errors.telephone : undefined}
                            >
                                Telefone
                            </LabelInputFormCampaign>
                        </Container.input>
                    </Container.form>
                </Container.content>
                <SmallBlueButton type="submit" loading={loading}>
                    Criar
                </SmallBlueButton>
            </Container.wrapper >
        </>
    );
};
