import { HttpClient } from "../../HttpClient";

export interface ListGoodersMembers {
  username: string,
  email: string,
  userId: number,
  role: string,
  id: number
}

  export async function ListGoodersUseCase() {
    const response = await HttpClient<void, { data: ListGoodersMembers[] }>(
      'get', 
      `/users/gooders/members`
      );
    return response;
  }

  

