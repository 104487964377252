import React, { useState } from 'react';
import { Container } from './styles';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import dowm_arrow from '../../../../../assets/icons/down_arrow.svg';
import up_arrow from '../../../../../assets/icons/up_arrow.svg';
import check from '../../../../../assets/icons/check.svg';
import { useNavigate } from 'react-router-dom';
import { UserRoleEnum, UserTypeEnum } from '../../../../../../domain/interfaces/User';
import acess_acl from '../../../../../assets/icons/acess_alc.svg'

export const AclSelection: React.FC = () => {
  const users = useGlobalStore();
  const [showContainers, setShowContainers] = useState(false);
  const navigate = useNavigate();
  const toggleContainers = () => {
    setShowContainers(!showContainers);
  };

  const handleSelectGoodersMember = (goodersMember: { role: UserRoleEnum }) => {
    users.actions.selectACL({ role: goodersMember.role, id: 0, type: UserTypeEnum.GOODERS, name: UserTypeEnum.GOODERS });
    toggleContainers();
    navigate('/home');
    localStorage.setItem('selectedACL', JSON.stringify({ role: goodersMember.role, id: 0, type: UserTypeEnum.GOODERS, name: UserTypeEnum.GOODERS }));
  };

  const handleSelectOrg = (org: { id: number; name: string; role: UserRoleEnum }) => {
    users.actions.selectACL({ id: org.id, role: org.role, type: UserTypeEnum.ORG, name: org.name });
    toggleContainers();
    navigate('/home');
    localStorage.setItem('selectedACL', JSON.stringify({ id: org.id, role: org.role, type: UserTypeEnum.ORG, name: org.name }));
  };

  const handleSelectInst = (institution: { id: number; name: string; role: UserRoleEnum }) => {
    users.actions.selectACL({ id: institution.id, role: institution.role, type: UserTypeEnum.INSTITUTION, name: institution.name, });
    toggleContainers();
    navigate('/home');
    localStorage.setItem('selectedACL', JSON.stringify({ id: institution.id, role: institution.role, type: UserTypeEnum.INSTITUTION, name: institution.name, }));
  };

  const orgs = users.state.user?.acls.orgs || [];
  const institutions = users.state.user?.acls.institutions || [];

  return (
    <Container.acl>
      <h2>Acessar</h2>
      <Container.header onClick={toggleContainers}>
        <p> <img src={acess_acl} alt='acess' />{users.state.acl.selectedACL?.name}</p>
        {showContainers ? <img src={up_arrow} alt="arrow" /> : <img src={dowm_arrow} alt="arrow" />}
      </Container.header>
      {showContainers && (
        <>
          <Container.list>
            {users.state.user?.goodersMember !== null && (
              <>
                <Container.inst>
                  <p>Gooders</p>
                </Container.inst>
                <Container.box>
                  <ul>
                    <li onClick={() => handleSelectGoodersMember(users.state.user?.goodersMember ?? { role: UserRoleEnum.ADMIN })}>
                      <img src={check} alt="item" />
                      {users.state.user?.goodersMember?.role === UserRoleEnum.ADMIN ? 'Admin' : 'User'}
                    </li>
                  </ul>
                </Container.box>
              </>
            )}
            {users.state.acl.orgs.length !== 0 && (
              <Container.inst>
                <p>Organizações</p>
              </Container.inst>
            )}
            <Container.box>
              <ul>
                {orgs.map((orgs, index) => (
                  <li key={index} onClick={() => handleSelectOrg(orgs)}>
                    <img src={check} alt="item" />
                    {orgs.name.slice(0, 24)}
                  </li>
                ))}
              </ul>
            </Container.box>
            {users.state.acl.institutions.length !== 0 && (
              <Container.inst>
                <p> Instituições</p>
              </Container.inst>
            )}
            <Container.box>
              <ul>
                {institutions.map((institution, index) => (
                  <li key={index} onClick={() => handleSelectInst(institution)}>
                    <img src={check} alt="item" />
                    {institution.name.slice(0, 24)}
                  </li>
                ))}
              </ul>
            </Container.box>
          </Container.list>
        </>
      )
      }
    </Container.acl >
  );
};
