import { HttpClient } from "../../HttpClient";

interface EditProfile {
  name: string,
  username: string,
  telephone: string,
}
export async function EditProfileUseCase(
  dto: EditProfile
) {
  return await HttpClient<EditProfile, void>(
    'put',
    `/users`,
    dto
  );
}