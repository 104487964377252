import more from '../../../../assets/icons/more.svg';
import { Container, Text } from '../../../../pages/gooders/editar-campanha/styles';
import { useGlobalCampaignUnique } from '../../../../../infra/state/useCampaign';
import { LabelInputFormCampaign } from '../../../../components/common/Input';
import { SmallBlueButton } from '../../../../components/common/Button';
import { useFormik } from 'formik';
import { VacanciesTable } from './components/table/VacanciesTable';
import { useAlert } from '../../../../../infra/config/AlertContext/useAlert';
import { validationSchema } from '../../gerenciar-campanha/Create/component/Voluntary';
import { useGlobalStore } from '../../../../../infra/state/GlobalStore';
import { CreateVacancyForCampaignUseCase } from '../../../../../application/useCases/campaign/vacancy/CreateVacancyForCampaignUseCase';
import { DeleteVacancyFromCampaignUseCase } from '../../../../../application/useCases/campaign/vacancy/DeleteVacancyFromCampaignUseCase';
import { SelectInput } from '../../../common/SelectInput';
import { selectOptionsState } from '../../../common/SelectInput/components/SelectOptionsState';
import { TextArea } from '../../../common/TextArea';
import { ActiveVacancyUseCase } from '../../../../../application/useCases/campaign/vacancy/ActiveVacancyUseCase';

interface Props {
  useCase1: CreateVacancyForCampaignUseCase;
  useCase2: DeleteVacancyFromCampaignUseCase;
  useCase3: ActiveVacancyUseCase;
}

export const VoluntaryEdit = ({ useCase2, useCase1, useCase3 }: Props) => {
  const { campaignUnique, actions } = useGlobalCampaignUnique();
  const { setAlert } = useAlert();
  const users = useGlobalStore();
  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      title: '',
      address: '',
      city: '',
      state: '',
      startDate: '',
      description: '',
      multiplier: '',
      endHour: '',
      endDate: '',
      startHour: '',
      quantity: '',
      isActive: true
    },
    validationSchema: validationSchema,
    onSubmit: async (fieldValues) => {
      const [initialHour, initialMinute] = values.startHour.split(':');
      const [endHour, endMinute] = values.endHour.split(':');
      if (initialHour > endHour) {
        setAlert('A hora de inicio não pode ser maior que a de fim', 'error');
        return;
      } else if (initialHour === endHour && initialMinute > endMinute) {
        setAlert('A hora de inicio não pode ser maior que a de fim', 'error');
        return;
      }

      const oneDay = 1000 * 60 * 60 * 24;
      const vacancyDate = new Date(values.startDate).getTime();
      const currentDate = new Date(new Date().getTime() - oneDay).getTime();

      if (vacancyDate < currentDate) {
        setAlert('A data de inicio não pode ser anterior a hoje!', 'error');
        return;
      }

      if (!users.state.acl.selectedACL || !campaignUnique) {
        return;
      }
      const orgId = users.state.acl.selectedACL.id;
      const institutionId = campaignUnique.institution.id;
      const campaignId = campaignUnique.id;

      const { error } = await useCase1.execute({ orgId, institutionId, campaignId }, fieldValues);
      if (error) {
        setAlert(error, 'error');
      } else {
        setAlert('Vaga cadastrada com sucesso', 'success');
        actions.vacancies.add({
          ...fieldValues,
          startDate: new Date(fieldValues.startDate).toISOString(),
          id: new Date().getTime(),
          multiplier: +fieldValues.multiplier,
          quantity: +fieldValues.quantity,
          subscribersCount: 0
        });
      }
    },
  });

  return (
    <Container.main onSubmit={handleSubmit}>
      <Text.title>Voluntariado</Text.title>
      <Container.input>
        <Container.content>
          <Container.wrapper>
            <LabelInputFormCampaign
              htmlFor="title"
              id="title"
              name="title"
              type="text"
              placeholder=""
              value={values.title}
              onChange={handleChange}
              aria-labelledby="Título da vaga"
              onBlur={handleBlur}
              error={touched.title && errors.title ? errors.title : undefined}
            >
              Título da vaga
            </LabelInputFormCampaign>
            <LabelInputFormCampaign
              htmlFor="quantity"
              id="quantity"
              name="quantity"
              type="number"
              placeholder=""
              value={values.quantity}
              onChange={handleChange}
              onBlur={handleBlur}
              aria-labelledby="Quantidade de vagas"
              error={touched.quantity && errors.quantity ? errors.quantity : undefined}
            >
              Quantidade de vagas
            </LabelInputFormCampaign>
            <Container.inputs>
              <LabelInputFormCampaign
                htmlFor="startDate"
                id="startDate"
                name="startDate"
                type="date"
                placeholder=""
                value={values.startDate}
                onChange={handleChange}
                aria-labelledby="Data de Início"
                onBlur={handleBlur}
                error={touched.startDate && errors.startDate ? errors.startDate : undefined}
              >
                Data de Início *
              </LabelInputFormCampaign>
              <LabelInputFormCampaign
                htmlFor="endDate"
                id="endDate"
                name="endDate"
                type="date"
                placeholder=""
                value={values.endDate}
                onChange={handleChange}
                aria-labelledby="Data de Início"
                onBlur={handleBlur}
                error={touched.endDate && errors.endDate ? errors.endDate : undefined}
              >
                Data de fim *
              </LabelInputFormCampaign>
            </Container.inputs>
          </Container.wrapper>
          <TextArea
            htmlFor="description"
            id="description"
            name="description"
            placeholder=""
            rows={7}
            maxLength={500}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-labelledby="Descrição da vaga"
            error={touched.description && errors.description ? errors.description : undefined}
          >
            Descrição da vaga
          </TextArea>
        </Container.content>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="multiplier"
            id="multiplier"
            name="multiplier"
            type="number"
            placeholder=""
            value={values.multiplier}
            onChange={handleChange}
            aria-labelledby="Multiplicador Gooders"
            onBlur={handleBlur}
            error={touched.multiplier && errors.multiplier ? errors.multiplier : undefined}
          >
            Multiplicador Gooders
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="startHour"
            id="startHour"
            name="startHour"
            type="time"
            placeholder=""
            value={values.startHour}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-labelledby="Data de Início"
            error={touched.startHour && errors.startHour ? errors.startHour : undefined}
          >
            Hora de início *
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="endHour"
            id="endHour"
            name="endHour"
            type="time"
            placeholder=""
            value={values.endHour}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-labelledby="Data de Fim"
            error={touched.endHour && errors.endHour ? errors.endHour : undefined}
          >
            Hora de fim *
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="address"
            id="address"
            name="address"
            type="text"
            placeholder=""
            value={String(values.address)}
            onChange={handleChange}
            aria-labelledby="Endereço"
            onBlur={handleBlur}
            error={touched.address && errors.address ? errors.address : undefined}
          >
            Endereço *
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="city"
            id="city"
            name="city"
            type="text"
            placeholder=""
            value={values.city}
            onChange={handleChange}
            aria-labelledby="Cidade"
            onBlur={handleBlur}
            error={touched.city && errors.city ? errors.city : undefined}
          >
            Cidade *
          </LabelInputFormCampaign>
          <SelectInput
            htmlFor="state"
            id="state"
            name="state"
            options={[...selectOptionsState]}
            placeholder=""
            value={values.state}
            onChange={handleChange}
            aria-labelledby="Estado"
            onBlur={handleBlur}
            error={touched.state && errors.state ? errors.state : undefined}
          >
            Estado *
          </SelectInput>
        </Container.inputs>
        <Container.button>
          <SmallBlueButton type="submit" loading={false}>
            <img src={more} alt="more" /> Adicionar vaga
          </SmallBlueButton>
        </Container.button>
        <VacanciesTable useCase={useCase2} useCase3={useCase3} />
      </Container.input>
    </Container.main>
  );
};
