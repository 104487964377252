import more from '../../../../../../assets/icons/more.svg';
import { Container, Text } from '../CreateCause/styles';
import { useContext, useState } from 'react';
import { CampaignContext } from '../../../../../../../infra/config/CampaignContext';
import { SmallBlueButton } from '../../../../../../components/common/Button';
import { LabelInputFormCampaign } from '../../../../../../components/common/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Table from '../../../../../../components/common/Table';
import { GridColDef } from '@mui/x-data-grid';
import remove from '../../../../../../assets/icons/remove.svg';
import { SelectInput } from '../../../../../common/SelectInput';
import { selectOptionsState } from '../../../../../common/SelectInput/components/SelectOptionsState';
import { useAlert } from '../../../../../../../infra/config/AlertContext/useAlert';
import { TextArea } from '../../../../../common/TextArea';
import update from '../../../../../../assets/icons/update.svg';
import { MyDate } from '../../../../../../../application/utils';

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(50, 'Limite de caracteres excedido')
    .required('Preencha o titulo da vaga'),
  description: Yup.string()
    .max(500, 'Limite de caracteres excedido')
    .required('Insira a descrição'),
  address: Yup.string().max(255, 'Limite de caracteres excedido').required('Insira o endereço'),
  state: Yup.string().required('Insira o estado'),
  city: Yup.string().max(255, 'Limite de caracteres excedido').required('Insira a cidade'),
  startDate: Yup.string().required('Insira a data de inicio'),
  endDate: Yup.string().required('Insira a data de fim'),
  multiplier: Yup.number()
    .required('Insira o multiplicador gooders')
    .min(0, 'Selecione um valor maior ou igual a zero'),
  endHour: Yup.string().required('Insira o horário final'),
  startHour: Yup.string().required('Insira o horário de inicio'),
  quantity: Yup.number()
    .min(1, 'Deve ter ao menos 1 vaga')
    .required('Selecione a quantidade de vagas'),
});

export const Voluntary = () => {
  const { vacancies, setVacancies } = useContext(CampaignContext);
  const { setAlert } = useAlert();
  const [editingVacancyIndex, setEditingVacancyIndex] = useState(-1);

  function handleVacancyEdit(vacancyIndex: number) {
    setEditingVacancyIndex(vacancyIndex);
    const vacancy = vacancies[vacancyIndex];
    const startDate = new Date(vacancy.startDate).toISOString().split('T')[0];
    const endDate = new Date(vacancy.endDate).toISOString().split('T')[0];
    formik.setValues({
      title: vacancy.title,
      address: vacancy.address,
      city: vacancy.city,
      state: vacancy.state,
      startDate: startDate,
      endDate: endDate,
      description: vacancy.description,
      multiplier: vacancy.multiplier.toString(),
      startHour: vacancy.startHour,
      endHour: vacancy.endHour,
      quantity: vacancy.quantity.toString(),
    });
  }

  function handleDeleteVacancie(itemIndex: number) {
    if (itemIndex === editingVacancyIndex) {
      setEditingVacancyIndex(-1);
    }
    setVacancies(vacancies.filter((vc, i) => i !== itemIndex));
  }


  const columns: GridColDef[] = [
    { field: 'Título da vaga:', headerName: 'Título da vaga:', minWidth: 180, flex: 1, headerClassName: 'table-header', },
    { field: 'Data início:', headerName: 'Data inicial:', minWidth: 120, flex: 1, headerClassName: 'table-header', sortable: false, filterable: false },
    { field: 'Data fim:', headerName: 'Data final:', minWidth: 120, flex: 1, headerClassName: 'table-header', sortable: false, filterable: false },
    { field: 'Hora de início:', headerName: 'Hora inicial:', minWidth: 110, flex: 1, headerClassName: 'table-header', sortable: false, filterable: false },
    { field: 'Hora de fim:', headerName: 'Hora final:', minWidth: 100, flex: 1, headerClassName: 'table-header', sortable: false, filterable: false },
    { field: 'Quantidade de vagas:', headerName: 'Quantidade:', minWidth: 100, flex: 1, headerClassName: 'table-header', },
    { field: 'Endereço:', headerName: 'Endereço:', minWidth: 180, flex: 1, headerClassName: 'table-header', },
    { field: 'Cidade:', headerName: 'Cidade:', minWidth: 80, flex: 1, headerClassName: 'table-header', },
    { field: 'Estado:', headerName: 'Estado:', minWidth: 80, flex: 1, headerClassName: 'table-header', },
    {
      field: 'Editar', headerName: 'Editar', minWidth: 80, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => (
        <img
          onClick={() => handleVacancyEdit(params.row.id)}
          src={update}
          alt="action"
          style={{ cursor: 'pointer' }}
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: 'Remover', headerName: 'Remover', minWidth: 80, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => (
        <img
          onClick={() => handleDeleteVacancie(params.row.id)}
          src={remove}
          alt="action"
          style={{ cursor: 'pointer' }}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ];

  const rows = vacancies.map((vacancy, index) => ({
    index: index,
    id: index,
    'Título da vaga:': vacancy.title,
    'Data início:': MyDate.formatISOString(vacancy.startDate),
    'Data fim:': MyDate.formatISOString(vacancy.endDate),
    'Hora de início:': vacancy.startHour,
    'Hora de fim:': vacancy.endHour,
    'Quantidade de vagas:': vacancy.quantity,
    'Endereço:': vacancy.address,
    'Cidade:': vacancy.city,
    'Estado:': vacancy.state,
  }))
    .sort((a, b) => a['Título da vaga:'].localeCompare(b['Título da vaga:']));

  const formik = useFormik({
    initialValues: {
      title: '',
      address: '',
      city: '',
      state: '',
      startDate: '',
      description: '',
      multiplier: '',
      endHour: '',
      endDate: '',
      startHour: '',
      quantity: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (editingVacancyIndex !== -1) {
        const updatedVacancies = vacancies.map((vacancy, index) => {
          if (index === editingVacancyIndex) {
            return {
              address: formik.values.address,
              city: formik.values.city,
              description: formik.values.description,
              startDate: new Date(formik.values.startDate).toISOString(),
              endDate: new Date(formik.values.endDate).toISOString(),
              state: formik.values.state,
              title: formik.values.title,
              multiplier: Number(formik.values.multiplier),
              endHour: formik.values.endHour,
              startHour: formik.values.startHour,
              quantity: Number(formik.values.quantity),
            };
          }
          return vacancy;
        });
        setVacancies(updatedVacancies);
        setEditingVacancyIndex(-1);
      } else {
        setVacancies([
          ...vacancies,
          {
            address: formik.values.address,
            city: formik.values.city,
            description: formik.values.description,
            startDate: new Date(formik.values.startDate).toISOString(),
            endDate: new Date(formik.values.endDate).toISOString(),
            state: formik.values.state,
            title: formik.values.title,
            multiplier: Number(formik.values.multiplier),
            endHour: formik.values.endHour,
            startHour: formik.values.startHour,
            quantity: Number(formik.values.quantity),
          },
        ]);
      }
      formik.resetForm();
    },
  });

  return (
    <Container.main>
      <Text.title>Voluntariado</Text.title>
      <Container.input>
        <Container.content>
          <Container.wrapper>
            <LabelInputFormCampaign
              htmlFor="title"
              id="title"
              name="title"
              type="text"
              placeholder=""
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-labelledby="Título da vaga"
              error={formik.touched.title && formik.errors.title ? formik.errors.title : undefined}
            >
              Título da vaga
            </LabelInputFormCampaign>
            <LabelInputFormCampaign
              htmlFor="quantity"
              id="quantity"
              name="quantity"
              type="number"
              placeholder=""
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-labelledby="Quantidade de vagas"
              error={
                formik.touched.quantity && formik.errors.quantity
                  ? formik.errors.quantity
                  : undefined
              }
            >
              Quantidade de vagas
            </LabelInputFormCampaign>
            <Container.inputs>
              <LabelInputFormCampaign
                htmlFor="startDate"
                id="startDate"
                name="startDate"
                type="date"
                placeholder=""
                value={formik.values.startDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-labelledby="Data de Início"
                error={
                  formik.touched.startDate && formik.errors.startDate
                    ? formik.errors.startDate
                    : undefined
                }
              >
                Data de início
              </LabelInputFormCampaign>
              <LabelInputFormCampaign
                htmlFor="endDate"
                id="endDate"
                name="endDate"
                type="date"
                placeholder=""
                value={formik.values.endDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-labelledby="Data de Início"
                error={
                  formik.touched.endDate && formik.errors.endDate
                    ? formik.errors.endDate
                    : undefined
                }
              >
                Data de fim
              </LabelInputFormCampaign>
            </Container.inputs>
          </Container.wrapper>
          <TextArea
            htmlFor="description"
            id="description"
            name="description"
            placeholder=""
            rows={7}
            maxLength={500}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby="Descrição da vaga"
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : undefined
            }
          >
            Descrição da vaga
          </TextArea>
        </Container.content>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="multiplier"
            id="multiplier"
            name="multiplier"
            type="number"
            placeholder=""
            value={formik.values.multiplier}
            onChange={formik.handleChange}
            aria-labelledby="Multiplicador Gooders"
            onBlur={formik.handleBlur}
            error={
              formik.touched.multiplier && formik.errors.multiplier
                ? formik.errors.multiplier
                : undefined
            }
          >
            Multiplicador Gooders
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="startHour"
            id="startHour"
            name="startHour"
            type="time"
            placeholder=""
            value={formik.values.startHour}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby="Data de Início"
            error={
              formik.touched.startHour && formik.errors.startHour
                ? formik.errors.startHour
                : undefined
            }
          >
            Hora de início
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="endHour"
            id="endHour"
            name="endHour"
            type="time"
            placeholder=""
            value={formik.values.endHour}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby="Data de Fim"
            error={
              formik.touched.endHour && formik.errors.endHour ? formik.errors.endHour : undefined
            }
          >
            Hora de fim
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="address"
            id="address"
            name="address"
            type="text"
            placeholder=""
            value={formik.values.address}
            onChange={formik.handleChange}
            aria-labelledby="Endereço"
            onBlur={formik.handleBlur}
            error={
              formik.touched.address && formik.errors.address ? formik.errors.address : undefined
            }
          >
            Endereço
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="city"
            id="city"
            name="city"
            type="text"
            placeholder=""
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-labelledby="Cidade"
            error={formik.touched.city && formik.errors.city ? formik.errors.city : undefined}
          >
            Cidade
          </LabelInputFormCampaign>
          <SelectInput
            htmlFor="state"
            id="state"
            name="state"
            placeholder=""
            value={formik.values.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={[...selectOptionsState]}
            aria-labelledby="Estado"
            error={formik.touched.state && formik.errors.state ? formik.errors.state : undefined}
          >
            Estado
          </SelectInput>
        </Container.inputs>
        <Container.button>
          <SmallBlueButton onClick={formik.handleSubmit} loading={false}>
            <img src={more} alt="more" /> Adicionar vaga
          </SmallBlueButton>
        </Container.button>
        <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />
      </Container.input>
    </Container.main>
  );
};
