import { Container, Input } from '../../styles'

interface SearchListCampaignProps {
	selectedCategory: string;
	handleCategoryChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;

}


export const CategoryTableListCampaign: React.FC<SearchListCampaignProps> = ({
	selectedCategory,
	handleCategoryChange,
}) => {
	return (
		<>
			<Container.search>
				<Input.label htmlFor="category-select"></Input.label>
				<Container.select
					id="category-select"
					value={selectedCategory}
					onChange={handleCategoryChange}
				>
					<option value="">Categoria</option>
					<option value="Combate à fome">Combate à fome</option>
					<option value="Combate à pobreza">Combate à pobreza</option>
					<option value="Saúde">Saúde</option>
					<option value="Educação">Educação</option>
					<option value="Água">Água</option>
					<option value="Energia">Energia</option>
					<option value="Desigualdade">Desigualdade</option>
					<option value="Ação Climática">Ação Climática</option>
					<option value="Vida Marinha">Vida Marinha</option>
					<option value="Vida Terreste">Vida Terreste</option>
				</Container.select>
			</Container.search>
		</>
	)
}