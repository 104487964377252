import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';


const Container__Main = styled.main`
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 36px 36px 180px;
  align-items: center;
  flex-direction: column;
  background: #f4f7f9;
  img {
    padding-bottom: 36px;
  }
`;

const Container__Header = styled.div`
`;

const Container__Form = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 16px rgba(73, 80, 87, 0.32);
  border-radius: 8px;
  padding: 0 24px 12px;
  @media (max-width: ${theme.media.laptop}) {
    width: 100%;
  }
`;

const Container__Progress = styled.div`
  width: 100%;
  position: relative;
`;

const Container__ProgressBar = styled.div`
  padding: 12px 50px;
  display: flex;
  justify-content: space-around;
  @media (max-width: ${theme.media.mobileSmall}) {
    span {
      display: none;
    }
  }
`;

const Container__Img = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  img {
    width: 30px;
    height: 30px;
    background: ${(props) => props.theme.colors.primary};
    padding: 9px;
    border-radius: 50px;
    margin: 2px;
    z-index: 2;
    cursor: pointer;
  }
  span {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize.md};
    line-height: 22px;
    color: ${(props) => props.theme.colors.primary};
  }
  @media (max-width: ${theme.media.mobile}) {
    span {
      font-size: ${(props) => props.theme.fontSize.smX};
    }
    }
`;

const Container__Cause = styled.div``;




const Container__Value = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  padding: 18px 36px;
  color: ${(props) => props.theme.colors.white};
`;

const Container__Footer = styled.div`
  display: flex;
  padding-top: 60px;
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray};
  .imgLogo {
    width: 100px;
  }
  p {
    display: flex;
    gap: 8px;
  }
`;

export const Container = {
  main: Container__Main,
  header: Container__Header,
  form: Container__Form,
  progress: Container__Progress,
  cause: Container__Cause,
  footer: Container__Footer,
  progressBar: Container__ProgressBar,
  img: Container__Img,
  value: Container__Value,

};

export const Line = styled.div`
  position: absolute;
  height: 2px;
  width: 65%;
  background: ${(props) => props.theme.colors.primary};
  opacity: 0.2;
  top: 60px;
  @media (max-width: ${theme.media.mobileSmall}) {
  top: 30px;
  width: 60%;}
`;

