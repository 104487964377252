import { CheckOutProvider } from '../../../../infra/config/CheckOut';
import { CheckOut } from './components/CheckOut';

export const CheckOutPay = () => {
  return (
    <CheckOutProvider>
      <CheckOut />
    </CheckOutProvider>
  );
};
