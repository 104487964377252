
import { useEffect, useState } from "react";
import { GetReport } from "../../../../../application/useCases/donation/GetReportUseCase";
import { GridColDef, GridSortCellParams } from "@mui/x-data-grid";
import Table from "../../../common/Table";
import { Container } from '../TableAllInformation/styles'
import { GetReportItemsUseCase, GetReportTableItems } from "../../../../../application/useCases/report/GetReportItemsUseCase";
import { useGlobalStore } from "../../../../../infra/state/GlobalStore";
import { useAlert } from "../../../../../infra/config/AlertContext/useAlert";
import { CardData, CardsTable } from "../../../common/CardsTable";

interface Props {
    useCase: GetReportItemsUseCase;
}

export const TableAllItems = ({ useCase }: Props) => {

    const [reportItems, setReportItems] = useState<GetReportTableItems[]>([]);
    const { state } = useGlobalStore();
    const { setAlert } = useAlert();

    useEffect(() => {
        const fetchData = async () => {
            if (state.acl.selectedACL) {
                const orgId = state.acl.selectedACL.id;
                const institutionId = state.acl.selectedACL.id;
                const { error, payload } = await useCase.execute({ orgId, institutionId });
                if (!error && payload) {
                    setReportItems(payload.data);
                } else {
                    setAlert(error!, 'error');
                }
            }
        };
        fetchData();
    }, []);

    const columns: GridColDef[] = [
        { field: 'Usuário', headerName: 'Usuário', minWidth: 250, flex: 1, headerClassName: 'table-header', },
        { field: 'Email', headerName: 'Email', minWidth: 250, flex: 1, headerClassName: 'table-header', },
        { field: 'Telefone', headerName: 'Telefone', minWidth: 120.3, flex: 1, headerClassName: 'table-header', },
        { field: 'Instituição', headerName: 'Instituição', minWidth: 120.3, flex: 1, headerClassName: 'table-header', },
        { field: 'Campanha', headerName: 'Campanha', minWidth: 120.3, flex: 1, headerClassName: 'table-header', },
        { field: 'Item', headerName: 'Item', minWidth: 120.3, flex: 1, headerClassName: 'table-header', },
        { field: 'Quantidade', headerName: 'Quantidade', minWidth: 100, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center' },
        {
            field: 'Data', headerName: 'Data', minWidth: 100, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center',
            valueFormatter: (params) => {
                const dateValue = params.value as Date;
                return dateValue instanceof Date && !isNaN(dateValue.getTime()) ? dateValue.toLocaleDateString('pt-BR') : '-';
            },
            sortComparator: (v1, v2, param1: GridSortCellParams<any>, param2: GridSortCellParams<any>) => {
                const date1 = param1.api.getCellValue(param1.id, 'Data') as Date;
                const date2 = param2.api.getCellValue(param2.id, 'Data') as Date;
                if (!(date1 instanceof Date) || isNaN(date1.getTime())) return 1;
                if (!(date2 instanceof Date) || isNaN(date2.getTime())) return -1;
                return date1.getTime() - date2.getTime();
            },
        },
        { field: 'Status', headerName: 'Status', minWidth: 100, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center' },
    ];


    const rows =
        (reportItems?.map((item, index: number) => ({
            index: index,
            id: index,
            'Usuário': item.userName,
            'Email': item.userEmail,
            'Telefone': item.userTelephone,
            'Instituição': item.institutionName,
            'Campanha': item.campaignTitle,
            'Item': item.itemName,
            'Quantidade': item.itemQuantity,
            'Data': item.closedAt !== null ? new Date(item.closedAt) : '-',
            'Status': item.orderStatus,
        })) ?? [])
            .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

    const cardData: CardData[] = reportItems.map((item, index: number) => ({
        id: index,
        title: [
            'Usuário',
            'Email',
            'Telefone',
            'Instituição',
            'Campanha',
            'Item',
            'Quantidade',
            'Data',
            'Status',
        ],
        value: [
            item.userName,
            item.userEmail,
            item.userTelephone,
            item.institutionName,
            item.campaignTitle,
            item.itemName,
            item.itemQuantity,
            item.closedAt !== null ? new Date(item.closedAt).toLocaleDateString('pt-BR') : '-',
            item.orderStatus,
        ],
    }));

    return (
        <>
            <Container.title> Doações de Itens - Analítico </Container.title>
            <Container.table>
                <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={10} />
            </Container.table >
            <Container.mobile>
                <CardsTable cardData={cardData} />
            </Container.mobile>
        </>
    );
};