import { useContext } from 'react';
import { Container, Line } from './styles';
import { CheckOutContext } from '../../../../../../infra/config/CheckOut';
import logo_blue from '../../../../../assets/logo/logo_blue.svg';
import { DataPerson } from './components/DataPerson';
import { Address } from './components/Address';
import { PaymentMethod } from './components/PaymentMethod';
import person_white from '../../../../../assets/icons/person_white.svg';
import locality_white from '../../../../../assets/icons/locality_white.svg';
import credit_card_white from '../../../../../assets/icons/credit_card_white.svg';
import summary_white from '../../../../../assets/icons/summary_white.svg';
import { Revision } from './components/Revision';
import security from '../../../../../assets/icons/security.svg';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';

export const CheckOut = () => {
  const {
    activeComponentIndex,
    setActiveComponentIndex,
    dataPerson,
    address,
    paymentMethod,
    amount,
  } = useContext(CheckOutContext);

  const { setAlert } = useAlert();

  const isDataPersonValid = (dataPerson: any) => {
    return (
      dataPerson && dataPerson.name && dataPerson.email && dataPerson.CPF && dataPerson.cellPhone
    );
  };

  const isAddressValid = (address: any) => {
    return (
      address &&
      address.cep &&
      address.address &&
      address.number &&
      address.neighbordhood &&
      address.city &&
      address.state
    );
  };

  const formSteps = [
    {
      image: person_white,
      text: 'Identificação',
      onClick: () => setActiveComponentIndex(0),
    },
    {
      image: locality_white,
      text: 'Endereço',
      onClick: () => {
        if (isDataPersonValid(dataPerson)) {
          setActiveComponentIndex(1);
        } else {
          setAlert('Preencha todos os campos de identificação antes de prosseguir.', 'error');
        }
      },
    },
    {
      image: credit_card_white,
      text: 'Pagamento',
      onClick: () => {
        if (isAddressValid(address)) {
          setActiveComponentIndex(2);
        } else {
          setAlert('Preencha todos os campos de endereço antes de prosseguir.', 'error');
        }
      },
    },
    {
      image: summary_white,
      text: 'Revisão',
      onClick: () => {
        if (isDataPersonValid(dataPerson) && isAddressValid(address) && paymentMethod) {
          setActiveComponentIndex(3);
        } else {
          setAlert('Preencha todos os campos antes de prosseguir para a revisão.', 'error');
        }
      },
    },
  ];

  const formComponents = [
    <DataPerson key="DataPerson" />,
    <Address key="Address" />,
    <PaymentMethod key="PaymentMethod" />,
    <Revision key="Revision" />,
  ];

  return (
    <Container.main>
      <Container.header>
        <img src={logo_blue} alt="Gooders" />
      </Container.header>
      <Container.form>
        <Container.progress>
          <Container.progressBar>
            {formSteps.map((step, index) => (
              <Container.img key={index} onClick={step.onClick}>
                <span style={{ opacity: index <= activeComponentIndex ? 1 : 0.7 }}>
                  {step.text}
                </span>
                <img
                  src={step.image}
                  alt={step.text}
                  style={{ opacity: index <= activeComponentIndex ? 1 : 0.7 }}
                />
              </Container.img>
            ))}
            <Line></Line>
          </Container.progressBar>
        </Container.progress>
        <Container.cause>{formComponents[activeComponentIndex]}</Container.cause>
      </Container.form>
      <Container.value>
        <p>Total</p>
        <span>
          {(amount / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </Container.value>
      <Container.footer>
        <img src={logo_blue} alt="Gooders" className="imgLogo" />
        <p>
          | <img src={security} alt="Gooders" /> Ambiente Seguro
        </p>
      </Container.footer>
    </Container.main>
  );
};
