import styled from "styled-components";


const Container__Page = styled.main`
  background: #F5F5F5;
  min-height: 800px;
  color: #467DC0;
  padding: 80px 52px 40px;
  @media (max-width: 480px) {
    padding: 80px 20px;
  }
  @media (max-width: 300px) {
    padding: 80px 10px;
  }
`;


const Container__Header = styled.div`
  height: 62px;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  @media (max-width: 480px) {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
}
`;

const Container__Title = styled.h1`
	font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

const Container__Main = styled.div`
    padding: 20px;
    gap: 16px;
    color: #4E545C;
    background: #fff;
    border-radius: 8px;
`;

const Container__Head = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
`;

const Container__Cards = styled.div`
padding: 16px 0;
width: 100%;
display: flex;
gap: 8px;
flex-wrap: wrap;
justify-content: flex-start;
@media (max-width: 900px) {
  display: flex;
  flex-wrap: wrap;
    }
`;


export const Container = {
	page: Container__Page,
	title: Container__Title,
	header: Container__Header,
	Main: Container__Main,
	Cards: Container__Cards,
	head: Container__Head,
};




