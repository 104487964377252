import { Container } from './styles';
import IonIcon from '@reacticons/ionicons';

interface SearchBarProps {
  searchTerm: string;
  onSearchTermChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, onSearchTermChange }) => {
  return (
    <Container.Search >
      <label htmlFor="search">
        <button type="submit" data-test="search-bar-submit">
          <IonIcon name="search-outline" />
        </button>
        <input
          id='search'
          type="search"
          placeholder="Encontre uma campanha"
          value={searchTerm}
          onChange={onSearchTermChange}
          data-test="search-bar-input"
        />
      </label>
    </Container.Search>
  );
};
