import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';
import { SharedUseCase } from '../item/CreateItemForCampaignUseCase';

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
  vacancyId: number;
}

export class DeleteVacancyFromCampaignUseCase extends SharedUseCase<
  Params,
  void,
  {
    payload: void | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execOrg() {
    return await HttpClient<void, void>(
      'delete',
      `/campaigns/org/${this.params!.orgId}/institution/
      ${this.params!.institutionId}/${this.params!.campaignId}/vacancies/${this.params?.vacancyId}`,
      this.dto!
    );
  }

  protected override async execInstitution() {
    return await HttpClient<void, void>(
      'delete',
      `/campaigns/institution/${this.params!.institutionId}/
      ${this.params!.campaignId}/vacancies/${this.params?.vacancyId
      }`,
      this.dto!
    );
  }

  protected override async execGooders() {
    return await HttpClient<void, void>(
      'delete',
      `/campaigns/gooders/vacancies/${this.params?.vacancyId}`,
      this.dto!
    );
  }
}
