import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
`;

export const ContentLoading = styled.div`
  width: 200px;
  height: 200px;
  border: solid 14px ${(props) => props.theme.colors.blue};
  border-radius: 50%;
  border-right-color: transparent;
  animation-name: rotate;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
