import { useEffect, useState } from 'react';
import { Container } from './styles';
import { useSearch } from '../../../../../components/hooks/useSearch';
import { SearchBar } from '../../../../../components/common/Search';
import { Campaign } from '../../../../../components/common/CardsCause';
import { PaginationListCause } from './components/PaginationListCause';
import {
  CampaignArrayOutput,
  GetListCampaign,
} from '../../../../../../application/useCases/campaign/GetListCamapign';
import { CategorieIcons } from './components/CategorieIcons';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { defaultOption } from '../../../../../components/common/SelectInput/components/SelectOptionsState';


const pageSizeOptions = [50, 100, 200, 300];

export const ListCampaing = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const { searchTerm, handleSearchTermChange } = useSearch({
    onSearchTermChange: (term) => console.log(term),
  });
  const [selectedOds, setSelectedOds] = useState('')
  const [campaignOds, setCampaignOds] = useState<string[]>([]);
  const list = useGlobalStore()

  useEffect(() => {
    if (list.state.campaigns.length === 0) {
      const fetchData = async () => {
        const { payload, error } = await GetListCampaign();
        if (!error && payload) {
          list.actions.setCampaigns(payload.data)
          const uniqueOds = Array.from(new Set(payload.data.map((cause) => cause.ods)));
          setCampaignOds(uniqueOds);
        }
      };
      fetchData();
    }
  }, []);

  const handlePageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const filteredCauses = list.state.campaigns
    .filter((cause: CampaignArrayOutput) =>
      searchTerm
        ? [cause.title?.toLowerCase(), cause.description?.toLowerCase()].some((field) =>
          field?.includes(searchTerm.toLowerCase())
        )
        : true
    )
    .filter((cause: CampaignArrayOutput) =>
      selectedCategory
        ? selectedCategory === 'money'
          ? cause.donations?.money
          : selectedCategory === 'items'
            ? cause.donations?.items
            : cause.donations?.voluntary
        : true
    )
    .filter((cause: CampaignArrayOutput) =>
      selectedOds ? cause.ods === selectedOds
        : true
    )
    .sort((a: CampaignArrayOutput, b: CampaignArrayOutput) =>
      a.title?.localeCompare(b.title)
    );
  const startIndex = (currentPage - 1) * pageSize;
  const pagedCauses = filteredCauses.slice(startIndex, startIndex + pageSize);


  return (
    <Container.Page>
      <Container.Header>
        <SearchBar searchTerm={searchTerm} onSearchTermChange={handleSearchTermChange} />
        <CategorieIcons setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
        {filteredCauses.length > 0 && (
          <PaginationListCause
            totalCount={0}
            pageSize={pageSize}
            startIndex={startIndex}
            pagedCauses={pagedCauses}
            causes={list.state.campaigns}
            pageSizeOptions={pageSizeOptions}
            handlePageSizeChange={handlePageSizeChange}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </Container.Header>
      <Container.Cards>
        {pagedCauses.filter((cause: CampaignArrayOutput) =>
          searchTerm
            ? [cause.title?.toLowerCase(), cause.description?.toLowerCase()].some((field) =>
              field?.includes(searchTerm.toLowerCase())
            )
            : true
        ).length > 0 ? (
          pagedCauses.map((cause: CampaignArrayOutput) => <Campaign key={cause.id} cause={cause} />)
        ) : (
          <div>
            <p>
              Lamentamos, nenhuma campanha encontrada. Tente novamente com outro termo para busca.
            </p>
          </div>
        )}
      </Container.Cards>
    </Container.Page>
  );
};
