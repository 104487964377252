import { Container, Text } from '../../styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ChangeEvent, useState } from 'react';
import { GreenButton, SmallBlueButton } from '../../../../../components/common/Button';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { LabelInputFormCampaign } from '../../../../../components/common/Input';
import logo_coin from '../../../../../assets/logo/logo_coin.svg'
import outlined from '../../../../../assets/icons/outlined.svg'
import delet from '../../../../../assets/icons/delet.svg'
import { CreatePatnerUseCase } from '../../../../../../application/useCases/gooders/CreatePatnerUseCase';


export const validationSchema = Yup.object().shape({
	name: Yup.string().required('Preencha o nome'),
	cnpj: Yup.string()
		.matches(/^\d{14}$/, 'Insira um CNPJ válido com 14 números')
		.required('Insira o seu CNPJ'),
	contact: Yup.string()
		.required('Informe o seu email ou telefone')
		.max(100, 'Limite de caracteres excedido'),
	image: Yup.mixed()
		.test('fileType', 'Formato inválido. Selecione um arquivo SVG, JPEG, JPG ou PNG.', function (value) {
			if (value && value instanceof File) {
				const acceptedFormats = ['image/svg+xml', 'image/jpeg', 'image/jpg', 'image/png'];
				return acceptedFormats.includes(value.type);
			}
			return true;
		})
		.test('fileSize', 'O tamanho máximo do arquivo é de 200KB.', function (value) {
			if (value && value instanceof File) {
				const maxSize = 200 * 1024;
				return value.size <= maxSize;
			}
			return true;
		})
		.required('Selecione uma imagem'),

});

export const FormCreatePatner = () => {

	const [loading, setLoading] = useState(false);
	const { setAlert } = useAlert();
	const [Image, setImage] = useState<File | null>(null);

	const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedImages = e.target.files;
		if (selectedImages && selectedImages.length > 0) {
			const selectedImage = selectedImages[0];
			formik.setFieldValue('image', selectedImage);
			setImage(selectedImage)
		}
	};

	const handleDeleteImage = () => {
		setImage(null);
		formik.setFieldValue('image', null);
	};

	const formik = useFormik({
		initialValues: {
			name: '',
			cnpj: '',
			contact: '',
			image: null,
		},
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			setLoading(true);
			const formData = new FormData();
			if (values.image) {
				formData.append('images', values.image);
			}
			formData.append('name', values.name);
			formData.append('cnpj', values.cnpj);
			formData.append('contact', values.contact);

			const { error } = await CreatePatnerUseCase(formData);

			setLoading(false);
			if (!error) {
				setAlert('Parceiro cadastrado com sucesso', 'success');
				resetForm()
				formik.setFieldValue('image', null);
				setImage(null);
			} else {
				setAlert(error, 'error');
				return;
			}
		}
	},
	);

	return (
		<>
			<Container.head>
				<Container.subTitle data-test="title-Patner">Informações</Container.subTitle>
			</Container.head>
			<Container.wrapper onSubmit={formik.handleSubmit}>
				<Container.content>
					<Container.form>
						<Container.input>
							<LabelInputFormCampaign
								id="name"
								name="name"
								type="text"
								htmlFor="name"
								placeholder="Nome"
								aria-labelledby="name"
								onBlur={formik.handleBlur}
								value={formik.values.name}
								onChange={formik.handleChange}
								error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
							>
								Nome
							</LabelInputFormCampaign>
						</Container.input>
						<Container.input>
							<LabelInputFormCampaign
								htmlFor="cnpj"
								id="cnpj"
								name="cnpj"
								type="text"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.cnpj}
								placeholder="00.000.000/0000-00"
								aria-labelledby="cnpj"
								error={formik.touched.cnpj && formik.errors.cnpj ? formik.errors.cnpj : undefined}
							>
								CNPJ
							</LabelInputFormCampaign>
						</Container.input>
						<Container.input>
							<LabelInputFormCampaign
								htmlFor="contact"
								id="contact"
								name="contact"
								type="text"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.contact}
								placeholder="Email ou telefone"
								aria-labelledby="contact"
								error={formik.touched.contact && formik.errors.contact ? formik.errors.contact : undefined}
							>
								Contato
							</LabelInputFormCampaign>
						</Container.input>

					</Container.form>

					<Container.avatar>

						<Container.card >
							<span className="error">{formik.errors.image}</span>
							<Container.image>

								<label htmlFor="image">
									{Image ? (
										<>
											<img src={URL.createObjectURL(Image)} alt="logo" className='selectedImage' />
											<img className='deletImage' src={delet} onClick={handleDeleteImage} />
										</>
									) : (
										<>
											<img src={outlined} alt="logo" />
											<p>Selecione a sua logo</p>
										</>
									)}
									<input
										style={{ display: "none", cursor: "pointer" }}
										id="image"
										name="image"
										type="file"
										onChange={handleImage}
										accept=".svg, .jpeg, .jpg, .png"
										maxLength={200 * 1024}
										onBlur={formik.handleBlur}
									/>
								</label>
							</Container.image>
							<Container.box>
								<p>% OFF</p>
								<span>Parceiro </span>
								<Container.gooders>
									<img src={logo_coin} alt="gooders" />
									<p>Coins</p>
								</Container.gooders>
							</Container.box>
							<Text.description>
								Descrição
							</Text.description>
							<Container.perforated>
								<div></div><div></div><div></div><div></div><div></div>
								<div></div><div></div><div></div><div></div><div></div>
								<div></div><div></div>
							</Container.perforated>
							<GreenButton onClick={undefined} loading={false} disabled> Resgatar </GreenButton>
						</Container.card>
					</Container.avatar>
				</Container.content>
				<SmallBlueButton type="submit" loading={loading}>
					Salvar
				</SmallBlueButton>
			</Container.wrapper >
		</>
	);
};
