import more from '../../../../../../assets/icons/more.svg';
import { Container, Text } from '../CreateCause/styles';
import { useContext, useState } from 'react';
import { CampaignContext } from '../../../../../../../infra/config/CampaignContext';
import { LabelInputFormCampaign } from '../../../../../../components/common/Input';
import { SmallBlueButton } from '../../../../../../components/common/Button';
import { SelectInput } from '../../../../../../components/common/SelectInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Table from '../../../../../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import remove from '../../../../../../assets/icons/remove.svg';
import update from '../../../../../../assets/icons/update.svg';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Preencha o nome do item'),
  quantity: Yup.string()
    .required('Preencha a quantidade')
    .matches(/^[0-9]+$/, 'Insira um número válido'),
  address: Yup.string().required('Insira o endereço'),
  category: Yup.string().required('Selecione a categoria'),
  multiplier: Yup.string()
    .required('Multiplicador gooders')
    .matches(/^[0-9]+$/, 'Insira um número válido'),
});

export const DonationItems = () => {
  const { items, setItems } = useContext(CampaignContext);
  const [loading, setLoading] = useState(false);
  const [editingItemIndex, setEditingItemIndex] = useState(-1);

  function handleItemEdit(itemIndex: number) {
    setEditingItemIndex(itemIndex);
    const item = items[itemIndex];
    itemFormik.setValues({
      name: item.name,
      quantity: item.quantity.toString(),
      address: item.address,
      multiplier: item.multiplier.toString(),
      category: item.category,
    });
  }

  function handleItemDelete(itemIndex: number) {
    if (itemIndex === editingItemIndex) {
      setEditingItemIndex(-1);
    }
    setItems(items.filter((item, i) => i !== itemIndex));
  }

  const defaultOption = { value: '', label: 'Selecione uma opção', disabled: true, selected: true };

  const columns: GridColDef[] = [
    { field: 'Nome do item:', headerName: 'Nome do item:', minWidth: 100, flex: 1, headerClassName: 'table-header', },
    { field: 'Categoria:', headerName: 'Categoria:', minWidth: 100, flex: 1, headerClassName: 'table-header', },
    { field: 'Quantidade:', headerName: 'Quantidade:', minWidth: 100, flex: 1, headerClassName: 'table-header', },
    { field: 'Endereço:', headerName: 'Endereço:', minWidth: 100, flex: 1, headerClassName: 'table-header', },
    { field: 'Multiplicador:', headerName: 'Multiplicador:', minWidth: 100, flex: 1, headerClassName: 'table-header', },
    {
      field: 'Editar', headerName: 'Editar', minWidth: 50, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => (
        <img
          onClick={() => handleItemEdit(params.row.id)}
          src={update}
          alt="action"
          style={{ cursor: 'pointer' }}
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: 'Remover', headerName: 'Remover', minWidth: 50, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
      renderCell: (params) => (
        <img
          onClick={() => handleItemDelete(params.row.id)}
          src={remove}
          alt="action"
          style={{ cursor: 'pointer' }}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ];

  const rows = items.map((item, index) => ({
    index: index,
    id: index,
    'Nome do item:': item.name,
    'Categoria:': item.category,
    'Quantidade:': item.quantity,
    'Endereço:': item.address,
    'Multiplicador:': item.multiplier,
  }))
    .sort((a, b) => a['Nome do item:'].localeCompare(b['Nome do item:']));

  const itemFormik = useFormik({
    initialValues: {
      name: '',
      quantity: '',
      address: '',
      multiplier: '',
      category: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const quantity = parseInt(itemFormik.values.quantity);
      if (editingItemIndex !== -1) {
        const updatedItems = items.map((item, index) => {
          if (index === editingItemIndex) {
            return {
              address: itemFormik.values.address,
              category: itemFormik.values.category,
              multiplier: itemFormik.values.multiplier,
              name: itemFormik.values.name,
              quantity: quantity,
            };
          }
          return item;
        });
        setItems(updatedItems);
        setEditingItemIndex(-1);
      } else {
        setItems([
          ...items,
          {
            address: itemFormik.values.address,
            category: itemFormik.values.category,
            multiplier: itemFormik.values.multiplier,
            name: itemFormik.values.name,
            quantity: quantity,
          },
        ]);
      }
      itemFormik.values.address = itemFormik.values.address;
      itemFormik.values.category = '';
      itemFormik.values.name = '';
      itemFormik.values.quantity = '';
      itemFormik.values.multiplier = itemFormik.values.multiplier;
      setLoading(false);
    },
  });

  return (
    <Container.main>
      <Text.title>Doação de Itens</Text.title>
      <Container.input>
        <Container.inputs>
          <SelectInput
            htmlFor="category"
            id="category"
            name="category"
            value={itemFormik.values.category}
            onChange={itemFormik.handleChange}
            aria-labelledby="Categoria de itens"
            onBlur={itemFormik.handleBlur}
            options={[
              defaultOption,
              { value: 'Alimentos', label: 'Alimentos' },
              { value: 'Vestuário', label: 'Vestuário' },
              { value: 'Outros', label: 'Outros' },
            ]}
            error={
              itemFormik.touched.category && itemFormik.errors.category
                ? itemFormik.errors.category
                : undefined
            }
          >
            Categoria
          </SelectInput>
          <LabelInputFormCampaign
            htmlFor="name"
            id="name"
            name="name"
            type="text"
            placeholder=""
            onChange={itemFormik.handleChange}
            onBlur={itemFormik.handleBlur}
            value={itemFormik.values.name}
            aria-labelledby="Nome do item"
            error={
              itemFormik.touched.name && itemFormik.errors.name ? itemFormik.errors.name : undefined
            }
          >
            Name
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="quantity"
            id="quantity"
            name="quantity"
            type="number"
            placeholder=""
            value={itemFormik.values.quantity}
            onChange={itemFormik.handleChange}
            onBlur={itemFormik.handleBlur}
            aria-labelledby="Quantidade"
            error={
              itemFormik.touched.quantity && itemFormik.errors.quantity
                ? itemFormik.errors.quantity
                : undefined
            }
          >
            Quantidade
          </LabelInputFormCampaign>
        </Container.inputs>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="multiplier"
            id="multiplier"
            name="multiplier"
            type="number"
            placeholder=""
            value={itemFormik.values.multiplier}
            onChange={itemFormik.handleChange}
            onBlur={itemFormik.handleBlur}
            aria-labelledby="Multiplicador Gooders"
            error={
              itemFormik.touched.multiplier && itemFormik.errors.multiplier
                ? itemFormik.errors.multiplier
                : undefined
            }
          >
            Multiplicador Gooders
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="address"
            id="address"
            name="address"
            type="text"
            placeholder=""
            value={itemFormik.values.address}
            onChange={itemFormik.handleChange}
            onBlur={itemFormik.handleBlur}
            aria-labelledby="Endereço"
            error={
              itemFormik.touched.address && itemFormik.errors.address
                ? itemFormik.errors.address
                : undefined
            }
          >
            Endereço
          </LabelInputFormCampaign>
        </Container.inputs>

        <Container.button>
          <SmallBlueButton onClick={itemFormik.handleSubmit} loading={loading}>
            <img src={more} alt="more" /> Adicionar item
          </SmallBlueButton>
        </Container.button>
        <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />
      </Container.input>
    </Container.main>
  );
};
