import { Container, Input } from '../../styles'

interface SearchListCampaignProps {
    selectedCategory: string;
    handleCategoryChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const CategoryTableListRewards: React.FC<SearchListCampaignProps> = ({
    selectedCategory,
    handleCategoryChange,
}) => {
    return (
        <>
            <Container.search>
                <Input.label htmlFor="category-select"></Input.label>
                <Container.select
                    id="category-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                >
                    <option value="">Categoria</option>
                    <option value="Descontos em porcentagem">Descontos em porcentagem</option>
                    <option value="Descontos em valores">Descontos em valores</option>
                    <option value="Link de redirecionamento">Link de redirecionamento</option>
                </Container.select>
            </Container.search>
        </>
    )
}