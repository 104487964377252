import { UserTypeEnum } from '../../../domain/interfaces/User';
import { HttpClient } from '../../HttpClient';
import { SharedUseCase } from '../campaign/item/CreateItemForCampaignUseCase';

export interface GetReceipt {
  orderId: string;
}

interface Params {
  orgId?: number;
  institutionId?: number;
  orderId: string;
}


export class GetReceiptOrgInstUseCase extends SharedUseCase<
  Params,
  GetReceipt,
  {
    payload: any;
    error: string | null;
    statusCode: number | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execOrg(): Promise<{
    payload: string;
    error: string | null;
    statusCode: number | null;
  }> {
    const result = await HttpClient<any, any>(
      'get',
      `/api/pdf/orders/${this.params!.orderId}/orgs/${this.params!.orgId}`,
      null,
      false,
      true
    );
  
    return {
      payload: result.payload,
      error: result.error,
      statusCode: result.statusCode,
    };
  }

  protected override async execInstitution(): Promise<{
    payload: string;
    error: string | null;
    statusCode: number | null;
  }> {
    const result = await HttpClient<any, any>(
      'get',
      `/api/pdf/orders/${this.params!.orderId}/institutions/${this.params!.institutionId}`,
      null,
      false,
      true
    );
  
    return {
      payload: result.payload,
      error: result.error,
      statusCode: result.statusCode,
    };
  }

}



