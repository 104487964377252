import { useGlobalCampaignUnique } from '../../../../../../infra/state/useCampaign';
import { Container, Line } from '../DonationItems/styles';
import { useEffect, useState } from 'react';
import { VacanciesOutput } from '../../../../../../application/useCases/campaign/GetCampaign';
import { MyDate } from '../../../../../../application/utils';
import { SelectInput } from '../../../../../components/common/SelectInput';
import { BlueButton } from '../../../../../components/common/Button';
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { useFormik } from 'formik';
import { CreateVacancieOrderDto, UserSubscribeInVacancy } from '../../../../../../application/useCases/donation/UserSubscribeInVacancy';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { validationSchema } from '../../../../../components/common/CardsCause/component/Modal/ModalVoluntary/index'

export const DonationVoluntary: React.FC = () => {

  const state = useGlobalCampaignUnique();
  const [selectedVacancie, setSelectedVacancie] = useState<VacanciesOutput | null>(null)
  const [renderConfirmModal, setRenderConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const [returnVacancie, setReturnVacancie] = useState(false);

  useEffect(() => {
    const vacancies = state.campaignUnique?.vacancies || [];
    if (vacancies.length > 0) {
      setSelectedVacancie(vacancies[0]);
      formik.setFieldValue('vacancieId', vacancies[0].id.toString());
    }
  }, [state.campaignUnique?.vacancies]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVacancieId = Number(e.target.value);
    const selectedCampaignVacancie = state?.campaignUnique?.vacancies.find(
      (vc) => vc.id === selectedVacancieId
    );
    setSelectedVacancie(selectedCampaignVacancie || null);
    formik.setFieldValue('vacancieId', selectedVacancieId.toString());
  };

  function handleConfirmModal() {
    setRenderConfirmModal(true);
  }

  function handleCloseConfirmModal(result: boolean) {
    setRenderConfirmModal(false);

    if (result) {
      formik.submitForm();
    }
  }



  const formik = useFormik({
    initialValues:
    {
      vacancieId: selectedVacancie ? selectedVacancie.id.toString() : ''
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!state.campaignUnique) {
        setAlert('Não foi possível se candidatar para essa vaga no momento!', 'error');
        return;
      }
      const dto: CreateVacancieOrderDto = {
        campaignId: state.campaignUnique.id,
        vacancieId: Number(values.vacancieId),
      };

      const { error } = await UserSubscribeInVacancy(dto);
      if (!error) {
        setAlert('Cadastro realizado com sucesso!', 'success');
        setReturnVacancie(true)
      } else {
        setAlert('Não foi possível realizar o cadastro no momento!', 'error');
      }
    },
  });

  return (
    <Container.box>
      <Container.Input>
        <Container.Text>
          <SelectInput
            htmlFor="vacancieId"
            name="vacancieId"
            id="vacancieId"
            value={formik.values.vacancieId}
            onChange={handleSelectChange}
            required
            options={[
              ...(state.campaignUnique?.vacancies?.map((vacancie) => ({
                value: vacancie.id.toString(),
                label: vacancie.title,
              })) || []),
            ]}
          >
            Vagas
          </SelectInput>
          {formik.touched.vacancieId && formik.errors.vacancieId && (
            <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.vacancieId}</span>
          )}
          <Container.Address style={{ width: '100%', paddingTop: '16px', paddingBottom: '16px' }}>
            <span>Informações </span>
            Endereço: {selectedVacancie?.address} ,
            <p>
              {selectedVacancie?.city}, {selectedVacancie?.state}
            </p>
            <Line className="line" />
            {selectedVacancie?.startDate && (
              <p>Data de início: {MyDate.formatISOString(selectedVacancie?.startDate)}</p>
            )}
            {selectedVacancie?.endDate && (
              <p>Data de fim: {MyDate.formatISOString(selectedVacancie?.endDate)}</p>
            )}
            <p>
              Hora: {selectedVacancie?.startHour} às {selectedVacancie?.endHour}{' '}
            </p>
            <Line className="line" />
            Vagas: {selectedVacancie?.quantity}
            <p>Voluntários Inscritos: {selectedVacancie?.subscribersCount} </p>
          </Container.Address>
        </Container.Text>
        <Container.Text>
          <Container.Address >
            <span>Descrição </span>
            {selectedVacancie?.description}
          </Container.Address>
        </Container.Text>
      </Container.Input>
      <Container.button >
        <BlueButton type="submit" onClick={handleConfirmModal} loading={loading}>
          Finalizar Inscrição
        </BlueButton>
      </Container.button>
      {renderConfirmModal && (
        <ModalConfirm
          title="Termos do uso de imagem"
          content="Ao clicar em 'Finalizar Inscrição', você concorda com os nossos termos, deseja continuar?"
          rightButtonText="Finalizar Inscrição"
          onClose={handleCloseConfirmModal}
          linkText="Clique para visualizar: Lei do Serviço Voluntário "
          linkText2="Clique para visualizar: Termos de Adesão"
        />
      )}
    </Container.box>
  );
};
