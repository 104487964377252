import React, { MouseEvent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { _NavIconLink, _Props } from "./styles";

interface Props extends _Props {
  icon: ReactNode;
  redirectPath: string;
  id?: string;
  onClick?: (ev: MouseEvent<HTMLAnchorElement>) => void;
}

export const NavIconLink = ({ id, onClick, icon, redirectPath, ...rest }: Props) => {

	return (
		<_NavIconLink {...rest}>
			<Link id={id} onClick={onClick} to={redirectPath}>
				{icon}
			</Link>
		</_NavIconLink>
	);
};