import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';

interface CreateItemForCampaignDto {
  name: string;
  quantity: string;
  address: string;
  category: string;
  multiplier: string;
}
export async function CreateItemForCampaignUseCase(
  dto: CreateItemForCampaignDto,
  orgId: number,
  institutionId: number,
  campaignId: number
) {
  return await HttpClient<CreateItemForCampaignDto, void>(
    'post',
    `/campaigns/org/${orgId}/institution/${institutionId}/${campaignId}/items`,
    dto
  );
}

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
}
export class SharedUseCase<P, D, R> {
  params?: P;
  dto?: D;

  constructor(
    protected entityThatExecuteUseCase: UserTypeEnum,
    private config: { hasParams: boolean; hasDto: boolean }
  ) {}

  async execute(params?: P, dto?: D): Promise<R> {
    if (this.config.hasParams && !params) {
      throw Error('Missing params');
    } else if (!this.config.hasParams && params) {
      throw Error('Not has params');
    } else if (this.config.hasDto && !dto) {
      throw Error('Missing dto');
    } else if (!this.config.hasDto && dto) {
      throw Error('Not has dto');
    }

    this.params = params;
    this.dto = dto;

    if (this.entityThatExecuteUseCase === UserTypeEnum.INSTITUTION) {
      return await this.execInstitution();
    } else if (this.entityThatExecuteUseCase === UserTypeEnum.ORG) {
      return await this.execOrg();
    } else {
      return await this.execGooders();
    }
  }

  protected async execInstitution(): Promise<R> {
    throw Error('Method execInstitution not impletemented');
  }
  protected async execOrg(): Promise<R> {
    throw Error('Method execOrg not impletemented');
  }
  protected async execGooders(): Promise<R> {
    throw Error('Method execGooders not impletemented');
  }

  protected missingParamsError() {
    throw Error('Missing params');
  }
}

export class CreateItemForCampaignUseCases extends SharedUseCase<
  Params,
  CreateItemForCampaignDto,
  {
    payload: void | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execInstitution() {
    return await HttpClient<CreateItemForCampaignDto, void>(
      'post',
      `/campaigns/institution/${this.params!.institutionId}/${this.params!.campaignId}/items`,
      this.dto
    );
  }

  protected override async execOrg() {
    return await HttpClient<CreateItemForCampaignDto, void>(
      'post',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}/${
        this.params!.campaignId
      }/items`,
      this.dto
    );
  }

  protected override async execGooders() {
    return await HttpClient<CreateItemForCampaignDto, void>(
      'post',
      `/campaigns/gooders/${this.params!.campaignId}/items`,
      this.dto
    );
  }
}
