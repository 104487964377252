import { HttpClient } from "../../HttpClient";
import { UserTypeEnum } from "../../../domain/interfaces/User";
import { SharedUseCase } from "../campaign/item/CreateItemForCampaignUseCase";

export interface DashboardPayload {
  itemOrders: ItemsOrdersDahsboardOrg[]
  vacancyOrders: VacancyOrdersOrg[]
  total_active_campaigns: string;
  total_volunteers_subscribed: string;
  total_campaigns_money_goal: string;
  total_donations_accumulated: string;
}

export interface ItemsOrders {
id: number,
campaignTitle: string,
itemName: string,
quantity: number,
orderStatus: string,
userEmail: string
}

export interface ItemsOrdersDahsboardOrg {
  id: number,
  createdAt: string,
  campaign: { title: string },
  donation: { quantity: number },
  institution: { name: string }
  item: { name: string },
  user: { name: string },
}


export interface VacancyOrders {
id: number,
campaignTitle: string,
quantityHours: number,
orderStatus: string,
vacancyId: number,
userEmail: string
vacancyTitle: string
}


export interface VacancyOrdersOrg {
  id: number,
  createdAt: string,
  user: { name: string},
  campaign: {title: string},
  institution: {name: string},
  vacancy: {
    title: string,
    createdAt: string,
    endDate: string,
    startDate: string,
  },
  }

interface Params {
  orgId?: number;
  institutionId?: number;
  campaignId?: number;
}

export class GetDahsboardUseCase extends SharedUseCase<
  Params,
  DashboardPayload,
  {
    payload: { data: DashboardPayload;} | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execOrg() {
    return await HttpClient<void, { data: DashboardPayload }>(
      'get',
      `/analytics/org/${this.params!.orgId}`
    );
  }
}