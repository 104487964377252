import { useEffect, useState } from "react";
import { GetReport, GetReportUseCase } from "../../../../../application/useCases/donation/GetReportUseCase";
import { useGlobalStore } from "../../../../../infra/state/GlobalStore";
import { useAlert } from "../../../../../infra/config/AlertContext/useAlert";
import { GridColDef, GridSortCellParams } from "@mui/x-data-grid";
import Table from "../../../common/Table";
import { Container } from './styles'
import { dateSortComparator, dateValueFormatter, formatWalletBalanceValue } from "../../../../../application/utils";
import { CardData, CardsTable } from "../../../common/CardsTable";

interface Props {
    useCase: GetReportUseCase;
}

export const TableAllInformation = ({ useCase }: Props) => {

    const [report, setReport] = useState<GetReport[]>([]);
    const { state } = useGlobalStore();
    const { setAlert } = useAlert();


    useEffect(() => {
        const fetchData = async () => {
            if (state.acl.selectedACL) {
                const orgId = state.acl.selectedACL.id;
                const institutionId = state.acl.selectedACL.id;
                const { error, payload } = await useCase.execute({ orgId, institutionId });
                if (!error && payload) {
                    setReport(payload.data);
                } else {
                    setAlert(error!, 'error');
                }
            }
        };
        fetchData();
    }, []);


    const columns: GridColDef[] = [
        {
            field: 'Usuário', headerName: 'Usuário', minWidth: 250, flex: 1, headerClassName: 'table-header',
        },
        {
            field: 'Data', headerName: 'Data', minWidth: 100, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center',
            sortComparator: dateSortComparator,
            valueFormatter: dateValueFormatter,
        },
        {
            field: 'Status da Doação', headerName: 'Status', minWidth: 80, flex: 1, headerClassName: 'table-header', headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Valor', headerName: 'Valor BRL', minWidth: 100, flex: 1, headerClassName: 'table-header', headerAlign: 'center',
            align: 'center',

        },
        { field: 'Campanha', headerName: 'Campanha', minWidth: 120.3, flex: 1, headerClassName: 'table-header', },
        {
            field: 'Perfil do usuário', headerName: 'Perfil', minWidth: 120, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center',

        },
        {
            field: 'Id Transação', headerName: 'Id Transação', minWidth: 200, flex: 1, headerClassName: 'table-header', headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Data da Liberação', headerName: 'Data de Processamento', minWidth: 180, flex: 1, headerClassName: 'table-header', headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
                const dateValue = params.value as Date;
                return dateValue instanceof Date && !isNaN(dateValue.getTime()) ? dateValue.toLocaleDateString('pt-BR') : '-';
            },
            sortComparator: (v1, v2, param1: GridSortCellParams<any>, param2: GridSortCellParams<any>) => {
                const date1 = param1.api.getCellValue(param1.id, 'Data da Liberação') as Date;
                const date2 = param2.api.getCellValue(param2.id, 'Data da Liberação') as Date;
                if (!(date1 instanceof Date) || isNaN(date1.getTime())) return 1;
                if (!(date2 instanceof Date) || isNaN(date2.getTime())) return -1;
                return date1.getTime() - date2.getTime();
            },
        },
        {
            field: 'Doar Anônimo', headerName: 'Doar Anônimo', minWidth: 120, flex: 1, headerClassName: 'table-header', headerAlign: 'center',
            align: 'center',
        },
    ];


    const rows =
        (report?.map((item, index: number) => ({
            index: index,
            id: index,
            'Usuário': item.nameOfUser || 'Doação Anônima',
            'Data': new Date(item.createdAt),
            'Status da Doação': item.orderStatus,
            'Valor': formatWalletBalanceValue(item.amount),
            'Campanha': item.campaign,
            'Perfil do usuário': item.isPerson ? 'Pessoa Física' : 'Pessoa Jurídica',
            'Id Transação': item.gatewayId,
            'Data da Liberação': item.closedAt !== null ? new Date(item.closedAt) : '-',
            'Doar Anônimo': item.anonymous ? 'Sim' : 'Não',
        })) ?? [])
            .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

    const cardData: CardData[] = report.map((item, index: number) => ({
        id: index,
        title: [
            'Usuário',
            'Data',
            'Status',
            'Valor BRL',
            'Campanha',
            'Perfil do usuário',
            'Id Transação',
            'Data do Processamento',
            'Doar Anônimo',
        ],
        value: [
            item.nameOfUser || 'Doação Anônima',
            new Date(item.createdAt).toLocaleDateString('pt-BR'),
            item.orderStatus,
            formatWalletBalanceValue(item.amount),
            item.campaign,
            item.isPerson ? 'Pessoa Física' : 'Pessoa Jurídica',
            item.gatewayId,
            item.closedAt !== null ? new Date(item.closedAt).toLocaleDateString('pt-BR') : '-',
            item.anonymous ? 'Sim' : 'Não',
        ],
    }));


    return (
        <>
            <Container.title> Doações monetárias - Analítico </Container.title>
            <Container.table>
                <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={10} />
            </Container.table >
            <Container.mobile>
                <CardsTable cardData={cardData} />
            </Container.mobile>
        </>
    );
};