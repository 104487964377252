import { Container } from "../LoadingPay/styles";
import sucess from "../../../../../../../../../assets/icons/sucess.svg";
import toshare from "../../../../../../../../../assets/icons/toshare.svg";
import { SmallBlueButton, SmallTransparentBlueButton } from "../../../../../../../Button";
import { useState } from "react";

interface Props {
	onClose: () => void;
	campaignId: number | null
}

export default function SucessPay({ onClose, campaignId }: Props) {

	const [isLoading, setIsLoading] = useState(false);
	const [Loading, setLoading] = useState(false);


	const handleButtonClick = () => {
		setIsLoading(true);
		navigator.share({
			title: document.title,
			text: "Gooders",
			url: window.location.href,
		});

	};

	const handleButtonClose = () => {
		onClose();
	};

	return (
		<Container.box>
			<img src={sucess} alt="sucess" />
			<Container.title>Transação concluída</Container.title>
			<Container.text>Obrigado por contribuir!</Container.text>
			<SmallBlueButton onClick={handleButtonClick} loading={Loading}>
				<img src={toshare} style={{ width: "16px", height: "16px" }} alt="share" /> Compartilhe sua boa ação!
			</SmallBlueButton>
			<SmallTransparentBlueButton onClick={handleButtonClose} loading={false}>
				Fechar
			</SmallTransparentBlueButton>
		</Container.box>
	);
}
