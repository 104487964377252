import { useEffect, useState } from 'react';
import { Container, Text } from '../Donation/styles';
import { LabelInputFormCampaign } from '../../../common/Input';
import { TextArea } from '../../../common/TextArea';
import { useGlobalCampaignUnique } from '../../../../../infra/state/useCampaign';
import { SmallBlueButton } from '../../../common/Button';
import { UpdateDonationMoneyForCampaignUseCase } from '../../../../../application/useCases/campaign/donation/UpdateDonationMoneyForCampaignUseCase';
import { useAlert } from '../../../../../infra/config/AlertContext/useAlert';
import { useFormik } from 'formik';
import { validationSchema } from '../../gerenciar-campanha/Create/component/DonationPay/index';
import { useGlobalStore } from '../../../../../infra/state/GlobalStore';
import { divideBy100 } from '../../../../../application/utils';
import { FinishCampaignGoalUseCase } from '../../../../../application/useCases/campaign/donation/FinishCampaignGoalUseCase';
import { UserTypeEnum } from '../../../../../domain/interfaces/User';


interface Props {
    useCase: FinishCampaignGoalUseCase
}

export const ComplementAmount = ({ useCase }: Props) => {
    const { campaignUnique } = useGlobalCampaignUnique();
    const users = useGlobalStore();
    const [loading, setLoading] = useState(false);
    const { setAlert } = useAlert();

    const { values, handleSubmit, setValues, handleChange } = useFormik({
        initialValues: {
            amount: ''
        },
        onSubmit: async (values) => {
            setLoading(true);
            if (!campaignUnique || !users.state.acl.selectedACL) {
                setAlert('Não foi possível alterar a campanha no momento', 'error');
                return;
            }
            const campaignId = campaignUnique.id;
            const orgId = users.state.acl.selectedACL.id;
            const { error } = await useCase.execute({ orgId, campaignId }, { amount: +values.amount });

            if (!error) {
                setAlert('Meta finalizada com sucesso!', 'success');
            } else {
                setAlert(error, 'error');
            }
            setLoading(false);
        },
    });

    useEffect(() => {
        if (campaignUnique) {
            setValues({
                amount: divideBy100(campaignUnique.manualPaymentOrder.amount ?? 0).toString(),
            });
        }
    }, [campaignUnique]);

    return (
        <Container.main onSubmit={handleSubmit}>
            <Container.input>
                <Text.title>Complementar Meta</Text.title>
                <Container.inputs style={{ width: '100%' }}>
                    <LabelInputFormCampaign
                        htmlFor="amount"
                        id="amount"
                        name="amount"
                        type="number"
                        placeholder="R$ 0,00"
                        value={values.amount}
                        onChange={handleChange}
                        aria-labelledby="Complemento para meta"
                    >
                        Acrescentar Valor
                    </LabelInputFormCampaign>
                </Container.inputs>
                <SmallBlueButton type="submit" loading={loading}>
                    Salvar
                </SmallBlueButton>
            </Container.input>
        </Container.main>

    );
};

