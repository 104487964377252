import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

const Container__Box = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: ${theme.media.tablet}) {
    flex-direction: column;
  }
`;

const Container__Value = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.colors.dark};
`;

export const Container = {
  Value: Container__Value,
  Box: Container__Box,
};

const Description__SubTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray};
  p {
    color: ${(props) => props.theme.colors.gray};
    padding-bottom: 8px;
  }
  h5 {
    padding-left: 8px;
  }
`;

const Description__Text = styled.div`
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 32px;
  color: ${(props) => props.theme.colors.dark};
  padding-left: 8px;
`;

export const Description = {
  Subtitle: Description__SubTitle,
  Text: Description__Text,
};
