import styled from "styled-components";
import { theme } from '../../../themes/blue';

const Container__Box = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(70, 125, 192, 0.03);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.black};
  z-index: 999;
  cursor: initial;
`;

const Container__Warning = styled.div`
  width: 50%;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 16px rgba(73, 80, 87, 0.32);
  border-radius: 8px;
  padding: 28px 24px;
  position: relative;
  h3 {
  font-weight: 500;
  font-size: ${props => props.theme.fontSize.base};
  line-height: 24px;
  }
  p {
  font-weight: 300;
  line-height: 24px;
  padding-bottom: 40px;
  }
  @media (max-width: ${theme.media.mobile}) {
    width: 90%;
  }
`;

const Container__Close = styled.img`
  width: 17px;
  height: 17px;
  position: absolute;
  top: 31px;
  right: 31px;
  cursor: pointer;
`;

const Container__Icon = styled.div`
  display: flex;
  font-weight: 500;
  line-height: 24px;
  gap: 12px;
  padding-bottom: 20px;
  img {
    width: 25px;
    height: 25px;
  }
`;

const Container__Action = styled.div`
  display: flex;
  gap: 16px;
  font-weight: 500;
  line-height: 24px;
  @media (max-width: ${theme.media.mobile}) {
   flex-direction: column;
  }
`;

export const Container = {
	box: Container__Box,
	icon: Container__Icon,
	action: Container__Action,
	warning: Container__Warning,
	close: Container__Close
};
