import { useState } from 'react';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { LabelInputFormCampaign } from '../../../../../components/common/Input';
import { Container } from '../../styles';
import { PersonalData } from './components/PersonalData';
import { FormAdress } from './components/FormAdress';
import { NewPasswordForm } from './components/NewPasswordForm';

export function EditData() {
  const users = useGlobalStore();


  return (
    <Container.wrapper>
      <Container.head>
        <Container.subTitle data-test="text-email">Email Cadastrado</Container.subTitle>
      </Container.head>

      <Container.input>
        <LabelInputFormCampaign
          id="email"
          name="email"
          type="text"
          htmlFor="email"
          placeholder="Email"
          aria-labelledby="email"
          value={users.state.user?.email}
          disabled
          data-test="text-label-email"
        >
          Email
        </LabelInputFormCampaign>
      </Container.input>

      <Container.subTitle data-test="text-dados-pessoais">Dados Pessoais</Container.subTitle>
      <PersonalData />

      <Container.subTitle data-test="text-new-address">Endereço</Container.subTitle>
      <FormAdress />

      <Container.subTitle data-test="text-new-password">Nova Senha</Container.subTitle>
      <NewPasswordForm />

    </Container.wrapper>
  );
}


