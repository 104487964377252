import { HttpClient } from '../../HttpClient';

export interface CreateCoupon {
  quantity: number;
  code: string;
  description: string;
  goodersPrice: number;
  partnerId: number;
  expiresAt:  string;
  type: string;
  off: number;
  isLink: boolean;
}



export async function CreateCouponUseCase(dto: CreateCoupon) {
  const updatedDto = {
    ...dto,
    goodersPrice: dto.goodersPrice * 100,
  };
  const { error, payload } = await HttpClient<CreateCoupon, void>(
    'post', 
    '/api/rewards/coupons', updatedDto);
  if (!error && payload) {
    return {
      data: payload,
    };
  } else {
    return { error };
  }
}

