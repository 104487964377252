import { Container, Input, SearchIcon } from '../../styles'
import search_glass from '../../../../../../../assets/icons/search_glass.svg';
import filter from '../../../../../../../assets/icons/filter.svg';

interface SearchListCampaignProps {
    search: string;
    searchText: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clearFilters: () => void
}


export const SearchTableListUser: React.FC<SearchListCampaignProps> = ({
    search,
    searchText,
    clearFilters
}) => {
    return (
        <Container.search >
            <Input.label htmlFor="search-input" >
                <SearchIcon >
                    <img src={search_glass} alt="search" />
                </SearchIcon>
                <Input.field
                    id="search-input"
                    type="text"
                    placeholder="Pesquisar"
                    value={search}
                    onChange={searchText}
                    data-test="search-bar-input"
                />
            </Input.label>
            <Container.filterButton onClick={clearFilters}>
                <img src={filter} alt="filter" />
                Limpar
            </Container.filterButton>
        </Container.search>

    )
}