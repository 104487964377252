import { Container, Button } from '../../gooders/gerenciar-campanhas/styles';
import more from '../../../assets/icons/more.svg';
import { useState } from 'react';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { SmallBlueButton } from '../../../components/common/Button';
import { CampaignCreate } from '../../../components/shared/gerenciar-campanha/Create/index';
import { CampaignProvider } from '../../../../infra/config/CampaignContext';
import { CreateCampaignUseCase } from '../../../../application/useCases/campaign/CreateCampaignUseCase';
import { PatchImageForCampaignUseCase } from '../../../../application/useCases/campaign/images/PatchImageForCampaignUseCase';
import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { TableListCampaign } from '../../../components/shared/gerenciar-campanha/TableListCampaignOrg';
import { GetCampaignsLinkedWith } from '../../../../application/useCases/campaign/GetCampaignsLinkedWithOrg';
import { DeleteCampaignUseCase } from '../../../../application/useCases/campaign/OrgDeleteCampaign';

export const GerenciarCampanhas = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setLoading(true);
    setOpenModal(true);
    setLoading(false);
  };

  return (
    <CampaignProvider
      onClose={() => setOpenModal(false)}
      useCase1={new CreateCampaignUseCase(UserTypeEnum.ORG)}
      useCase2={new PatchImageForCampaignUseCase(UserTypeEnum.ORG)}
    >
      <LayoutSidebar>
        <Container.box>
          <Container.header>
            <Container.title data-test="title-campaign">Campanhas</Container.title>
            <Button>
              <SmallBlueButton onClick={handleButtonClick} loading={loading}>
                <img src={more} alt="more" />
                Criar Campanha
              </SmallBlueButton>
            </Button>
          </Container.header>
          <TableListCampaign
            useCase={new GetCampaignsLinkedWith(UserTypeEnum.ORG)}
            useCaseDelete={new DeleteCampaignUseCase(UserTypeEnum.ORG)}
          />
        </Container.box>
      </LayoutSidebar>
      <CampaignCreate open={openModal} onClose={() => setOpenModal(false)} />
    </CampaignProvider>
  );
};
