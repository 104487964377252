/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */

interface Props {
	active: boolean;
}
export const Hash = ({ active }: Props) => {

	if (active) return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.66732 3.99967H1.33398V13.333C1.33398 14.0663 1.93398 14.6663 2.66732 14.6663H12.0007V13.333H2.66732V3.99967ZM13.334 1.33301H5.33398C4.60065 1.33301 4.00065 1.93301 4.00065 2.66634V10.6663C4.00065 11.3997 4.60065 11.9997 5.33398 11.9997H13.334C14.0673 11.9997 14.6673 11.3997 14.6673 10.6663V2.66634C14.6673 1.93301 14.0673 1.33301 13.334 1.33301ZM13.334 10.6663H5.33398V2.66634H13.334V10.6663ZM8.66732 9.99967H10.0007V7.33301H12.6673V5.99967H10.0007V3.33301H8.66732V5.99967H6.00065V7.33301H8.66732V9.99967Z" fill="#fff" />
		</svg>
	);

	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.66732 3.99967H1.33398V13.333C1.33398 14.0663 1.93398 14.6663 2.66732 14.6663H12.0007V13.333H2.66732V3.99967ZM13.334 1.33301H5.33398C4.60065 1.33301 4.00065 1.93301 4.00065 2.66634V10.6663C4.00065 11.3997 4.60065 11.9997 5.33398 11.9997H13.334C14.0673 11.9997 14.6673 11.3997 14.6673 10.6663V2.66634C14.6673 1.93301 14.0673 1.33301 13.334 1.33301ZM13.334 10.6663H5.33398V2.66634H13.334V10.6663ZM8.66732 9.99967H10.0007V7.33301H12.6673V5.99967H10.0007V3.33301H8.66732V5.99967H6.00065V7.33301H8.66732V9.99967Z" fill="#1485C8" />
		</svg>

	);
};