import Table from '../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { Container } from './styles';
import { GridColDef } from '@mui/x-data-grid';
import update from '../../../../../assets/icons/update.svg'
import { SearchTableListUser } from './components/SearchTableListUser';
import { ListPartner, ListPatnerUseCase } from '../../../../../../application/useCases/gooders/ListPatnerUseCase';
import { ModalEditPartner } from './components/ModalEditPartner';
import delet from '../../../../../assets/icons/delet.svg'
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { DeletePartnerUseCase } from '../../../../../../application/useCases/gooders/DeletePartnerUseCase';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';
import { EditPartner } from '../../../../../../application/useCases/gooders/EditPatnerUseCase';


export const TableListPartner = () => {
	const [listPartner, setListPatner] = useState<ListPartner[]>([]);
	const { setAlert } = useAlert();
	const [search, setSearch] = useState('');
	const clearFilters = () => { setSearch(''); };
	const [selectedPartner, setSelectedPartner] = useState<EditPartner>();
	const [openModal, setOpenModal] = useState(false);
	const [partnerId, setPartnerId] = useState<number>(0);
	const [renderConfirmModal, setRenderConfirmModal] = useState(false);



	useEffect((
	) => {
		const fetchData = async () => {
			const { error, payload } = await ListPatnerUseCase();
			if (!error && payload) {
				setListPatner(payload.data);
			} else {
				setAlert(error!, 'error');
			}
		}
		fetchData();
	}, [])


	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Parceiro', minWidth: 216, flex: 1, headerClassName: 'table-header', },
		{ field: 'cnpj', headerName: 'CNPJ', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
		{ field: 'contact', headerName: 'Contato', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
		{
			field: 'Ações', headerName: 'Ações', minWidth: 50, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				return (
					<>
						<img
							onClick={() => {
								setPartnerId(params.row.id);
								setSelectedPartner(params.row.id);
								setOpenModal(true);
							}}
							src={update}
							alt="action"
							style={{ cursor: 'pointer', paddingRight: "16px" }}
						/>
						<img
							onClick={() => {
								handleConfirmModal()
								setPartnerId(params.row.id);
							}}
							src={delet}
							alt="action"
							style={{ cursor: 'pointer', }}
						/>
					</>
				)
			},
			sortable: false,
			filterable: false,
		},
	];

	const rows =
		listPartner?.map((partner, index) => ({
			index: index,
			id: partner.id,
			name: partner.name,
			cnpj: partner.cnpj,
			contact: partner.contact,
		})) ?? [];

	const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const filterRows = (search: string) => {
		let filteredRows = rows;
		if (search !== '') {
			const searchTerm = search.toLowerCase();
			filteredRows = filteredRows.filter((row) => {
				const name = row['name'].toLowerCase();
				const document = row['cnpj'].toLowerCase();
				return name.includes(searchTerm) || document.includes(searchTerm);
			});
		}
		return filteredRows;
	};

	const filteredRows = filterRows(search);

	function handleConfirmModal() {
		setRenderConfirmModal(true);
	}

	async function handleCloseConfirmModal(result: boolean) {
		setRenderConfirmModal(false);
		if (result) {
			const { error } = await DeletePartnerUseCase(partnerId);
			if (error) {
				setAlert(error, 'error');
			} else {
				setAlert('Parceiro deletado com sucesso!', 'success');
			}
		}
	}

	function IconEdit(id: number) {
		setOpenModal(true);
		setPartnerId(id);
		setSelectedPartner(id as any);
	}

	function IconDelete(id: number) {
		handleConfirmModal()
		setPartnerId(id);
	}

	const cardData: CardData[] = filteredRows.map((partner) => {
		return {
			id: partner.id,
			title: [
				'Name',
				'Cnpj',
				'Contato',
			],
			value: [
				partner.name,
				partner.cnpj,
				partner.contact,
			],
		};
	});


	return (
		<>
			<Container.main>
				<Container.filter>
					<SearchTableListUser search={search} searchText={searchText} clearFilters={clearFilters} />
				</Container.filter>
				<Container.table>
					<Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
				</Container.table>
				<Container.mobile>
					<CardsTable cardData={cardData} IconEdit={IconEdit} IconDelete={IconDelete} />
				</Container.mobile>
				{openModal && selectedPartner && (
					<ModalEditPartner
						onClose={() => setOpenModal(false)}
						selectedPartner={listPartner}
						partnerId={partnerId}
					/>
				)}
				{renderConfirmModal && (
					<ModalConfirm
						title="Excluir Parceiro"
						content="Deseja continuar? Ao clicar em deletar seu parceiro será excluído permanentemente!"
						rightButtonText="Deletar"
						onClose={handleCloseConfirmModal}

					/>
				)}
			</Container.main>
		</>
	);
};
