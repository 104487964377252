import { Container } from '../../gooders/editar-campanha/styles';
import { Link, useNavigate } from 'react-router-dom';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { InformationEdit } from '../../../components/shared/editar-campanha/Information';
import { VoluntaryEdit } from '../../../components/shared/editar-campanha/Voluntary';
import { ImageCauseEdit } from '../../../components/shared/editar-campanha/Images';
import { useGlobalCampaignUnique } from '../../../../infra/state/useCampaign';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { useEffect, useState } from 'react';
import { SmallBlueButton } from '../../../components/common/Button';
import { ROUTES_PATH } from '../../../../infra/config/routes';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { GetCampaignForInstitutionEdit } from '../../../../application/useCases/campaign/GetCampaignForOrgEdit';
import { UniqueCampaignOutput } from '../../../../application/useCases/campaign/GetCampaign';
import { DonationItemsEdit } from '../../../components/shared/editar-campanha/Items';
import { CreateItemForCampaignUseCases } from '../../../../application/useCases/campaign/item/CreateItemForCampaignUseCase';
import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { UpdateCampaignInformationUseCase } from '../../../../application/useCases/campaign/UpdateCampaignInformation';
import { CreateVacancyForCampaignUseCase } from '../../../../application/useCases/campaign/vacancy/CreateVacancyForCampaignUseCase';
import { CreateImageForCampaignUseCase } from '../../../../application/useCases/campaign/images/CreateImageForCampaignUseCase';
import { DeleteImageOfCampaignUseCase } from '../../../../application/useCases/campaign/images/DeleteImageOfCampaignUseCase';
import { DeleteVacancyFromCampaignUseCase } from '../../../../application/useCases/campaign/vacancy/DeleteVacancyFromCampaignUseCase';
import { UpdateActiveStatusOfCampaignUseCase } from '../../../../application/useCases/org/campaign/OrgUpdateActiveStatusOfCampaign';
import { UpdateDonationMoneyForCampaignUseCase } from '../../../../application/useCases/campaign/donation/UpdateDonationMoneyForCampaignUseCase';
import { DonationPayEdit } from '../../../components/shared/editar-campanha/Donation';
import { DeleteItemFromCampaignUseCase } from '../../../../application/useCases/campaign/item/DeleteItemFromCampaignUseCase';
import { FinishCampaignGoalUseCase } from '../../../../application/useCases/campaign/donation/FinishCampaignGoalUseCase';
import { UpdateActiveDonationUseCase } from '../../../../application/useCases/campaign/donation/UpdateActiveDonationUseCase';
import { ActiveItemUseCase } from '../../../../application/useCases/campaign/item/ActiveItemUseCase';
import { ActiveVacancyUseCase } from '../../../../application/useCases/campaign/vacancy/ActiveVacancyUseCase';

export interface Props {
  cause: UniqueCampaignOutput;
}

export const InstitutionEditarCampanha: React.FC = () => {
  const { setCampaignUnique } = useGlobalCampaignUnique();
  const {
    state: { acl },
  } = useGlobalStore();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const urlQuerys = new URLSearchParams(window.location.search);
  const { setAlert } = useAlert();
  const handleClick = () => {
    setloading(true);
    navigate(ROUTES_PATH.manageCampaign);
    setloading(false);
  };

  useEffect(() => {
    try {
      const campaignId = Number(urlQuerys.get('c'));
      const institutionId = Number(urlQuerys.get('i'));

      const fetchData = async () => {
        const { error, payload } = await GetCampaignForInstitutionEdit(institutionId, campaignId);

        if (!error && payload?.data) {
          setCampaignUnique(payload.data);
        } else if (!error && !payload?.data) {
          setAlert('Campanha não encontrada', 'error');
        } else {
          setAlert(error!, 'error');
        }
      };
      fetchData();
    } catch (err) {
      navigate('/home');
    }
  }, []);

  return (
    <LayoutSidebar>
      <Container.box>
        <Container.header>
          <Container.title>
            <p>Campanhas / Alterar Campanha</p>
            <h1>Alterar campanha: Ajudando Vidas</h1>
          </Container.title>
          <Link to={ROUTES_PATH.manageCampaign}>
            <SmallBlueButton onClick={handleClick} loading={loading}>
              Voltar
            </SmallBlueButton>
          </Link>
        </Container.header>
        <Container.components>
          <InformationEdit
            useCase1={new UpdateCampaignInformationUseCase(UserTypeEnum.INSTITUTION)}
            useCase2={new UpdateActiveStatusOfCampaignUseCase(UserTypeEnum.INSTITUTION)}
          />
        </Container.components>
        <Container.components>
          <DonationPayEdit
            useCase={new UpdateDonationMoneyForCampaignUseCase(UserTypeEnum.INSTITUTION)}
          />
        </Container.components>

        <Container.components>
          <DonationItemsEdit
            useCase={new CreateItemForCampaignUseCases(UserTypeEnum.INSTITUTION)}
            useCase2={new DeleteItemFromCampaignUseCase(UserTypeEnum.INSTITUTION)}
            useCase3={new ActiveItemUseCase(UserTypeEnum.INSTITUTION)}
          />
        </Container.components>
        <Container.components>
          <VoluntaryEdit
            useCase1={new CreateVacancyForCampaignUseCase(UserTypeEnum.INSTITUTION)}
            useCase2={new DeleteVacancyFromCampaignUseCase(UserTypeEnum.INSTITUTION)}
            useCase3={new ActiveVacancyUseCase(UserTypeEnum.INSTITUTION)}
          />
        </Container.components>
        <Container.components>
          <ImageCauseEdit
            useCase1={new CreateImageForCampaignUseCase(UserTypeEnum.INSTITUTION)}
            useCase2={new DeleteImageOfCampaignUseCase(UserTypeEnum.INSTITUTION)}
          />
        </Container.components>
      </Container.box>
    </LayoutSidebar>
  );
};
