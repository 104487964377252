import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

const Container__Auth = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  p {
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.sm};
    line-height: 20px;
    color: ${(props) => props.theme.colors.primary};
  }
  span {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize.sm};
    line-height: 20px;
    color: ${(props) => props.theme.colors.dark};
    padding: 16px;
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    width: 100%;
  }
`;

const Container__Password = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  font-size: ${(props) => props.theme.fontSize.sm};
  line-height: 18px;
  padding-bottom: 16px;
  label {
    display: flex;
    justify-content: center;
    span {
      padding: 0 8px;
    }
    input {
      transform: scale(1.5);
    }
  }
  p {
    border: none;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.white};
    font-size: 12px;
    text-decoration: underline 1px solid ${(props) => props.theme.colors.primary};
  }
`;

const Container__Section = styled.section`
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  gap: 8px;
  color: ${(props) => props.theme.colors.paleBlue};
  p {
    font-size: ${(props) => props.theme.fontSize.sm};
  }
  span {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize.sm};
    text-decoration: underline 1px ${(props) => props.theme.colors.primary};
    cursor: pointer;
    padding: 0;
  }
  /* @media (max-width: ${theme.media.monitor}) {
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    p {
      font-size: ${(props) => props.theme.fontSize.sm};
    }
    span {
      padding: 0;
    }
  } */
`;

export const Container = {
  auth: Container__Auth,
  section: Container__Section,
  password: Container__Password,
};
