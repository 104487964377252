import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 100px 0;
`;

const Container__Cards = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0px 25px;
  @media (max-width: ${theme.media.laptop}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Container__Card = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const Container__Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0px 25px;
`;

const Container__Graphic = styled.div`
  display: flex;
  max-width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Container = {
  main: Container__Main,
  cards: Container__Cards,
  card: Container__Card,
  table: Container__Table,
  graphic: Container__Graphic,
};
