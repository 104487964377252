import { Container, Line } from '../../LayoutModal/styles';
import { useState } from 'react';
import { useGlobalCampaignUnique } from '../../../../../../../infra/state/useCampaign';
import { useFormik } from 'formik';
import { useAlert } from '../../../../../../../infra/config/AlertContext/useAlert';
import * as Yup from 'yup';
import { SelectInput } from '../../../../SelectInput';
import { BlueButton } from '../../../../Button';
import {
  CreateVacancieOrderDto,
  UserSubscribeInVacancy,
} from '../../../../../../../application/useCases/donation/UserSubscribeInVacancy';
import { MyDate } from '../../../../../../../application/utils';
import { VacanciesOutput } from '../../../../../../../application/useCases/campaign/GetCampaign';
import { ModalConfirm } from '../../../../ModalConfirm';
import SucessVoluntary from './components';

export const validationSchema = Yup.object().shape({
  vacancieId: Yup.string().required('É necessário selecionar uma vaga!'),
});

interface Props {
  onClose: () => void;
}

export const ModalVoluntary: React.FC<Props> = ({ onClose }) => {
  const { campaignUnique } = useGlobalCampaignUnique();
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<VacanciesOutput | null>(null);
  const [renderConfirmModal, setRenderConfirmModal] = useState(false);
  const [returnVacancie, setReturnVacancie] = useState(false);

  const formik = useFormik({
    initialValues: { quantityHours: '', vacancieId: '' },
    validationSchema,
    onSubmit: async (values) => {
      if (!campaignUnique) {
        setAlert('Não foi possível se candidatar para essa vaga no momento!', 'error');
        return;
      }

      const dto: CreateVacancieOrderDto = {
        campaignId: campaignUnique.id,
        quantityHours: Number(values.quantityHours),
        vacancieId: Number(values.vacancieId),
      };

      const { error } = await UserSubscribeInVacancy(dto);
      if (!error) {
        setAlert('Cadastro realizado com sucesso!', 'success');
        setReturnVacancie(true)
      } else {
        setAlert(error, 'error');
      }
    },
  });

  function handleChangeVacancieId(ev: React.ChangeEvent<HTMLSelectElement>) {
    formik.handleChange(ev);

    if (!ev.target.value) {
      setSelectedItem(null);
    }
    campaignUnique?.vacancies.forEach((vc, index) => {
      if (vc.id === Number(ev.target.value)) {
        setSelectedItem(campaignUnique.vacancies[index]);
      }
    });
  }

  function handleBlurVacancieId(ev: React.FocusEvent<HTMLSelectElement>) {
    formik.handleBlur(ev);
  }

  function handleConfirmModal() {
    setRenderConfirmModal(true);
  }

  function handleCloseConfirmModal(result: boolean) {
    setRenderConfirmModal(false);

    if (result) {
      formik.submitForm();
    }
  }
  return (
    <>
      {returnVacancie ? (
        <SucessVoluntary onClose={onClose} />
      ) : (
        <>
          <Container.Input>
            <Container.Text>
              <SelectInput
                htmlFor="vacancieId"
                name="vacancieId"
                id="vacancieId"
                value={formik.values.vacancieId}
                onChange={handleChangeVacancieId}
                onBlur={handleBlurVacancieId}
                required
                options={[
                  { value: '', label: '' },
                  ...(campaignUnique?.vacancies?.map((vacancie) => ({
                    value: vacancie.id.toString(),
                    label: vacancie.title,
                  })) || []),
                ]}
              >
                Vagas
              </SelectInput>
              {formik.touched.vacancieId && formik.errors.vacancieId && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.vacancieId}</span>
              )}
              {selectedItem && (
                <Container.Address style={{ width: '100%', paddingTop: '16px', paddingBottom: '16px' }}>
                  <span>Informações </span>
                  Endereço: {selectedItem.address} ,
                  <p>
                    {selectedItem.city}, {selectedItem.state}
                  </p>
                  <Line className="line" />
                  Data de início: {MyDate.formatISOString(selectedItem.startDate)}
                  {selectedItem.endDate && (
                    <p>Data de fim: {MyDate.formatISOString(selectedItem.endDate)}</p>
                  )}
                  <p>
                    Hora: {selectedItem.startHour} às {selectedItem.endHour}{' '}
                  </p>
                  <Line className="line" />
                  Vagas: {selectedItem.quantity}
                  <p>Voluntários Inscritos: {selectedItem.subscribersCount} </p>
                </Container.Address>
              )}
            </Container.Text>
            <Container.Text>
              {selectedItem && (
                <Container.Address >
                  <span>Descrição </span>
                  {selectedItem.description},
                </Container.Address>
              )}
            </Container.Text>
          </Container.Input>
          <BlueButton type="submit" onClick={handleConfirmModal} loading={loading}>
            Finalizar Inscrição
          </BlueButton>
          {renderConfirmModal && (
            <ModalConfirm
              title="Termos do uso de imagem"
              content="Ao clicar em 'Finalizar Inscrição', você concorda com os nossos termos, deseja continuar?"
              rightButtonText="Finalizar Inscrição"
              onClose={handleCloseConfirmModal}
              linkText="Clique para visualizar: Lei do Serviço Voluntário "
              linkText2="Clique para visualizar: Termos de Adesão"

            />
          )}
        </>
      )}
    </>
  );
};
