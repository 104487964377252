import styled from 'styled-components';
import { theme } from '../../../../themes/blue';


const Container__Modal = styled.section`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 12px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.black};
  z-index: 999;
  @media (max-width: ${theme.media.laptop}) {
    width: 100%;
    padding-top: 0px;
  }
`;

const Container__Form = styled.div`
  position: relative;
  width: 70%;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 16px rgba(73, 80, 87, 0.32);
  border-radius: 8px;
  padding: 0 24px 12px;
  @media (max-width: ${theme.media.laptop}) {
    min-height: 100vh;
    width: 100%;
  }
`;

const Container__Progress = styled.div`
  width: 100%;
  position: relative;
`;

const Container__Cause = styled.div``;

const Container__Footer = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100%);
  border-top: 2px solid ${(props) => props.theme.colors.lightgray};
  margin: 8px 0 0;
  padding-top: 16px;
  gap: 8px;
  button {
    padding: 4px 8px;
    background: ${(props) => props.theme.colors.white};
    width: 225px;
    height: 32px;
    border: 1px solid ${(props) => props.theme.colors.paleGray};
    border-radius: 4px;
    cursor: pointer;
  }
  .next-button-blue {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
  .previous-button-border {
    border: 1px solid ${(props) => props.theme.colors.primary};
    margin-right: 8px;
    color: ${(props) => props.theme.colors.primary};
  }
  .last-button {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;

const Container__ProgressBar = styled.div`
  padding: 12px 50px;
  display: flex;
  justify-content: space-around;
  @media (max-width: ${theme.media.laptop}) {
    span {
      display: none;
    }
  }
`;

const Container__Img = styled.div`
  display: flex;
  width: 225px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  img {
    width: 30px;
    height: 30px;
    background: ${(props) => props.theme.colors.primary};
    padding: 9px;
    border-radius: 50px;
    margin: 2px;
    z-index: 2;
    cursor: pointer;
  }
  span {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize.md};
    line-height: 22px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Container = {
  modal: Container__Modal,
  form: Container__Form,
  progress: Container__Progress,
  cause: Container__Cause,
  footer: Container__Footer,
  progressBar: Container__ProgressBar,
  img: Container__Img,
};

export const Line = styled.div`
  position: absolute;
  height: 4px;
  width: 70%;
  background: ${(props) => props.theme.colors.primary};
  opacity: 0.2;
  top: 40px;
`;

export const CloseIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
`;
