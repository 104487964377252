
import { Container, CloseIcon, Text } from './styles';
import close from '../../../../../assets/icons/close.svg';
import { SelectInput } from '../../../../../components/common/SelectInput';
import { LabelInputFormCampaign } from '../../../../../components/common/Input';
import { TextArea } from '../../../../../components/common/TextArea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SmallBlueButton } from '../../../../../components/common/Button';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { ListPartner, ListPatnerUseCase } from '../../../../../../application/useCases/gooders/ListPatnerUseCase';
import { CreateCouponUseCase } from '../../../../../../application/useCases/gooders/CreateCouponUseCase';

enum RewardType {
  PERCENTAGE = 'PERCENTAGE',
  MONEY = 'MONEY',
  LINK = 'LINK',
}


export const validationSchema = Yup.object().shape({
  partnerId: Yup.string().required('Selecione um parceiro'),
  quantity: Yup.string().required('Insira a quantidade')
    .matches(/^[0-9]+$/, 'Insira um número válido')
    .min(1, 'A quantidade deve ser maior ou igual a 1'),
  type: Yup.string().required('Selecione a categoria').oneOf([
    RewardType.PERCENTAGE,
    RewardType.MONEY,
  ],
    'Selecione uma opção válida'
  ),
  off: Yup.string().required('Insira o valor').matches(/^[0-9]+$/, 'Insira um número válido'),
  code: Yup.string().required('Insira um link válido'),
  expiresAt: Yup.string().required('Seleciona uma data'),
  description: Yup.string().required('Informe a descrição da sua recompensa').max(255, 'Limite de caracteres excedido'),

});

export interface ModalIconProps {
  open: boolean;
  onClose: () => void;
}


export const CreateRewards = ({ open, onClose }: ModalIconProps) => {
  if (!open) return null;

  const { setAlert } = useAlert();
  const [loading, setloading] = useState(false);
  const [listPatner, setListPatner] = useState<ListPartner[]>([]);
  const defaultOption = { value: '', label: 'Selecione um parceiro', disabled: true, selected: true };


  const formik = useFormik({
    initialValues: {
      partnerId: '',
      quantity: '',
      off: '',
      type: '',
      code: '',
      expiresAt: '',
      description: '',
      goodersPrice: '',
      isLink: false
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setloading(true);
      const expiresAt = new Date(values.expiresAt);
      const isoExpiresAt = expiresAt.toISOString();
      const convertedValues = {
        ...values,
        quantity: Number(values.quantity),
        goodersPrice: Number(values.goodersPrice),
        partnerId: Number(values.partnerId),
        off: Number(values.off),
        expiresAt: isoExpiresAt,
      };

      const { error } = await CreateCouponUseCase(convertedValues);
      setloading(false);
      if (!error) {
        setAlert('Cupom criado com sucesso!', 'success');
        resetForm();
      } else {
        setAlert(error, 'error');
      }
    },
  });

  useEffect((
  ) => {
    const fetchData = async () => {
      const { error, payload } = await ListPatnerUseCase();
      if (!error && payload) {
        setListPatner(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    }
    fetchData();
  }, [])

  return (
    <Container.modal>
      <Container.form onSubmit={formik.handleSubmit}>
        <Container.progress>
          <CloseIcon src={close} alt="close" onClick={onClose} />
        </Container.progress>
        <Container.main>
          <Text.title>Cadastro de Recompensas</Text.title>
          <Container.input>
            <Container.header>
              < SelectInput
                htmlFor="partnerId"
                id="partnerId"
                name="partnerId"
                value={formik.values.partnerId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  defaultOption,
                  ...listPatner.map((patner) => ({
                    value: String(patner.id),
                    label: patner.name,
                  })),
                ]}
                error={formik.touched.partnerId && formik.errors.partnerId ? formik.errors.partnerId : undefined}>
                Parceiros
              </SelectInput>
              <LabelInputFormCampaign
                htmlFor="quantity"
                id="quantity"
                name="quantity"
                type="number"
                placeholder=""
                value={formik.values.quantity}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                aria-labelledby="quantity"
                error={formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : undefined}>
                Quantidade
              </LabelInputFormCampaign>
            </Container.header>
            <Container.inputs>
              <SelectInput
                htmlFor="type"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  defaultOption,
                  { value: RewardType.PERCENTAGE, label: 'Descontos em porcentagem' },
                  { value: RewardType.MONEY, label: 'Descontos em valores' },
                ]}
                error={formik.touched.type && formik.errors.type ? formik.errors.type : undefined}>
                Categoria
              </SelectInput>
              <LabelInputFormCampaign
                htmlFor="off"
                id="off"
                name="off"
                type="number"
                placeholder=""
                value={formik.values.off}
                onChange={formik.handleChange}
                aria-labelledby="Título"
                onBlur={formik.handleBlur}
                error={formik.touched.off && formik.errors.off ? formik.errors.off : undefined}
              >
                Valor de desconto
              </LabelInputFormCampaign><LabelInputFormCampaign
                htmlFor="goodersPrice"
                id="goodersPrice"
                name="goodersPrice"
                type="number"
                placeholder=""
                value={formik.values.goodersPrice}
                onChange={formik.handleChange}
                aria-labelledby="Título"
                onBlur={formik.handleBlur}
                error={formik.touched.goodersPrice && formik.errors.goodersPrice ? formik.errors.goodersPrice : undefined}>
                Preço em Gooders
              </LabelInputFormCampaign>
            </Container.inputs>
            <Container.inputs>
              <Container.checkbox>
                <label htmlFor="code">
                  <input type="checkbox"
                    id="code"
                    checked={formik.values.isLink}
                    onChange={(e) => formik.setFieldValue('isLink', e.target.checked)} />
                  <p>É um link?</p>
                </label>
              </Container.checkbox>
              <LabelInputFormCampaign
                htmlFor="code"
                id="code"
                name="code"
                type="text"
                placeholder=""
                value={formik.values.code}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                aria-labelledby="Descrição do projeto"
                error={formik.touched.code && formik.errors.code ? formik.errors.code : undefined}>
                Link ou código do cupom
              </LabelInputFormCampaign>
              <LabelInputFormCampaign
                htmlFor="expiresAt"
                id="expiresAt"
                name="expiresAt"
                type="date"
                placeholder=""
                value={formik.values.expiresAt}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                aria-labelledby="Descrição do projeto"
                error={formik.touched.expiresAt && formik.errors.expiresAt ? formik.errors.expiresAt : undefined}>
                Validade
              </LabelInputFormCampaign>
            </Container.inputs>
            <Container.textarea>
              <TextArea
                htmlFor="description"
                id="description"
                name="description"
                rows={3}
                maxLength={255}
                placeholder=""
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-labelledby="Descrição do projeto"
                error={formik.touched.description && formik.errors.description ? formik.errors.description : undefined}>
                Descrição
              </TextArea>
            </Container.textarea>
            <Container.inputs>
            </Container.inputs>
          </Container.input>
        </Container.main >
        <Container.footer>
          <SmallBlueButton type="submit" loading={loading}>Concluir</SmallBlueButton>
        </Container.footer>
      </Container.form>
    </Container.modal>
  );
};
