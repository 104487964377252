import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

const Container__Page = styled.div`
  padding-bottom: 50px;
`;

const Container__Cards = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 8px;
  justify-items: center;
`;

const Container__Header = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.media.monitor}) {
    grid-template-columns: 3fr 1fr;
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    grid-template-columns: 3fr 1fr;
  }
  @media (max-width: ${theme.media.laptop}) {
    grid-template-columns: 1fr;
  }
`;

export const Container = {
  Page: Container__Page,
  Cards: Container__Cards,
  Header: Container__Header,
};
