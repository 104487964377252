import { Container } from './styles';
import { Link } from 'react-router-dom';
import { LayoutModal } from './component/LayoutModal';
import { useState } from 'react';
import location from '../../../assets/icons/location.svg';
import { GetCampaign } from '../../../../application/useCases/campaign/GetCampaign';
import { CampaignArrayOutput } from '../../../../application/useCases/campaign/GetListCamapign';
import { useGlobalCampaignUnique } from '../../../../infra/state/useCampaign';
import doe_money from '../../../assets/icons/doe_money.svg';
import doe_items from '../../../assets/icons/doe_items.svg';
import doe_voluntary from '../../../assets/icons/doe_voluntary.svg';

interface Props {
  cause: CampaignArrayOutput;
}

export const Campaign = ({ cause }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const state = useGlobalCampaignUnique();

  const handleClickApoiarCausa = async () => {
    const { payload, error } = await GetCampaign(cause?.id?.toString() || '');
    if (!error && payload) {
      state.setCampaignUnique(payload.data);
    }
    setOpenModal(true);
  };
  return (
    <Container.Box>
      <Container.Cards onClick={() => cause?.category}>
        <Link to={`/campanhas/${cause?.id}`}>
          <Container.Image>
            <img src={cause?.image} alt="cause" />
          </Container.Image>
        </Link>

        <Container.Text>
          <h3 data-test="card-title-h3">
            {cause?.title && cause?.title.length > 30
              ? cause?.title.substring(0, 30) + '...'
              : cause?.title}
          </h3>
          <Container.Location>
            <img src={location} alt="cidade" />
            <span data-test="card-city-span">
              {cause?.city.length > 15 ? `${cause.city.slice(0, 15)}...` : cause?.city} -{' '}
            </span>
            <span data-test="card-state-span">{cause?.state}</span>
          </Container.Location>
          <p data-test="card-description-p">
            {cause?.summary && cause?.summary?.length > 255
              ? cause?.summary.substring(0, 255) + '...'
              : cause?.summary}
          </p>
        </Container.Text>
        <Container.Content>
          <Container.Icons>
            {cause?.donations.money ? <img src={doe_money} alt="doe_money" /> : null}
            {cause?.donations.items ? <img src={doe_items} alt="doe_items" /> : null}
            {cause?.donations.voluntary ? <img src={doe_voluntary} alt="doe_voluntary" /> : null}
          </Container.Icons>
        </Container.Content>
        <Container.Progress>
          <span>{cause.progress ?? 0}%</span>
          <div className="progress">
            <div className="barprogress" style={{ width: `${cause.progress}%`, maxWidth: '100%' }}></div>
          </div>
        </Container.Progress>
        <Container.Footer>
          <Container.Button data-test="btn-apoiar-cause" onClick={handleClickApoiarCausa}>
            Apoiar Causa
          </Container.Button>
        </Container.Footer>
        <LayoutModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          selectedCause={state.campaignUnique?.id || null}
        />
      </Container.Cards>
    </Container.Box>
  );
};