import { GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../../../components/common/Table';
import { Container } from '../../../../styles';
import { useGlobalStore } from '../../../../../../../../infra/state/GlobalStore';
import { CardData, CardsTable } from '../../../../../../../components/common/CardsTable';

const columns: GridColDef[] = [
    { field: 'Organizações:', headerName: 'Organizações:', width: 996, flex: 1, headerClassName: 'table-header' },
];

export default function TableOrg() {

    const users = useGlobalStore();
    const rows = (users.state.user?.acls.orgs.map((org, index) => ({
        index: index,
        id: index,
        'Organizações:': org.name,
    })) || [])
        .sort((a, b) => a['Organizações:'].localeCompare(b['Organizações:']));

    const cardData: CardData[] = (users.state.user?.acls.orgs ?? []).map((org, index) => ({
        id: index,
        title: [
            'Organizações'
        ],
        value: [
            org.name
        ]
    }));
    return (
        <Container.table>
            {rows.length === 0 ? (
                <p>Você não possui organizações cadastradas.</p>
            ) : (
                <>
                    <Container.tables>
                        <Table rows={rows} columns={columns} menuIconDisplay='flex' numberRows={5} />
                    </Container.tables>
                    <Container.mobile>
                        <CardsTable cardData={cardData} />
                    </Container.mobile>
                </>
            )}
        </Container.table>
    );
}