import { HttpClient } from "../../HttpClient";


export async function DeleteMemberInstUseCase(institutionId: number, memberinstitutionId: number,)
{
  return await HttpClient(
    'delete',
    `/users/institutions/${institutionId}/members/${memberinstitutionId}`
    );
}
