import { useEffect, useState } from "react";
import { Image } from "./styles";

interface ImageCampaignProps {
	srcOfImages: string[];
}

export const ImageCampaign: React.FC<ImageCampaignProps> = ({ srcOfImages }) => {

	const [srcOfPrincipalImage, setSRCPrincipalImage] = useState(srcOfImages[0]);

	useEffect(() => {
		if (srcOfImages.length > 0) {
			setSRCPrincipalImage(srcOfImages[0]);
		}
	}, [srcOfImages]);

	function handleChangePrincipalImage(indexOfImage: number) {
		setSRCPrincipalImage(srcOfImages[indexOfImage]);
	}

	return (
		<Image.Campaign>
			<Image.Cards>
				<img src={srcOfPrincipalImage}
					alt="campaign"
				/>
			</Image.Cards>
			<Image.Small  >
				{srcOfImages?.map((data, i) =>
					<img
						key={i}
						src={data}
						onClick={() => handleChangePrincipalImage(i)}
					/>
				)}
			</Image.Small>
		</Image.Campaign>
	);
};
