import { HttpClient } from "../../HttpClient";

export interface ListInstituion {
  id: number;
  name: string;
  instId: number,
}

  export async function ListInstitutionUseCase(instId: number) {
    const response = await HttpClient<void, { data: ListInstituion[] }>('get', `/campaigns/org/${instId}`);
    return response;
  }

  export async function ListInstitutionGoodersUseCase() {
    const response = await HttpClient<void, { data: ListInstituion[] }>('get', `/campaigns/gooders/institutions`);
    return response;
  }

