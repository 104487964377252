import { LoginUserDto } from '../../../domain/dto/LoginUserDto';
import { HttpClient } from '../../HttpClient';

interface LoginDto {
  email: string;
  password: string;
}

interface LoginPayload {
  data: {
    accessToken: string;
    refreshToken: string;
  };
}
export async function LoginUserUseCase(dto: LoginUserDto) {
  const { payload, error } = await HttpClient<LoginDto, LoginPayload>('post', '/auth', dto);

  if (!error && payload) {
    localStorage.setItem('accessToken', payload.data.accessToken);
    localStorage.setItem('refreshToken', payload.data.refreshToken);
    return { error, payload };
  } else {
    return { error, payload };
  }
}
