import { useGlobalStore } from '../../../infra/state/GlobalStore';
import { HttpClient } from '../../HttpClient';

export interface CreateInstitution {
  name: string;
  document: string;
  telephone: string;
}


export async function CreateInstOrgUseCase(dto: CreateInstitution, orgId?: number) {
  const { error, payload } = await HttpClient<CreateInstitution, void>(
    'post', 
    `/users/orgs/${orgId}/institutions`, 
    dto);
  if (!error && payload) {
    return {
      data: payload,
    };
  } else {
    return { error };
  }
}
