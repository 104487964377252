import styled from "styled-components";

const Container__Main = styled.section`
  width: 100%;
  flex-flow: column;
  align-items: center;
  text-align: center;
  .error {
    color: ${props => props.theme.colors.red};
    font-size: 10px;
    line-height: 18px;
    text-align: start;
  }
`;

const Container__Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-bottom: 8px;
`;


export const Container = {
	main: Container__Main,
	form: Container__Form,
};
