import { HttpClient } from '../../HttpClient';

export enum PaymentMethodEnum {
  CREDIT_CARD = 'CREDIT_CARD',
  BOLETO = 'BOLETO',
  PIX = 'PIX',
}

export interface CreatePaymentOrderDto {
  paymentMethod: string;
  amount: number;
  billingAddress: {
    city: string;
    country: string;
    number: string;
    street: string;
    state: string;
    zipCode: string;
  };
  completeUserPaymentData: {
    document: string;
    anonymous: boolean;
    telephone: {
      countryCode: number;
      number: number;
      areaCode: number;
    };
  };
  creditCard?: {
    number: string;
    holderName: string;
    expMonth: number;
    expYear: number;
    cvv: string;
  };
}

export interface CreatePaymentOrderPayloadPix {
  qrCodeImage: string;
  qrCodePaymentLink: string;
}

export interface CreatePaymentOrderPayloadBoleto {
  barcode: string;
  urlPdf: string;
}

export type CreatePaymentOrderPayload =
  | CreatePaymentOrderPayloadPix // 'string | caso meio de pagamento seja boleto, será retornado esse campo';
  | CreatePaymentOrderPayloadBoleto; // 'string | caso meio de pagamento seja pix, será retornado esse campo';

export async function CreatePaymentOrderUseCase(preOrderId: number, dto: CreatePaymentOrderDto) {
  const response = await HttpClient<CreatePaymentOrderDto, { data: CreatePaymentOrderPayload }>(
    'post',
    `/api/checkout/orders/${preOrderId}`,
    dto
  );
  return response;
}
