import React, { ReactNode, useState } from 'react';
import { useAlert } from '../AlertContext/useAlert';
import { AlertType } from '../AlertContext';
import { DataPerson } from '../../../ui/pages/users/checkOutPay/components/CheckOut/components/DataPerson';
import { Address } from '../../../ui/pages/users/checkOutPay/components/CheckOut/components/Address';
import { CardData } from '../../../ui/pages/users/checkOutPay/components/CheckOut/components/DataMethod';
import {
  CreatePaymentOrderPayload,
  CreatePaymentOrderUseCase,
  PaymentMethodEnum,
} from '../../../application/useCases/checkout/CreatePaymentOrder';

interface CheckoutContextType {
  setAlert: (text: string, type: AlertType) => void;
  SubmitForm: () => void;
  paymentMethod: PaymentMethodEnum | null;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodEnum | null>>;
  dataMethod: CardData;
  setDataMethod: (dataMethod: CardData) => void;
  dataPerson: DataPerson;
  setDataPerson: (dataPerson: DataPerson) => void;
  address: Address;
  setAddress: (address: Address) => void;
  activeComponentIndex: number;
  setActiveComponentIndex: React.Dispatch<React.SetStateAction<number>>;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  preOrderId: number;
  setPreOrderId: React.Dispatch<React.SetStateAction<number>>;
  renderPaymentComponent: {
    render: boolean;
    data: CreatePaymentOrderPayload | null;
  };
}

export const CheckOutContext = React.createContext<CheckoutContextType>({} as CheckoutContextType);

interface Props {
  children: ReactNode;
}

export const CheckOutProvider = ({ children }: Props) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEnum | null>(null);
  const [amount, setAmount] = useState(0);
  const [preOrderId, setPreOrderId] = useState(0);
  const [dataMethod, setDataMethod] = useState({} as CardData);
  const [dataPerson, setDataPerson] = useState({} as DataPerson);
  const [address, setAddress] = useState({} as Address);
  const [activeComponentIndex, setActiveComponentIndex] = useState(0);
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [renderPaymentComponent, setRenderPaymentComponent] = useState({
    render: false,
    data: null as CreatePaymentOrderPayload | null,
  });
  const resetForm = () => {
    setDataMethod({} as CardData);
    setDataPerson({} as DataPerson);
    setAddress({} as Address);
  };

  async function SubmitForm() {
    setLoading(true);

    if (!paymentMethod) {
      setAlert('Selecione o método de pagamento!', 'warning');
      return;
    }
    if (!dataMethod) {
      setAlert('Preencha todos os dados do seu cartão de crédito!', 'warning');
      return;
    }
    if (!dataPerson) {
      setAlert('Verifique se todos os dados pessoais foram preenchidos!', 'warning');
      return;
    }
    if (!address) {
      setAlert('Confira se o endereço foi preenchido corretamente!', 'warning');
      return;
    }

    const adapter = {
      amount: amount,
      paymentMethod: paymentMethod,
      dataMethod: dataMethod,
      dataPerson: dataPerson,
      address: address,
    };
    const dto = generateDto(paymentMethod, adapter);

    const response = await CreatePaymentOrderUseCase(preOrderId, dto);
    if (response.error) {
      setAlert(response.error, 'error');
    } else {
      resetForm();
      setRenderPaymentComponent({
        data: response.payload?.data!,
        render: true,
      });
    }

    setLoading(false);
  }

  function generateDto(paymentMethod: string, adapter: any) {
    switch (paymentMethod) {
      case 'CREDIT_CARD':
        return generateCreditCardDto(adapter);
      case 'BOLETO':
        return generateBoletoDto(adapter);
      case 'PIX':
        return generatePixDto(adapter);
      default:
        throw new Error('Método de pagamento não suportado.');
    }
  }

  function generateCreditCardDto(adapter: any) {
    return {
      paymentMethod: 'CREDIT_CARD',
      amount: adapter.amount,
      billingAddress: {
        city: adapter.address.city,
        country: adapter.address.country,
        number: adapter.address.number,
        street: adapter.address.street,
        state: adapter.address.state,
        zipCode: adapter.address.zipCode,
      },
      completeUserPaymentData: {
        document: adapter.dataPerson.document,
        anonymous: adapter.dataPerson.anonymous,
        telephone: {
          countryCode: adapter.dataPerson.telephone.countryCode,
          areaCode: adapter.dataPerson.telephone.areaCode,
          number: adapter.dataPerson.telephone.number,
        },
      },
      creditCard: {
        number: adapter.dataMethod.number,
        holderName: adapter.dataMethod.holderName,
        expMonth: adapter.dataMethod.expMonth,
        expYear: adapter.dataMethod.expYear,
        cvv: adapter.dataMethod.cvv,
      },
    };
  }

  function generateBoletoDto(adapter: any) {
    return {
      paymentMethod: 'BOLETO',
      amount: adapter.amount,
      billingAddress: {
        city: adapter.address.city,
        country: adapter.address.country,
        number: adapter.address.number,
        street: adapter.address.street,
        state: adapter.address.state,
        zipCode: adapter.address.zipCode,
      },
      completeUserPaymentData: {
        document: adapter.dataPerson.document,
        anonymous: adapter.dataPerson.anonymous,
        telephone: {
          countryCode: adapter.dataPerson.telephone.countryCode,
          areaCode: adapter.dataPerson.telephone.areaCode,
          number: adapter.dataPerson.telephone.number,
        },
      },
    };
  }

  function generatePixDto(adapter: any) {
    return {
      paymentMethod: 'PIX',
      amount: adapter.amount,
      billingAddress: {
        city: adapter.address.city,
        country: adapter.address.country,
        number: adapter.address.number,
        street: adapter.address.street,
        state: adapter.address.state,
        zipCode: adapter.address.zipCode,
      },
      completeUserPaymentData: {
        document: adapter.dataPerson.document,
        anonymous: adapter.dataPerson.anonymous,
        telephone: {
          countryCode: adapter.dataPerson.telephone.countryCode,
          areaCode: adapter.dataPerson.telephone.areaCode,
          number: adapter.dataPerson.telephone.number,
        },
      },
    };
  }

  const contextValue = {
    setAlert,
    SubmitForm,
    paymentMethod,
    amount,
    setAmount,
    setPaymentMethod,
    dataMethod,
    setDataMethod,
    dataPerson,
    setDataPerson,
    address,
    setAddress,
    activeComponentIndex,
    setActiveComponentIndex,
    preOrderId,
    setPreOrderId,
    renderPaymentComponent,
  };

  return <CheckOutContext.Provider value={contextValue}>{children}</CheckOutContext.Provider>;
};
