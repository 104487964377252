import { HttpClient } from "../../HttpClient";

export interface ListOrgs {
  id: number,
  name: string,
  cnpj: string,
  telephone: string,
}

  export async function ListOrgsUseCase() {
    const response = await HttpClient<void, { data: ListOrgs[] }>(
      'get', 
      `/users/gooders/orgs`
      );
    return response;
  }

  

