export const defaultOption = { value: '', label: 'Selecione', disabled: true, selected: true };

export const selectOptionsState = [
    defaultOption,
    { value: 'AC', label: 'AC' },
    { value: 'AL', label: 'AL' },
    { value: 'AP', label: 'AP' },
    { value: 'AM', label: 'AM' },
    { value: 'BA', label: 'BA' },
    { value: 'CE', label: 'CE' },
    { value: 'DF', label: 'DF' },
    { value: 'ES', label: 'ES' },
    { value: 'GO', label: 'GO' },
    { value: 'MA', label: 'MA' },
    { value: 'MT', label: 'MT' },
    { value: 'MS', label: 'MS' },
    { value: 'MG', label: 'MG' },
    { value: 'PA', label: 'PA' },
    { value: 'PB', label: 'PB' },
    { value: 'PR', label: 'PR' },
    { value: 'PE', label: 'PE' },
    { value: 'PI', label: 'PI' },
    { value: 'RJ', label: 'RJ' },
    { value: 'RN', label: 'RN' },
    { value: 'RS', label: 'RS' },
    { value: 'RO', label: 'RO' },
    { value: 'RR', label: 'RR' },
    { value: 'SC', label: 'SC' },
    { value: 'SP', label: 'SP' },
    { value: 'SE', label: 'SE' },
    { value: 'TO', label: 'TO' },
]

export const selectOptionsOds = [
    // defaultOption,
    { value: 'Erradicação da pobreza', label: 'Erradicação da pobreza' },
    { value: 'Fome zero e agricultura sustentável', label: 'Fome zero e agricultura sustentável' },
    { value: 'Saúde e Bem-Estar', label: 'Saúde e Bem-Estar' },
    { value: 'Educação de qualidade', label: 'Educação de qualidade' },
    { value: 'Igualdade de gênero', label: 'Igualdade de gênero' },
    { value: 'Água potável e saneamento', label: 'Água potável e saneamento' },
    { value: 'Energia limpa e acessível', label: 'Energia limpa e acessível' },
    { value: 'Trabalho decente e crescimento econômico', label: 'Trabalho decente e crescimento econômico' },
    { value: 'Indústria, inovação e infraestrutura', label: 'Indústria, inovação e infraestrutura' },
    { value: 'Redução das desigualdades', label: 'Redução das desigualdades' },
    { value: 'Cidades e comunidades sustentáveis', label: 'Cidades e comunidades sustentáveis' },
    { value: 'Consumo e produção responsáveis', label: 'Consumo e produção responsáveis' },
    { value: 'Ação contra a mudança global do clima', label: 'Ação contra a mudança global do clima' },
    { value: 'Vida na água', label: 'Vida na água' },
    { value: 'Vida terrestre', label: 'Vida terrestre' },
    { value: 'Paz, Justiça e Instituições Eficazes', label: 'Paz, Justiça e Instituições Eficazes' },
    { value: 'Parcerias e meios de implementação', label: 'Parcerias e meios de implementação' },
]