import { UserTypeEnum } from "../../../domain/interfaces/User";
import { HttpClient } from "../../HttpClient";
import { SharedUseCase } from "../campaign/item/CreateItemForCampaignUseCase";

export interface Params {
  orgId: number;
  institutionId: number;
  memberId: number
}

export class DeleteConnectionUserInstituitonOrgUseCase extends SharedUseCase<
  Params,
  null,
  {
    payload: unknown;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execOrg() {
    return await HttpClient(
      'delete',
      `/users/orgs/${this.params!.orgId}/members/${this.params!.memberId}`
    );
  }

  protected override async execInstitution() {
    return await HttpClient(
      'delete',
      `/users/orgs/${this.params!.orgId}/institutions/${this.params!.institutionId}/members/${this.params!.memberId}`
    );
  }

}


