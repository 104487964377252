import { Container } from "./styles";
import IonIcon from "@reacticons/ionicons";

// verificar se este componente será utilizado
interface SearchBarProps {
	searchTerm: string;
	onSearchTermChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchDiscount: React.FC<SearchBarProps> = ({
	searchTerm,
	onSearchTermChange,
}) => {


	return (

		<Container.Categorie>
			<Container.Search >
				<label htmlFor="search">
					<button type="submit"><IonIcon name="search-outline" /></button>
					<input
						type="search"
						placeholder="Encontre uma causa"
						value={searchTerm}
						onChange={onSearchTermChange} />
				</label>
			</Container.Search>

			<Container.Box>
				<Container.Button>Cupons</Container.Button>
				<Container.Button>Cashback</Container.Button>
			</Container.Box>
		</Container.Categorie>

	);
};
