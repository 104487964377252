import styled from "styled-components";
import { theme } from '../../../../../themes/blue';

const Container__Location = styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between;
  gap: 10px;
  color: ${props => props.theme.colors.gray};
  align-items: center;
  img {
    padding-right: 10px;
  }
  span {
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 18px;
  }
  button {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    padding: 4px 16px;
    border-radius: 4px;
    background: ${props => props.theme.colors.white};
    font-weight: 700;
  }
  @media (max-width:${theme.media. mobileSmall}) {
  flex-direction: column;
  }
`;

export const Container = {
	Location: Container__Location,
};

const Description__Title = styled.h2` 
  font-weight: 600;
  font-size: ${props => props.theme.fontSize.l};
  line-height: 26px;
  color: ${props => props.theme.colors.dark};
`;

const Description__SubTitle = styled.div` 
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.colors.gray};
  p {
  color: ${props => props.theme.colors.gray};
  font-size: ${props => props.theme.fontSize.sm};
  }
`;

export const Description = {
	Title: Description__Title,
	Subtitle: Description__SubTitle,
};