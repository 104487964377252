import Table from '../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { Container } from '../TableListPartner/styles';
import { GridColDef } from '@mui/x-data-grid';
import update from '../../../../../assets/icons/update.svg'
import delet from '../../../../../assets/icons/delet.svg'
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { SearchTableListUser } from '../TableListPartner/components/SearchTableListUser';
import { ListOrgs, ListOrgsUseCase } from '../../../../../../application/useCases/gooders/ListOrgsUseCase';
import { ModalEditOrg } from './components/ModalEditOrg';
import { DeleteOrgUseCase } from '../../../../../../application/useCases/gooders/DeleteOrgUseCase';
import createUser from '../../../../../assets/icons/createUser.svg'
import seeUsers from '../../../../../assets/icons/seeUsers.svg'
import { ModalListConnectUser } from '../TableListInstitutions/components/ModalListConnectUser';
import { ModalConnectUser } from '../TableListInstitutions/components/ModalConnectUser';
import { ListConnectUserInstUseCase } from '../../../../../../application/useCases/gooders/ListConnectUserInstUseCase';
import { UserTypeEnum } from '../../../../../../domain/interfaces/User';
import { ConnectUserInstUseCase } from '../../../../../../application/useCases/gooders/ConnectUserInstUseCase';
import { DeleteConnectionUserInstituitonUseCase } from '../../../../../../application/useCases/gooders/DeleteConnectionUserInstituitonUseCase';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';


export const TableListOrgs = () => {
	const [listOrgs, setListOrgs] = useState<ListOrgs[]>([]);
	const { setAlert } = useAlert();
	const [search, setSearch] = useState('');
	const clearFilters = () => { setSearch(''); };
	const [selectedOrgs, setSelectedOrgs] = useState<ListOrgs>();
	const [openModal, setOpenModal] = useState(false);
	const [openModalConnect, setOpenModalConnect] = useState(false);
	const [openModalConnectList, setOpenModalConnectList] = useState(false);
	const [orgId, setOrgId] = useState<number>(0);
	const [renderConfirmModal, setRenderConfirmModal] = useState(false);


	useEffect((
	) => {
		const fetchData = async () => {
			const { error, payload } = await ListOrgsUseCase();
			if (!error && payload) {
				setListOrgs(payload.data);
			} else {
				setAlert(error!, 'error');
			}
		}
		fetchData();
	}, [])

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Organização', minWidth: 216, flex: 1, headerClassName: 'table-header', },
		{ field: 'cnpj', headerName: 'Documento', minWidth: 200, flex: 1, headerClassName: 'table-header', },
		{ field: 'telephone', headerName: 'Telefone', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
		{
			field: 'Usuários', headerName: 'Usuários', minWidth: 100, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				return (
					<>
						<img
							onClick={() => {
								setOrgId(params.row.id);
								setOpenModalConnect(true);
							}}
							src={createUser}
							alt="action"
							title='Associar Usuário'
							style={{ cursor: 'pointer', paddingRight: "16px" }}
						/>
						<img
							onClick={() => {
								setOrgId(params.row.id);
								setOpenModalConnectList(true);
							}}
							src={seeUsers}
							alt="action"
							title='Listar Usuário'
							style={{ cursor: 'pointer', }}
						/>
					</>
				)
			},
			sortable: false,
			filterable: false,
		}, {
			field: 'Ações', headerName: 'Ações', minWidth: 50, flex: 1, headerAlign: 'center', align: 'center', headerClassName: 'table-header',
			renderCell: (params) => {
				return (
					<>
						<img
							onClick={() => {
								setOrgId(params.row.id);
								setSelectedOrgs(params.row.id);
								setOpenModal(true);
							}}
							src={update}
							alt="action"
							style={{ cursor: 'pointer', paddingRight: "16px" }}
						/>
						<img
							onClick={() => {
								handleConfirmModal()
								setOrgId(params.row.id);
							}}
							src={delet}
							alt="action"
							style={{ cursor: 'pointer', }}
						/>
					</>
				)
			},
			sortable: false,
			filterable: false,
		},
	];

	const rows =
		listOrgs?.map((inst, index) => ({
			index: index,
			id: inst.id,
			name: inst.name,
			cnpj: inst.cnpj,
			telephone: inst.telephone,
		})) ?? [];

	const searchText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const filterRows = (search: string) => {
		let filteredRows = rows;
		if (search !== '') {
			const searchTerm = search.toLowerCase();
			filteredRows = filteredRows.filter((row) => {
				const name = row['name']?.toLowerCase() || '';
				const cnpj = row['cnpj']?.toLowerCase() || '';
				return name.includes(searchTerm) || cnpj.includes(searchTerm);
			});
		}
		return filteredRows;
	};

	const filteredRows = filterRows(search);

	function handleConfirmModal() {
		setRenderConfirmModal(true);
	}

	async function handleCloseConfirmModal(result: boolean) {
		setRenderConfirmModal(false);
		if (result) {
			const { error } = await DeleteOrgUseCase(orgId);
			if (error) {
				setAlert(error, 'error');
			} else {
				setAlert('Organização deletado com sucesso!', 'success');
			}
		}
	}

	function IconEdit(id: number) {
		setOpenModal(true);
		setOrgId(id)
		setSelectedOrgs(id as any);
	}

	function IconDelete(id: number) {
		handleConfirmModal()
		setOrgId(id);
	}

	function IconList(id: number) {
		setOrgId(id);
		setOpenModalConnectList(true);
	}

	function IconConnect(id: number) {
		setOrgId(id);
		setOpenModalConnect(true);
	}

	const cardData: CardData[] = filteredRows.map((inst) => ({
		id: inst.id,
		title: [
			'Name',
			'Cnpj',
			'Telephone',
		],
		value: [
			inst.name,
			inst.cnpj,
			inst.telephone,
		]
	}));

	return (
		<>
			<Container.main>
				<Container.filter>
					<SearchTableListUser search={search} searchText={searchText} clearFilters={clearFilters} />
				</Container.filter>
				<Container.table>
					<Table rows={filteredRows} columns={columns} menuIconDisplay="flex" numberRows={10} />
				</Container.table>
				<Container.mobile>
					<CardsTable cardData={cardData} IconEdit={IconEdit} IconDelete={IconDelete} IconList={IconList} IconConnect={IconConnect} />
				</Container.mobile>
				{openModalConnect && (
					<ModalConnectUser
						onClose={() => setOpenModalConnect(false)}
						useCase={new ConnectUserInstUseCase(UserTypeEnum.ORG)}
						rowId={orgId}
					/>
				)}
				{openModalConnectList && (
					<ModalListConnectUser
						onClose={() => setOpenModalConnectList(false)}
						useCase={new ListConnectUserInstUseCase(UserTypeEnum.GOODERS)}
						useCase2={new DeleteConnectionUserInstituitonUseCase(UserTypeEnum.GOODERS)}
						Id={orgId}

					/>
				)}
				{openModal && selectedOrgs && (
					<ModalEditOrg
						onClose={() => setOpenModal(false)}
						selectedOrg={listOrgs}
						orgId={orgId}
					/>
				)}
				{renderConfirmModal && (
					<ModalConfirm
						title="Deletar Organização"
						content="Deseja continuar? Ao clicar em Deletar sua organização será excluída permanentemente!"
						rightButtonText="Deletar"
						onClose={handleCloseConfirmModal}

					/>
				)}
			</Container.main>
		</>
	);
};
