import { useEffect, useState } from "react";
import { HeaderPages } from "../../../components/common/HeaderPages";
import { Container, Text } from './styles';
import { Link, useLocation } from "react-router-dom";
import { FormCreatePatner } from "./components/FormCreatePatner";
import { FormCreateOrg } from "./components/FormCreateOrg";
import { FormCreateInst } from "./components/FormCreateInst";
import { useGlobalStore } from "../../../../infra/state/GlobalStore";
import { UserTypeEnum } from "../../../../domain/interfaces/User";
import { CreateInstOrgUseCase } from "../../../../application/useCases/org/CreateInstOrgUseCase";
import { CreateInstUseCase } from "../../../../application/useCases/gooders/CreateInstUseCase";


enum ActiveTabEnum {
	'PARTNER',
	'INSTITUTION',
	'ORG'
}



export const CreateUserType: React.FC = () => {

	const user = useGlobalStore()
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const userType = searchParams.get('type');
	const [activeTab, setActiveTab] = useState<ActiveTabEnum>(
		userType === 'org' ? ActiveTabEnum.ORG :
			userType === 'institution' ? ActiveTabEnum.INSTITUTION :
				ActiveTabEnum.PARTNER
	);

	return (
		<HeaderPages title="Criar usuários">
			<>
				{user.state.acl.selectedACL?.type === UserTypeEnum.GOODERS ? (
					<>
						<Container.support>
							<Container.choice>
								<Link to="/createuser?type=partner" id="partner" onClick={() => setActiveTab(ActiveTabEnum.PARTNER)}>
									<Text.subTitle active={activeTab === ActiveTabEnum.PARTNER}
										data-test="subtitle-Patner"
									> Parceiro</Text.subTitle>
								</Link>
								<Link to="/createuser?type=org" id="org" onClick={() => setActiveTab(ActiveTabEnum.ORG)}>
									<Text.subTitle active={activeTab === ActiveTabEnum.ORG}
										data-test="subtitle-Org"
									> Org</Text.subTitle>
								</Link>
								<Link to="/createuser?type=institution" id="institution" onClick={() => setActiveTab(ActiveTabEnum.INSTITUTION)}>
									<Text.subTitle active={activeTab === ActiveTabEnum.INSTITUTION}
										data-test="subtitle-Institution"
									>Instituição </Text.subTitle>
								</Link>
							</Container.choice>
						</Container.support>
						<Container.support>
							{activeTab === ActiveTabEnum.PARTNER ? <FormCreatePatner /> : null}
							{activeTab === ActiveTabEnum.ORG ? <FormCreateOrg /> : null}
							{activeTab === ActiveTabEnum.INSTITUTION ? <FormCreateInst useCase={CreateInstUseCase} /> : null}
						</Container.support>
					</>
				) : user.state.acl.selectedACL?.type === UserTypeEnum.ORG ? (
					<>
						<Container.support>
							<Container.choice>
								<Link to="/createuser?type=institution" id="institution" onClick={() => setActiveTab(ActiveTabEnum.INSTITUTION)}>
									<Text.subTitle active={activeTab === ActiveTabEnum.INSTITUTION}
										data-test="subtitle-Institution"
									>Instituição </Text.subTitle>
								</Link>
							</Container.choice>
						</Container.support>
						<Container.support>
							{activeTab === ActiveTabEnum.INSTITUTION ? <FormCreateInst useCase={CreateInstOrgUseCase} /> : null}
						</Container.support>
					</>
				) : null}
			</>
		</HeaderPages>
	);
};