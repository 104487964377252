import { ReactNode, createContext, useState } from 'react';
import AlertPopup from './AlertPopup';

const initialState = {
  text: '',
  type: '',
};

export type AlertType = 'error' | 'success' | 'warning' | 'alert' | 'info';

export const AlertContext = createContext({
  ...initialState,
  setAlert: (text: string, type: AlertType) => {
    //
  },
});

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [text, setText] = useState('');
  const [type, setType] = useState('');

  const setAlert: (text: string, type: string) => void = (text, type) => {
    setText(text);
    setType(type);

    const ALERT_TIME = 3000;

    setTimeout(() => {
      setText('');
      setType('');
    }, ALERT_TIME);
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        setAlert,
      }}
    >
      <AlertPopup />
      {children}
    </AlertContext.Provider>
  );
};
