import { Container } from './styles'
import close from '../../../../../../../assets/icons/close.svg'
import { ImgAvatar } from '../../../../../CreateAccount/components/Avatar/components/ImgAvatar';
import { useState } from 'react';
import { SmallBlueButton } from '../../../../../../../components/common/Button';

export interface Props {
    open: boolean;
    onClose: () => void;
    handleSelectImage: (avatar: { src: string; alt: string; }) => void
    onSave: () => void;
}

export function ModalAvatar({ open, onClose, handleSelectImage, onSave }: Props) {

    const [loading, setLoading] = useState(false)

    const handleSaveAvatar = () => {
        setLoading(true);
        onSave();
        setLoading(false);
        onClose();
    };

    if (!open) return null;
    return (
        <Container.box >
            <Container.modal>
                <Container.close src={close} alt="close" onClick={onClose} />
                <ImgAvatar handleSelectImage={handleSelectImage} />
                <SmallBlueButton onClick={handleSaveAvatar} type="submit" loading={loading} > Editar Avatar </SmallBlueButton>
            </Container.modal>
        </Container.box>
    )
}

