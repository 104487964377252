import { useState } from "react";

interface UseSearchProps {
    onSearchTermChange: (term: string) => void;
}

export const useSearch = ({ onSearchTermChange }: UseSearchProps) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [category, setCategory] = useState("all");

	const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		onSearchTermChange(event.target.value);
	};
	

	return { 
		searchTerm,
		handleSearchTermChange, };
};