import { HttpClient } from '../../HttpClient';

export interface CreateInstitution {
  name: string;
  document: string;
  telephone: string;
}


export async function CreateInstUseCase(dto: CreateInstitution) {
  const { error, payload } = await HttpClient<CreateInstitution, void>(
    'post', 
    '/users/gooders/institutions', 
    dto);
  if (!error && payload) {
    return {
      data: payload,
    };
  } else {
    return { error };
  }
}
