import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../themes/blue';
import Footer from '../../common/Footer';
import Navbar from '../../common/Navbar';
import { Sidebar } from '../../common/Sidebar';

interface Props {
  children: ReactNode;
}

export const Container = styled.div<{ openModal: boolean }>`
  padding-left: ${(p) => (p.openModal ? '240px' : '90px')};
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  @media (max-width: ${theme.media.mobile}) {
    padding-left: 0;
  }
`;

export const LayoutSidebar = ({ children }: Props) => {
  const [openModal, setOpenModal] = useState(true);
  const navbarWidth = openModal ? 'calc(100% - 240px)' : 'calc(100% - 90px)';

  return (
    <>
      <Container openModal={openModal}>
        <Navbar openModal={openModal} width={navbarWidth} />
        <Sidebar openModal={openModal} setOpenModal={setOpenModal} />
        {children}
        <Footer openModal={openModal} width={navbarWidth} />
      </Container>
    </>
  );
};
