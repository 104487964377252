import { Alert, AlertColor } from '@mui/material';
import { useAlert } from '../useAlert';

const AlertPopup = () => {
  const { text, type } = useAlert();

  if (text && type) {
    let severity: AlertColor | undefined;

    if (['success', 'info', 'warning', 'error'].includes(type)) {
      severity = type as AlertColor;
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          height: '100px',
          top: '10px',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <Alert severity={severity}>{text}</Alert>
      </div>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;
