import { useContext } from 'react';
import { Container } from '../PaymentMethod/styles';
import { CheckOutContext } from '../../../../../../../../infra/config/CheckOut';
import { Pay } from '../Pay';

export function Revision() {
  const {
    SubmitForm,
    dataPerson,
    address,
    paymentMethod,
    dataMethod,
    setActiveComponentIndex,
    amount,
    renderPaymentComponent,
  } = useContext(CheckOutContext);

  const handlePrevious = () => {
    setActiveComponentIndex((prevIndex: number) => prevIndex - 1);
  };

  const paymentMethodNames: { [key: string]: string } = {
    CREDIT_CARD: 'Cartão de Crédito',
    PIX: 'Pix',
    BOLETO: 'Boleto',
  };

  const formatNumberCard = (numberCard: string) => {
    if (numberCard) {
      const cleanedNumber = numberCard.replace(/\D/g, '').slice(0, 16);
      const formattedNumber = `${'**** '.repeat(3)}${cleanedNumber.slice(12)}`;
      return formattedNumber;
    }
  };

  if (renderPaymentComponent.render) {
    return <Pay payload={renderPaymentComponent.data!} paymentMethod={paymentMethod!} />;
  }

  return (
    <>
      <Container.boxRevision>
        <h3>Identificação</h3>
        <Container.content>
          <p>{dataPerson.document}</p>
          <p>
            ({dataPerson.telephone.areaCode}){dataPerson.telephone.number}
          </p>
        </Container.content>
      </Container.boxRevision>
      <Container.boxRevision>
        <h3>Endereço</h3>
        <Container.content>
          <p>Cobrança</p>
          <p>{address.zipCode}</p>
          <p>
            {address.street}, {address.number}{' '}
          </p>
          <p>
            {address.city}, {address.state}
          </p>
        </Container.content>
      </Container.boxRevision>
      <Container.boxRevision>
        <h3>Pagamento</h3>
        <Container.content>
          <p>{paymentMethodNames[paymentMethod!]}</p>
          {paymentMethod === 'CREDIT_CARD' && (
            <>
              <p>{dataMethod.holderName}</p>
              <p>{formatNumberCard(dataMethod.number)}</p>
              <p>{amount / 100}</p>
            </>
          )}
          {paymentMethod === 'PIX' && <p>{amount / 100}</p>}
          {paymentMethod === 'BOLETO' && <p>{amount / 100}</p>}
        </Container.content>
        <Container.buttons>
          <button type="button" onClick={handlePrevious} className="previous-button-border">
            Voltar
          </button>

          <button type="button" className="last-button" onClick={SubmitForm}>
            Concluir
          </button>
        </Container.buttons>
      </Container.boxRevision>
    </>
  );
}
