import styled from 'styled-components';
import {theme} from '../../../../../../../themes/blue'

const Container__Box = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px 0 8px;
  h2 {
    font-weight: 600;
  }
`;

const Container__Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
`;

const Container__Button = styled.div`
  padding: 8px 0;
`;

const Container__Inputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container__Wrapper = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
  }
`;

const Container__CardCredit = styled.div`
  display: flex;
  width: 100%;
  padding: 4px 48px;
  color: ${(props) => props.theme.colors.white};
  align-items: center;
  justify-content: center;
  .card {
    background: ${(props) => props.theme.colors.primary};
    width: 100%;
    min-width: 250px;
    max-width: 300px;
    border-radius: 8px;
    position: relative;
    padding: 16px;
  }
  .title {
    font-weight: 700;
    padding-bottom: 32px;
  }
  .containerText {
    display: flex;
    justify-content: space-between;
  }
  .numberCard {
    padding-bottom: 16px;
  }
`;

const Container__Checkbox = styled.div`
  padding: 16px 0;
`
const Container__ChoicePay = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.colors.lightgray};
  padding: 8px 16px;
  border-radius: 4px;
`

const Container__ChoiceCardCredit = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  img {
    width: 24px;
    padding: 0;
  }
`

const Container__ChoicePix = styled(Container__ChoiceCardCredit)`
`

const Container__ChoiceBoleto = styled(Container__ChoiceCardCredit)`

`


const Container__Buttons = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100%);
  border-top: 2px solid ${(props) => props.theme.colors.lightgray};
  margin: 8px 0 0;
  padding-top: 16px;
  gap: 8px;
  button {
    padding: 4px 8px;
    background: ${(props) => props.theme.colors.white};
    width: 225px;
    height: 32px;
    border: 1px solid ${(props) => props.theme.colors.paleGray};
    border-radius: 4px;
    cursor: pointer;
  }
  .next-button-blue {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
  .previous-button-border {
    border: 1px solid ${(props) => props.theme.colors.primary};
    margin-right: 8px;
    color: ${(props) => props.theme.colors.primary};
  }
  .last-button {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;


const Container__BoxRevision = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h3 {
    padding: 32px 0 0;
  }
  p {
    color: ${props => props.theme.colors.gray};
    font-size: ${(props) => props.theme.fontSize.md};
    :first-child{
      padding-bottom: 12px;
    }
  }
`;

const Container__Content = styled.div`
  border: 1px solid ${props => props.theme.colors.lightgray};
  padding: 16px;
  border-radius: 4px;
`;




export const Container = {
  box: Container__Box,
  input: Container__Input,
  button: Container__Button,
  inputs: Container__Inputs,
  wrapper: Container__Wrapper,
  cardCredit: Container__CardCredit,
  checkbox: Container__Checkbox,
  choicePay: Container__ChoicePay,
  choiceCardCredit: Container__ChoiceCardCredit,
  choicePix: Container__ChoicePix,
  choiceBoleto: Container__ChoiceBoleto,
  buttons: Container__Buttons,
  boxRevision: Container__BoxRevision,
  content: Container__Content
};