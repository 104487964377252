import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Box = styled.main`
  background: ${(props) => props.theme.colors.backgroundGray};
  color: ${(props) => props.theme.colors.primary};
  padding: 70px 40px 150px;
  @media (max-width: ${theme.media.laptop}) {
    padding: 70px 20px 150px;
  }

`;

const Container__Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0 16px;
  @media (max-width: ${theme.media.mobileSmall}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
  }
`;

const Container__Title = styled.h1`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.x};
  line-height: 24px;
`;


export const Container = {
  box: Container__Box,
  title: Container__Title,
  header: Container__Header,
};

export const Button = styled.div``;
