import styled from "styled-components";
import background_login from '../../../assets/auth/background-login.png';


const Container__Page = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${background_login});
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary};
  padding: 40px 52px 40px;
`;

const Container__Box = styled.main`
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 8px;
  margin-top: 50px;
  padding: 40px;
  gap: 24px;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
  max-width: 600px;
  }
  h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  max-width: 60%;
  @media (max-width: 480px) {
  font-size: 18px;
  }
  }
  img {
  max-width: 90%;
  }
`;

const Container__Button = styled.div`
width: 30%;
`;



const Container__Section = styled.section`
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  gap: 8px;
  color: ${(props) => props.theme.colors.paleBlue};
  p {
    font-size: ${(props) => props.theme.fontSize.sm};
  }
  span {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize.sm};
    text-decoration: underline 1px ${(props) => props.theme.colors.primary};
    cursor: pointer;
    padding: 0;
  }
`;

export const Container = {
	page: Container__Page,
	box: Container__Box,
  button: Container__Button,
  section: Container__Section
};