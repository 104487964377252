import { Container } from './styles';
import warning from '../../../assets/icons/warning.svg';
import close from '../../../assets/icons/close.svg';
import { SmallRedButton, SmallTransparentBlueButton } from '../Button';
import { useState } from 'react';
import { useGlobalStore } from '../../../../infra/state/GlobalStore';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';
import { DeleteCampaignUseCase } from '../../../../application/useCases/campaign/OrgDeleteCampaign';

interface ModalIconProps {
  open: boolean;
  onClose: () => void;
  campaignId: number;
  institutionId: number;
  posts: any[];
  setPosts: React.Dispatch<React.SetStateAction<any[]>>;
  useCase: DeleteCampaignUseCase;
}

export const ModalDelete = ({
  onClose,
  campaignId,
  setPosts,
  posts,
  institutionId,
  useCase,
}: ModalIconProps) => {
  const [loading, setLoading] = useState(false);
  const user = useGlobalStore();
  const { setAlert } = useAlert();
  const handleButtonClose = () => {
    setLoading(true);
    onClose();
  };

  const handleDelete = async (institutionId: number, campaignId: number) => {
    setLoading(true);
    if (user.state.acl.selectedACL) {
      const { id } = user.state.acl.selectedACL;

      const orgId = id;

      const { error } = await useCase.execute({ orgId, institutionId, campaignId });

      if (!error) {
        setPosts(posts.filter((currentPost) => currentPost.campaignId !== campaignId));
        setAlert('Campanha desativada com sucesso!', 'success');
      } else {
        setAlert(error, 'error');
      }

      setLoading(false);
    }
  };

  return (
    <Container.box onClick={onClose}>
      <Container.warning>
        <Container.close src={close} alt="warning" />
        <Container.icon>
          <img src={warning} alt="warning" />
          <h3>Deletar campanhas</h3>
        </Container.icon>
        <p>Deseja continuar? Ao clicar em deletar sua campanha será excluída permanentemente.</p>
        <Container.action>
          <SmallTransparentBlueButton onClick={handleButtonClose} loading={loading}>
            Fechar
          </SmallTransparentBlueButton>
          {campaignId && (
            <SmallRedButton
              onClick={() => handleDelete(institutionId, campaignId)}
              loading={loading}
            >
              Deletar
            </SmallRedButton>
          )}
        </Container.action>
      </Container.warning>
    </Container.box>
  );
};
