import React, { useState } from 'react';
import { Container, Text } from '../../styles';
import { Link } from 'react-router-dom';
import { ImgAvatar } from './components/ImgAvatar';
import { HeaderLogin } from '../../../../../components/common/HeaderLogin';
import { BlueButton } from '../../../../../components/common/Button';
import { FormAvatar } from './components/FormAvatar';
import { useForm } from '../context/formContext';

interface NewAccountProps {
  setShowAvatar: (showAvatar: boolean) => void;
}

export const Avatar: React.FC<NewAccountProps> = ({ setShowAvatar }) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
  const { formik, loading } = useForm();
  const handleSelectImage = (avatar: any) => {
    const imageName = avatar.src.split('/').pop()?.split('.')[0];
    formik.setFieldValue('avatar', imageName || 'Avatar1');
    setSelectedImage(imageName || 'Avatar1');
  };
  if (!selectedImage) {
    formik.setFieldValue('avatar', 'Avatar1');
    setSelectedImage('Avatar1');
  }
  return (
    <>
      <Container.headerTable>
        <HeaderLogin>Escolha seu avatar</HeaderLogin>
        <Text.title>
          <p>Escolha um avatar para continuar</p>
        </Text.title>
      </Container.headerTable>

      <ImgAvatar handleSelectImage={handleSelectImage} />

      <Container.create>
        <Container.header>
          <HeaderLogin>Escolha o avatar</HeaderLogin>
          <Text.title>
            <p>Escolha um avatar ao lado para continuar</p>
          </Text.title>
        </Container.header>

        <Container.form>
          <FormAvatar />
          <Container.choice>
            {!selectedImage && (
              <img
                src={'/avatar/Avatar1.svg'}
                alt="avatar"
                onClick={() => setSelectedImage('Avatar1')}
              />
            )}
            {selectedImage && (
              <img
                src={`/avatar/${selectedImage}.svg`}
                alt={`Avatar ${selectedImage}`}
                onClick={() => setSelectedImage(undefined)}
              />
            )}
          </Container.choice>
          {formik.errors.avatar ? <div className="error">{formik.errors.avatar}</div> : null}
        </Container.form>

        <BlueButton
          dataTest="btn-finish-create-account"
          onClick={formik.submitForm}
          loading={loading}
        >
          Avançar
        </BlueButton>

        <Container.line>
          <Text.line /> <span> Ou </span> <Text.line />
        </Container.line>
        <Container.section>
          <p>Já tem uma conta?</p>
          <Link to="/login">
            <span>Entrar</span>
          </Link>
        </Container.section>
        <p
          onClick={() => setShowAvatar(false)}
          style={{ fontWeight: '600', color: 'gray', cursor: 'pointer' }}
        >
          Voltar
        </p>
      </Container.create>
    </>
  );
};
