import { HttpClient } from "../../HttpClient";


export interface TransactionsItems {
  id: string,
  campaign: {
    title: string
  },
  status: string,
  gooders: number,
  createdAt: string;
}

export async function UserTransactionsItems() {

  const response = await HttpClient<undefined, { data: TransactionsItems[] }>('get', '/orders/items');
  return response;
  }

