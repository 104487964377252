import { useContext, useMemo } from 'react';
import { CheckOutContext } from '../../../../../../../../infra/config/CheckOut';
import {
  CreatePaymentOrderPayload,
  CreatePaymentOrderPayloadBoleto,
  CreatePaymentOrderPayloadPix,
  PaymentMethodEnum,
} from '../../../../../../../../application/useCases/checkout/CreatePaymentOrder';
import { PayPix } from './components/Pix';
import { PayBoleto } from './components/Boleto';
import { Link } from '@mui/material';
import { PayCreditCard } from './components/CreditCard';
import { useNavigate } from 'react-router-dom';
import { ROUTES_PATH } from '../../../../../../../../infra/config/routes';

interface Props {
  paymentMethod: PaymentMethodEnum;
  payload: CreatePaymentOrderPayload;
}

export const Pay = ({ paymentMethod, payload }: Props) => {
  const navigate = useNavigate();
  const linkComponent = (
    <Link
      onClick={() => navigate(ROUTES_PATH.listCampaignsForUsers)}
      margin={'1rem'}
      textAlign={'center'}
      style={{ cursor: 'pointer' }}
    >
      voltar para plataforma
    </Link>
  );

  const PaymentComponents = useMemo(() => {
    return {
      [PaymentMethodEnum.PIX]: (
        <PayPix payload={payload as CreatePaymentOrderPayloadPix}>{linkComponent}</PayPix>
      ),
      [PaymentMethodEnum.BOLETO]: (
        <PayBoleto payload={payload as CreatePaymentOrderPayloadBoleto}>{linkComponent}</PayBoleto>
      ),
      [PaymentMethodEnum.CREDIT_CARD]: <PayCreditCard>{linkComponent}</PayCreditCard>,
    };
  }, [paymentMethod, payload]);

  return <>{PaymentComponents[paymentMethod]}</>;
};
