import styled from 'styled-components';
import { theme } from '../../../../../../../themes/blue';

const Container__Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const Container__checkBox = styled.div`
  display: flex;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.dark};
  padding: 8px 0;
  label {
    font-size: ${(props) => props.theme.fontSize.sm};
  }
  input {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid ${(props) => props.theme.colors.paleGray};
    border-radius: 5px;
    outline: none;
    margin-right: 10px;
    position: relative;
  }
  input:checked::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
    height: 15px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 3px;
  }
  span {
    font-size: 12px;
    text-decoration: underline 1px solid ${(props) => props.theme.colors.dark};
  }
`;

export const Container = {
  form: Container__Form,
  checkBox: Container__checkBox,
};

const Content__Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content__Acess = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${theme.media.mobileSmall}) {
    flex-wrap: wrap;
  }
`;

export const Content = {
  form: Content__Form,
  acess: Content__Acess,
};
