import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Main = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  gap: 16px;
  height: 100%;
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.background};
  border-radius: 8px;
  @media (max-width: ${theme.media.monitorSmall}) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 50px 6px;
  }
`;

const Container__Description = styled.div`
  width: 50%;
  min-height: 100%;
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${theme.media.monitorSmall}) {
    width: 100%;
  }
`;

const Container__Box = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.media.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Container__Share = styled.div`
  flex: 3;
  display: flex;
  gap: 16px;
  padding-top: 12px;
  font-weight: 500;
  cursor: pointer;
  span {
    border-bottom: 2px solid ${(props) => props.theme.colors.hoverBlue};
    color: ${(props) => props.theme.colors.hoverBlue};
    img {
      padding-right: 8px;
    }
  }
  @media (max-width: ${theme.media.mobileSmall}) {
    flex-direction: column;
    width: 100%;
  }
`;



const Container__Progress = styled.div`
  margin: 6px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  span {
  font-size: ${props => props.theme.fontSize.md};
  line-height: 22px;
  }
  .progress{
    background: ${props => props.theme.colors.lightgray};
    border-radius: 4px;
    height: 8px;
    width: 100%;
  }
  .barprogress {
    border-radius: 4px;
    height: 8px;
    background: ${props => props.theme.colors.primary};
    border-radius: 40px;
  }
`

const Container__Content= styled.div`
  width: 100%;
  min-height: 100%;
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width:${theme.media.monitorSmall}){
  width: 100%;
  }
`
const Container__Wrapper = styled.div`
	display: flex;
	gap: 24px;
	padding-top: 12px;
`;


const Container__SubTitle = styled.p<{ active?: boolean }>`
font-weight: ${props => props.active && "700"};
border-bottom: ${props => props.active && "1px solid gray"};
@media (max-width: ${theme.media.mobileSmall}) {
  font-size: ${props => props.theme.fontSize.sm};
}
`;

const Container__Button = styled.div``;

export const Container = {
  main: Container__Main,
  description: Container__Description,
  box: Container__Box,
  share: Container__Share,
  Progress: Container__Progress,
   content: Container__Content,
  wrapper: Container__Wrapper,
  subTitle: Container__SubTitle,
    button: Container__Button,
};
