import { HttpClient } from "../../HttpClient";


export async function DeleteMemberOrgUseCase(orgId: number, memberGoodersId: number,)
{
  return await HttpClient(
    'delete',
    `/users/orgs/${orgId}/members/${memberGoodersId}`
    );
}
