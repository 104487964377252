import styled from "styled-components";

const Container__Box = styled.div`
  padding: 8px;
  max-width: 500px;
  font-family: 'Poppins', sans-serif;
  position: relative;
`;
const Container__Cards = styled.div`
  max-width: 100%;
  color: ${props => props.theme.colors.black};
  border-radius: 8px;
  box-shadow: none;
  transition: all 300ms linear 0s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
`;

const Container__Image = styled.div`
  margin: 0 6px;
  display: flex;
  justify-content: center;
  img {
  width: 240px;
  height: 160px;
  border-radius: 8px;
  object-fit: cover; }
`;

const Container__Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  gap: 8px;
  height: 170px;
  h3 {
  font-weight: 500;
  line-height: 24px;
  }
  p {
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 18px;
  color: ${props => props.theme.colors.gray};
  }
`;

const Container__Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Container__Button = styled.button`
 width: 100%;
  font-family: 'Poppins', sans-serif;
  padding: 4px 28px;
  border: none;
  background: ${props => props.theme.colors.primary};
  border-radius: 4px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center; 
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  transition: all 300ms linear 0s; 
    &:hover{
      background:  ${props => props.theme.colors.gray};
    }
`;

const Container__Location = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  color:  ${props => props.theme.colors.gray};
  align-items: center;
  span {
  font-weight: 400;
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 18px;
  }
`;

const Container__Icons = styled.div`
  display: flex;
  gap: 16px;
  img {
  width: 20px;
  }
`;

const Container__Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0 8px;
  border-bottom: 2px solid ${props => props.theme.colors.lightgray};

`;

const Container__Progress = styled.div`
  margin: 12px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  span {
  font-size: ${props => props.theme.fontSize.md};
  line-height: 22px;
  }
  .progress{
    background: ${props => props.theme.colors.lightgray};
    border-radius: 4px;
    height: 8px;
    width: 100%;
  }
  .barprogress {
    border-radius: 4px;
    height: 8px;
    background: ${props => props.theme.colors.primary};
    border-radius: 40px;
  }
`;


export const Container = {
	Box: Container__Box,
	Cards: Container__Cards,
	Image: Container__Image,
	Text: Container__Text,
	Button: Container__Button,
	Footer: Container__Footer,
	// Gooders: Container__Gooders,
  Icons: Container__Icons,
	Progress: Container__Progress,
	Location: Container__Location,
  Content: Container__Content
};

export const Line = styled.div``;
