import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';
import { SharedUseCase } from '../../campaign/item/CreateItemForCampaignUseCase';

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
}

interface UpdateACtiveStatusOfCampaignDto {
  isActive: boolean;
}

export class UpdateActiveStatusOfCampaignUseCase extends SharedUseCase<
  Params,
  UpdateACtiveStatusOfCampaignDto,
  {
    payload: unknown;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execInstitution() {
    return await HttpClient(
      'put',
      `/campaigns/institution/${this.params!.institutionId}/${this.params!.campaignId}`,
      this.dto
    );
  }

  protected override async execOrg() {
    return await HttpClient(
      'put',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}/${
        this.params!.campaignId
      }`,
      this.dto
    );
  }

  protected override async execGooders() {
    return await HttpClient(
      'put',
      `/campaigns/gooders/${this.params!.campaignId}`,
      this.dto!
    );
}
}
