import { Container, Text } from './styles';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import close from '../../../../../../../assets/icons/close.svg';
import { validationSchema } from '../../../../../../users/createusertype/components/FormCreatePatner/index'
import { EditPartner, EditPatnerUseCase, PatchImagePatnerUseCase } from '../../../../../../../../application/useCases/gooders/EditPatnerUseCase';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { GreenButton, SmallBlueButton } from '../../../../../../../components/common/Button';
import logo_coin from '../../../../../../../assets/logo/logo_coin.svg'
import outlined from '../../../../../../../assets/icons/outlined.svg'
import delet from '../../../../../../../assets/icons/delet.svg'


interface FormCreatePatnerProps {
	selectedPartner: EditPartner[];
	onClose: () => void;
	partnerId: number;
}
export const ModalEditPartner: React.FC<FormCreatePatnerProps> = ({ selectedPartner, onClose, partnerId }) => {


	const [loading, setLoading] = useState(false);
	const { setAlert } = useAlert();
	const [Image, setImage] = useState<File[] | null>(null);
	const [partnerLogo, setPartnerLogo] = useState<string | null>(null);


	const formik = useFormik({
		initialValues: {
			name: '',
			cnpj: '',
			contact: '',
			id: '',
			image: '',
		},
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			setLoading(true);
			const patnerId = +values?.id;
			if (patnerId !== undefined) {
				const { error } = await EditPatnerUseCase({ ...values, id: patnerId });
				if (error) {
					setAlert(error, 'error');
					setLoading(false);
					return;
				}
				if (Image) {
					const formData = new FormData();
					Image.forEach((image) => {
						formData.append(`images`, image);
					});
					const { error: logoError } = await PatchImagePatnerUseCase(patnerId, formData);
					if (logoError) {
						setAlert(logoError, 'error');
						setLoading(false);
						return;
					}
				}
				setAlert('Parceiro alterado com sucesso', 'success');
				resetForm();
				setLoading(false);
				onClose();
			}
		}
	},
	);

	useEffect(() => {
		if (selectedPartner && selectedPartner.length > 0 && partnerId) {
			const selectedPartnerObj = selectedPartner.find((partner) => partner.id === partnerId);

			if (selectedPartnerObj) {
				formik.setValues({
					name: selectedPartnerObj.name || '',
					cnpj: selectedPartnerObj.cnpj || '',
					contact: selectedPartnerObj.contact || '',
					id: selectedPartnerObj.id.toString(),
					image: selectedPartnerObj.logo || '',
				});
				setPartnerLogo(selectedPartnerObj.logo || outlined);
			}
		}
	}, [selectedPartner, partnerId]);


	const handleDeleteImage = () => {
		setImage(null);
		setPartnerLogo(outlined);
		formik.setFieldValue('image', null);
	};

	const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedImages = e.target.files;
		if (selectedImages && selectedImages.length > 0) {
			const selectedImagesArray = Array.from(selectedImages);
			formik.setFieldValue('image', selectedImagesArray);
			setImage(selectedImagesArray)
			const firstImage = selectedImagesArray[0];
			setPartnerLogo(URL.createObjectURL(firstImage));
		}
	};

	return (
		<>
			<Container.box >
				<Container.modal>
					<Container.close src={close} alt="warning" onClick={onClose} />
					<Container.head>
						<Container.subTitle data-test="title-Patner">Editar Parceiro</Container.subTitle>
					</Container.head>
					<Container.wrapper onSubmit={formik.handleSubmit}>
						<Container.content>
							<Container.form>
								<Container.input>
									<LabelInputFormCampaign
										id="name"
										name="name"
										type="text"
										htmlFor="name"
										placeholder="Nome"
										aria-labelledby="name"
										onBlur={formik.handleBlur}
										value={formik.values.name}
										onChange={formik.handleChange}
										error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
									>
										Nome
									</LabelInputFormCampaign>
								</Container.input>
								<Container.input>
									<LabelInputFormCampaign
										htmlFor="cnpj"
										id="cnpj"
										name="cnpj"
										type="text"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.cnpj}
										placeholder="00.000.000/0000-00"
										aria-labelledby="cnpj"
										error={formik.touched.cnpj && formik.errors.cnpj ? formik.errors.cnpj : undefined}
									>
										CNPJ
									</LabelInputFormCampaign>
								</Container.input>
								<Container.input>
									<LabelInputFormCampaign
										htmlFor="contact"
										id="contact"
										name="contact"
										type="text"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.contact}
										placeholder="Email ou telefone"
										aria-labelledby="contact"
										error={formik.touched.contact && formik.errors.contact ? formik.errors.contact : undefined}
									>
										Contato
									</LabelInputFormCampaign>
								</Container.input>

							</Container.form>
							<Container.avatar>

								<Container.card >
									<span className="error">{formik.errors.image}</span>
									<Container.image>

										<label htmlFor="image">
											{partnerLogo ? (
												<>
													<img src={partnerLogo} alt="logo" className='selectedImage' />
													<img className='deletImage' src={delet} onClick={handleDeleteImage} />
												</>
											) : (
												<>
													<img src={outlined} alt="logo" />
													<p>Selecione a sua logo</p>
												</>
											)}
											<input
												style={{ display: "none", cursor: "pointer" }}
												id="image"
												name="image"
												type="file"
												onChange={handleImage}
												accept=".svg, .jpeg, .jpg, .png"
												maxLength={200 * 1024}
												onBlur={formik.handleBlur}
											/>
										</label>
									</Container.image>
									<Container.cardBox>
										<p>% OFF</p>
										<span>Parceiro </span>
										<Container.gooders>
											<img src={logo_coin} alt="gooders" />
											<p>Coins</p>
										</Container.gooders>
									</Container.cardBox>
									<Text.description>
										Descrição
									</Text.description>
									<Container.perforated>
										<div></div><div></div><div></div><div></div><div></div>
										<div></div><div></div><div></div><div></div><div></div>
										<div></div><div></div>
									</Container.perforated>
									<GreenButton onClick={undefined} loading={false} disabled> Resgatar </GreenButton>
								</Container.card>
							</Container.avatar>
						</Container.content>
						<SmallBlueButton type="submit" loading={loading}>
							Salvar
						</SmallBlueButton>
					</Container.wrapper >
				</Container.modal>
			</Container.box>
		</>
	);
};
