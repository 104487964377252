import styled from 'styled-components';
import { theme } from '../../../../../../../themes/blue';

const Container__Categorie = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  color: ${(props) => props.theme.colors.gray};
  padding: 0 16px;
  img {
    width: 22px;
  }
  @media (max-width: ${theme.media.laptop}) {
    padding: 16px 0;
    justify-content: flex-start;
    gap: 16px;
  }
`;

const Container__Icon = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSize.sm};
  border-bottom: 1px solid ${(props) => (props.active ? props.theme.colors.orange : 'transparent')};
  font-weight:  ${(props) => (props.active ? 700 : 500)};
  opacity: ${(props) => (props.active ? 1 : 0.8)};;
  gap: 8px;
  color: ${(props) => props.theme.colors.primary};
  :hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.orange};
  }
  @media (max-width: ${theme.media.tablet}) {
    gap: 4px;
    font-size: ${(props) => props.theme.fontSize.smX};
  }
`;

export const Container = {
  Categorie: Container__Categorie,
  Icon: Container__Icon,
};
