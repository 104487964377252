import { Container } from "./styles";
import warning from "../../../../../assets/icons/warning.svg";
import close from "../../../../../assets/icons/close.svg";
import { useState } from "react";
import { SmallBlueButton, SmallTransparentBlueButton } from "../../../../../components/common/Button";
import { handleLogout } from "../../../../../../application/handlers/HandleLogout";

interface Props {
	open: boolean;
	onClose: () => void;
}


export const ModalCancel = ({ open, onClose }: Props) => {

	if (!open) return null;

	const [loading, setLoading] = useState(false);

	return (
		<Container.box onClick={onClose}>
			<Container.warning>
				<Container.close src={close} alt="warning" />
				<Container.icon>
					<img src={warning} alt="warning" />
					<h3>Cancelar</h3>
				</Container.icon>
				<p>Seu usuário foi criado, para seguir é necessário selecionar um perfil.</p>
				<p>Deseja entrar com outra conta?</p>
				<Container.action>
					<SmallTransparentBlueButton
						onClick={handleLogout}
						loading={loading}>
						Entrar com outra conta
					</SmallTransparentBlueButton>
					<SmallBlueButton
						onClick={onClose}
						loading={loading}>
						Continuar
					</SmallBlueButton>
				</Container.action>
			</Container.warning>
		</Container.box>
	);
};
