import { Container } from './styles';

import { useEffect, useState } from 'react';

import number_user from '../../../assets/dashboard/number_user.svg';
import number_campaign from '../../../assets/dashboard/number_campaign.svg';
import number_donation from '../../../assets/dashboard/number_donation.svg';
import number_new_cause from '../../../assets/dashboard/number_new_cause.svg';
import { useAlert } from '../../../../infra/config/AlertContext/useAlert';

import {
  GoodersDashboardDto,
  GoodersDashboardUseCase,
} from '../../../../application/useCases/dahsboard/GoodersDashboardUseCase';
import { LoadingPage } from '../../../components/common/LoadingPage';
import { CardStatistics } from '../../../components/shared/dashboard/CardStatistics';
import { ItemsTableDahsboardGooders } from './components/table/ItemsTableDahsboardGooders';
import { VacanciesTableDahsboardGooders } from './components/table/VacanciesTableDahsboardGooders';


export function convertStringNumberToString(stringNumber: string) {
  const number = +stringNumber;
  if (number <= 0) return 'R$ 0,00';
  const formattedNumber = (number / 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return 'R$ ' + formattedNumber;
}

export const DashboardGoodersMember: React.FC = () => {
  const [data, setData] = useState<GoodersDashboardDto>();
  const { setAlert } = useAlert();

  useEffect(() => {
    async function fetchData() {
      const { error, payload } = await GoodersDashboardUseCase(29);
      if (!error && payload) {
        setData(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    }
    fetchData();
  }, []);

  const images = [number_user, number_campaign, number_donation, number_new_cause];

  if (data)
    return (
      <Container.main>
        <Container.cards>
          <Container.card>
            <CardStatistics
              title="criadas"
              startNumber={data.campaigns.count}
              percentage=""
              label="Últimos 30 dias"
              subTitle="Campanhas"
              imageSrc={images[1]}
            />
            <CardStatistics
              title="Usuários"
              startNumber={data.users.count}
              percentage=""
              label="Últimos 30 dias"
              subTitle="Novos usuários"
              imageSrc={images[0]}
            />
          </Container.card>
          <Container.card>
            <CardStatistics
              title=""
              startNumber={convertStringNumberToString(data.donations.sum)}
              percentage=""
              label="Últimos 30 dias"
              subTitle="Soma de doações"
              imageSrc={images[2]}
            />
            <CardStatistics
              title=""
              startNumber={data.donations.count + ' doações'}
              percentage=""
              label="Total"
              subTitle="Quantidade de doações"
              imageSrc={images[3]}
            />
          </Container.card>
        </Container.cards>
        <Container.table>
          <ItemsTableDahsboardGooders data={data?.itemOrders} />
          <VacanciesTableDahsboardGooders data={data?.vacancyOrders} />
        </Container.table>
      </Container.main>
    );

  return <LoadingPage />;
};
