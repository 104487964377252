import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  img {
    width: 244px;
    @media (max-width: ${theme.media.mobileSmall}) {
      width: 90%;
    }
  }
`;

export const Container = {
  header: Container__Header,
};

const Text__Title = styled.h1`
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.xll};
  line-height: 32px;
  color: ${(props) => props.theme.colors.primary};
  @media (max-width: 480px) {
    font-size: ${(props) => props.theme.fontSize.l};
  }
`;

export const Text = {
  title: Text__Title,
};
