import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';
import { SharedUseCase } from '../item/CreateItemForCampaignUseCase';

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
  imageId: number;
}

export class DeleteImageOfCampaignUseCase extends SharedUseCase<
  Params,
  void,
  {
    payload: unknown;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execInstitution() {
    return await HttpClient(
      'delete',
      `/campaigns/institution/${this.params!.institutionId}/${this.params!.campaignId}/images/${
        this.params!.imageId
      }`
    );
  }

  protected override async execOrg() {
    return await HttpClient(
      'delete',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}/${
        this.params!.campaignId
      }/images/${this.params!.imageId}`
    );
  }

  protected override async execGooders() {
    return await HttpClient(
      'delete',
      `/campaigns/gooders/${this.params!.campaignId}/images/${this.params!.imageId}`
    );
  }
}
