export enum UserRoleEnum {
  USER = 'USER',
  VOLUNTEER = 'VOLUNTEER',
  ADMIN = 'ADMIN',
}

export enum UserTypeEnum {
  INSTITUTION = 'INSTITUTION',
  ORG = 'ORG',
  GOODERS = 'GOODERS',
}

export interface User {
  id: number;
  name: string;
  email: string;
  role: UserRoleEnum;
  type: string | undefined;
  completed: boolean;
  avatar: string;
  wallet: Wallet;
  username: string;
  telephone: string;
  acls: Acls;
  goodersMember: GoodersMember;
  address: {
    state: string;
    city: string;
  };
}

export interface Acls {
  institutions: { id: number; name: string; role: UserRoleEnum; type: 'institutions' }[];
  orgs: { id: number; name: string; role: UserRoleEnum; type: 'orgs' }[] ;
  goodersMember: { role: UserRoleEnum | UserRoleEnum; type: UserTypeEnum.GOODERS} | null;
  selectedACL: selectACLs | null;
}

export interface selectACLs {
  id: number ;
  role: UserRoleEnum;
  type: UserTypeEnum;
  name: string;
}

interface Wallet {
  id: number;
  balance: number;
}

export interface GoodersMember {
  role: UserRoleEnum | UserRoleEnum;
}
