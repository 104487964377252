import { useGlobalStore } from '../../../../../../../../infra/state/GlobalStore';
import { CardData, CardsTable } from '../../../../../../../components/common/CardsTable';
import Table from '../../../../../../../components/common/Table';
import { Container } from '../../../../styles';
import { GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'Instituições:', headerName: 'Instituições:', width: 996, flex: 1, headerClassName: 'table-header' },
];

export default function TableInstituion() {

    const users = useGlobalStore();
    const rows = (users.state.user?.acls.institutions?.map((institution, index) => ({
        index: index,
        id: index,
        'Instituições:': institution.name,
    })) || [])
        .sort((a, b) => a['Instituições:'].localeCompare(b['Instituições:']));

    const cardData: CardData[] = (users.state.user?.acls.institutions ?? []).map((institution, index) => ({
        id: index,
        title: [
            'Instituições'
        ],
        value: [
            institution.name
        ]
    }));


    return (
        <Container.table>
            {rows.length === 0 ? (
                <p>Você não possui instituições cadastradas.</p>
            ) : (
                <>
                    <Container.tables>
                        <Table rows={rows} columns={columns} menuIconDisplay='flex' numberRows={5} />
                    </Container.tables>
                    <Container.mobile>
                        <CardsTable cardData={cardData} />
                    </Container.mobile>
                </>
            )}
        </Container.table>
    );
}