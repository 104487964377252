import { HttpClient } from "../../HttpClient"


export interface RescueHash {
  hashCode: string
}

export async function RescueHashUseCase(dto: RescueHash) {
  const response = await HttpClient<RescueHash, unknown>(
    'post',
    '/api/hashes/redeem',
    dto
  )
  return response
}
