import { Container } from '../../styles';
import TableInstituion from './components/TableInstituion';
import TableOrg from './components/TableOrg';

export default function Member() {

    return (
        <Container.wrapper>
            <TableOrg />
            <TableInstituion />
        </Container.wrapper>
    );
}