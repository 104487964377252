interface Props {
	active: boolean
}
export const User = ({ active }: Props) => {

	if (active) return (
		<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.00065 1.3335C4.32065 1.3335 1.33398 4.32016 1.33398 8.00016C1.33398 11.6802 4.32065 14.6668 8.00065 14.6668C11.6807 14.6668 14.6673 11.6802 14.6673 8.00016C14.6673 4.32016 11.6807 1.3335 8.00065 1.3335ZM4.71398 12.1868C5.00065 11.5868 6.74732 11.0002 8.00065 11.0002C9.25398 11.0002 11.0073 11.5868 11.2873 12.1868C10.3807 12.9068 9.24065 13.3335 8.00065 13.3335C6.76065 13.3335 5.62065 12.9068 4.71398 12.1868ZM12.2407 11.2202C11.2873 10.0602 8.97398 9.66683 8.00065 9.66683C7.02732 9.66683 4.71398 10.0602 3.76065 11.2202C3.08065 10.3268 2.66732 9.2135 2.66732 8.00016C2.66732 5.06016 5.06065 2.66683 8.00065 2.66683C10.9407 2.66683 13.334 5.06016 13.334 8.00016C13.334 9.2135 12.9207 10.3268 12.2407 11.2202ZM8.00065 4.00016C6.70732 4.00016 5.66732 5.04016 5.66732 6.3335C5.66732 7.62683 6.70732 8.66683 8.00065 8.66683C9.29398 8.66683 10.334 7.62683 10.334 6.3335C10.334 5.04016 9.29398 4.00016 8.00065 4.00016ZM8.00065 7.3335C7.44732 7.3335 7.00065 6.88683 7.00065 6.3335C7.00065 5.78016 7.44732 5.3335 8.00065 5.3335C8.55398 5.3335 9.00065 5.78016 9.00065 6.3335C9.00065 6.88683 8.55398 7.3335 8.00065 7.3335Z" fill="#fff" />
		</svg>
	);

	return (
		<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.00065 1.3335C4.32065 1.3335 1.33398 4.32016 1.33398 8.00016C1.33398 11.6802 4.32065 14.6668 8.00065 14.6668C11.6807 14.6668 14.6673 11.6802 14.6673 8.00016C14.6673 4.32016 11.6807 1.3335 8.00065 1.3335ZM4.71398 12.1868C5.00065 11.5868 6.74732 11.0002 8.00065 11.0002C9.25398 11.0002 11.0073 11.5868 11.2873 12.1868C10.3807 12.9068 9.24065 13.3335 8.00065 13.3335C6.76065 13.3335 5.62065 12.9068 4.71398 12.1868ZM12.2407 11.2202C11.2873 10.0602 8.97398 9.66683 8.00065 9.66683C7.02732 9.66683 4.71398 10.0602 3.76065 11.2202C3.08065 10.3268 2.66732 9.2135 2.66732 8.00016C2.66732 5.06016 5.06065 2.66683 8.00065 2.66683C10.9407 2.66683 13.334 5.06016 13.334 8.00016C13.334 9.2135 12.9207 10.3268 12.2407 11.2202ZM8.00065 4.00016C6.70732 4.00016 5.66732 5.04016 5.66732 6.3335C5.66732 7.62683 6.70732 8.66683 8.00065 8.66683C9.29398 8.66683 10.334 7.62683 10.334 6.3335C10.334 5.04016 9.29398 4.00016 8.00065 4.00016ZM8.00065 7.3335C7.44732 7.3335 7.00065 6.88683 7.00065 6.3335C7.00065 5.78016 7.44732 5.3335 8.00065 5.3335C8.55398 5.3335 9.00065 5.78016 9.00065 6.3335C9.00065 6.88683 8.55398 7.3335 8.00065 7.3335Z" fill="#1485C8" />
		</svg>
	);
};
