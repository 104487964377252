import { Container } from './styles';
import { HeaderLogin } from '../../../../../components/common/HeaderLogin';
import { HeaderImg } from '../../../../../components/common/HeaderImg';
import { FormLogin, VerifyNextAndRedirect } from './components/FormLogin';
import image_login from '../../../../../assets/auth/image_login.svg';
import { LayoutAuth } from '../../../../../components/containers/LayoutAuth';
import background_login from '../../../../../assets/auth/background-login.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoginUser = () => {
  const navigate = useNavigate();
  const urlQuerys = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      VerifyNextAndRedirect(urlQuerys);

      return;
    } else {
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
    }
  }, []);

  return (
    <>
      <LayoutAuth background={background_login}>
        <HeaderImg imageSrc={image_login} altText="login" />
        <Container.auth>
          <HeaderLogin>Seja bem-vindo!</HeaderLogin>
          <FormLogin />
          <Container.password>
            <label htmlFor="remember">
              <input type="checkbox" />
              <span>Lembrar-me</span>
            </label>
            <p
              onClick={() => navigate('/request-recovery-password')}
              data-test="link-/request-recovery-password"
            >
              Esqueceu a senha?
            </p>
          </Container.password>
          <Container.section>
            <p>Não tem uma conta?</p>
            <span onClick={() => navigate('/createaccount')} data-test="link-create-account">
              Criar conta
            </span>
          </Container.section>
          {/* <Container.section>
            <p>Criar uma Organização</p>
            <span onClick={() => navigate('/createorg')} data-test="link-create-org">
              Criar Org
            </span>
          </Container.section> 
          <Container.section>
            <p>Criar uma instituição</p>
            <span onClick={() => navigate('/createinst')} data-test="link-create-inst">
              Criar Inst
            </span>
          </Container.section>*/}
        </Container.auth>
      </LayoutAuth>
    </>
  );
};
