import { UserTypeEnum } from '../../../../domain/interfaces/User';
import { HttpClient } from '../../../HttpClient';
import { SharedUseCase } from '../item/CreateItemForCampaignUseCase';

interface CreateVacancyForCampaignDto {
  title: string;
  description: string;
  address: string;
  state: string;
  city: string;
  multiplier: string;
  startDate: string;
  startHour: string;
  endHour: string;
}

interface Params {
  orgId: number;
  institutionId: number;
  campaignId: number;
}

export class CreateVacancyForCampaignUseCase extends SharedUseCase<
  Params,
  CreateVacancyForCampaignDto,
  {
    payload: void | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execOrg() {
    return await HttpClient<CreateVacancyForCampaignDto, void>(
      'post',
      `/campaigns/org/${this.params!.orgId}/institution/${this.params!.institutionId}/${
        this.params!.campaignId
      }/vacancies`,
      this.dto!
    );
  }

  protected override async execInstitution() {
    return await HttpClient<CreateVacancyForCampaignDto, void>(
      'post',
      `/campaigns/institution/${this.params!.institutionId}/${this.params!.campaignId}/vacancies`,
      this.dto!
    );
  }

  protected override async execGooders() {
    return await HttpClient<CreateVacancyForCampaignDto, void>(
      'post',
      `/campaigns/gooders/${this.params!.campaignId}/vacancies`,
      this.dto!
    );
  }
}
