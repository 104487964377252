import { useContext } from 'react';
import { Container } from './styles';
import pix from '../../../../../../../assets/icons/pix.svg';
import credit_card from '../../../../../../../assets/icons/credit_card.svg';
import boleto from '../../../../../../../assets/icons/boleto.svg';
import { LabelInputRadio } from '../../../../../../../components/common/Input';
import { CheckOutContext } from '../../../../../../../../infra/config/CheckOut';
import { SmallTransparentBlueButton } from '../../../../../../../components/common/Button';
import { DataMethod } from '../DataMethod';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { PaymentMethodEnum } from '../../../../../../../../application/useCases/checkout/CreatePaymentOrder';

export const PaymentMethod = () => {
  const { paymentMethod, setPaymentMethod, activeComponentIndex, setActiveComponentIndex } =
    useContext(CheckOutContext);
  const { setAlert } = useAlert();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value as PaymentMethodEnum);
  };

  const handleAlterarClick = () => {
    setPaymentMethod(null);
  };

  const handleNext = () => {
    if (paymentMethod) {
      setActiveComponentIndex((prevIndex: number) => prevIndex + 1);
    } else {
      setAlert('Escolha um método de pagamento antes de avançar.', 'error');
    }
  };

  const handlePrevious = () => {
    setActiveComponentIndex((prevIndex: number) => prevIndex - 1);
  };

  return (
    <>
      <Container.box>
        {!paymentMethod && (
          <>
            <LabelInputRadio
              src3={credit_card}
              id="CREDIT_CARD"
              htmlFor="CREDIT_CARD"
              name="paymentMethod"
              type="radio"
              value="CREDIT_CARD"
              onChange={handleRadioChange}
              aria-labelledby="CREDIT_CARD"
            >
              Cartão de Crédito
            </LabelInputRadio>
            <LabelInputRadio
              src3={pix}
              id="PIX"
              htmlFor="PIX"
              name="paymentMethod"
              type="radio"
              value="PIX"
              onChange={handleRadioChange}
              aria-labelledby="PIX"
            >
              Pix
            </LabelInputRadio>
            <LabelInputRadio
              src3={boleto}
              id="BOLETO"
              htmlFor="BOLETO"
              name="paymentMethod"
              type="radio"
              value="BOLETO"
              onChange={handleRadioChange}
              aria-labelledby="BOLETO"
            >
              Boleto
            </LabelInputRadio>
          </>
        )}
        {paymentMethod && (
          <Container.choicePay>
            {paymentMethod === 'CREDIT_CARD' && (
              <Container.choiceCardCredit>
                <img src={credit_card} alt=""></img>Cartão de crédito
              </Container.choiceCardCredit>
            )}
            {paymentMethod === 'PIX' && (
              <Container.choicePix>
                <img src={pix} alt=""></img> Pix
              </Container.choicePix>
            )}
            {paymentMethod === 'BOLETO' && (
              <Container.choiceBoleto>
                <img src={boleto} alt=""></img> Boleto
              </Container.choiceBoleto>
            )}
            <Container.button>
              <SmallTransparentBlueButton onClick={handleAlterarClick} loading={false}>
                Alterar
              </SmallTransparentBlueButton>
            </Container.button>
          </Container.choicePay>
        )}
      </Container.box>
      {paymentMethod === 'CREDIT_CARD' && <DataMethod />}
      <Container.buttons>
        <button type="button" onClick={handlePrevious} className="previous-button-border">
          Voltar
        </button>
        <button
          type="submit"
          onClick={handleNext}
          className={activeComponentIndex ? 'next-button-blue' : ''}
        >
          Avançar
        </button>
      </Container.buttons>
    </>
  );
};
