import { HttpClient } from '../../HttpClient';


export interface ListCoupon {
  id: number;
  type: "MONEY" | "PERCENTAGE",
  goodersPrice: number;
  description: string;
  off: number;
  expiresAt: string,
  code: string,
  quantity: number,
  partnerId: number,
  isLink: boolean;
  partner: {
    logo: string;
    name: string;
    id: number
  }
}

export async function GetListCouponUseCase() {

  const response = await HttpClient<void, { data: ListCoupon[] }>(
    'get', 
    '/api/rewards/coupons');
  return response;
  }

  export async function GetListCouponForEditUseCase() {

    const response = await HttpClient<void, { data: ListCoupon[] }>(
      'get', 
      '/api/rewards/gooders/coupons');
    return response;
    }
  
