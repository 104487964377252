import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Container, Text } from '../../../../pages/gooders/editar-campanha/styles';
import file from '../../../../assets/icons/file.svg';
import delet from '../../../../assets/icons/delet.svg';
import { useGlobalCampaignUnique } from '../../../../../infra/state/useCampaign';
import { GetCampaign } from '../../../../../application/useCases/campaign/GetCampaign';
import { useParams } from 'react-router-dom';
import { SmallBlueButton } from '../../../common/Button';
import { useAlert } from '../../../../../infra/config/AlertContext/useAlert';
import { useGlobalStore } from '../../../../../infra/state/GlobalStore';
import { CreateImageForCampaignUseCase } from '../../../../../application/useCases/campaign/images/CreateImageForCampaignUseCase';
import { DeleteImageOfCampaignUseCase } from '../../../../../application/useCases/campaign/images/DeleteImageOfCampaignUseCase';
import outlined from '../../../../assets/icons/outlinedgray.svg';
import { Loading } from '../../../common/Loading';

interface Props {
  useCase1: CreateImageForCampaignUseCase;
  useCase2: DeleteImageOfCampaignUseCase;
}
export const ImageCauseEdit = ({ useCase1, useCase2 }: Props) => {
  const [imagesEditCampaign, setImagesEditCampaign] = useState<File | null>(null);
  const { setCampaignUnique, campaignUnique, removeImageOfCampaign } = useGlobalCampaignUnique();
  const location = useParams();
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const users = useGlobalStore();
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await GetCampaign(location.path || '');
      if (!error && payload) {
        setCampaignUnique(payload.data);
      }
    };
    fetchData();
  }, []);

  const handleSaveImage = async () => {
    setLoading(true);

    if (!isImageChanged) {
      setAlert('Nada foi alterado, não é necessário salvar.', 'info');
      setLoading(false);
      return;
    }

    if (!imagesEditCampaign) {
      setAlert('Selecione uma imagem para ser enviada', 'error');
      setLoading(false);
      return;
    }

    if (!users.state.acl.selectedACL || !campaignUnique) {
      return;
    }

    if (campaignUnique.images && campaignUnique.images.length >= 4) {
      setAlert('Máximo de 4 imagens permitidas', 'error');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('image', imagesEditCampaign);
    const institutionId = campaignUnique.institution.id;
    const campaignId = campaignUnique.id;
    const orgId = users.state.acl.selectedACL.id;

    const { error } = await useCase1.execute({ orgId, institutionId, campaignId }, formData);

    if (!error) {
      setAlert('Imagem adicionada com sucesso', 'success');
      setImagesEditCampaign(null);
      window.location.reload();
    } else {
      setAlert(error, 'error');
    }

    setLoading(false);
  };

  async function handleChangeImage(ev: ChangeEvent<HTMLInputElement>) {
    const files = ev.currentTarget.files;
    const imageExist = files?.length;

    if (!imageExist) {
      return;
    } else {
      const image = files[0];
      setImagesEditCampaign(image);
      setIsImageChanged(true);
    }
  }

  async function handleDeleteImage(imageId: number, src: string) {
    if (!users.state.acl.selectedACL || !campaignUnique) {
      return;
    }

    if (campaignUnique.images && campaignUnique.images.length === 1) {
      setAlert('Não é possível apagar a única imagem', 'error');
      return;
    }

    const orgId = users.state.acl.selectedACL.id;
    const institutionId = campaignUnique.institution.id;
    const campaignId = campaignUnique.id;

    const { error } = await useCase2.execute({ orgId, institutionId, campaignId, imageId });

    if (!error) {
      setAlert('Imagem removida com sucesso', 'success');
      removeImageOfCampaign(imageId);
    } else {
      setAlert(error, 'error');
    }
  }

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const imagesCount = campaignUnique?.images?.length || 0;

  return (
    <Container.main>
      <Text.title>Imagens</Text.title>
      <Container.images>
        {campaignUnique ? (
          <>
            <Container.boxImage>
              <Container.inputImage>
                <Container.image
                  onDrop={(event) => {
                    event.preventDefault();
                    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                      const droppedFile = event.dataTransfer.files[0];
                      setImagesEditCampaign(droppedFile);
                    }
                  }}
                  onDragOver={(event) => {
                    event.preventDefault();
                  }}
                  style={{
                    border: '2px dashed #ccc',
                    width: '100%',
                    padding: '15% 0',
                  }}
                >
                  Arraste a imagem aqui
                </Container.image>
                <Container.image>
                  <label htmlFor="image">
                    <img src={file} alt="file" />
                    {imagesCount >= 4 ? 'Limite máximo 4 imagens' : 'Escolher Arquivo'}
                    <input
                      style={{ marginTop: '1rem', background: 'red' }}
                      id="image"
                      name="image"
                      type="file"
                      onChange={(ev) => handleChangeImage(ev)}
                      disabled={imagesCount >= 4 ? true : undefined}
                    />
                  </label>
                </Container.image>
              </Container.inputImage>
              <h3>Imagens ativas</h3>
              <Container.imagesSmall>
                {imagesCount > 0 &&
                  campaignUnique.images.map((image, index) => (
                    <Container.small key={index}>
                      <img
                        src={image.src}
                        alt={`imagem selecionada ${index + 1}`}
                        onClick={() => handleImageClick(index)}
                      />
                      <img
                        className="iconImage"
                        src={delet}
                        onClick={() => handleDeleteImage(image.id, image.src)}
                      />
                    </Container.small>
                  ))}
              </Container.imagesSmall>
              {imagesEditCampaign && (
                <Container.preview style={{ paddingTop: '16px' }}>
                  <h3>Imagem Adicionada</h3>
                  <div className="contentImage">
                    <img
                      className="imageCampaign"
                      src={URL.createObjectURL(imagesEditCampaign)}
                      alt="imagem selecionada"
                      style={{ borderRadius: '8px', paddingRight: '4px' }}
                    />
                    <img
                      className="IconImage"
                      src={delet}
                      onClick={() => setImagesEditCampaign(null)}
                    />
                  </div>
                </Container.preview>
              )}
            </Container.boxImage>
            <Container.boxImage>
              <Container.preview>
                <Container.cards>
                  {imagesCount > 0 && (
                    <>
                      {selectedImageIndex !== null ? (
                        <img src={campaignUnique.images[selectedImageIndex].src} alt="Campaign" />
                      ) : (
                        <>
                          {campaignUnique.images.length > 0 ? (
                            <img src={campaignUnique.images[0].src} alt="First Campaign" />
                          ) : (
                            <>
                              <p>Vizualize as imagens para sua campanha</p>
                              <img src={outlined} alt="logo" />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Container.cards>
              </Container.preview>
            </Container.boxImage>
          </>
        ) : (
          <Loading />
        )}
      </Container.images>
      <SmallBlueButton onClick={handleSaveImage} loading={loading}>
        ADICIONAR NOVA IMAGEM
      </SmallBlueButton>
    </Container.main>
  );
};
