import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

const Container__Main = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  height: auto;
  width: 100%;
  margin-top: 25px;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  .select {
    font-size: ${(props) => props.theme.fontSize.sm};
    border: none;
  }
`;

const Container__Title = styled.div`
  padding-bottom: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

const Container__Table = styled.div`
  padding: 14px 16px;
  .sort {
    color: ${(props) => props.theme.colors.white};
    span {
      color: ${(props) => props.theme.colors.white};
    }
  }
  @media (max-width: ${theme.media.mobile}) {
    display: none;
}
`;

const Container__Mobile = styled.div`
  display: none;
  width: 100%;
@media (max-width: ${theme.media.mobile}) {
  display: initial;
}
`;


const Container__filter = styled.section`
  display: flex;
  gap: 8px;
  padding: 0 0 16px;
  border-bottom: 2px solid #e4e7eb;
  position: relative;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: 16px;
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
  }
  .date {
    @media (max-width: ${theme.media.tablet}) {
      display: flex;
      flex-direction: column;
      height: 60px;
    }
  }
`;

const Container__Search = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  height: 32px;
  border: 1px solid ${(props) => props.theme.colors.lightgray};
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSize.smXXX};
  @media (max-width: ${theme.media.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Container__Select = styled.select`
  padding: 4px 8px;
  border: none;
  background: transparent;
  color: ${(props) => props.theme.colors.gray};
  outline: none;
  width: 224px;
  @media (max-width: ${theme.media.laptop}) {
    width: 100%;
  }
`;

const Container__Date = styled.input`
  padding: 4px 8px;
  border: none;
  background: transparent;
  color: ${(props) => props.theme.colors.gray};
  outline: none;
  cursor: pointer;
  @media (max-width: ${theme.media.laptop}) {
    width: 100%;
  }
`;

const Container__FilterButton = styled.div`
  padding: 4px 8px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  cursor: pointer;
  img {
    width: 16px;
    margin-right: 10px;
  }
`;

export const Container = {
  main: Container__Main,
  title: Container__Title,
  table: Container__Table,
  filter: Container__filter,
  search: Container__Search,
  select: Container__Select,
  date: Container__Date,
  filterButton: Container__FilterButton,
  mobile: Container__Mobile
};

const Input__Label = styled.label`
  max-width: 100%;
  @media (max-width: ${theme.media.tablet}) {
    display: flex;
  }
`;

const Input__Field = styled.input`
  padding: 4px 8px;
  border: none;
  background: transparent;
  outline: none;
`;

export const Input = {
  label: Input__Label,
  field: Input__Field,
};

export const SearchIcon = styled.i`
  margin-left: 0.5rem;
`;
