import { HttpClient } from '../../HttpClient';

export interface CreateOrgs {
  name: string;
  cnpj: string;
  telephone: string;
}


export async function CreateOrgUseCase(dto: CreateOrgs) {
  const { error, payload } = await HttpClient<CreateOrgs, void>(
    'post', 
    '/users/gooders/orgs', 
    dto);
  if (!error && payload) {
    return {
      data: payload,
    };
  } else {
    return { error };
  }
}
