import { HttpClient } from "../../HttpClient";

export interface EditPartner {
  contact: string,
  name: string,
  logo?: string,
  cnpj: string,
  id: number
}

  export async function EditPatnerUseCase (
    dto: EditPartner,
  ) {
    return await HttpClient<EditPartner, void>(
      'put',
      `/api/rewards/partners/${dto.id}`,
      dto
    );
  }

  export async function PatchImagePatnerUseCase (partnerId: number, formData: FormData) {
    return await HttpClient<FormData, void>(
      'patch',
      `/api/rewards/partners/${partnerId}/update-logo`, 
      formData,
      true
    );
  }

