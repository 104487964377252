import React, { useState } from "react";
import { Container } from "./styles";
import warning from "../../../../../assets/icons/warning.svg";
import logo_coin_white from "../../../../../assets/logo/logo_coin_white.svg";
import logo_coin from "../../../../../assets/logo/logo_coin.svg";
import close from "../../../../../assets/icons/close.svg";
import { SmallGreenButton } from "../../../Button";
import { useGlobalStore } from "../../../../../../infra/state/GlobalStore";
import { ReddemCoupon, ReddemCouponUseCase } from "../../../../../../application/useCases/rewards/RedeemCouponUseCase";
import { useAlert } from "../../../../../../infra/config/AlertContext/useAlert";
import { divideBy100 } from "../../../../../../application/utils";

interface ModalRewardProps extends React.HTMLAttributes<HTMLDivElement> {
	open: boolean;
	onClose: () => void;
	selectedCoupon: number | null;
	updateButtonStatus: (status: boolean) => void;
}

export const ModalReward = ({ open, onClose, selectedCoupon, updateButtonStatus }: ModalRewardProps) => {


	const [isLoading, setIsLoading] = useState(false);
	const list = useGlobalStore()
	const { setAlert } = useAlert();
	const [codeCoupon, setCodeCoupon] = useState('')

	if (!open) return null;

	const selectedCouponData = list.state.rewards.find(
		(coupon) => coupon.id === selectedCoupon
	);

	if (!selectedCouponData) {
		return null;
	}

	const { off, partner, goodersPrice, code, isLink } = selectedCouponData;
	const formattedGoodersPrice = divideBy100(goodersPrice);
	const formattedWallet = divideBy100(list.state.user?.wallet?.balance ?? 0);

	const handleButtonClick = async () => {
		setIsLoading(true);
		const dto: ReddemCoupon = {
			couponId: selectedCouponData.id,
		};
		const { payload, error }: { payload: any; error: any } = await ReddemCouponUseCase(dto);
		if (error) {
			setAlert(error, 'error');
			updateButtonStatus(true);
		} else {
			setAlert('Resgate efetuado com sucesso!', 'success');
			updateButtonStatus(true);
			if (isLink) {
				window.open(payload!.data.code, '_blank');
				onClose();
			}
			if (!isLink) {
				setCodeCoupon(payload!.data.code)
			}

		}
		setIsLoading(false);
	};

	return (
		<Container.box >
			<Container.warning>
				<Container.close src={close} alt="warning" onClick={onClose} />
				<Container.icon>
					<img src={warning} alt="warning" />
					<h3>Deseja confirmar a transação?</h3>
				</Container.icon>
				<p>Ao clicar no botão abaixo você vai resgatar a recompensa:</p>
				<span> CUPOM {off}% - {partner.name}</span>
				<Container.action>
					<SmallGreenButton onClick={handleButtonClick} loading={isLoading}>
						<img src={logo_coin_white} alt="warning" /> {formattedGoodersPrice} Gooders
					</SmallGreenButton>
				</Container.action>
				<p>
					Novo Saldo: <img src={logo_coin} alt="warning" width="18px" />{" "}
					<span>
						{formattedWallet - formattedGoodersPrice} Gooders Coins
					</span>
				</p>

				{codeCoupon && !isLink && (
					<p>
						Código de acesso ao cupom: <span>{codeCoupon}</span>
					</p>
				)}
			</Container.warning>
		</Container.box>
	);
};
