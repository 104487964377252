import styled from 'styled-components';
import { theme } from '../../../themes/blue';

export interface _Props {
  width?: string;
  ulWidht?: string;
  alignItems?: string;
  justifyContent?: string;
  bgColor?: string;
  openModal?: boolean;
}

interface Container__Main {
  openModal: boolean;
}

const Container__Main = styled.section<Container__Main>`
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0px;
  width: ${(p) => (p.openModal ? '241px' : '96px')};
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px rgba(73, 80, 87, 0.08);
  z-index: 100;
  .logout {
    padding: 6px;
    position: absolute;
    bottom: 75px;
    @media (max-width: ${theme.media.mobile}) {
      position: initial;
    }
  }

  @media (max-width: ${theme.media.mobile}) {
    width: 0;
    .ulMobileNone {
   display: none 
  }
  }
`;

const Container__Sidebar = styled.div<_Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  nav {
    align-items: center;
    flex-flow: column nowrap;
    justify-content: flex-start;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 22px;
    .line {
      margin: 16px 0;
      width: 100%;
    }
    span {
      font-size: ${(props) => props.theme.fontSize.sm};
    }
  }
  ul {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: ${(p) => (p.openModal ? 'flex-start' : 'center')};
    width: 100%;
    height: 100%;
    list-style: none;
    gap: 8px;
    .icon {
      align-items: flex-end;
      width: 100%;
      display: flex;
      justify-content: ${(p) => (p.openModal ? 'flex-end' : 'center')};
      position: ${(p) => (p.openModal ? 'absolute' : 'initial')};
      top: 45px;
      left: -25px;
      @media (max-width: ${theme.media.mobile}) {
        display: none;
      }
    }
    :last-child {
      align-items: center;
      margin-top: auto;
      width: 100%;
      justify-content: flex-end;
    }
    .line {
      margin: 0;
      width: ${(p) => (p.openModal ? '100%' : '40px')};
    }
  }
    li {
    display: flex;
    justify-content: ${(p) => (p.openModal ? 'flex-start' : 'center')};
    align-items: center;
    a {
      display: flex;
      justify-content: center;
    }
    p {
      display: ${(p) => (p.openModal ? 'initial' : 'none')};
    }
  }
  .navIconMobile{
      display: none;
    }
  @media (max-width: ${theme.media.mobile}) {
    position: fixed;
    width: 100%;
    background:  ${(props) => props.theme.colors.white};;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: auto;
    bottom: 0;
    flex-flow: row nowrap;
    height: 4.5rem;
    border-radius: 1rem 1rem 0px 0px;
    box-shadow: 5px 0px 10px 5px rgb(153 153 153 / 30%);
    .navIconLink{
      display: none;
    }
    .navIconMobile{
      display: flex;
    }
    img {
      display: none;
    }
    nav {
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        display: none;
      }
    }
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 0 32px;
    }
    section {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    li {

  } }
`;
const Container__Header = styled.div<_Props>`
  display: flex;
`;

const Container__Logo = styled.div<_Props>``;

const Container__Inst = styled.div<_Props>`
  display: ${(p) => (p.openModal ? 'initial' : 'none')};
  @media (max-width: ${theme.media.mobile}) {
    display: none;
  }
`;

const Container__Acl = styled.div<_Props>`
  display: ${(p) => (p.openModal ? 'flex' : 'none')};
  width: 100%;
  @media (max-width: ${theme.media.mobile}) {
    display: none;
  }
`;

export const Container = {
  main: Container__Main,
  sidebar: Container__Sidebar,
  header: Container__Header,
  logo: Container__Logo,
  inst: Container__Inst,
  acl: Container__Acl,
};

const Header__title = styled.div<_Props>`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  display: ${(p) => (p.openModal ? 'initial' : 'none')};
  @media (max-width: ${theme.media.mobile}) {
    display: none;
  }
`;

const Header__name = styled.div`
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.md};
  line-height: 24px;
  text-transform: capitalize;
`;

const Header__edit = styled.div`
  display: flex;
  text-align: left;
  a {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize.smX};
    line-height: 18px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
  }
`;

const Header__Img = styled.div`
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid ${(props) => props.theme.colors.primary};
  }
`;

const Header__Text = styled.div``;

export const Header = {
  title: Header__title,
  name: Header__name,
  edit: Header__edit,
  img: Header__Img,
  text: Header__Text,
};

const Nav__Text = styled.div<_Props>`
  display: ${(p) => (p.openModal ? 'initial' : 'none')};
  padding-left: 12px;
  font-size: ${(props) => props.theme.fontSize.sm};
  letter-spacing: 0.02em;
  @media (max-width: ${theme.media.mobile}) {
    display: none;
  }
`;

const Nav__Img = styled.div<_Props>`
  display: ${(p) => (p.openModal ? 'flex' : 'none')};
  width: 100%;
  justify-content: center;
  align-items: center;
  img {
    min-width: 150px;
  }
  @media (max-width: ${theme.media.mobile}) {
    display: none;
  }
`;

export const Nav = {
  text: Nav__Text,
  img: Nav__Img,
};

export const Lines = styled.div`
  width: 90%;
  border-bottom: 2px solid ${(props) => props.theme.colors.paleGray};
`;
