import { UserTypeEnum } from "../../../domain/interfaces/User";
import { HttpClient } from "../../HttpClient";
import { SharedUseCase } from "../campaign/item/CreateItemForCampaignUseCase";

export interface ConnectUser {
  email: string,
  role: 'USER' | 'VOLUNTEER' | 'ADMIN',
}


interface Params {
  orgId: number;
  institutionId: number;
}


export class ConnectUserInstOrgUseCase extends SharedUseCase<
  Params,
  ConnectUser,
  {
    payload: void | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: true, hasParams: true });
  }

  protected override async execOrg() {
    return await HttpClient<ConnectUser, void>(
      'post',
      `/users/orgs/${this.params!.orgId}/members`,
      this.dto!
    );
  }

  protected override async execInstitution() {
    return await HttpClient<ConnectUser, void>(
      'post',
      `/users/orgs/${this.params!.orgId}/institution/${this.params!.institutionId}/members`,
      this.dto!
    );
  }
}



