import styled from 'styled-components';
import { theme } from '../../../themes/blue';

export const Container = styled.footer<{ openModal: boolean; width?: string }>`
  position: absolute;
  width: ${(props) => props.width};
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 30px 8px 16px;
  background: ${(props) => props.theme.colors.orange};
  box-shadow: 0px 2px 8px rgba(73, 80, 87, 0.08);
  color: ${(props) => props.theme.colors.white};
  letter-spacing: 0.02em;
  p {
    font-size: ${(props) => props.theme.fontSize.md};
    line-height: 20px;
  }
  a {
    font-size: ${(props) => props.theme.fontSize.md};
    line-height: 20px;
  }
  @media (max-width: ${theme.media.mobile}) {
    display: none;
  }
`;
