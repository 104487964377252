

/* eslint-disable max-len */
import React from "react";

interface Props {
	active: boolean
}
export const Discount = ({ active }: Props) => {

	if (active) return (
		<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.19935 7.26667C6.68602 6.87333 6.19935 6.46667 6.19935 5.83333C6.19935 5.10667 6.87268 4.6 7.99935 4.6C8.94602 4.6 9.41935 4.96 9.59268 5.53333C9.67268 5.8 9.89268 6 10.1727 6H10.3727C10.8127 6 11.126 5.56667 10.9727 5.15333C10.6927 4.36667 10.0393 3.71333 8.99935 3.46V3C8.99935 2.44667 8.55268 2 7.99935 2C7.44602 2 6.99935 2.44667 6.99935 3V3.44C5.70602 3.72 4.66602 4.56 4.66602 5.84667C4.66602 7.38667 5.93935 8.15333 7.79935 8.6C9.46602 9 9.79935 9.58667 9.79935 10.2067C9.79935 10.6667 9.47268 11.4 7.99935 11.4C6.89935 11.4 6.33268 11.0067 6.11268 10.4467C6.01268 10.1867 5.78602 10 5.51268 10H5.32602C4.87935 10 4.56602 10.4533 4.73268 10.8667C5.11268 11.7933 5.99935 12.34 6.99935 12.5533V13C6.99935 13.5533 7.44602 14 7.99935 14C8.55268 14 8.99935 13.5533 8.99935 13V12.5667C10.2993 12.32 11.3327 11.5667 11.3327 10.2C11.3327 8.30667 9.71268 7.66 8.19935 7.26667Z" fill="#fff" />
		</svg>
	);

	return (
		<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.19935 7.26667C6.68602 6.87333 6.19935 6.46667 6.19935 5.83333C6.19935 5.10667 6.87268 4.6 7.99935 4.6C8.94602 4.6 9.41935 4.96 9.59268 5.53333C9.67268 5.8 9.89268 6 10.1727 6H10.3727C10.8127 6 11.126 5.56667 10.9727 5.15333C10.6927 4.36667 10.0393 3.71333 8.99935 3.46V3C8.99935 2.44667 8.55268 2 7.99935 2C7.44602 2 6.99935 2.44667 6.99935 3V3.44C5.70602 3.72 4.66602 4.56 4.66602 5.84667C4.66602 7.38667 5.93935 8.15333 7.79935 8.6C9.46602 9 9.79935 9.58667 9.79935 10.2067C9.79935 10.6667 9.47268 11.4 7.99935 11.4C6.89935 11.4 6.33268 11.0067 6.11268 10.4467C6.01268 10.1867 5.78602 10 5.51268 10H5.32602C4.87935 10 4.56602 10.4533 4.73268 10.8667C5.11268 11.7933 5.99935 12.34 6.99935 12.5533V13C6.99935 13.5533 7.44602 14 7.99935 14C8.55268 14 8.99935 13.5533 8.99935 13V12.5667C10.2993 12.32 11.3327 11.5667 11.3327 10.2C11.3327 8.30667 9.71268 7.66 8.19935 7.26667Z" fill="#1485C8" />
		</svg>
	);
};

