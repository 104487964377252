import { HttpClient } from "../../HttpClient";


export async function DeleteOrgUseCase(orgId: number,)
{
  return await HttpClient(
    'delete',
    `/users/gooders/orgs/${orgId}`
    );
}
