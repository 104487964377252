import { Container, Title } from './styles';
import { Link } from 'react-router-dom';
import { ModalPayment } from '../Modal/ModalPayment';
import { ModalVoluntary } from '../Modal/ModalVoluntary';
import { useEffect, useState } from 'react';
import { ModalDonation } from '../Modal/ModalDonation';
import { useGlobalCampaignUnique } from '../../../../../../infra/state/useCampaign';
import { GetCampaign } from '../../../../../../application/useCases/campaign/GetCampaign';
import close from '../../../../../assets/icons/close.svg';


interface Props {
  open: boolean;
  onClose: () => void;
  selectedCause: number | null;
}

export const LayoutModal = ({ open, onClose, selectedCause }: Props) => {
  const [activeTab, setActiveTab] = useState('');
  const [error, setError] = useState<string | null>(null);
  const state = useGlobalCampaignUnique();

  useEffect(() => {
    if (!state.campaignUnique && selectedCause !== null) {
      const fetchData = async () => {
        const { error, payload } = await GetCampaign(selectedCause.toString());
        if (!error && payload) {
          state.setCampaignUnique(payload?.data);
        }
        setError(error);
      };
      fetchData();
    }
  }, [state.campaignUnique, selectedCause]);

  useEffect(() => {
    if (state.campaignUnique && !activeTab) {
      if (state.campaignUnique.moneyDonationOptions) {
        setActiveTab('payment');
      } else if (state.campaignUnique.items) {
        setActiveTab('donation');
      } else if (state.campaignUnique.vacancies) {
        setActiveTab('voluntary');
      }
    } else if (state.campaignUnique && activeTab === 'payment' && !state.campaignUnique.moneyDonationOptions) {
      setActiveTab(state.campaignUnique.items ? 'donation' : 'voluntary');
    }
  }, [state.campaignUnique, activeTab]);


  if (!open) {
    return null;
  }

  const { items, moneyDonationOptions, vacancies } = state.campaignUnique || {};

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <Container.Main onClick={onClose}>
      <Container.Modal onClick={(e) => { e.stopPropagation(); }}>
        <Container.close src={close} alt="warning" onClick={onClose} />
        <Container.Title>
          {moneyDonationOptions && (
            <Link to="#" onClick={() => handleTabClick('payment')}>
              <Title.Donate active={activeTab === 'payment'}>Arrecadação</Title.Donate>
            </Link>
          )}
          {items && (
            <Link to="#" onClick={() => handleTabClick('donation')}>
              <Title.Pay active={activeTab === 'donation'} data-test="donation_item_modal">Doação</Title.Pay>
            </Link>
          )}
          {vacancies && (
            <Link to="#" onClick={() => handleTabClick('voluntary')}>
              <Title.Voluntary active={activeTab === 'voluntary'} data-test="donation_voluntary_modal">Voluntariado</Title.Voluntary>
            </Link>
          )}
        </Container.Title>
        <Container.Content>
          <Container.Border>
            {items && activeTab === 'donation' ? <ModalDonation onClose={onClose} /> : null}
            {vacancies && activeTab === 'voluntary' ? <ModalVoluntary onClose={onClose} /> : null}
            {moneyDonationOptions && activeTab === 'payment' ? (<ModalPayment onClose={onClose} />) : null}
          </Container.Border>
        </Container.Content>
      </Container.Modal>
    </Container.Main>
  );
};
