import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

const Container__Support = styled.div`
  position: relative;
`;

const Container__CardsSupport = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Container__NoneSupport = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  padding-bottom: 16px;
  img {
    width: 264px;
  }
  p {
    text-align: center;
  }
  @media (max-width: ${theme.media.laptop}) {
    img {
      display: none;
    }
    p {
      padding-top: 20px;
    }
  }
`;

const Container__Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  p {
    font-size: ${(props) => props.theme.fontSize.lg};
    line-height: 32px;
  }
  @media (max-width: ${theme.media.tablet}) {
    width: 100%;
    p {
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }
`;

const Container__Cards = styled.div`
  padding: 16px 0;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Container__Buttons = styled.div`
  display: flex;
gap: 16px;
align-items: center;
justify-content: center;
padding-bottom: 16px;
  button {
    background: transparent;
    width: 32px;
    height: 32px;
    border: 1px solid ${(props) => props.theme.colors.lightgray};
    color: ${(props) => props.theme.colors.gray};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      color: #522E87;
      border: 1px solid ${(props) => props.theme.colors.primary};
    }
    span {
      font-size: 18px;
      &:hover {
      color:${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const Container = {
  text: Container__Text,
  support: Container__Support,
  noneSupport: Container__NoneSupport,
  cards: Container__Cards,
  cardsSupport: Container__CardsSupport,
  buttons: Container__Buttons,
};
