import { Box } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useAlert } from '../../../../../../../../../../infra/config/AlertContext/useAlert';

interface Props {
  children: ReactNode;
}

export const PayCreditCard = ({ children }: Props) => {
  const alert = useAlert();

  useEffect(() => {
    alert.setAlert('Pagamento concluído com sucesso.', 'success');
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding="0 1rem"
      height="100%"
      gap="1rem"
    >
      {children}
    </Box>
  );
};
