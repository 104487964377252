import { useState } from 'react';
import { Container, Text } from './styles';
import { HeaderImg } from '../../../components/common/HeaderImg';
import { HeaderLogin } from '../../../components/common/HeaderLogin';
import user from '../../../assets/auth/user.svg';
import { LayoutAuth } from '../../../components/containers/LayoutAuth';
import background_login from '../../../assets/auth/background-login.png';
import { handleLogout } from '../../../../application/handlers/HandleLogout';
import { ModalCancel } from './components/ModalCancel';
import { FormAcessUser } from './components/CreateUser/components/FormAcessUser';

export const CreateAcess: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <LayoutAuth background={background_login}>
      <HeaderImg imageSrc={user} altText="voluntary" />
      <Container.create>
        <HeaderLogin>{ }</HeaderLogin>
        <Container.header>
          <Text.desc>Complete seu cadastro para continuar</Text.desc>
        </Container.header>
        <FormAcessUser />
        <Container.section>
          <span
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Cancelar
          </span>
          <p onClick={handleLogout}>Deseja entrar com outra conta?</p>
        </Container.section>
        <ModalCancel open={openModal} onClose={() => setOpenModal(false)} />
      </Container.create>
    </LayoutAuth>
  );
};
