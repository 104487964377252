import styled from "styled-components";


const Container__Modal = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(70, 125, 192, 0.2);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 999;
`;

const Container__Close = styled.img`
position: absolute;
top: -70px;
right: 60px;
cursor: pointer;
`;

const Container__Box = styled.div`
  width: 90%;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 16px rgba(73, 80, 87, 0.92);
  border-radius: 8px;
  padding: 16px 24px;
  justify-content: center;
  `;

const Container__Cards = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
  display: none;}
`;

const Container__Button = styled.div`
display: flex;
gap: 16px;
align-items: center;
justify-content: center;
padding-top: 16px;
  button {
    background: transparent;
    width: 32px;
    height: 32px;
    border: 1px solid  ${(props) => props.theme.colors.lightgray};
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.colors.primary};
      border: 1px solid  ${(props) => props.theme.colors.primary};
    }
    span {
      font-size: 18px;
    }
  }
`;

export const Container = {
	modal: Container__Modal,
  box: Container__Box,
  cards: Container__Cards,
  button: Container__Button,
  close: Container__Close
};
