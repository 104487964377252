
import { Container } from "./styles";
import heart from "../../../assets/icons/heart.svg"

interface Props {
	openModal: boolean;
	width?: string;
}

const Footer: React.FC<Props> = ({ openModal, width = "100%" }) => {
	return (
		<Container openModal={openModal} width={width}>
			<p>© 2023 - Gooders | Desenvolvido com  <img src={heart} alt="heart" /><a href="https://ifdot.com.br/" target="_blank"> por IFdot.</a></p>
		</Container>
	);
};

export default Footer;