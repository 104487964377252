import { Container, Description } from "./styles";
import locality from "../../../../../assets/icons/locality.svg";
import { useGlobalCampaignUnique } from "../../../../../../infra/state/useCampaign";

export const HeaderCampaign: React.FC = () => {
	const { campaignUnique } = useGlobalCampaignUnique();

	return (
		<>
			<Description.Title>{campaignUnique?.title.slice(0, 30)}</Description.Title>
			<Description.Subtitle style={{ minHeight: "210px" }}>
				<p>{campaignUnique?.description.slice(0, 700)}</p>
			</Description.Subtitle>

			<Container.Location>
				<div>
					{campaignUnique?.items && campaignUnique?.items?.length > 0 || campaignUnique?.vacancies && campaignUnique?.vacancies?.length > 0 ? (
						<a href='#' onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
							e.preventDefault();
							const targetElement = document.getElementById('ItemAndVoluntary');
							if (targetElement) {
								const yOffset = -200;
								const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
								window.scrollTo({ top: y, behavior: 'smooth' });
							}
						}}>
							<button> Ver doações e voluntariados</button></a>
					) : null}
				</div>
				<div>
					<img src={locality} alt="cidade" />
					<span>{campaignUnique?.city} - </span>
					<span>{campaignUnique?.state}</span>
				</div>

			</Container.Location>
		</>
	);
};
