import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';


const Container__Modal = styled.section`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  background: rgba(70, 125, 192, 0.03);
  color: ${(props) => props.theme.colors.black};
  z-index: 999;
  @media (max-width: ${theme.media.laptop}) {
    position: absolute;
    width: 100%;
    height: auto;
    padding-top: 0px;
  }
`;

const Container__Form = styled.form`
  position: relative;
  width: 70%;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 16px rgba(73, 80, 87, 0.32);
  border-radius: 8px;
  padding: 24px;
  @media (max-width: ${theme.media.laptop}) {
    min-height: 100vh;
    width: 100%;
  }
`;

const Container__Progress = styled.div`
  width: 100%;
  position: relative;
`;


const Container__Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  .error {
    color: ${(props) => props.theme.colors.red};
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.smX};
    line-height: 18px;
  }
`;

const Container__Input = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
`;


const Container__Header = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Inputs = styled.div`
  display: flex;
  width: 100%;
  padding-top: 8px;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Textarea = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const Container__Footer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 2px solid ${(props) => props.theme.colors.lightgray};
  margin: 12px 0 0;
  padding-top: 16px;
`;


const Container__Checkbox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 8px;
  label {
    display: flex;
    border: 1px solid ${(props) => props.theme.colors.lightgray};
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.gray};
    p {
      font-size: ${(props) => props.theme.fontSize.sm};
    }
  }
  label.checked {
    background-color: ${(props) => props.theme.colors.gray};
    color: ${(props) => props.theme.colors.white};
  }
  input {
    appearance: none;
    width: 80%;
    width: 18px;
    height: 18px;
    border: 2px solid #cccccc;
    border-radius: 50%;
    outline: none;
    margin-right: 4px;
    position: relative;
    color: ${(props) => props.theme.colors.gray};
  }
  input:checked::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.colors.gray};
    border-radius: 50%;
  }
`;

export const Container = {
  modal: Container__Modal,
  form: Container__Form,
  progress: Container__Progress,
  footer: Container__Footer,
  main: Container__Main,
  header: Container__Header,
  textarea: Container__Textarea,
  input: Container__Input,
  inputs: Container__Inputs,
  checkbox: Container__Checkbox,
};

export const Line = styled.div`
  position: absolute;
  height: 4px;
  width: 70%;
  background: ${(props) => props.theme.colors.primary};
  opacity: 0.2;
  top: 40px;
`;

export const CloseIcon = styled.img`
  position: absolute;
  right: 15px;
  cursor: pointer;
`;

const Text__Title = styled.h2`
  padding: 20px 0 ;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};;
`;

export const Text = {
  title: Text__Title,
};

