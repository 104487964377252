import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 8px;
  background: ${props => props.theme.colors.white};
  border: none;
  label {
    width: 100%;
    position: relative;
    border: 1px solid${props => props.theme.colors.lightgray};
    border-radius: 4px;
    width: 100%;
  }
  textarea {
    border: none;
    outline: none;
    min-width: 100%;
    padding: 12px;
    font-weight: 400;
    font-size: ${props => props.theme.fontSize.sm};
    line-height: 20px;
    border-radius: 4px;
  }
  span {
    padding: 0px 4px !important;
    border-radius: 4px;
    background:${props => props.theme.colors.white};
    position: absolute;
    top: -10px;
    left: 10px;
    font-weight: 400;
    font-size: ${props => props.theme.fontSize.sm};
    line-height: 20px;
    color: ${props => props.theme.colors.dark};
  }
`;

export const _TextAreaFormCampaign = styled(Container)``;
