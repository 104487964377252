

/* eslint-disable max-len */
import React from "react";

interface Props {
	active: boolean
}
export const Logoff = ({ active }: Props) => {

	if (active) return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.10547 2H8.10547C8.65547 2 9.10547 1.55 9.10547 1C9.10547 0.45 8.65547 0 8.10547 0H2.10547C1.00547 0 0.105469 0.9 0.105469 2V16C0.105469 17.1 1.00547 18 2.10547 18H8.10547C8.65547 18 9.10547 17.55 9.10547 17C9.10547 16.45 8.65547 16 8.10547 16H2.10547V2Z" fill="#1485C8" />
			<path d="M17.7555 8.65L14.9655 5.86C14.6455 5.54 14.1055 5.76 14.1055 6.21V8H7.10547C6.55547 8 6.10547 8.45 6.10547 9C6.10547 9.55 6.55547 10 7.10547 10H14.1055V11.79C14.1055 12.24 14.6455 12.46 14.9555 12.14L17.7455 9.35C17.9455 9.16 17.9455 8.84 17.7555 8.65Z" fill="#1485C8" />
		</svg>




	);

	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.10547 2H8.10547C8.65547 2 9.10547 1.55 9.10547 1C9.10547 0.45 8.65547 0 8.10547 0H2.10547C1.00547 0 0.105469 0.9 0.105469 2V16C0.105469 17.1 1.00547 18 2.10547 18H8.10547C8.65547 18 9.10547 17.55 9.10547 17C9.10547 16.45 8.65547 16 8.10547 16H2.10547V2Z" fill="#1485C8" />
			<path d="M17.7555 8.65L14.9655 5.86C14.6455 5.54 14.1055 5.76 14.1055 6.21V8H7.10547C6.55547 8 6.10547 8.45 6.10547 9C6.10547 9.55 6.55547 10 7.10547 10H14.1055V11.79C14.1055 12.24 14.6455 12.46 14.9555 12.14L17.7455 9.35C17.9455 9.16 17.9455 8.84 17.7555 8.65Z" fill="#1485C8" />
		</svg>




	);
};

