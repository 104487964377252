import { GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../../../components/common/Table';
import { useEffect, useState } from 'react';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import logo_coin from '../../../../../../../assets/logo/logo_coin.svg';
import { TransactionsVacancy, UserTransactionsVacancy } from '../../../../../../../../application/useCases/donation/UserTransactionsVacancy';
import { Container } from '../../styles'
import { CardData, CardsTable } from '../../../../../../../components/common/CardsTable';

export const TableTransactionsVoluntary = () => {
  const { setAlert } = useAlert();
  const [transaction, setTransaction] = useState<TransactionsVacancy[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await UserTransactionsVacancy();
      if (!error && payload) {
        setTransaction(payload.data);
      } else {
        setAlert(error!, 'error');
      }
    };
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    { field: 'Data:', headerName: 'Data:', minWidth: 120, flex: 1, headerClassName: 'table-header', sortable: false, filterable: false, },
    { field: 'Campanha:', headerName: 'Campanha:', minWidth: 300.3, flex: 1, headerClassName: 'table-header', },
    {
      field: 'Status:', headerName: 'Status:', minWidth: 120.3, flex: 1, headerClassName: 'table-header',
      renderCell: (params) => (
        <div
          style={{
            color:
              params.row['Status:'] === 'SUCESSO'
                ? '#01742D'
                : params.row['Status:'] === 'PENDENTE'
                  ? '#ecd500'
                  : params.row['Status:'] === 'CANCELADO'
                    ? '#C31526'
                    : 'inherit',
          }}
        >
          {params.row['Status:']}
        </div>
      ),
    },
    {
      field: 'Gooders Coins', headerName: 'Gooders Coins', minWidth: 120, flex: 1, headerAlign: 'right', align: 'right', headerClassName: 'table-header',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', color: 'rgb(1, 116, 45)', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
          <img src={logo_coin} alt="logo_coin" style={{ width: '20px', paddingRight: '6px' }} />
          {params.row['Gooders Coins']}
        </div>
      ),
    },
  ];

  const sortedTransactions = [...transaction].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());


  const rows = sortedTransactions.map((item, index) => ({
    index: index,
    id: index,
    'Data:': new Date(item.createdAt).toLocaleDateString('pt-BR'),
    'Campanha:': item.campaign.title,
    'Status:': item.status,
    'Gooders Coins': String(item.gooders) + ' Coins',
  }));

  const cardData: CardData[] = sortedTransactions.map((item) => ({
    id: item.id,
    title: [
      'Data',
      'Campanha',
      'Status',
      'Gooders Coins'
    ],
    value: [
      new Date(item.createdAt).toLocaleDateString('pt-BR'),
      item.campaign.title,
      item.status,
      String(item.gooders)
    ]
  }));


  return (
    <>
      <Container.desktop>
        <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />;
      </Container.desktop>
      <Container.mobile>
        <CardsTable cardData={cardData} />
      </Container.mobile>
    </>
  )
};
