import { HeaderPages } from "../../../components/common/HeaderPages";
import { ListCampaing } from "./components/ListCampaigns";

export const ListCampaigns: React.FC = () => {
	
	return (
		<HeaderPages title="Ajude a fazer o bem!">
			<ListCampaing />
		</HeaderPages>
	);
};
