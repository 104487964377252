import { Container } from "./styles";
import { LayoutSidebar } from "../../../components/containers/LayoutSidebar";
import { ReactNode } from "react";


interface PropsHeaderPages {
	title: string
	children: ReactNode
}

export const HeaderPages: React.FC<PropsHeaderPages> = ({ children, title }) => {

	return (
		<LayoutSidebar>
			<Container.box>
				<Container.header>
					<Container.title>{title}</Container.title>
				</Container.header>
				<Container.main>
					{children}
				</Container.main>
			</Container.box>
		</LayoutSidebar>
	);
};