
import { Container } from '../../gooders/gerenciar-usuarios/styles';
import { LayoutSidebar } from '../../../components/containers/LayoutSidebar';
import { TableListMemberInst } from './components/TableListMemberInst';


export const GerenciarUserInst = () => {

    return (
        <>
            <LayoutSidebar>
                <Container.box>
                    <Container.header>
                        <Container.title data-test="title-campaign">Usuários</Container.title>
                    </Container.header>
                    <Container.support>
                        <Container.choice>
                            <Container.subTitle >
                                Instituições
                            </Container.subTitle>
                        </Container.choice>
                    </Container.support>
                    <Container.support>
                        <TableListMemberInst />
                    </Container.support>
                </Container.box>
            </LayoutSidebar>
        </>
    );
};
