import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { theme } from './ui/themes/blue';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import { AuhtProvider } from './infra/config/AuthProvider';
import { RouterProvider } from './infra/config/routes';
import { AlertProvider } from './infra/config/AlertContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme as DefaultTheme}>
    <AuhtProvider>
      <AlertProvider>
        <RouterProvider />
      </AlertProvider>
    </AuhtProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

reportWebVitals();
