import { Container, Input } from '../../styles'

interface SearchListCampaignProps {
    startDate: string;
    endDate: string;
    handleDateChange: (event: React.ChangeEvent<HTMLInputElement>, type: 'start' | 'end') => void;
}


export const DateTableListCampaign: React.FC<SearchListCampaignProps> = ({
    startDate,
    endDate,
    handleDateChange,
}) => {
    return (
        <Container.search className="date">
            <Input.label htmlFor="start-date-input"></Input.label>
            <Container.date
                id="start-date-input"
                type="date"
                value={startDate}
                onChange={(event) => handleDateChange(event, 'start')}
            />
            <Container.date
                id="end-date-input"
                type="date"
                value={endDate}
                onChange={(event) => handleDateChange(event, 'end')}
            />
        </Container.search>

    )
}