import { ChangeEvent, useContext, useState } from 'react';
import { Container, Text } from '../CreateCause/styles';
import file from '../../../../../../assets/icons/file.svg';
import delet from '../../../../../../assets/icons/delet.svg';
import { CampaignContext } from '../../../../../../../infra/config/CampaignContext';
import outlined from '../../../../../../assets/icons/outlinedgray.svg'

export const ImageCause = () => {
  const { images, setImages } = useContext(CampaignContext);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);


  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedImages = Array.from(e.target.files || []);
    if (selectedImages && selectedImages.length > 0) {
      setImages((prevImages) => [...(prevImages ?? []), ...selectedImages]);
    }
  };

  const handleDeleteImage = (index: number) => {
    setImages((prevImages) => {
      const newImages = [...(prevImages ?? [])];
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  return (
    <Container.main style={{ flexDirection: "row" }}>
      <Container.images>
        <Container.box>
          <Container.inputImage>
            <Text.title>Defina as images da sua campanha</Text.title>
            <Container.image
              onDrop={(event) => {
                event.preventDefault();
                if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                  const droppedFile = event.dataTransfer.files[0];
                  setImages((prevImages) => [...(prevImages ?? []), droppedFile]);
                }
              }}
              onDragOver={(event) => {
                event.preventDefault();
              }}
              style={{ border: '2px dashed #ccc', width: '100%', padding: '15% 0' }}>
              Arraste a imagem aqui
            </Container.image>
            <Container.image>
              <label htmlFor="image">
                <img src={file} alt="file" />
                Escolher Arquivo
                <input
                  style={{ marginTop: '1rem', background: 'red' }}
                  id="image"
                  name="image"
                  type="file"
                  onChange={handleImage}
                />
              </label>
              <p>Selecione até 4 imagens. A primeira imagem será definada como capa da sua campanha.</p>
            </Container.image>
          </Container.inputImage>

          <Container.imagesSmall>
            {images && images.length > 0 ? (
              images.slice(0, 4).map((image, index) => (
                <Container.small key={index}>
                  <img src={URL.createObjectURL(image)} alt={`imagem selecionada ${index + 1}`} onClick={() => handleImageClick(index)} />
                  <img className="iconImage" src={delet} onClick={() => handleDeleteImage(index + 1)} />
                </Container.small>
              ))
            ) : (
              <>
                {[1, 2, 3, 4].map((index) => (
                  <Container.small key={index}>
                    <img src={outlined} alt="logo" />
                  </Container.small>
                ))}
              </>
            )}
          </Container.imagesSmall>
        </Container.box>
        <Container.box>
          <Container.preview>

            <Container.cards>
              {images && images.length > 0 ? (
                <>
                  {selectedImageIndex !== null ? (
                    <img src={URL.createObjectURL(images[selectedImageIndex])} alt="Campaign" />
                  ) : (
                    <>
                      {images.length > 0 ? (
                        <img src={URL.createObjectURL(images[0])} alt="First Campaign" />
                      ) : (
                        <>
                          <p>Vizualize as imagens para sua campanha</p>
                          <img src={outlined} alt="logo" />
                        </>
                      )}
                    </>
                  )}
                  <img className="iconImage" src={delet} onClick={() => handleDeleteImage(selectedImageIndex!)} />
                </>
              ) : (
                <>
                  <p>Vizualize as imagens para sua campanha</p>
                  <img src={outlined} alt="logo" />
                </>
              )}
            </Container.cards>


          </Container.preview>
        </Container.box>
      </Container.images>
    </Container.main>
  );
};
