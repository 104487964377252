import { Container } from '../../styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import { SmallBlueButton } from '../../../../../components/common/Button';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { LabelInputFormCampaign } from '../../../../../components/common/Input';
import { CreateInstitution } from '../../../../../../application/useCases/gooders/CreateInstUseCase';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';

interface FormCreateInst {
  useCase: (dto: CreateInstitution, orgId?: number) => Promise<any>;
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Preencha o nome da Instituição'),
  document: Yup.string()
    .matches(/^\d{14}$/, 'Insira um CNPJ válido com 14 números')
    .required('Insira o seu CNPJ'),
  splitKey: Yup.string().optional(),
});

export const FormCreateInst = ({ useCase }: FormCreateInst) => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert();
  const user = useGlobalStore();

  const orgId = user.state.acl.selectedACL?.id ?? 0;
  const formik = useFormik({
    initialValues: {
      name: '',
      document: '',
      telephone: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { error } = await useCase(values, orgId);

      setLoading(false);
      if (!error) {
        setAlert('Instituição cadastrado com sucesso', 'success');
        resetForm();
      } else {
        setAlert(error, 'error');
        return;
      }
      setLoading(false);
    },
  });

  return (
    <>
      <Container.head>
        <Container.subTitle data-test="title-Institution">Informações</Container.subTitle>
      </Container.head>
      <Container.wrapper onSubmit={formik.handleSubmit}>
        <Container.content>
          <Container.form>
            <Container.input>
              <LabelInputFormCampaign
                id="name"
                name="name"
                type="text"
                htmlFor="name"
                placeholder="Instituição"
                aria-labelledby="name"
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
              >
                Nome
              </LabelInputFormCampaign>
            </Container.input>
            <Container.input>
              <LabelInputFormCampaign
                htmlFor="document"
                id="document"
                name="document"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.document}
                placeholder="CNPJ"
                aria-labelledby="document"
                error={
                  formik.touched.document && formik.errors.document
                    ? formik.errors.document
                    : undefined
                }
              >
                CNPJ
              </LabelInputFormCampaign>
            </Container.input>
            <Container.input>
              <LabelInputFormCampaign
                htmlFor="telephone"
                id="telephone"
                name="telephone"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.telephone}
                placeholder="Telefone"
                aria-labelledby="telephone"
                error={
                  formik.touched.telephone && formik.errors.telephone
                    ? formik.errors.telephone
                    : undefined
                }
              >
                Telefone
              </LabelInputFormCampaign>
            </Container.input>
          </Container.form>
        </Container.content>
        <SmallBlueButton type="submit" loading={loading}>
          Salvar
        </SmallBlueButton>
      </Container.wrapper>
    </>
  );
};
