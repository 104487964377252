import styled from "styled-components";

const Container__Box = styled.div`
	display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 16px;
  position: relative;
`;

const Container__Card = styled.div`
	display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  align-items: flex-start;
  box-shadow: 0px 2px 10px 0px rgba(73, 80, 87, 0.16);
  border-radius: 8px;
`;

const Container__Row= styled.div`
  display: flex;
  flex-direction: row;
`;
const Container__Text = styled.div`
  p {
    line-height: 24px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
  }
  span {
    font-weight: 400;
    color: #4E545C;
    font-size: 14px;
  }
`;

const Container__Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 8px;
  gap: 8px;
  .icons {
    padding: 5px; 
    border-radius: 4px;
  }
`;

const Container__Pagination = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  span {
    cursor: pointer;

    &.active {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
      padding: 6px 12px;
      border-radius: 4px;
    }
  }
`;

export const Container = {
	box: Container__Box,
  card: Container__Card,
  row: Container__Row,
  text: Container__Text,
  icon: Container__Icon,
  pagination: Container__Pagination,
};

