import { HttpClient } from '../../HttpClient';
import { Institution } from './ListInstitutionsUseCase';

export async function EditInstitutionsUseCase(institution: Institution) {
  return await HttpClient<Institution, void>(
    'put',
    `/users/gooders/institutions/${institution.id}`,
    institution
  );
}
