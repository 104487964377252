import styled from 'styled-components';
import { CreatePaymentOrderPayloadPix } from '../../../../../../../../../../application/useCases/checkout/CreatePaymentOrder';
import { BlueButton } from '../../../../../../../../../components/common/Button';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  payload: CreatePaymentOrderPayloadPix;
  children: ReactNode;
}

export const PayPix = ({ payload, children }: Props) => {
  const CopyPaymentLinkToClipboard = () => {
    navigator.clipboard.writeText(payload.qrCodePaymentLink);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding="0 1rem"
      height="100%"
    >
      <QrCodeStyle width={'400px'} src={payload.qrCodeImage} />

      <BlueButton loading={false} onClick={CopyPaymentLinkToClipboard}>
        Copiar pix para pagamento
      </BlueButton>

      {children}
    </Box>
  );
};

const QrCodeStyle = styled.img`
  width: 80%;
  max-width: 400px;
`;
