import { useEffect, useState } from 'react';
import { Container } from '../../../../styles';
import { useFormik } from 'formik';
import { useGlobalStore } from '../../../../../../../../infra/state/GlobalStore';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { SmallBlueButton } from '../../../../../../../components/common/Button';
import * as Yup from 'yup';
import { EditAddressUseCase } from '../../../../../../../../application/useCases/user/EditAddressUseCase';
import { SelectInput } from '../../../../../../../components/common/SelectInput';
import { selectOptionsState } from '../../../../../../../components/common/SelectInput/components/SelectOptionsState';

const validationSchema = Yup.object().shape({
  state: Yup.string().required('Informe seu estado'),
  city: Yup.string().required('Informe sua cidade'),
});

export const FormAdress = () => {
  const users = useGlobalStore();
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const { values, touched, errors, handleBlur, handleChange, handleSubmit, setValues, dirty } = useFormik({
    initialValues: {
      city: users.state.user?.address.city ?? '',
      state: users.state.user?.address.state ?? '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { error } = await EditAddressUseCase(values)
      if (error) {
        setAlert(error, 'error');
      } else {
        setAlert('Alterações salvas com sucesso!', 'success');
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (users.state.user) {
      setValues({
        city: users.state.user?.address.city,
        state: users.state.user?.address.state,
      });
    }
  }, [users.state.user]);

  return (
    <Container.form onSubmit={handleSubmit}>
      <Container.input>
        <LabelInputFormCampaign
          htmlFor="city"
          id="city"
          name="city"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          placeholder="Cidade"
          aria-labelledby="city"
          error={touched.city && errors.city ? errors.city : undefined}
        >
          Cidade *
        </LabelInputFormCampaign>
        <SelectInput
          htmlFor="state"
          id="state"
          name="state"
          options={[...selectOptionsState]}
          onChange={handleChange}
          value={values.state}
          onBlur={handleBlur}
          aria-labelledby="state"
          error={touched.state && errors.state ? errors.state : undefined}
        >
          Estado *
        </SelectInput>
      </Container.input>

      <SmallBlueButton type="submit" loading={loading} disabled={!dirty}>
        Salvar
      </SmallBlueButton>
    </Container.form >
  );
}

