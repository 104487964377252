import { HttpClient } from "../../HttpClient";


export interface apagar {
  checkoutUrl: string;
  OrderStatus: {
    status: string;
  };
  createdAt: string;
  amount: number;
  Campaigns: {
    id: string;
    title: string;
  };
}

export interface TransactionsMoney {
  id: string,
  campaign: {
    title: string
  },
  status: string,
  gooders: number,
  createdAt: string;
  amount: number;
}

export async function UserTransactionsMoney() {

  const response = await HttpClient<undefined, { data: TransactionsMoney[] }>('get', '/orders/payments');
  return response;
  }

