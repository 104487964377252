import styled from "styled-components";
import { theme } from "../../../themes/blue"

const Container__Main = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  height: auto;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 100px;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;
const Container__Box = styled.main`
  background: ${(props) => props.theme.colors.backgroundGray};
  color: ${(props) => props.theme.colors.primary};
  padding: 70px 40px 15px;
  @media (max-width: ${theme.media.laptop}) {
    padding: 70px 20px 150px;
  }
`;

const Container__Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0 16px;
  @media (max-width: ${theme.media.mobileSmall}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
  }
`;

const Container__Title = styled.h1`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.x};
  line-height: 24px;
`;

const Container__SubTitle = styled.h1`
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding-bottom: 8px;
`;

const Container__Button = styled.div`
  display: flex;
  gap: 16px;
  min-width: 200px;
  @media (max-width: ${theme.media.laptop}) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${theme.media.mobileSmall}) {
    flex-direction: column;
  }
`;

const Container__Table = styled.div`
  width: 100%;
  @media (max-width: ${theme.media.mobile}) {
    display: none;
}
`;

const Container__Mobile = styled.div`
  display: none;
  width: 100%;
@media (max-width: ${theme.media.mobile}) {
  display: initial;
}
`;


export const Container = {
  main: Container__Main,
  box: Container__Box,
  title: Container__Title,
  header: Container__Header,
  subTitle: Container__SubTitle,
  button: Container__Button,
  table: Container__Table,
  mobile: Container__Mobile
};



