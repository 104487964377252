import { HttpClient } from "../../HttpClient";

export interface DashboardPayload {
    itemOrders: ItemsOrders[]
    vacancyOrders: VacancyOrders[]
    total_active_campaigns: string;
    total_volunteers_subscribed: string;
    total_campaigns_money_goal: string;
    total_donations_accumulated: string;
}

export interface ItemsOrders {
  id: number,
  createdAt: string,
  campaignTitle: string,
  itemName: string,
  quantity: number,
  orderStatus: string,
  name: string
}

export interface VacancyOrders {
  id: number,
  createdAt: string,
  campaignTitle: string,
  quantityHours: number,
  orderStatus: string,
  vacancyId: number,
  name: string,
  vacancyTitle: string,
  startDate: string,
  endDate: string,
}


  export async function InstitutionDahsboardUseCase(instId: number) {
    const response = await HttpClient<void, { data: DashboardPayload }>('get', `/analytics/institution/${instId}`);
    return response;
  }
