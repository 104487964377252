import { Container } from '../PaymentMethod/styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import padlock from '../../../../../../../assets/icons/padlock.svg';
import { useContext, useEffect, useState } from 'react';
import { CheckOutContext } from '../../../../../../../../infra/config/CheckOut';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { DataMethodService } from './service';

export const validationSchema = Yup.object().shape({
  number: Yup.string()
    .test('creditCard', 'Numero inválido', DataMethodService.validateCreditCardNumber)
    .max(16, 'O número do cartão deve conter exatamente 16 dígitos numéricos!')
    .required('Campo obrigatório!'),
  holderName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Digite apenas letras no nome.')
    .required('Campo obrigatório'),
  expDate: Yup.string().matches(
    /^([01]?[0-9]|1[0-2])\/(0[0-9]|[1-9][0-9])$/,
    'Data inválida, preencha o campo seguindo o padrão MM/AA '
  ),
  cvv: Yup.string().matches(/^\d{3,4}$/, 'Somente numeros são aceitos!'),
});

export interface CardData {
  number: string;
  holderName: string;
  expMonth: number;
  expYear: number;
  cvv: string;
}

export const DataMethod = () => {
  const { dataMethod, setDataMethod } = useContext(CheckOutContext);

  const formik = useFormik({
    initialValues: {
      number: '',
      holderName: '',
      cvv: '',
      expDate: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const { expDate, ...rest } = values;

      const splitedExpDate = DataMethodService.splitMonthAndYearOfCreditCardExpiresData(expDate);
      const cardData: CardData = {
        ...rest,
        expMonth: +splitedExpDate.month,
        expYear: +splitedExpDate.year,
      };
      setDataMethod(cardData);
    },
  });

  // Add "/" in credit card expires date
  useEffect(() => {
    if (formik.values.expDate.length === 2 && !formik.values.expDate.includes('/')) {
      formik.setFieldValue('expDate', formik.values.expDate + '/');
    }
  }, [formik.values.expDate]);

  // Set input values when user rowback steps
  useEffect(() => {
    if (dataMethod) {
      let expDate = '';

      if (dataMethod.expMonth && dataMethod.expYear) {
        expDate = dataMethod.expMonth + '/' + dataMethod.expYear;
      }

      formik.setFieldValue('number', dataMethod.number || '');
      formik.setFieldValue('holderName', dataMethod.holderName || '');
      formik.setFieldValue('expDate', expDate);
      formik.setFieldValue('cvv', dataMethod.cvv);
    }
  }, []);

  // Send values to to context on unmount component
  useEffect(() => {
    return () => {
      const formikIsValidatingInputs = formik.isValidating;
      const noErrorsOnFormikValidations = Object.keys(formik.errors).length === 0;
      if (!formikIsValidatingInputs && noErrorsOnFormikValidations) {
        formik.handleSubmit();
      }
    };
  }, []);

  return (
    <Container.box onSubmit={formik.handleSubmit}>
      <Container.wrapper>
        <Container.inputs>
          <LabelInputFormCampaign
            htmlFor="number"
            id="number"
            name="number"
            type="text"
            placeholder=""
            value={formik.values.number}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            aria-labelledby="Número do cartão"
            error={formik.touched.number && formik.errors.number ? formik.errors.number : undefined}
          >
            Número do cartão
          </LabelInputFormCampaign>
          <LabelInputFormCampaign
            htmlFor="holderName"
            id="holderName"
            name="holderName"
            type="text"
            placeholder=""
            value={formik.values.holderName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            aria-labelledby=" Titular"
            error={
              formik.touched.holderName && formik.errors.holderName
                ? formik.errors.holderName
                : undefined
            }
          >
            Titular
          </LabelInputFormCampaign>
          <Container.wrapper>
            <LabelInputFormCampaign
              htmlFor="expDate"
              id="expDate"
              name="expDate"
              placeholder="01/29"
              type="text"
              value={formik.values.expDate}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              aria-labelledby="Mês"
              error={
                formik.touched.expDate && formik.errors.expDate ? formik.errors.expDate : undefined
              }
            >
              Data de vencimento
            </LabelInputFormCampaign>
            <LabelInputFormCampaign
              htmlFor="cvv"
              id="cvv"
              name="cvv"
              type="text"
              placeholder=""
              value={formik.values.cvv}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              src2={padlock}
              aria-labelledby="CVV"
              error={formik.touched.cvv && formik.errors.cvv ? formik.errors.cvv : undefined}
            >
              CVV
            </LabelInputFormCampaign>
          </Container.wrapper>
        </Container.inputs>
      </Container.wrapper>
    </Container.box>
  );
};
