import { Container } from '../../../../../components/shared/gerenciar-campanha/TableListCampaignOrg/styles';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import Table from '../../../../../components/common/Table';
import { VacancyOrders } from '../../../../../../application/useCases/dahsboard/InstitutionDashboardUseCase';
import { useState } from 'react';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import { useGlobalStore } from '../../../../../../infra/state/GlobalStore';
import { StatusVacanciesUseCase } from '../../../../../../application/useCases/donation/StatusVacanciesUseCase';
import { ModalConfirm } from '../../../../../components/common/ModalConfirm';
import { MyDate } from '../../../../../../application/utils';
import { CardData, CardsTable } from '../../../../common/CardsTable';
import { ModalEditStatus } from '../TableItems/components/ModalEditStatus';

export const VacanciesTableDahsboard = ({ data }: { data: VacancyOrders[] | undefined }) => {
  const { setAlert } = useAlert();
  const users = useGlobalStore();
  const statusOptions = ['PENDENTE', 'CANCELADO', 'SUCESSO'];
  const [vacanciesDonation, setVacanciesDonation] = useState<VacancyOrders[]>(data || []);
  const [renderConfirmModal, setRenderConfirmModal] = useState(false);
  const [renderEditStatusModal, setRenderEditStatusModal] = useState(false);
  const [idOfOrderToChangeStatus, setIdOfOrderToChangeStatus] = useState({
    id: 0,
    newStatus: '',
  });

  async function updateStatus(status: typeof idOfOrderToChangeStatus) {
    if (status.newStatus !== 'CANCELADO' && status.newStatus !== 'SUCESSO') {
      setAlert('Selecione um status válido!', 'error');
      return;
    }

    const instId = users.state.acl.selectedACL?.id;
    if (!instId) {
      return;
    }
    const vacancieId = status.id;
    const { error } = await StatusVacanciesUseCase(status.newStatus, instId, vacancieId);

    if (!error) {
      setAlert('Status atualizado com sucesso!', 'success');
      setVacanciesDonation((data) =>
        data.map((data) => {
          if (data.id === status.id) {
            data.orderStatus = status.newStatus;
          }
          return data;
        })
      );
    } else {
      setAlert(error, 'error');
    }
  }

  function onCloseConfirmModal(result: boolean) {
    if (result) {
      updateStatus(idOfOrderToChangeStatus);
    }
    setRenderConfirmModal(false);
  }

  async function handleStatusChange(event: SelectChangeEvent<any>, params: GridCellParams) {
    const value = event.target.value as string;
    const id = params.row.id as number;

    setIdOfOrderToChangeStatus({ id, newStatus: value });
    setRenderConfirmModal(true);
  }

  const columns: GridColDef[] = [
    { field: 'Usuário', headerName: 'Usuário', minWidth: 216, flex: 1, headerClassName: 'table-header', },
    { field: 'Campanha', headerName: 'Campanha', minWidth: 240, flex: 1, headerClassName: 'table-header', },
    { field: 'Voluntariado', headerName: 'Voluntariado', minWidth: 200.3, flex: 1, headerClassName: 'table-header', },
    { field: 'Quantidade de horas', headerName: 'Horas', minWidth: 100, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center' },
    {
      field: 'Data', headerName: 'Data', minWidth: 180, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center'
    },
    {
      field: 'Status', headerName: 'Status', minWidth: 120, flex: 1, headerClassName: 'table-header', headerAlign: 'center', align: 'center',
      renderCell: (params) => {
        const selectedValue = params.value || '';
        return (
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <label id={`status-label-${params.row.id}`}>
                <Select
                  className="select"
                  labelId={`status-label-${params.row.id}`}
                  value={selectedValue}
                  onChange={(event) => handleStatusChange(event, params)}
                  sx={{
                    '& fieldset': {
                      border: 'none',
                      color:
                        params.row['Status:'] === 'SUCESSO'
                          ? '#01742D'
                          : params.row['Status:'] === 'PENDENTE'
                            ? '#ecd500'
                            : params.row['Status:'] === 'CANCELADO'
                              ? '#C31526'
                              : 'inherit',
                    },
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </label>
            </FormControl>
          </Box>
        );
      },
    },
  ];

  const rows =
    (data?.map((item: VacancyOrders, index: number) => ({
      index: index,
      id: item.id,
      Usuário: item.name,
      Campanha: item.campaignTitle,
      Status: item.orderStatus,
      'Quantidade de horas': item.quantityHours,
      Voluntariado: item.vacancyTitle,
      Data: MyDate.formatISOString(item.startDate) + "-" + MyDate.formatISOString(item.endDate),
    })) ?? [])
      .sort((a, b) => a['Usuário'].localeCompare(b['Usuário']));

  const cardData: CardData[] = (data ?? []).map((item: VacancyOrders) => ({
    id: item.id,
    title: [
      'Usuário',
      'Campanha',
      'Voluntariado',
      'Quantidade de horas',
      'Status',
      'Data',
    ],
    value: [
      item.name,
      item.campaignTitle,
      item.vacancyTitle,
      item.quantityHours,
      item.orderStatus,
      MyDate.formatISOString(item.startDate) + "-" + MyDate.formatISOString(item.endDate)
    ],
  }));

  function IconStatus(id: number) {
    setIdOfOrderToChangeStatus({ id, newStatus: '' });
    setRenderEditStatusModal(true);
  }

  return (
    <>
      <Container.main>
        <Container.title>Voluntariado</Container.title>
        <Container.table>
          <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={5} />
        </Container.table>
        <Container.mobile>
          <CardsTable cardData={cardData} IconStatus={IconStatus} />
        </Container.mobile>
        {renderConfirmModal && (
          <ModalConfirm
            title="Deseja atualizar o status?"
            content="Uma vez que o status for atualizado não será possível desfazer essa ação!"
            rightButtonText="Atualizar"
            onClose={onCloseConfirmModal}
          />
        )}
        {renderEditStatusModal && (
          <ModalEditStatus
            onClose={(status) => {
              setRenderEditStatusModal(false);
            }}
            idOfOrderToChangeStatus={idOfOrderToChangeStatus}
            updateStatus={updateStatus}
          />
        )}
      </Container.main>
    </>
  );
};
