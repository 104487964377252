import axios from 'axios';

const fetchAddressFromCep = async (cep: string) => {
  try {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('O servidor retornou um status diferente de 200 OK.');
    }
  } catch (error) {
    throw error;
  }
};

export default fetchAddressFromCep;
