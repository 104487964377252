import styled from 'styled-components';
import { theme } from '../../../../../../../themes/blue';

const Container__Img = styled.div`
  width: 60%;
  display: flex;
  ul {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
  img {
    object-fit: cover;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    @media (max-width: ${theme.media.mobileSmall}) {
      max-width: 40px;
      max-height: 40px;
    }
  }
  @media (max-width: ${theme.media.monitor}) {
    width: 100%;
  }
`;

export const Container = {
  img: Container__Img,
};
