import { useEffect, useState } from 'react';
import { Container } from '../../../../styles';
import { useFormik } from 'formik';
import { useGlobalStore } from '../../../../../../../../infra/state/GlobalStore';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { SmallBlueButton } from '../../../../../../../components/common/Button';
import * as Yup from 'yup';
import { ChangePasswordUseCase } from '../../../../../../../../application/useCases/user/ForgetPasswordUseCase';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Informe uma senha'),
  newPassword: Yup.string().min(4, 'A senha deve ter no mínimo 4 caracteres').required('Informe uma nova senha'),
  confirmPassword: Yup.string().min(4, 'A senha deve ter no mínimo 4 caracteres').required('Confirme sua senha de acesso'),
})

export const NewPasswordForm = () => {
  const users = useGlobalStore();
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { values, touched, errors, handleBlur, handleChange, handleSubmit, setValues, dirty } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { error } = await ChangePasswordUseCase({
        ...values,
      });
      setLoading(false);
      if (error) {
        setAlert(error, 'error');
      } else {
        setAlert('Alterações salvas com sucesso!', 'success');
      }
    },
  });

  useEffect(() => {
    if (users.state.user) {
      setValues({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    }
  }, [users.state.user]);

  return (
    <Container.form onSubmit={handleSubmit}>
      <Container.input>
        <LabelInputFormCampaign
          id="currentPassword"
          type="password"
          name="currentPassword"
          htmlFor="currentPassword"
          placeholder="******"
          aria-labelledby="currentPassword"
          onBlur={handleBlur}
          value={values.currentPassword}
          onChange={handleChange}
          error={touched.currentPassword && errors.currentPassword ? errors.currentPassword : undefined}
        >
          Senha Atual
        </LabelInputFormCampaign>
        <LabelInputFormCampaign
          id="newPassword"
          type="password"
          name="newPassword"
          htmlFor="newPassword"
          placeholder="******"
          aria-labelledby="newPassword"
          onBlur={handleBlur}
          value={values.newPassword}
          onChange={handleChange}
          error={touched.newPassword && errors.newPassword ? errors.newPassword : undefined}
        >
          Nova Senha
        </LabelInputFormCampaign>
        <LabelInputFormCampaign
          id="confirmPassword"
          type="password"
          name="confirmPassword"
          htmlFor="confirmPassword"
          placeholder="******"
          aria-labelledby="confirmPassword"
          onBlur={handleBlur}
          value={values.confirmPassword}
          onChange={handleChange}
          error={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : undefined}
        >
          Confirmar senha
        </LabelInputFormCampaign>
      </Container.input>
      <SmallBlueButton type="submit" loading={loading} disabled={!dirty}>
        Salvar
      </SmallBlueButton>
    </Container.form>
  );
}


