import { Container } from "./styles";
import processing from "../../../assets/icons/processing.svg";
import { SmallBlueButton } from "../../../components/common/Button";
import { useState } from "react";

export default function ReturnPay() {

	const [isLoading, setIsLoading] = useState(false);

	const handleCloseClick = () => {
		window.close();
	};

	return (
		<Container.page>
			<Container.main>
				<Container.box>
					<img src={processing} alt="sucess" />
					<Container.title>Aguarde!</Container.title>
					<Container.text>Estamos Processando a sua transação</Container.text>
					<SmallBlueButton
						onClick={handleCloseClick}
						loading={isLoading}
					>Clique aqui para voltar a página
					</SmallBlueButton>
				</Container.box>
			</Container.main>
		</Container.page>
	);
}
