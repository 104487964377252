import { HttpClient } from '../../HttpClient';

export interface GoodersDashboardDto {
  campaigns: {
    count: string;
  };
  donations: {
    count: string;
    sum: string;
  };
  users: {
    count: string;
  };
  vacancyOrders: [
    {
      id: number;
      institution: {
        name: string;
      },
      campaign: {
        title: string;
      },
      user: {
        name: string;
      },
      vacancy: {
        title: string,
        createdAt: string,
        endDate: string,
        startDate: string,
      },
      createdAt: string;
    }
  ],
  itemOrders: [
    {
      id: number,
      institution: {
        name: string;
      },
      campaign: {
        title: string;
      },
      donation: {
        quantity: number;
      },
      item: {
        name: string;
      },
      user: {
        name: string;
      },
      createdAt: string;
    }
  ]
}

export async function GoodersDashboardUseCase(inLastDays: number) {
  return await HttpClient<null, { data: GoodersDashboardDto }>(
    'get',
    `/analytics/gooders?last_days=${inLastDays}`
  );
}
