import { Container } from "./styles";
import doe_money from "../../../../../../../assets/icons/doe_money.svg";
import doe_items from "../../../../../../../assets/icons/doe_items.svg";
import doe_voluntary from "../../../../../../../assets/icons/doe_voluntary.svg";

interface Props {
	setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
	selectedCategory: string;
}

export const CategorieIcons: React.FC<Props> = ({ setSelectedCategory, selectedCategory }) => {

	const handleCategoryClick = (category: string) => {
		if (selectedCategory === category) {
			setSelectedCategory("");
		} else {
			setSelectedCategory(category);
		}
	};


	return (
		<Container.Categorie>
			<Container.Icon
				active={selectedCategory === 'money'}
				onClick={() => handleCategoryClick("money")}
			>
				<img src={doe_money} alt="doe_money" data-test="img-doe-money" />
				Arrecadação
			</Container.Icon>
			<Container.Icon
				active={selectedCategory === 'items'}
				onClick={() => handleCategoryClick("items")}
			>
				<img src={doe_items} alt="doe_items" data-test="img-doe-items" />
				Doação
			</Container.Icon>
			<Container.Icon
				active={selectedCategory === 'voluntary'}
				onClick={() => handleCategoryClick("voluntary")}
			>
				<img src={doe_voluntary} alt="doe_voluntary" data-test="img-doe-voluntary" />
				Voluntariado
			</Container.Icon>
		</Container.Categorie>
	);
};
