import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Container } from '../../styles';
import { useAlert } from '../../../../../../infra/config/AlertContext/useAlert';
import Table from '../../../../../components/common/Table';
import {
  ListHash,
  ListHashUseCase,
} from '../../../../../../application/useCases/gooders/ListHashUseCase';
import { CardData, CardsTable } from '../../../../../components/common/CardsTable';
import { MyDate } from '../../../../../../application/utils';

export const TableHash = () => {
  const [hash, setHash] = useState<ListHash[]>([]);
  const { setAlert } = useAlert();

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await ListHashUseCase();
      if (!error && payload && Array.isArray(payload.data)) {
        setHash(payload.data);
      } else {
        setAlert('Não foi possível obter os hashes', 'error');
      }
    };
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Hash Gerados',
      minWidth: 350,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'gooders',
      headerName: 'Gooders',
      minWidth: 50,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'createdAt',
      headerName: 'Data',
      minWidth: 70,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'redeemedAt',
      headerName: 'Resgate',
      minWidth: 70,
      flex: 1,
      headerClassName: 'table-header',
    },
    {
      field: 'institution',
      headerName: 'Instituição',
      minWidth: 130,
      flex: 1,
      headerClassName: 'table-header',
    },
  ];

  const rows = hash
    ? hash.map((hash, index) => ({
        id: index,
        code: hash.code,
        gooders: hash.gooders / 100,
        createdAt: MyDate.formatISOString(hash.createdAt),
        redeemedAt: MyDate.formatISOString(hash.redeemedAt),
        institution: hash.institution.name,
      }))
    : [];

  const cardData: CardData[] = hash.map((hash) => ({
    id: hash.id,
    title: ['Hash Gerados', 'Gooders', 'Data', 'Resgate', 'Instituição'],
    value: [
      hash.code,
      hash.gooders,
      MyDate.formatISOString(hash.createdAt),
      MyDate.formatISOString(hash.redeemedAt),
      hash.institution.name,
    ],
  }));

  return (
    <>
      <Container.table>
        <Table rows={rows} columns={columns} menuIconDisplay="flex" numberRows={10} />
      </Container.table>
      <Container.mobile>
        <CardsTable cardData={cardData} />
      </Container.mobile>
    </>
  );
};
