import axios, { Axios, AxiosError, AxiosResponse } from 'axios';
import { handleLogout } from './handlers/HandleLogout';

type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

interface HttpClientResult<Q> {
  payload: Q | null;
  error: null | string;
  statusCode: number;
}

async function getNewAccessToken(): Promise<{ error: boolean }> {
  try {
    const newAxios = axios.create();
    newAxios.defaults.baseURL = process.env.REACT_APP_BACKEND;
    const response = await newAxios.get<{ data: { accessToken: string } }>('/auth', {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('refreshToken'),
      },
    });

    localStorage.setItem('accessToken', response.data.data.accessToken);
    return { error: false };
  } catch (err) {
    return { error: true };
  }
}

export async function HttpClient<T, Q>(
  httpMethod: HttpMethod,
  url: string,
  body?: T,
  isFormData: boolean = false,
  isArrayBuffer: boolean = false,
  retry: boolean = true
): Promise<HttpClientResult<Q>> {
  let payload: HttpClientResult<Q> = {
    payload: null,
    error: null,
    statusCode: 200,
  };

  const axiosDef = axios.create();
  axiosDef.defaults.baseURL = process.env.REACT_APP_BACKEND;
  axiosDef.defaults.headers.Authorization = 'Bearer ' + window.localStorage.getItem('accessToken');

  try {
    let response: AxiosResponse<Q, any>;

    if (httpMethod !== 'get') {
      response = await axiosDef[httpMethod]<Q>(url, body, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
          'Content-Type': isFormData ? 'multipart/form-data' : 'application/json; charset=utf-8',
        },
        responseType: isArrayBuffer ? 'arraybuffer' : 'json',
      });
    } else {
      response = await axiosDef.get<Q>(url, {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
          'Content-Type': isFormData ? 'multipart/form-data' : 'application/json; charset=utf-8',
        },
        responseType: isArrayBuffer ? 'arraybuffer' : 'json',
      });
    }

    payload.payload = response.data;
    payload.error = null;

    return payload;
  } catch (err) {
    const error = err as AxiosError<{ error: string }>;

    if (!error.response) {
      return {
        payload: null,
        error: 'Não foi possível realizar essa ação no momento!',
        statusCode: 500,
      };
    }

    const status = error.response.status;
    const errorMessage = error.response.data.error;

    if (status !== 401 && url !== '/auth') {
      return { payload: null, error: errorMessage, statusCode: status };
    }

    if (status !== 401 && url === '/auth' && !window.localStorage.getItem('accessToken')) {
      return { payload: null, error: errorMessage, statusCode: status };
    }

    if (!retry) {
      return { payload: null, error: errorMessage, statusCode: status };
    } else {
      const { error } = await getNewAccessToken();

      if (error) {
        handleLogout();
        return { payload: null, error: '', statusCode: status };
      } else {
        return await HttpClient<T, Q>(httpMethod, url, body, isFormData, isArrayBuffer, false);
      }
    }
  }
}
