import styled from "styled-components";

// verificar se este componente será utilizado
const Container__Categorie = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;

const Container__Search = styled.form`
width: 100%;
min-width: 280px;
  label {
    padding: 6px 0;
  border: 1px solid #E4E7EB;
  border-radius: 4px;
  width: 100%;
}
  input {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6B7786;
  border-radius: 4px;
  width: 90%;
  @media (max-width: 768px) {
    width: 85%;
  }
}
  button {
  padding: 0 6px;
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;


const Container__Box = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    
  }
`;

const Container__Icon = styled.div`
  
`;

const Container__Button = styled.button`
  padding: 8px;
  border: 1px solid #467DC0;
  border-radius: 16px;
  color: #467DC0;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  min-width: 140px;
  cursor: pointer;
  :first-child {
    background: #467DC0;
    color: #fff;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    
  }
`;

export const Container = {
	Search: Container__Search,
	Box: Container__Box,
	Icon: Container__Icon,
	Categorie: Container__Categorie,
	Button: Container__Button,
};


