import { UserTypeEnum } from "../../../domain/interfaces/User";
import { HttpClient } from "../../HttpClient";
import { SharedUseCase } from "../campaign/item/CreateItemForCampaignUseCase";

export interface ListConnectUser {
  id: number,
  name: string,
  document: string,
  telephone: string,
  members: [
    {
      username: string,
      email: string,
      userId: number,
      role: string
    }
  ]
}

interface Params {
  orgId?: number;
  institutionId?: number;
}


export class ListConnectUserInstUseCase extends SharedUseCase<
  Params,
  ListConnectUser,
  {
    payload: { data: ListConnectUser;} | null;
    error: string | null;
  }
> {
  constructor(entity: UserTypeEnum) {
    super(entity, { hasDto: false, hasParams: true });
  }

  protected override async execGooders(){
    return await HttpClient<void, { data:  ListConnectUser }>(
      'get',
      `/users/gooders/orgs/${this.params!.orgId}`
    );
  }

  protected override async execInstitution(){
    return await HttpClient<void, { data:  ListConnectUser }>(
      'get',
      `/users/gooders/institutions/${this.params!.institutionId}`
    );
  }

}

  

