import { HttpClient } from '../../HttpClient';

export interface GetReceiptDto {
  orderId: string;
}

export async function GetReceiptUseCase(orderId: string) {
  return await HttpClient<any, any>(
    'get',
    `/api/pdf/receipts/orders/${orderId}`,
    null,
    false,
    true
  );
}


