import { Container, } from '../../../../../../gooders/gerenciar-usuarios/components/TableListPartner/components/ModalEditPartner/styles';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from '../../../../../../../../infra/config/AlertContext/useAlert';
import close from '../../../../../../../assets/icons/close.svg';
import { LabelInputFormCampaign } from '../../../../../../../components/common/Input';
import { SmallBlueButton } from '../../../../../../../components/common/Button';
import { ConnectUser } from '../../../../../../../../application/useCases/gooders/ConnectUserInstUseCase';
import { SelectInput } from '../../../../../../../components/common/SelectInput';
import * as Yup from 'yup';
import { emailRegex } from '../../../../../../users/CreateAccount/components/context/formContext';
import { ConnectUserInstOrgUseCase } from '../../../../../../../../application/useCases/org/ConnectUserInstOrgUseCase';
import { useGlobalStore } from '../../../../../../../../infra/state/GlobalStore';

export const validationSchema = Yup.object().shape({
	email: Yup.string()
		.matches(emailRegex, 'Digite um email válido')
		.required('O campo de email é obrigatório'),
	role: Yup.string()
		.required('Selecione o tipo de usuário')
		.oneOf(
			['USER', 'VOLUNTEER', 'ADMIN',],
			'Selecione uma opção válida'
		),
});
interface FormCreatePatnerProps {
	onClose: () => void;
	rowId: number;
	useCase: ConnectUserInstOrgUseCase
}
export const ModalConnectUserOrg: React.FC<FormCreatePatnerProps> = ({ onClose, rowId, useCase }) => {


	const [loading, setLoading] = useState(false);
	const { setAlert } = useAlert();
	const user = useGlobalStore()

	const orgId = user.state.acl.selectedACL?.id ?? 0

	const formik = useFormik<ConnectUser>({
		initialValues: {
			email: '',
			role: 'USER',
		},
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			setLoading(true);
			const institutionId = rowId;
			const { error } = await useCase.execute({ orgId, institutionId }, values);
			if (error) {
				setAlert(error, 'error');
				setLoading(false);
				return;
			}
			setAlert('Usuário associado com sucesso!', 'success');
			resetForm();
			setLoading(false);
			onClose();
		}
	}
	);

	return (
		<>
			<Container.box >
				<Container.modal>
					<Container.close src={close} alt="warning" onClick={onClose} />
					<Container.head>
						<Container.subTitle data-test="title-Patner">Associar Usuário</Container.subTitle>
					</Container.head>
					<Container.wrapper onSubmit={formik.handleSubmit}>
						<Container.content>
							<Container.form>
								<Container.input>
									<LabelInputFormCampaign
										id="email"
										name="email"
										type="text"
										htmlFor="email"
										placeholder="Email"
										aria-labelledby="email"
										onBlur={formik.handleBlur}
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
									>
										Email
									</LabelInputFormCampaign>
								</Container.input>
								<Container.input>
									<SelectInput
										htmlFor="role"
										id="role"
										name="role"
										value={formik.values.role}
										onChange={formik.handleChange}
										aria-labelledby="Tipo de usuário"
										options={[
											{ value: 'USER', label: 'Usuário' },
											{ value: 'VOLUNTEER', label: 'Voluntario' },
											{ value: 'ADMIN', label: 'Administrador' },
										]}
										onBlur={formik.handleBlur}
										error={formik.touched.role && formik.errors.role ? formik.errors.role : undefined}
									>
										Categoria
									</SelectInput>
								</Container.input>
							</Container.form>
						</Container.content>
						<SmallBlueButton type="submit" loading={loading}>
							Associar
						</SmallBlueButton>
					</Container.wrapper >
				</Container.modal>
			</Container.box>
		</>
	);
};
