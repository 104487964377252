import { Container } from '../login/components/LoginUser/styles';
import createaccount from '../../../assets/auth/createaccount.svg';
import background_login from '../../../assets/auth/background-login.png';
import { useNavigate } from 'react-router-dom';
import { LayoutAuth } from '../../../components/containers/LayoutAuth';
import { HeaderImg } from '../../../components/common/HeaderImg';
import { HeaderLogin } from '../../../components/common/HeaderLogin';
import { FormNewPassword } from './components/FormNewPassword';

export const NewPassword = () => {
    const navigate = useNavigate();


    return (
        <>
            <LayoutAuth background={background_login}>
                <HeaderImg imageSrc={createaccount} altText="login" />
                <Container.auth>
                    <HeaderLogin>Recuperar Senha</HeaderLogin>
                    <p>Preencha os campos abaixo para criar uma nova senha</p>
                    <FormNewPassword />
                    <Container.section>
                        <p>Fazer login?</p>
                        <span onClick={() => navigate('/login')} data-test="link-create-account">
                            Entrar
                        </span>
                    </Container.section>
                </Container.auth>
            </LayoutAuth>
        </>
    );
};
