import styled from 'styled-components';
import { theme } from '../../../themes/blue';

const Container__Box = styled.div`
 display: flex;
  gap: 8px;
  width: 100%;
  padding: 0px 25px 24px;
  @media (max-width: ${theme.media.laptop}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Container__Card = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Container = {
  box: Container__Box,
  card: Container__Card,
};

