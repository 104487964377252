import styled from 'styled-components';
import { theme } from '../../../../themes/blue';

type InputActive = {
  isActive: boolean;
};

const Container__Main = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
`;
const Container__Input = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Container__Box = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  @media (max-width: ${theme.media.mobile}) {
    flex-direction: column;
  }
`;

const Container__Inputs = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  flex-direction: column;
  h3 {
    font-weight: 300;
    line-height: 24px;
    padding-bottom: 16px;
  }
`;

const Container__Value = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Container__Types = styled.div``;

const Container__Fiedlset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const Container__FinishGoal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Container__Checkbox = styled.div`
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  margin-top: 8px;
  label {
    padding: 6px 8px;
  }
  input {
    padding: 6px;
  }
  span {
    padding-left: 8px;
    font-size: 12px;
    color: #4e545c;
  }
`;

export const Container__Header = styled.div<InputActive>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  label {
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 12px;
    background: ${(props) => (props.isActive ? props.theme.colors.red : props.theme.colors.green)};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    &:hover {
      background: ${(props) =>
        props.isActive ? props.theme.colors.hoverRed : props.theme.colors.hoverGren};
    }
  }
  input {
    display: none;
  }
`;

export const Container = {
  main: Container__Main,
  input: Container__Input,
  types: Container__Types,
  box: Container__Box,
  fieldset: Container__Fiedlset,
  inputs: Container__Inputs,
  value: Container__Value,
  finishGoal: Container__FinishGoal,
  checkbox: Container__Checkbox,
  header: Container__Header,
};

const Text__Title = styled.h3`
  font-weight: 500;
  line-height: 24px;
`;

export const Text = {
  title: Text__Title,
};

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 8px;
  font-weight: 400;
  line-height: 24px;
  &:checked::before {
    background: #467dc0;
  }
`;
