import styled from 'styled-components';
import { theme } from '../../../../../themes/blue';

const Container__Head = styled.div`
  display: flex;
  padding: 12px 40px;
  background: ${(props) => props.theme.colors.ligthBlue};
  border-radius: 8px;
  justify-content: space-between;
  max-width: 100%;
  @media (max-width: ${theme.media.monitorSmall}) {
    flex-direction: column;
    align-items: center;
    padding: 12px;
  }
`;

const Container__Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  p {
    font-size: ${(props) => props.theme.fontSize.xll};
    line-height: 32px;
  }
  h2 {
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.xxll};
    line-height: 52px;
    color: ${(props) => props.theme.colors.primary};
    text-shadow: 0px 2px 8px rgba(73, 80, 87, 0.08);
  }
  @media (max-width: ${theme.media.tablet}) {
    width: 100%;
    text-align: start;
    flex-direction: row;
    p {
      font-size: ${(props) => props.theme.fontSize.md};
      padding-right: 8px;
    }
    h2 {
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }
`;

const Container__HeaderRight = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding: 12px 32px;
  img {
    border-radius: 50%;
  }
  @media (max-width: ${theme.media.mobile}) {
  padding: 8px 0;
  position: relative;
  width: 100%;
  .linkSettings {
    position: absolute;
    right: 0;
  }  
  }
`;

const Container__TextUser = styled.div`
  color: ${(props) => props.theme.colors.primary};
  div {
    display: flex;
    gap: 8px;
    padding-top: 16px;
    p {
      font-weight: 500;
      font-size: ${(props) => props.theme.fontSize.smX};
      line-height: 18px;
    }
  }
  h4 {
    font-weight: 700;
    line-height: 24px;
    font-size: ${(props) => props.theme.fontSize.md};
  }

`;

export const Container = {
  head: Container__Head,
  text: Container__Text,
  textUser: Container__TextUser,
  headerRight: Container__HeaderRight,
};
