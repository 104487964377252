import { HttpClient } from "../../HttpClient";

export interface ListMembersInst {
  username: string,
  email: string,
  userId: number,
  role: string,
  id: number
}

  export async function ListMemberInstUseCase(institutionId: number) {
    const response = await HttpClient<void, { data: ListMembersInst[] }>(
      'get', 
      `/users/institutions/${institutionId}/members`
      );
    return response;
  }

  