import { HttpClient } from "../../HttpClient";


export async function DeleteMemberGoodersUseCase(memberGoodersId: number,)
{
  return await HttpClient(
    'delete',
    `/users/gooders/members/${memberGoodersId}`
    );
}
