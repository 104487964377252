import { Container, Content } from './styles';
import { LabelInput } from '../../../../../../../components/common/Input';
import email from '../../../../../../../assets/icons/email.svg';
import eyes from '../../../../../../../assets/icons/eyes.svg';
import padlock from '../../../../../../../assets/icons/padlock.svg';
import { useForm } from '../../../context/formContext';

export const FormAccount = () => {
  const { formik } = useForm();

  if (formik) {
    return (
      <Container.form>
        <Content.acess>
          <Content.form>
            <LabelInput
              id="name"
              name="name"
              type="text"
              htmlFor="name"
              aria-labelledby="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder="Digite seu nome"
              error={formik.handleRenderError('name')}
            >
              Nome
            </LabelInput>
          </Content.form>
        </Content.acess>

        <Content.acess>
          <Content.form>
            <LabelInput
              id="email"
              name="email"
              type="text"
              src1={email}
              htmlFor="email"
              placeholder="Email"
              aria-labelledby="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.handleRenderError('email')}
            >
              Email
            </LabelInput>
          </Content.form>
        </Content.acess>
        <Content.acess>
          <Content.form>
            <LabelInput
              src2={eyes}
              src1={padlock}
              id="password"
              type="password"
              name="password"
              htmlFor="password"
              placeholder="******"
              aria-labelledby="password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.handleRenderError('password')}
            >
              Senha
            </LabelInput>
          </Content.form>
        </Content.acess>
        <Content.acess>
          <Content.form>
            <LabelInput
              src2={eyes}
              src1={padlock}
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              htmlFor="confirmPassword"
              placeholder="******"
              aria-labelledby="confirmPassword"
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.handleRenderError('confirmPassword')}
            >
              Confirme sua senha
            </LabelInput>
          </Content.form>
        </Content.acess>
        <Container.checkBox>
          <input
            id="isCheckboxChecked"
            type="checkbox"
            aria-labelledby="terms"
            data-test="checkbox-accept-terms"
            checked={formik.values.isCheckboxChecked}
            onChange={formik.handleChange}
          />
          <label htmlFor="isCheckboxChecked">
            Aceito os <span>termos e condições</span>
          </label>
        </Container.checkBox>
      </Container.form>
    );
  }
  return <></>;
};
