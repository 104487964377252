import styled from "styled-components";


const Container__Acl = styled.div`
justify-content: space-between;
border-radius: 4px;
padding: 8px;
width: 100%;
position: relative;
border: 1px solid ${props => props.theme.colors.primary};
z-index: 1;
h2 {
  position: absolute;
  top: -13px;
  background: ${props => props.theme.colors.white};
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
}
p {
  font-size: ${props => props.theme.fontSize.sm};
  line-height: 20px;
}
img { 
  padding-left: 32px;
}
`;

const Container__Header = styled.div`
display: flex;
justify-content: space-between;
padding: 8px 0;
p {
  display: flex;
  gap: 16px;
  font-size: ${props => props.theme.fontSize.md};
}
img {
  padding: 0 0 0 8px !important;
  cursor: pointer;
}
`;


const Container__List = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
  position: absolute;
  width: 101%;
  background:  ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 4px;
  left: -1px;
  padding: 8px;
`;

const Container__Inst = styled.div`
	border-radius: 4px;
	display: flex;
p {
  font-weight: 600;
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 20px;
	}
`;

const Container__Box = styled.div`
	background:  ${props => props.theme.colors.white};
ul {
  gap: 4px;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: flex-start !important;
  justify-content: flex-start;
  width: 100%;
}
li {
  font-size: ${props => props.theme.fontSize.smX};
  display: flex;
  text-align: start;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  img {
    padding: 0 8px 0 0;
  }
  cursor: pointer;
  :hover {
    font-weight: 600;
    border-bottom: 1px solid ${props => props.theme.colors.orange};
  }
}
`;





export const Container = {
  acl: Container__Acl,
  header: Container__Header,
list: Container__List,
inst: Container__Inst,
box: Container__Box,
}