import { ReactNode } from 'react';
import { Container } from './styles';

interface Props {
  children: ReactNode;
  background: string;
}

export const LayoutAuth = ({ children, background }: Props) => {
  return (
    <>
      <Container.main background={background} className="background-image">
        <Container.box>{children}</Container.box>
      </Container.main>
    </>
  );
};
