import { useState } from "react";
import { _TextAreaFormCampaign } from "./styles";
import { StyledComponent } from "styled-components";


interface TextAreaProps {
	htmlFor: string;
	id: string;
	children?: React.ReactNode;
	name: string;
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	value?: string;
	placeholder?: string;
	"aria-labelledby": string;
	required?: boolean;
	maxLength?: number;
	rows?: number;
	error?: string;
	onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
	StyledInput: StyledComponent<"textarea", any, any, never>
}

export const TextAreaCampaign =
	({ id, htmlFor, children, value,
		onChange, name, placeholder, StyledInput, error, onBlur }: TextAreaProps) => {

		return (
			<StyledInput>
				<label htmlFor={htmlFor} >
					<textarea
						id={id}
						name={name}
						placeholder={placeholder}
						onChange={onChange}
						value={value}
						aria-labelledby={htmlFor}
						onBlur={onBlur}
					/>
					<span>{children}</span>
				</label>
				<div className="error">{error}</div>
			</StyledInput>
		);
	};

export const TextArea = ({
	id,
	htmlFor,
	children,
	name,
	value,
	maxLength,
	rows,
	onChange,
	placeholder,
	error,
	onBlur,
	StyledInput = _TextAreaFormCampaign,
}: Omit<TextAreaProps, "StyledInput"> & {
	StyledInput?: StyledComponent<"textarea", any, any, never>
}) => {

	const [charCount, setCharCount] = useState(value?.length || 0);
	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const inputValue = event.target.value;
		setCharCount(inputValue.length);
		onChange(event);
	};

	return (
		<StyledInput>
			<label htmlFor={htmlFor} >
				<textarea
					id={id}
					name={name}
					placeholder={placeholder}
					onChange={handleInputChange}
					value={value}
					maxLength={maxLength}
					rows={rows}
					aria-labelledby={htmlFor}
					onBlur={onBlur}
				/>
				<span>{children}</span>
			</label>
			<div className="char-counter">
				{charCount}/{maxLength}
			</div>
			<div className="error">{error}</div>
		</StyledInput>
	);
};
