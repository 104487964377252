import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Glogo } from "./ui/assets/icons/Glogo";
import { NavIconLink } from "./ui/components/common/NavIconLink/NavIconLink";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {

	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		return navigate("/login");
	}, []);
	return (
		<div>
			<NavIconLink icon={<Glogo active={pathname.includes("/home")} />} redirectPath="/home" />
		</div>
	);
};

export default App;